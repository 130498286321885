import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import useOpportunity from '../../common/hooks/useOpportunity';
import { ButtonType, Layer, UseCases } from '../../common/types';
import Button from '../Button';
import Icon from '../Icon';
import styles from './LayerItem.module.scss';

type Props = {
    style?: CSSProperties;
    className?: string;
    layerDescriptor: Layer;
    hideDeleteButton?: boolean;
    locked?: boolean;
    active: boolean;
    onButtonClick: (id: string, type: ButtonType) => void;
};

const pointerCursorStyle = { cursor: 'pointer' };

const LayerItem: FunctionComponent<Props> = ({
    style,
    className,
    layerDescriptor,
    hideDeleteButton,
    locked,
    active,
    onButtonClick,
}) => {
    const { t } = useTranslation(['common']);
    const { windZone } = useOpportunity();

    // G: hack until Icons updated
    const layerName = layerDescriptor.name.split(' ')[0].toLowerCase();
    const layerNumber = layerDescriptor.name.split(' ')[1];
    const layerIconType = layerName === 'surface' ? 'system' : layerName;
    return (
        <div
            style={style}
            className={classNames(className, styles.root, {
                [styles.active]: active,
            })}
        >
            <div
                key={layerDescriptor.id + 'left'}
                className={styles.labelContainer}
                style={pointerCursorStyle}
                onClick={() =>
                    onButtonClick(layerDescriptor.id, ButtonType.label)
                }
            >
                <Icon
                    id={layerIconType}
                    size={'small'}
                    className={styles.layerIcon}
                    useCase={UseCases.inLayersMenu}
                />
                {t(layerName) + ' ' + layerNumber}
            </div>
            <div className={styles.rightIconButtons}>
                <div className={styles.windZone}>
                    <span className={styles.zone}>{windZone}</span>
                    <Icon id="wind" size="small" />
                </div>
                <Button
                    className={locked ? styles.iconButtonLocked : undefined}
                    icon={locked ? 'lock' : 'lock-open'}
                    iconSize="small"
                    iconStrokeWidth={10}
                    onClick={() =>
                        onButtonClick(layerDescriptor.id, ButtonType.lock)
                    }
                    tooltip={t('lockSurface') as string}
                />
                {!hideDeleteButton && (
                    <Button
                        className={styles.deleteIconButton}
                        icon={'trash'}
                        iconSize="small"
                        iconStrokeWidth={10}
                        onClick={() =>
                            onButtonClick(layerDescriptor.id, ButtonType.delete)
                        }
                        tooltip={t('removeSurface') as string}
                    />
                )}
            </div>
        </div>
    );
};

export default LayerItem;
