import React, { CSSProperties, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Canvas, Photo, VoidHandler } from '../../common/types';
import Header from '../Header';
import PhotoGrid from '../PhotoGrid';
import styles from './CanvasManager.module.scss';

type Props = {
    style?: CSSProperties;
    canvases: Canvas[];
    activeCanvasId: string;
    onCanvasClick: (id: string) => void;
    onAddPictureClick?: VoidHandler;
    onDeleteClick?: (id: string) => void;
};

const CanvasManager: FunctionComponent<Props> = ({
    style,
    canvases,
    activeCanvasId,
    onCanvasClick,
    onAddPictureClick,
    onDeleteClick,
}) => {
    const { t } = useTranslation(['common']);
    const canvasPictures: Photo[] = canvases.map((canvas) => ({
        id: canvas.id,
        url: canvas.imageBox.url,
        name: 'canvas ' + canvas.id,
    }));

    return (
        <>
            <Header>{t('pictures')}</Header>
            <PhotoGrid
                style={style}
                className={styles.photoGrid}
                photos={canvasPictures}
                onPictureClick={onCanvasClick}
                selectedPictureIds={[activeCanvasId]}
                columnAmount={2}
                onAddPictureClick={onAddPictureClick}
                onDeleteClick={onDeleteClick}
            />
        </>
    );
};

export default CanvasManager;
