import { createValidateData } from '../common/functions';
import { Package } from '../common/types';
import { callEigenApiWith } from './index';

const validateProductsData = createValidateData('object');

const fetchPackages = async (): Promise<
    [{ Kraftpaket: Package[]; Base: Package[] }?, Error?]
> => {
    try {
        const res = await callEigenApiWith<{
            Kraftpaket: Package[];
            Base: Package[];
        }>({
            name: 'packages',
            middleware: [validateProductsData],
        });
        return [res];
    } catch (e) {
        return [undefined, e as Error];
    }
};

export default fetchPackages;
