import { AppActionType, AppReducer } from '../../common/types';
import { defaultAppState } from '../../store/defaults';

export const appReducer: AppReducer = (state = defaultAppState, action) => {
    switch (action.type) {
        case AppActionType.APP_SET_IS_INITIALISED:
            return {
                ...state,
                isInitialised: action.isInitialised,
            };
        default:
            return state;
    }
};
