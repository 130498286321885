import { createValidateData, throttle } from '../common/functions';
import { ApiFilter, ProductsMap } from '../common/types';
import { callEigenApiWith } from './index';

const validateProductsData = createValidateData('object');

const fetchProducts = async (
    filters?: ApiFilter[]
): Promise<[ProductsMap?, Error?]> => {
    try {
        const res = await callEigenApiWith<ProductsMap>({
            name: 'products',
            middleware: [validateProductsData],
            filters,
        });
        return [res];
    } catch (e) {
        return [undefined, e as Error];
    }
};

export default throttle(fetchProducts, 5000);
