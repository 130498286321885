import React, { CSSProperties, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import useProductAvailability from '../../common/hooks/useProductAvailability';
import { Direction } from '../../common/types';
import IconWithDropdown from '../IconWithDropdown/IconWithDropdown';

type Props = {
    style?: CSSProperties;
    direction?: Direction;
    className?: string;
};

const WarningDropdown: FunctionComponent<Props> = ({
    className,
    direction = 'right',
}) => {
    const { t } = useTranslation(['warningDropdown']);
    const {
        isAllProductsActive,
        isAllProductsVisible,
        nonActiveProducts,
        nonVisibleProducts,
    } = useProductAvailability();

    return (
        <IconWithDropdown
            className={className}
            direction={direction}
            isOpen={true}
            buttonType="sales"
            iconId="info"
        >
            {!isAllProductsActive && (
                <div>
                    <h3>{t('inactiveProducts')}</h3>
                    <ul>
                        {nonActiveProducts.map((product, i) => (
                            <li key={`inactiveProduct${i}`}>{product.name}</li>
                        ))}
                    </ul>
                </div>
            )}
            {!isAllProductsVisible && (
                <div>
                    <h3>{t('invisibleProducts')}</h3>
                    <ul>
                        {nonVisibleProducts.map((product, i) => (
                            <li key={`invisibleProduct${i}`}>{product.name}</li>
                        ))}
                    </ul>
                </div>
            )}
        </IconWithDropdown>
    );
};

export default WarningDropdown;
