import classNames from 'classnames';
import React, {
    CSSProperties,
    FunctionComponent,
    useEffect,
    useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ROIDropdown } from '..';
import useDesignerQuote from '../../../common/hooks/useDesignerQuote';
import useHandleSave from '../../../common/hooks/useHandleSave';
import useProductAvailability from '../../../common/hooks/useProductAvailability';
import { useUi } from '../../../common/hooks/useUi';
import { closeModal, openModal } from '../../../features/modals/modalActions';
import Button from '../../Button';
import RecalculateButton from '../../RecalculateButton';
import Stack from '../../Stack';
import WarningDropdown from '../../WarningDropdown';
import styles from './SalesMenu.module.scss';

type Props = {
    style?: CSSProperties;
    handleMountingSystemChange: (data: string) => void;
    mountingColor: string;
};

const SalesMenu: FunctionComponent<Props> = ({
    handleMountingSystemChange,
    mountingColor,
}) => {
    const { t } = useTranslation(['common']);
    const [{ resetStageZoom }] = useUi();
    const dispatch = useDispatch();

    const handleSave = useHandleSave();
    const handleSaveButtonClick = () => {
        handleSave();
    };
    const handleResetButtonClick = () => {
        resetStageZoom && resetStageZoom();
    };

    const {
        isAllProductsActive,
        isAllProductsVisible,
        isAllNonSalesActiveAndVisible,
    } = useProductAvailability();

    const quote = useDesignerQuote();

    const hasGrounding = useMemo(() => {
        return !!quote.salesforce.Products?.grounding;
    }, [quote.salesforce.Products?.grounding]);

    const [{ showRecalculateButton }] = useUi();

    const handleModalClose = () => {
        dispatch(closeModal());
    };

    useEffect(() => {
        !isAllNonSalesActiveAndVisible &&
            dispatch(
                openModal({
                    modalProps: {
                        title: t('modals:replanNecessary.title'),
                        description: t('modals:replanNecessary.description', {
                            quoteName: quote.salesforce.name,
                        }),
                        buttons: [
                            {
                                text: t(
                                    'modals:replanNecessary.cancel'
                                ) as string,
                                type: 'positive',
                                onClick: () => handleModalClose(),
                            },
                        ],
                        onModalClose: handleModalClose,
                        size: 'large',
                    },
                })
            );
    }, [isAllNonSalesActiveAndVisible]);

    return (
        <Stack orientation="horizontal" className={styles.root}>
            {resetStageZoom && (
                <Button
                    type="sales"
                    className={styles.saveButton + ' ' + styles.resetZoom}
                    onClick={handleResetButtonClick}
                >
                    {t('resetZoom')}
                </Button>
            )}

            {showRecalculateButton && (
                <RecalculateButton
                    type="sales"
                    iconStrokeWidth={8}
                    className={classNames(
                        styles.saveButton,
                        styles.RecalculateButton
                    )}
                />
            )}

            <Button
                type="sales"
                className={styles.saveButton}
                onClick={handleSaveButtonClick}
            >
                {t('common:save')}
            </Button>

            {(!isAllProductsActive || !isAllProductsVisible) && (
                <WarningDropdown
                    className={classNames(styles.warningDropdown)}
                />
            )}
            <ROIDropdown
                badgeContent={hasGrounding ? '!' : undefined}
                direction={'right'}
                handleMountingSystemChange={handleMountingSystemChange}
                mountingColor={mountingColor}
            />
        </Stack>
    );
};

export default SalesMenu;
