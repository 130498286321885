import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem } from 'semantic-ui-react';
import { hasOfferBeenPresented } from '../../../common/functions';
import useDesignerQuote from '../../../common/hooks/useDesignerQuote';
import useHandleSave from '../../../common/hooks/useHandleSave';
import useOpportunity from '../../../common/hooks/useOpportunity';
import { useUi } from '../../../common/hooks/useUi';
import useUserPermissions from '../../../common/hooks/useUserPermissions';
import {
    ObjectWithNameAndId,
    UseCases,
    VoidHandler,
} from '../../../common/types';
import Button from '../../Button';
import Header from '../../Header';
import RadioButtons from '../../RadioButtons';
import styles from './StringDrawer.module.scss';

type Props = {
    style?: CSSProperties;
    className?: string;
    stringOptions?: ObjectWithNameAndId[];
    stringValue: string;
    onStringToolClick: VoidHandler;
    onStringChange: (id: string) => void;
    onStringClick?: (id: string) => void;
};

const StringDrawer: FunctionComponent<Props> = ({
    style,
    className,
    stringOptions,
    stringValue,
    onStringToolClick,
    onStringChange,
    onStringClick,
}) => {
    const { t } = useTranslation(['common']);
    const permissions = useUserPermissions();
    const [{ isStringToolActive }] = useUi();
    const [isSaving, setIsSaving] = useState(false);
    const handleSaveFinished = () => setIsSaving(false);
    const handleSaveStarted = () => setIsSaving(true);
    const { stageName } = useOpportunity();
    const { salesforce: quote } = useDesignerQuote();
    const handleSave = useHandleSave(handleSaveFinished, handleSaveStarted);

    const shouldUpdate = !!(
        hasOfferBeenPresented(stageName!) && quote.isSyncing
    );

    const handleFinalize = () => {
        handleSave(shouldUpdate);
    };

    return (
        <List as="section" horizontal className={styles.section}>
            <ListItem>
                <div
                    className={classNames(styles.selector, className)}
                    style={style}
                >
                    <Header variant="h3">
                        <b>{t('string')}</b>-{t('selection')}
                    </Header>
                    <List as="article" horizontal className={styles.list}>
                        {permissions.canManipulateSurfaces && (
                            <ListItem>
                                <Button
                                    className={classNames(
                                        styles.buttonBasic,
                                        styles.stringTool
                                    )}
                                    iconSize="small"
                                    icon="edit-string"
                                    active={isStringToolActive}
                                    iconUseCase={UseCases.inLayersMenu}
                                    onClick={onStringToolClick}
                                    tooltip={t('string planning') as string}
                                    tooltipPosition={'top left'}
                                />
                                {stringOptions && stringValue && (
                                    <RadioButtons
                                        radioOptions={stringOptions}
                                        onChange={onStringChange}
                                        onClick={onStringClick}
                                        radioValue={stringValue}
                                        activeColorClass={
                                            isStringToolActive
                                                ? 'stringKeyColors'
                                                : undefined
                                        }
                                    />
                                )}
                            </ListItem>
                        )}
                    </List>
                </div>
            </ListItem>
            <ListItem style={{ flex: '0 1 70%' }}>
                <Header variant="h3" className={styles.mainHeader}>
                    <b>{t('string')}</b> {t('planning')}
                </Header>
            </ListItem>

            <ListItem>
                <Button
                    className={styles.finalizeButton}
                    icon="user-stringPlanner"
                    iconSize={'small'}
                    children={shouldUpdate ? t('update') : t('abschliessen')}
                    variant={'positive'}
                    onClick={handleFinalize}
                    disabled={isSaving}
                />
            </ListItem>
        </List>
    );
};

export default StringDrawer;
