import classNames from 'classnames';
import React, {
    CSSProperties,
    MouseEventHandler,
    MutableRefObject,
    useEffect,
    useState,
} from 'react';
import { doVerticesDiffer } from '../../common/functions';
import { useUi } from '../../common/hooks/useUi';
import useUserPermissions from '../../common/hooks/useUserPermissions';
import { FlatRoofModes, Point, RoofTypes, Vertices } from '../../common/types';
import OrientationBox from '../OrientationBox';
import VertexPoint from '../VertexPoint';
import {
    coordinatesToTransformMatrix3d,
    createDefaultVertices,
    createVertexIdFromIndex,
    dimensionsToVertices,
    getVertexIndexFromId,
    moveVertex,
    verticesToCoordinates,
    verticesToTransform,
} from './functions';
import styles from './SurfaceElement.module.scss';

type Props = {
    style?: CSSProperties;
    class?: string;
    offset?: Point;
    width: number;
    height: number;
    gutter: number;
    vertices?: Vertices;
    editable?: boolean;
    active?: boolean;
    showGrid?: boolean;
    onVerticesChange?: (vertices: Vertices) => void;
    perspectiveRef?: MutableRefObject<any>;
    environmentScale?: number;
    onMouseMove?: MouseEventHandler<HTMLDivElement>;
    onClick?: MouseEventHandler<HTMLDivElement>;
    roofType?: RoofTypes;
    flatRoofMode?: FlatRoofModes;
    azimuth?: number;
    children?: React.ReactNode;
};

const renderVertices = (
    vertices: Vertices,
    environmentScale = 1,
    onMouseDown?: MouseEventHandler
) =>
    vertices.map(([x, y], i) => (
        <VertexPoint
            id={createVertexIdFromIndex(i)}
            key={i.toString()}
            onMouseDown={onMouseDown}
            x={x}
            y={y}
            scale={environmentScale}
        />
    ));

const SurfaceElement: React.FC<Props> = ({
    style,
    width,
    height,
    gutter,
    offset = { x: 0, y: 0 },
    children,
    vertices,
    editable,
    active,
    onVerticesChange,
    perspectiveRef,
    showGrid = true,
    environmentScale = 1,
    onMouseMove,
    onClick,
    roofType,
    azimuth,
}) => {
    const [sourceVertices, setSourceVertices] = useState<Vertices>();
    const [editedVertices, setEditedVertices] = useState(
        createDefaultVertices(vertices, width, height)
    );
    const [editedVertexIndex, setEditedVertexIndex] = useState<number>();
    const [transform, setTransform] = useState('');
    const [strokeScale, setStrokeScale] = useState({ x: 1, y: 1 });
    const [gridScaled, setGridScaled] = useState(false);

    const permissions = useUserPermissions();

    const [{ selectedPanelIndices, isRenderingScreenshot, isDrawPanelsOn }] =
        useUi();

    const isFlatRoof = roofType === 'flat';
    const persToPerc = (p: number) => 130 - (p / 100) * Math.log2(p / 100);
    const perspective = {
        x: persToPerc(width),
        y: persToPerc(height),
    };

    useEffect(() => {
        setSourceVertices(dimensionsToVertices(width, height));
    }, [width, height]);

    useEffect(() => {
        vertices && setEditedVertices(vertices);
    }, [vertices]);

    useEffect(() => {
        if (!perspectiveRef) return;
        if (!perspectiveRef.current) {
            perspectiveRef.current = verticesToTransform(
                editedVertices,
                width,
                height
            );
        } else {
            sourceVertices &&
                sourceVertices.length &&
                (perspectiveRef.current = verticesToTransform(
                    editedVertices,
                    width,
                    height
                ));
        }
    }, [perspectiveRef, editedVertices, width, height]);

    useEffect(() => {
        if (editedVertices.length < 3 || !sourceVertices) return;

        const sourceCoords = verticesToCoordinates(sourceVertices);
        const destinationCoords = verticesToCoordinates(editedVertices);

        setTransform(
            coordinatesToTransformMatrix3d(sourceCoords, destinationCoords)
        );
    }, [editedVertices, sourceVertices]);

    useEffect(() => {
        // calculation of scaling to provide stable grid thickness
        const xTopLeft = editedVertices[0][0];
        const yTopLeft = editedVertices[0][1];
        const xTopRight = editedVertices[1][0];
        const yTopRight = editedVertices[1][1];
        const xBottomLeft = editedVertices[2][0];
        const yBottomLeft = editedVertices[2][1];
        const xBottomRight = editedVertices[3][0];
        const yBottomRight = editedVertices[3][1];

        const getDistance = (x1: number, x2: number, y1: number, y2: number) =>
            Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));

        const averageW =
            (getDistance(xTopRight, xTopLeft, yTopRight, yTopLeft) +
                getDistance(
                    xBottomRight,
                    xBottomLeft,
                    yBottomRight,
                    yBottomLeft
                )) /
            2;
        const averageH =
            (getDistance(xBottomRight, xTopRight, yBottomRight, yTopRight) +
                getDistance(xBottomLeft, xTopLeft, yBottomLeft, yTopLeft)) /
            2;
        const scale = {
            x: Math.max(width / averageW / environmentScale, 0.3),
            y: Math.max(height / averageH / environmentScale, 0.3),
        };

        setStrokeScale(scale);
        setGridScaled(scale.x + scale.y > 4);
    }, [editedVertices.flat().join(), width, height, environmentScale]);

    const rootStyle: CSSProperties = {
        ...style,
        marginLeft: offset.x,
        marginTop: offset.y,
        width,
        height,
    };

    const handleVertexMouseDown = (e: React.MouseEvent) => {
        setEditedVertexIndex(getVertexIndexFromId(e.currentTarget.id));
    };

    useEffect(() => {
        if (editedVertexIndex === undefined) return;

        const handleMouseMove = (e: MouseEvent) => {
            setEditedVertices((currentVertices) => {
                const dx = e.movementX / environmentScale;
                const dy = e.movementY / environmentScale;

                return moveVertex(currentVertices, editedVertexIndex, dx, dy);
            });
        };
        const handleMouseUp = () => {
            setEditedVertexIndex(undefined);
        };
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [editedVertexIndex]);

    useEffect(() => {
        if (
            editedVertexIndex === undefined &&
            editedVertices &&
            (!vertices || doVerticesDiffer(vertices, editedVertices))
        ) {
            onVerticesChange && onVerticesChange(editedVertices);
            return;
        }
    }, [editedVertexIndex, editedVertices]);

    return (
        <div
            style={{
                ...rootStyle,
                perspectiveOrigin: `${perspective.x}% ${perspective.y}%`,
            }}
            className={classNames(styles.root, {
                [styles.flatRoof]: isFlatRoof,
                [styles.drawPanelsOn]: isDrawPanelsOn,
            })}
        >
            <div
                style={{
                    ['--stroke-scale-x' as string]: strokeScale.x,
                    ['--stroke-scale-y' as string]: strokeScale.y,
                    ['--gutter-width' as string]: !isRenderingScreenshot
                        ? gutter
                        : 0,
                    transform: transform,
                }}
                className={classNames(styles.contentWrapper, styles.surface, {
                    [styles.showGrid]: showGrid,
                    [styles.showLightGrid]: gridScaled,
                    [styles.surfaceActive]:
                        active && permissions.canSeeSurfaceOutline,
                    'active-selection': !permissions.canStringPanels
                        ? selectedPanelIndices.length
                        : 0,
                })}
                onMouseMove={onMouseMove}
                onClick={onClick}
            >
                {children}
                {active &&
                    permissions.canSeeSurfaceOutline &&
                    azimuth !== undefined &&
                    width &&
                    height && <OrientationBox azimuth={azimuth} />}
            </div>
            {editable && (
                <>
                    {renderVertices(
                        editedVertices,
                        environmentScale,
                        handleVertexMouseDown
                    )}
                </>
            )}
        </div>
    );
};

export default SurfaceElement;
