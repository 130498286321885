import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    PanelPlanningData,
    PlanningData,
    Product,
    ProductsMap,
} from '../../common/types';
import Checkbox from '../Checkbox';
import { BETA_PLATTE_MOUNTING_SYSTEM_ACCESSORY_SUPPLEMENTARY } from '../../common/constants';
import Switch from '../Switch';
import useDesignerQuote from '../../common/hooks/useDesignerQuote';
import { useTranslation } from 'react-i18next';
import {
    filterOutInvalidPanels,
    getAllPanelsFromSurfaces,
} from '../../common/functions';
import { updateMountingSystemAccessory } from '../../features/designerQuote/designerQuoteActions';
import { useAppDispatch } from '../../common/hooks';

type Props = {
    availableProducts: ProductsMap;
    planning: PlanningData | undefined;
};

const BetaPlatten: FunctionComponent<Props> = ({
    availableProducts,
    planning,
}) => {
    const { t } = useTranslation(['productsWizard', 'common']);
    const quote = useDesignerQuote();
    const availableBetaPlatte =
        availableProducts.mountingSystemAccessories?.filter(
            (product) =>
                product.supplementary ===
                BETA_PLATTE_MOUNTING_SYSTEM_ACCESSORY_SUPPLEMENTARY
        ) ?? [];

    const quoteBetaPlatte =
        quote.salesforce.Products?.mountingSystemAccessories?.find(
            (product) =>
                product.supplementary ===
                BETA_PLATTE_MOUNTING_SYSTEM_ACCESSORY_SUPPLEMENTARY
        );
    const [betaPlatteChecked, setBetaPlatteChecked] = useState(
        !!quoteBetaPlatte ?? false
    );

    const [chosenBetaPlatte, setChosenBetaPlatte] = useState<Product>(
        quoteBetaPlatte ?? availableBetaPlatte[0]
    );
    let validPanels: PanelPlanningData[] = [];
    Object.values(planning?.canvases ?? {}).forEach((canvas) => {
        validPanels = [
            ...validPanels,
            ...filterOutInvalidPanels(
                getAllPanelsFromSurfaces(Object.values(canvas.surfaces ?? {}))
            ),
        ];
    });
    const getNumberOfBetaPlattens = () => {
        let count = 0;
        validPanels.forEach((panel) => {
            if (panel.isHorizontal) {
                count += 2.5;
            } else {
                count += 3;
            }
        });
        return Math.ceil(count);
    };

    const numberOfBetaPlattens = getNumberOfBetaPlattens();
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!betaPlatteChecked || numberOfBetaPlattens === 0) {
            dispatch(
                updateMountingSystemAccessory({
                    product: null,
                    supplementary:
                        BETA_PLATTE_MOUNTING_SYSTEM_ACCESSORY_SUPPLEMENTARY,
                })
            );
        } else if (chosenBetaPlatte) {
            dispatch(
                updateMountingSystemAccessory({
                    product: {
                        ...(chosenBetaPlatte as Product),
                        quantity: numberOfBetaPlattens,
                    },
                    supplementary: chosenBetaPlatte.supplementary as string,
                })
            );
        }
    }, [betaPlatteChecked, chosenBetaPlatte, numberOfBetaPlattens]);
    return availableBetaPlatte.length > 0 ? (
        <>
            <li>
                <Checkbox
                    label={t('betaPlatte') as string}
                    onChange={(event, props) => {
                        setBetaPlatteChecked(!!props.checked);
                    }}
                    checked={betaPlatteChecked}
                />
            </li>
            {betaPlatteChecked && (
                <>
                    <li>
                        <Switch
                            switchValue={chosenBetaPlatte!.id}
                            switchOptions={(
                                availableBetaPlatte.slice(
                                    0,
                                    2
                                ) as Array<Product>
                            ).map((accessory) => ({
                                id: accessory.id,
                                name:
                                    accessory.name.split(', ')[1] ??
                                    accessory.id,
                            }))}
                            handleChange={(id) => {
                                setChosenBetaPlatte(
                                    availableBetaPlatte.find(
                                        (product) => product.id === id
                                    ) as Product
                                );
                            }}
                            variant="toggle"
                        />
                    </li>
                    {quoteBetaPlatte && (
                        <li>
                            {quoteBetaPlatte.quantity}x {quoteBetaPlatte.name}
                        </li>
                    )}
                </>
            )}
        </>
    ) : null;
};

export default BetaPlatten;
