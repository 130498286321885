import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import styles from './Badge.module.scss';

type Props = {
    className?: string;
    style?: CSSProperties;
    float?: 'corner';
    size?: 'mediumSquare';
    bold?: boolean;
    content?: React.ReactNode;
    title?: string;
};

const Badge: FunctionComponent<Props> = ({
    style,
    className,
    content,
    float,
    size,
    bold,
    title,
}) => {
    //

    return (
        <div
            title={title}
            style={style}
            className={classNames(
                styles.root,
                className,
                size && styles[size],
                {
                    [styles.float]: !!float,
                    [styles.corner]: float === 'corner',
                    [styles.bold]: bold,
                }
            )}
        >
            {content}
        </div>
    );
};

export default Badge;
