import React, { CSSProperties, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { getRightUnits } from '../../common/helpers';
import { Panel, ProductTypes } from '../../common/types';
import styles from './PlanningInfo.module.scss';

type Props = {
    style?: CSSProperties;
    totalPanelAmount: number;
    canvasPanelAmount: number;
    panel: Panel;
};

const PlanningInfo: FunctionComponent<Props> = ({
    canvasPanelAmount,
    totalPanelAmount,
    panel,
}) => {
    const { t } = useTranslation(['common']);
    const unit = getRightUnits(ProductTypes.PANEL)?.unit;

    return (
        <div className={styles.planningInfo}>
            {panel && (
                <>
                    <div className={styles.element}>
                        <span className={styles.title}>{t('current')}</span>
                        <span className={styles.value}>
                            {': '}
                            <b>{canvasPanelAmount}</b>
                            {' ' + t('at') + ' '}
                            <b>{canvasPanelAmount * panel.power!}</b> {unit}
                        </span>
                    </div>
                    <div className={styles.element}>
                        <span className={styles.title}>{t('total')}</span>
                        <span className={styles.value}>
                            {': '}
                            <b>{totalPanelAmount}</b>
                            {' ' + t('at') + ' '}
                            <b>{totalPanelAmount * panel.power!}</b> {unit}
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};

export default PlanningInfo;
