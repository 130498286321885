import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Photo, RootState, UseCases } from '../../common/types';
import PictureSelector from '../PictureSelector';

type Props = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    onClick: Function;
    selectedPhotoIds: string[];
};

const RoofPhotoSelector: FunctionComponent<Props> = ({
    onClick,
    selectedPhotoIds,
}) => {
    const photos =
        useSelector<RootState, Photo[] | undefined>(
            (state) => state.opportunity.photos
        ) || [];

    const { t } = useTranslation(['common', 'startPlanning']);
    const description =
        photos.length === 0 ? t('startPlanning:noPictures') : undefined;

    return (
        <PictureSelector
            description={description}
            photos={photos}
            onPictureClick={(photoId) => onClick(photoId)}
            selectedPictureIds={selectedPhotoIds}
            useCase={UseCases.inStartPlanningWizard}
        />
    );
};

export default RoofPhotoSelector;
