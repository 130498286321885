import {
    adjustPanel,
    isPanelOnFlatRoof,
} from '../../components/ModuleElement/functions';
import {
    Dimensions,
    InverterDependency,
    ModulePlanningData,
    MountingSystemOrientation,
    ObjectWithNameAndId,
    ObjectWithWidthAndLength,
    PanelPlanningData,
    Photo,
    RoofTypes,
} from '../types';
import { getOrientedDimensions, getPanelSpacing, sortNumeric } from './index';

export const arePanelsNeighbouring = (
    panelA: PanelPlanningData,
    panelB: PanelPlanningData,
    panelSize: Dimensions,
    roofType: RoofTypes
) => {
    const [panelAWidth, panelAHeight] = getOrientedDimensions(
        panelSize.width,
        panelSize.height,
        roofType,
        panelA.isHorizontal
    );
    const [panelBWidth, panelBHeight] = getOrientedDimensions(
        panelSize.width,
        panelSize.height,
        roofType,
        panelB.isHorizontal
    );
    return (
        // Filtering out situations when panels are definitely apart (not intersecting or "touching" sides)
        !(
            panelA.x > panelB.x + (panelBWidth + getPanelSpacing(panelB).x) ||
            panelA.y > panelB.y + (panelBHeight + getPanelSpacing(panelB).y) ||
            panelA.x < panelB.x - (panelAWidth + getPanelSpacing(panelA).x) ||
            panelA.y < panelB.y - (panelAHeight + getPanelSpacing(panelA).y)
        ) &&
        // ...and looking for matching side positions
        (panelA.x === panelB.x + (panelBWidth + getPanelSpacing(panelB).x) ||
            panelA.y ===
                panelB.y + (panelBHeight + getPanelSpacing(panelB).y) ||
            panelA.x === panelB.x - (panelAWidth + getPanelSpacing(panelA).x) ||
            panelA.y === panelB.y - (panelAHeight + getPanelSpacing(panelA).y))
    );
};

export const getNeighbouringPanels = (
    panels: PanelPlanningData[],
    sourcePanelIndex: number,
    panelSize: Dimensions,
    roofType: RoofTypes
) => {
    const result: number[] = [sourcePanelIndex];
    const findNeighbourFor = (panel: PanelPlanningData) => {
        for (const p of panels) {
            const i = panels.indexOf(p);
            if (
                !result.includes(i) &&
                arePanelsNeighbouring(p, panel, panelSize, roofType)
            ) {
                result.push(i);
                findNeighbourFor(panels[i]);
            }
        }
    };
    findNeighbourFor(panels[sourcePanelIndex]);
    return result;
};

type NeighbourInfo = {
    left: number[];
    top: number[];
    right: number[];
    bottom: number[];
    topMatch: number[];
    bottomMatch: number[];
    leftMatch: number[];
    rightMatch: number[];
};

export const getNeighbourPanelsInfo = (
    panel: PanelPlanningData,
    panels: PanelPlanningData[],
    panelSize: Dimensions,
    roofType: RoofTypes
) => {
    const [panelW, panelH] = getOrientedDimensions(
        panelSize.width,
        panelSize.height,
        roofType,
        panel.isHorizontal
    );
    const neighbouringInfo: NeighbourInfo = {
        left: [],
        top: [],
        right: [],
        bottom: [],
        topMatch: [],
        bottomMatch: [],
        leftMatch: [],
        rightMatch: [],
    };
    panels.forEach((p, i) => {
        const [pW, pH] = getOrientedDimensions(
            panelSize.width,
            panelSize.height,
            roofType,
            p.isHorizontal
        );
        const overlapsX = p.x + pW > panel.x && p.x < panel.x + panelW;
        const overlapsY = p.y + pH > panel.y && p.y < panel.y + panelH;
        const left =
            overlapsY && panel.x === p.x + pW + getPanelSpacing(panel).x;
        const right =
            overlapsY && panel.x + panelW + getPanelSpacing(panel).x === p.x;
        const top =
            overlapsX && panel.y === p.y + pH + getPanelSpacing(panel).y;
        const bottom =
            overlapsX && panel.y + panelH + getPanelSpacing(panel).y === p.y;

        const topMatch = (left || right) && panel.y === p.y;
        const bottomMatch = (left || right) && panel.y + panelH === p.y + pH;
        const leftMatch = (top || bottom) && panel.x === p.x;
        const rightMatch = (top || bottom) && panel.x + panelW === p.x + pW;

        left && neighbouringInfo.left.push(i);
        right && neighbouringInfo.right.push(i);
        top && neighbouringInfo.top.push(i);
        bottom && neighbouringInfo.bottom.push(i);
        topMatch && neighbouringInfo.topMatch.push(i);
        bottomMatch && neighbouringInfo.bottomMatch.push(i);
        leftMatch && neighbouringInfo.leftMatch.push(i);
        rightMatch && neighbouringInfo.rightMatch.push(i);
    });

    return neighbouringInfo;
};

//'x' | 'y'
enum Axis {
    x = 'x',
    y = 'y',
}

type AxisProps = {
    dimension: 'width' | 'height';
    startSide: 'left' | 'top';
    startSideMatch: 'leftMatch' | 'topMatch';
    endSide: 'right' | 'bottom';
    endSideMatch: 'rightMatch' | 'bottomMatch';
    dimensionIndex: 0 | 1;
    startSideOtherAxis: 'top' | 'left';
    endSideOtherAxis: 'bottom' | 'right';
    startSideMatchOtherAxis: 'topMatch' | 'leftMatch';
    endSideMatchOtherAxis: 'bottomMatch' | 'rightMatch';
};

const getOrientedDimensionsByAxis = (
    axis: Axis,
    width: number,
    height: number,
    roofType: RoofTypes,
    isHorizontal: boolean | undefined
) =>
    getOrientedDimensions(width, height, roofType, isHorizontal)[
        propsByAxis[axis].dimensionIndex
    ];

// Some unused methods are kept to be potentially used for further improvement of smart layout feature
const adjustEndSide =
    (
        axis: Axis,
        panels: PanelPlanningData[],
        panelW: number,
        panel: PanelPlanningData
    ) =>
    (endSidePanelIndex: number) => {
        const endSidePanel = panels[endSidePanelIndex];
        endSidePanel[axis] =
            panel[axis] + panelW + getPanelSpacing(panel)[axis];
    };
const propsByAxis: {
    x: AxisProps;
    y: AxisProps;
} = {
    x: {
        dimension: 'width',
        startSide: 'left',
        startSideMatch: 'leftMatch',
        endSide: 'right',
        endSideMatch: 'rightMatch',
        dimensionIndex: 0,
        startSideOtherAxis: 'top',
        endSideOtherAxis: 'bottom',
        startSideMatchOtherAxis: 'topMatch',
        endSideMatchOtherAxis: 'bottomMatch',
    },
    y: {
        dimension: 'height',
        startSide: 'top',
        startSideMatch: 'topMatch',
        endSide: 'bottom',
        endSideMatch: 'bottomMatch',
        dimensionIndex: 1,
        startSideOtherAxis: 'left',
        endSideOtherAxis: 'right',
        startSideMatchOtherAxis: 'leftMatch',
        endSideMatchOtherAxis: 'rightMatch',
    },
};

const adjust = (
    axis: Axis,
    panels: PanelPlanningData[],
    group: number[],
    groupStartPos: number,
    groupEndPos: number,
    containerSize: Dimensions,
    oldPanelSize: Dimensions,
    newPanelSize: Dimensions,
    neighbourInfos: NeighbourInfo[],
    roofType: RoofTypes,
    flippedOrientationPanels: number[] | undefined
) => {
    const dimension = propsByAxis[axis].dimension;

    const panelsCopy = panels.map((p) => ({ ...p }));

    // Determining whether the group is closer to the left or right side of the container (or top/bottom for Y axis)
    const isStartAligned =
        Math.abs(groupStartPos) <
        Math.abs(containerSize[dimension] - groupEndPos);

    if (!isStartAligned) {
        // Aligned from "end": Mirroring panel positions to unify the approach
        panelsCopy.forEach((p, i) => {
            if (!group.includes(i)) return;
            const containerDimension = containerSize[dimension];
            p[axis] =
                containerDimension * 0.5 -
                (p[axis] +
                    getOrientedDimensionsByAxis(
                        axis,
                        oldPanelSize.width,
                        oldPanelSize.height,
                        roofType,
                        flippedOrientationPanels?.includes(i)
                            ? !p.isHorizontal
                            : p.isHorizontal
                    ) -
                    containerDimension * 0.5);
        });
    }

    // Sorting the group by x/y
    const sortedGroup = group.sort(
        (a, b) => panelsCopy[a][axis] - panelsCopy[b][axis]
    );

    const adjustedIndices: number[] = [];

    const adjustSingle = (
        panelIndex: number,
        neighbourSide: 'startSide' | 'endSide'
    ) => {
        if (adjustedIndices.includes(panelIndex)) return;
        adjustedIndices.push(panelIndex);

        const panel = panelsCopy[panelIndex];
        const panelAxisDimension = getOrientedDimensionsByAxis(
            axis,
            newPanelSize.width,
            newPanelSize.height,
            roofType,
            panel.isHorizontal
        );
        const neighboursInfo = neighbourInfos[panelIndex];

        // Handling some edge case situation here
        if (!neighboursInfo) return;

        neighboursInfo[propsByAxis[axis][neighbourSide]].forEach(
            adjustEndSide(axis, panelsCopy, panelAxisDimension, panel)
        );
    };

    if (isStartAligned) {
        sortedGroup.forEach((pIndex) => adjustSingle(pIndex, 'endSide'));

        panels.forEach((p, i) => {
            if (!group.includes(i)) return;
            p[axis] = panelsCopy[i][axis];
        });
    } else {
        sortedGroup.forEach((pIndex) => adjustSingle(pIndex, 'startSide'));

        // Mirroring the panel back to original alignment
        panels.forEach((p, i) => {
            if (!group.includes(i)) return;
            const containerDimension = containerSize[dimension];
            const copy = panelsCopy[i];
            p[axis] =
                containerDimension * 0.5 -
                (copy[axis] +
                    getOrientedDimensionsByAxis(
                        axis,
                        newPanelSize.width,
                        newPanelSize.height,
                        roofType,
                        copy.isHorizontal
                    ) -
                    containerDimension * 0.5);
        });
    }
};

export const grouping = (
    panels: PanelPlanningData[],
    oldPanelProduct: ObjectWithWidthAndLength,
    roofType: RoofTypes
) => {
    const oldPanelSize = {
        width: oldPanelProduct.width,
        height: oldPanelProduct.length,
    };

    // "group" in this scope represents sets of panels that are consequently placed side by side
    const groupedPanelIds: number[] = [];
    const groups: number[][] = [];
    panels.forEach((panel, i) => {
        if (groupedPanelIds.includes(i)) return;

        const neighbouringPanels = getNeighbouringPanels(
            panels,
            i,
            oldPanelSize,
            roofType
        );

        groups.push(neighbouringPanels);
        groupedPanelIds.push(...neighbouringPanels);
    });

    return groups;
};

export const getOrganisedGroups = (
    groups: number[][],
    panels: PanelPlanningData[]
) => {
    const organisedGroups: number[][][] = [];
    groups.forEach((group) => {
        // Get each unique y position
        const uniqueYPositions = [
            ...new Set(group.map((panelIndex) => panels[panelIndex].y)),
        ].sort(sortNumeric);

        // Get the panel objects within the group
        const panelsInGroup = group.map((panelIndex) => panels[panelIndex]);
        const organisedGroup: number[][] = [];

        uniqueYPositions.forEach((uniqueYPosition) => {
            // Get each panel that matches the y and order them left to right
            const panelsMatchedUniqueY = panelsInGroup
                .filter((panel) => panel.y === uniqueYPosition)
                .sort((panel, oldPanel) => sortNumeric(panel.x, oldPanel.x));

            const panelIndexes = panelsMatchedUniqueY.map((panel) =>
                panels.indexOf(panel)
            );

            organisedGroup.push(panelIndexes);
        });
        organisedGroups.push(organisedGroup);
    });

    return organisedGroups;
};

export const adjustPanelsFacing = (
    modifiedSurface: ModulePlanningData,
    panelProduct:
        | (ObjectWithNameAndId & {
              photos: Photo[];
          } & ObjectWithWidthAndLength & {
                  power?: number;
                  productCraft?: string | null;
                  productType?: string | null;
                  productCategory?: string | null;
                  productBrand?: string | null;
                  frameWidth?: number;
                  dataSheetUrl?: string | null;
                  description?: string | null;
                  isActive?: boolean;
                  isSolardesignerVisible?: boolean;
                  pricebookEntryId: string;
                  price: number;
                  quantity?: number;
                  dependencies?: InverterDependency[];
                  color?: string;
                  sortOrder?: number;
                  typeOfCraft?: string | null;
                  orientation?: MountingSystemOrientation | null;
                  isOptimizer?: boolean;
                  unit?: string;
              } & { quantity?: number } & {
                  photos: Photo[];
                  openCircuitVoltage: number;
                  shortCircuitCurrent: number;
              })
        | undefined
) => {
    const organisedGroups = getOrganisedGroups(
        grouping(
            modifiedSurface.panels,
            panelProduct as ObjectWithWidthAndLength,
            isPanelOnFlatRoof(modifiedSurface.panels[0])
                ? RoofTypes.flat
                : RoofTypes.slope
        ),
        modifiedSurface.panels
    );

    const newPanels: PanelPlanningData[] = modifiedSurface.panels;

    organisedGroups.forEach((organisedGroup) => {
        for (let i = 0; i < organisedGroup.length; i++) {
            for (let j = 0; j < organisedGroup[i].length; j++) {
                newPanels[organisedGroup[i][j]] = {
                    ...adjustPanel(
                        modifiedSurface,
                        { hozIndex: j, verIndex: i },
                        modifiedSurface.panels[organisedGroup[i][j]],
                        panelProduct as ObjectWithWidthAndLength
                    ),
                };
            }
        }
    });

    return newPanels;
};

export const smartLayout = (
    containerSize: Dimensions,
    panels: PanelPlanningData[],
    oldPanelProduct: ObjectWithWidthAndLength,
    newPanelProduct: ObjectWithWidthAndLength,
    roofType: RoofTypes,
    panelsToRotate?: number[]
) => {
    const oldPanelSize = {
        width: oldPanelProduct.width,
        height: oldPanelProduct.length,
    };
    const newPanelSize = {
        width: newPanelProduct.width,
        height: newPanelProduct.length,
    };

    const groups: number[][] = grouping(panels, oldPanelProduct, roofType);

    let updatedPanels = [...panels.map((p) => ({ ...p }))];

    groups.forEach((group) => {
        let groupLeft = Infinity;
        let groupTop = Infinity;
        let groupRight = -Infinity;
        let groupBottom = -Infinity;

        const neighbourInfos: ReturnType<typeof getNeighbourPanelsInfo>[] = [];

        group.forEach((panelIndex) => {
            const panel = updatedPanels[panelIndex];
            neighbourInfos[panelIndex] = getNeighbourPanelsInfo(
                panel,
                updatedPanels,
                oldPanelSize,
                roofType
            );
            const [panelW, panelH] = getOrientedDimensions(
                oldPanelSize.width,
                oldPanelSize.height,
                roofType,
                panel.isHorizontal
            );
            groupLeft = Math.min(panel.x, groupLeft);
            groupTop = Math.min(panel.y, groupTop);
            groupRight = Math.max(panel.x + panelW, groupRight);
            groupBottom = Math.max(panel.y + panelH, groupBottom);
        });

        // rotate panels if marked for rotation and belong to the group
        updatedPanels = panelsToRotate
            ? updatedPanels.map((p, i) =>
                  group.includes(i) && panelsToRotate.includes(i)
                      ? { ...p, isHorizontal: !p.isHorizontal }
                      : p
              )
            : updatedPanels;

        adjust(
            Axis.x,
            updatedPanels,
            group,
            groupLeft,
            groupRight,
            containerSize,
            oldPanelSize,
            newPanelSize,
            neighbourInfos,
            roofType,
            panelsToRotate
        );

        adjust(
            Axis.y,
            updatedPanels,
            group,
            groupTop,
            groupBottom,
            containerSize,
            oldPanelSize,
            newPanelSize,
            neighbourInfos,
            roofType,
            panelsToRotate
        );
    });

    return updatedPanels;
};
