import { throttle, validateObjectData } from '../common/functions';
import { AccessTokenFunction, OpportunityState } from '../common/types';
import { callEigenApiWith, callSalesforceApiWithoutResponse } from './index';

const fetchOpportunity = async (p: {
    opportunityId: string;
}): Promise<[OpportunityState?, Error?]> => {
    try {
        const res = await callEigenApiWith<OpportunityState>({
            name: 'opportunities',
            middleware: [validateObjectData],
            id: p.opportunityId,
        });
        return [res];
    } catch (e) {
        return [undefined, e as Error];
    }
};

export default throttle(fetchOpportunity, 5000);

export const updateOpportunity = async (p: {
    opportunityId: string;
    payload: { [key: string]: string | number | boolean };
    accessToken: AccessTokenFunction;
}) => {
    await callSalesforceApiWithoutResponse({
        name: 'Opportunity',
        id: p.opportunityId,
        middleware: [validateObjectData],
        options: {
            method: 'PATCH',
            body: JSON.stringify(p.payload),
        },
        accessToken: p.accessToken,
    });
};
