import { Product } from '../types';
import { PYRAMID_MOUNTING_SYSTEM_SUPPLEMENTARY } from '../constants';

export const findProductById = (
    products: Product[],
    id: string
): Product | undefined => {
    return products.find((product) => product.id === id);
};

export const isPyramidMountingSystem = (product: Product): boolean => {
    return product.supplementary === PYRAMID_MOUNTING_SYSTEM_SUPPLEMENTARY;
};
