import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    dispatchAndSaveToLocalStorage,
    updateProduct,
} from '../../features/designerQuote/designerQuoteActions';
import { findProductById } from '../functions/products';
import { Product, ProductTypes, SalesforceProductsState } from '../types';
import useDesignerQuote from './useDesignerQuote';
import useProducts from './useProducts';
import { useAppDispatch } from './index';

const inverterCanHavePowerSwitch = (
    inverterId: string,
    inverterProducts: Product[]
): boolean => {
    const inverter = inverterProducts.find(
        (inverter) => inverter.id === inverterId
    );
    return !!(
        inverter &&
        !!inverter.dependencies?.some((dep) => {
            return dep.isPowerSwitch === true;
        })
    );
};

const useEmergencyPower = (sfProducts?: SalesforceProductsState) => {
    const dispatch = useAppDispatch();

    const [addEmergencyPower, setAddEmergencyPower] = useState(
        !!(
            sfProducts?.inverter &&
            sfProducts.inverter.dependencies &&
            sfProducts.inverter.dependencies.some((dep) => dep.isPowerSwitch)
        )
    );

    const [inverterProducts] = useProducts(ProductTypes.INVERTER);
    const activeInverterProduct =
        useDesignerQuote().salesforce.Products?.[ProductTypes.INVERTER];

    const emergencyPowerPossible = useMemo(
        () =>
            sfProducts?.inverter &&
            sfProducts?.battery &&
            inverterCanHavePowerSwitch(
                sfProducts?.inverter.id,
                inverterProducts ?? []
            ),
        [sfProducts?.battery, sfProducts?.inverter]
    );

    const toggleEmergencyPower = useCallback(() => {
        setAddEmergencyPower((value) => !value);
    }, []);

    // when the emergencyPower changes, change the inverter dependency
    useEffect(() => {
        if (!inverterProducts || !activeInverterProduct) return;

        if (
            inverterCanHavePowerSwitch(
                activeInverterProduct.id,
                inverterProducts ?? []
            )
        ) {
            const product = findProductById(
                inverterProducts,
                activeInverterProduct.id
            );
            if (product && product.dependencies) {
                // Take the right dependency
                const dependencies = product.dependencies.filter((dep) => {
                    if (emergencyPowerPossible && addEmergencyPower) {
                        return dep.isPowerSwitch === true;
                    }
                    return dep.isPowerSwitch === false;
                });

                dispatch(
                    dispatchAndSaveToLocalStorage(
                        updateProduct({
                            productType: ProductTypes.INVERTER,
                            product: { ...product, dependencies },
                        })
                    )
                );
            }
        }
    }, [
        emergencyPowerPossible,
        addEmergencyPower,
        inverterProducts,
        activeInverterProduct ? activeInverterProduct.id : undefined,
    ]);

    return {
        addEmergencyPower,
        emergencyPowerPossible,
        toggleEmergencyPower,
    };
};

export default useEmergencyPower;
