import lscache from 'lscache';
import { RootState } from './types';

const EXPIRATION_TIME = 20160; // 2 weeks;

export const loadState = (): RootState | undefined => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state: RootState): void => {
    try {
        const serializedState = JSON.stringify(state);
        lscache.set('state', serializedState, EXPIRATION_TIME);
    } catch (err) {
        // NO state saved yet
    }
};

export const isJSON = (string: string) => {
    try {
        JSON.parse(string);
        return true;
    } catch (e) {
        return false;
    }
};

export const saveToLocalStorage = (key: string, tosave: object): void => {
    lscache.flushExpired();
    lscache.set(
        key,
        typeof tosave === 'object' ? JSON.stringify(tosave) : tosave,
        EXPIRATION_TIME
    );
};

export const loadFromLocalStorage = <T = object | string | undefined>(
    key: string
): T => {
    const value = lscache.get(key);
    return value && isJSON(value) ? JSON.parse(value) : value;
};

export const clearFromLocalStorage = (key: string) => {
    lscache.remove(key);
};
