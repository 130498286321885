import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import useUserRole from '../../common/hooks/useUserRole';
import { UseCases, UserRoles, VoidHandler } from '../../common/types';
import BadgeRecordType from '../BadgeRecordType';
import Button from '../Button';
import Icon from '../Icon';
import RoleMenu from '../RoleMenu';
import styles from './Logo.module.scss';

type Props = {
    style?: CSSProperties;
    className?: string;
    onClick?: VoidHandler;
    onRoleClick?: VoidHandler;
    recordType?: string;
};

const Logo: FunctionComponent<Props> = ({
    style,
    onClick,
    onRoleClick,
    className,
    recordType,
}) => {
    const role = useUserRole();

    return role !== UserRoles.sales ? (
        <div
            style={style}
            className={classNames(styles.logo, className, {
                [styles.clickable]: !!onClick,
            })}
        >
            <RoleMenu>
                <Button
                    icon={'rotate-ccw'}
                    iconSize={'small'}
                    iconOnly
                    iconStrokeWidth={10}
                    onClick={onClick}
                >
                    Home
                </Button>
            </RoleMenu>
            {recordType && (
                <BadgeRecordType
                    recordType={recordType}
                    className={'BadgeInPlannersView'}
                />
            )}
        </div>
    ) : (
        <div
            style={style}
            className={classNames(styles.logo, className, {
                [styles.clickable]: !!onClick,
            })}
            onClick={onClick}
        >
            <Icon id="logo-sd" useCase={UseCases.inLogo} />
            {recordType && (
                <BadgeRecordType
                    recordType={recordType}
                    className={'BadgeInPlannersView'}
                />
            )}
        </div>
    );
};

export default Logo;
