import { callEigenApiWith } from '.';
import {
    debounce,
    parseSurfacePayload,
    validateObjectData,
} from '../common/functions';
import {
    ApiFilter,
    ModulePlanningData,
    RecordType,
    SurfacePayload,
    UserRoles,
} from '../common/types';

import { InverterResponse } from '../common/responseTypes';

/**
 * Do a call to the `inverters` endpoint to obtain recommended inverters, annual yield and total yield.
 * The `surfaces` should not be an empty array.
 */
export const getRecommendedInverters = async (p: {
    opportunityId: string;
    surfaces: ModulePlanningData[];
    panelPower: number;
    role?: UserRoles;
    recordType?: RecordType;
}) => {
    const systems: SurfacePayload[] = parseSurfacePayload(p.surfaces);

    // This will not happen but is an security check for future developers
    if (systems.length === 0)
        throw new Error('Call nicht möglich: Keine Panels auf der Modulfläche');

    const payload = {
        opportunity_id: p.opportunityId,
        panel_power: p.panelPower,
        systems,
    };

    const filters: ApiFilter[] = [];
    p.role && filters.push({ name: 'role', value: p.role });
    p.recordType &&
        filters.push({ name: 'recordType', value: p.recordType.id });

    return await callEigenApiWith<InverterResponse>({
        name: 'inverters',
        middleware: [validateObjectData],
        options: { method: 'POST', body: JSON.stringify(payload) },
        filters,
    });
};

export const debouncedGetRecommendedInverters = debounce(
    getRecommendedInverters,
    4000
);
