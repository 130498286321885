import classNames from 'classnames';
import React, {
    CSSProperties,
    FocusEventHandler,
    FunctionComponent,
    useCallback,
} from 'react';
import { Input as SemanticInput } from 'semantic-ui-react';
import {
    HtmlInputrops,
    SemanticShorthandItem,
} from 'semantic-ui-react/dist/commonjs/generic';
import { InputSize, UseCases } from '../../common/types';
import styles from './Input.module.scss';

type Props = {
    className?: string;
    onChange?: (event: React.ChangeEvent, data: any) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>, data: any) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>, data: any) => void;
    disabled?: boolean;
    placeholder?: string;
    defaultValue?: string | number;
    resetToDefaultOnBlur?: boolean;
    size?: InputSize;
    id?: string | number;
    error?: boolean;
    label?: any;
    labelPosition?: any;
    type?: 'text' | 'number' | 'int' | string;
    input?: SemanticShorthandItem<HtmlInputrops>;
    value?: string;
    maxLength?: number;
    style?: CSSProperties;
    useCase?: UseCases;
};

const Input: FunctionComponent<Props> = ({
    className,
    onChange,
    onFocus,
    onBlur,
    disabled = false,
    placeholder,
    defaultValue,
    resetToDefaultOnBlur,
    size,
    id,
    error,
    label,
    labelPosition,
    type,
    input,
    value,
    maxLength,
    style,
    useCase,
}) => {
    const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
        (event) => {
            if (resetToDefaultOnBlur && defaultValue !== undefined)
                event.target.value = defaultValue.toString();
            onBlur && onBlur(event, { id, value: event.target.value });
        },
        [id, onBlur, defaultValue]
    );
    return (
        <SemanticInput
            input={input}
            type={type === 'int' ? 'number' : type}
            className={classNames(
                className,
                styles.input,
                useCase && styles[useCase]
            )}
            disabled={disabled}
            placeholder={placeholder}
            defaultValue={defaultValue}
            size={size}
            id={id}
            label={label}
            labelPosition={label && labelPosition}
            error={error}
            value={value}
            maxLength={maxLength}
            style={style}
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={onFocus}
            onKeyPress={(event: KeyboardEvent) => {
                if (type === 'int' && !/[0-9]/.test(event.key)) {
                    event.preventDefault();
                }
            }}
        />
    );
};

export default Input;
