import { MinAndMax } from '../types';

// Validation functions
export const valueInBetween = (val: string, limits: MinAndMax) => {
    return Number(val) >= limits.min && Number(val) <= limits.max;
};

export const isBlank = (val: string) => val === '';

export const isEmail = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const isNotBlank = (input: string) => {
    return input !== '';
};
