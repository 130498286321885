import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import useDesignerQuote from '../../common/hooks/useDesignerQuote';
import {
    dispatchAndSaveToLocalStorage,
    updateManualROIValueInPlanning,
    updateROIValue,
} from '../../features/designerQuote/designerQuoteActions';
import Button from '../Button';
import styles from './RecalculateButton.module.scss';
import { useAppDispatch } from '../../common/hooks';

type Props = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    onClick?: Function;
    className?: string;
    style?: CSSProperties;
    type?: 'planner' | 'sales';
    iconSize?: 'big' | 'small';
    iconStrokeWidth?: number;
};

const RecalculateButton: FunctionComponent<Props> = (props) => {
    const dispatch = useAppDispatch();
    const designerQuote = useDesignerQuote();
    const { t } = useTranslation(['common']);
    return (
        <Button
            icon={'rotate-cw'}
            iconSize={props.iconSize}
            iconStrokeWidth={props.iconStrokeWidth}
            type={props.type}
            style={props.style}
            className={classNames(props.className, {
                [styles.sales]: props.type === 'sales',
            })}
            onClick={() => {
                dispatch(
                    updateROIValue({
                        roiData: {
                            selfConsumptionRate:
                                designerQuote.planning.roiValues!
                                    .selfConsumptionRate!.machine,
                        },
                    })
                );
                dispatch(
                    dispatchAndSaveToLocalStorage(
                        updateManualROIValueInPlanning({
                            roiData: {
                                selfConsumptionRate: null,
                            },
                        })
                    )
                );
                if (props.onClick) {
                    props.onClick();
                }
            }}
        >
            {t('Recalculate')}
        </Button>
    );
};

export default RecalculateButton;
