import { OpportunityActionType, OpportunityReducer } from '../../common/types';
import { defaultOpportunityState } from '../../store/defaults';

export const opportunityReducer: OpportunityReducer = (
    state = defaultOpportunityState,
    action
) => {
    switch (action.type) {
        case OpportunityActionType.REQUEST_OPPORTUNITY:
            return {
                isFetching: true,
                opportunityId: action.payload.opportunityId,
            };
        case OpportunityActionType.SET_OPPORTUNITY:
            return {
                ...action.payload.data,
                isFetching: false,
                opportunityId: action.payload.opportunityId,
            };
        case OpportunityActionType.FETCH_OPPORTUNITY_FAILED:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
};
