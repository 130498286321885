import React from 'react';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import IconHost from './components/IconHost';
import i18n from './i18n';
import './index.css';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /^\//],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <React.StrictMode>
            <App />
            <IconHost />
        </React.StrictMode>
    </I18nextProvider>,
    document.getElementById('root')
);
