import classNames from 'classnames';
import React from 'react';
import { VoidHandler } from '../../common/types';
import Icon from '../Icon';
import Stack from '../Stack';
import styles from './ExpandableIcon.module.scss';

const ExpandableIcon: React.FC<{
    icon: string;
    expanded?: boolean;
    onIconClick?: VoidHandler;
    badgeValue?: number | string;
    hasValue?: boolean;
    oneLine?: boolean;
    children?: React.ReactNode;
}> = ({
    children,
    expanded,
    icon,
    onIconClick,
    badgeValue,
    hasValue = true,
    oneLine = false,
}) => {
    const showBadge = badgeValue !== undefined && !expanded;

    return (
        <Stack className={styles.expandableIcon}>
            <div
                className={classNames(styles.iconContainer, {
                    [styles.badge]: showBadge,
                })}
                data-value={showBadge ? badgeValue : undefined}
            >
                <Icon
                    id={icon}
                    onClick={onIconClick}
                    className={classNames(styles.icon, {
                        [styles.empty]: !hasValue,
                    })}
                />
            </div>
            <span
                hidden={!expanded}
                className={classNames(styles.subMenu, {
                    [styles.oneLine]: oneLine,
                })}
            >
                {children}
            </span>
        </Stack>
    );
};

export default ExpandableIcon;
