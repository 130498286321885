import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import styles from './StringPlanner.module.scss';

type Props = {
    style?: CSSProperties;
    className?: string;
    children: React.ReactNode;
};

const StringPlanner: FunctionComponent<Props> = ({
    children,
    style,
    className,
}) => {
    return (
        <section
            style={style}
            className={classNames(
                'stringPlanner',
                styles.stringPlanner,
                className
            )}
        >
            {children}
        </section>
    );
};

export default StringPlanner;
