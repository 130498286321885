import React from 'react';
import styles from './../Geometry.module.scss';

const xPosition = ['left', 'right'];
const yPosition = ['top', 'bottom'];
const generateCornerStyle = (
    x: string,
    y: string,
    size: number,
    overflow = '0'
) => ({
    [xPosition[Number(x)]]: 0,
    [yPosition[Number(y)]]: 0,
    width: size,
    height: size,
    transform: `translate(${x === '0' ? '-' : ''}${overflow}, ${
        y === '0' ? '-' : ''
    }${overflow})`,
});

const Corner = (props: {
    positionX: '0' | '1';
    positionY: '0' | '1';
    size?: number;
    overflow?: string;
    cursor?: string;
    onMouseDown?: React.MouseEventHandler;
}) => {
    const {
        onMouseDown,
        positionX,
        positionY,
        size = 20,
        overflow,
        cursor,
    } = props;
    return (
        <div
            onMouseDown={onMouseDown}
            style={{
                ...generateCornerStyle(positionX, positionY, size, overflow),
                ...(cursor ? { cursor } : {}),
            }}
            className={styles.corner}
        />
    );
};

export default Corner;
