import { ProductTypes } from './types';

export const productTypeOrder: {
    [key in any]: number; // If I change the any type, the parser doesnt stop crying
} = {
    [ProductTypes.PANEL]: 1,
    [ProductTypes.INVERTER]: 3,
    [ProductTypes.SECOND_INVERTER]: 3,
    [ProductTypes.BATTERY]: 5,
    [ProductTypes.MOUNTING_SYSTEM]: 6,
    [ProductTypes.MOUNTING_SYSTEM_ACCESSORIES]: 6,
    [ProductTypes.ELECTRICAL_SERVICES]: 8,
    [ProductTypes.ELECTRICAL_MATERIAL]: 9,
    [ProductTypes.ELECTRICAL_CABINETS]: 10,
    [ProductTypes.ELECTRICAL_CABINET_UPGRADES]: 11,
    [ProductTypes.ELECTRICAL_LABOR]: 11,
    [ProductTypes.SCAFFOLDING]: 12,
    [ProductTypes.SCAFFOLDING_ADD_ONS]: 12,
    [ProductTypes.WALLBOXES]: 13,
    [ProductTypes.GROUNDING]: 15,
    [ProductTypes.ADDITIONAL_LABOR]: 16,
    OPTIMIZER: 2, //  Inverter dependency with isOptimizer = true
    INVERTER_ACCESSORY: 4, // other Inverter dependencies
    MONTAGE_SERVICE: 7, // Montage  der Unterkonstruktion und der module ??
    WALLBOX_SERVICE: 14,
};

export default productTypeOrder;
