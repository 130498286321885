import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import commonDE from './translations/de/common.json';
import energyConsumersDE from './translations/de/energyConsumers.json';
import mainPageDE from './translations/de/mainPage.json';
import modalsDE from './translations/de/modals.json';
import notificationsDE from './translations/de/notifications.json';
import productsWizardDE from './translations/de/productsWizard.json';
import roiDE from './translations/de/roi.json';
import shortcutsDE from './translations/de/shortcuts.json';
import startPlanningDE from './translations/de/startPlanning.json';
import stringPlanDE from './translations/de/stringPlan.json';
import validationsDE from './translations/de/validations.json';
import warningDropdownDE from './translations/de/warningDropdown.json';
import commonEN from './translations/en/common.json';
import energyConsumersEN from './translations/en/energyConsumers.json';
import mainPageEN from './translations/en/mainPage.json';
import modalsEN from './translations/en/modals.json';
import notificationsEN from './translations/en/notifications.json';
import productsWizardEN from './translations/en/productsWizard.json';
import roiEN from './translations/en/roi.json';
import shortcutsEN from './translations/en/shortcuts.json';
import startPlanningEN from './translations/en/startPlanning.json';
import stringPlanEN from './translations/en/stringPlan.json';
import validationsEN from './translations/en/validations.json';
import warningDropdownEN from './translations/en/warningDropdown.json';

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        ns: [
            'common',
            'startPlanning',
            'mainPage',
            'modals',
            'notifications',
            'productsWizard',
            'shortcuts',
            'energyConsumers',
            'stringPlan',
        ],
        lng: 'de',
        resources: {
            de: {
                common: commonDE,
                startPlanning: startPlanningDE,
                mainPage: mainPageDE,
                modals: modalsDE,
                notifications: notificationsDE,
                validations: validationsDE,
                roi: roiDE,
                productsWizard: productsWizardDE,
                shortcuts: shortcutsDE,
                energyConsumers: energyConsumersDE,
                stringPlan: stringPlanDE,
                warningDropdown: warningDropdownDE,
            },
            en: {
                common: commonEN,
                startPlanning: startPlanningEN,
                mainPage: mainPageEN,
                modals: modalsEN,
                notifications: notificationsEN,
                validations: validationsEN,
                roi: roiEN,
                productsWizard: productsWizardEN,
                shortcuts: shortcutsEN,
                energyConsumers: energyConsumersEN,
                stringPlan: stringPlanEN,
                warningDropdown: warningDropdownEN,
            },
        },
        defaultNS: 'common',
        fallbackNS: 'common', // For common you don't need to use the namespace
        debug: false,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
