import React, { FunctionComponent } from 'react';
import { Form as SemanticForm, FormInputProps } from 'semantic-ui-react';
import FormInput from '../FormInput';

type Props = {
    onBlur?: (event: React.FocusEvent, data: any) => void;
    onChange?: (event: React.ChangeEvent, data: any) => void;
    formInputs: { [key: string]: FormInputProps };
    sales?: boolean;
};

const Form: FunctionComponent<Props> = ({
    onChange,
    onBlur,
    formInputs,
    sales,
}) => {
    // Plans to add submit functionality at some point if needed
    return (
        <SemanticForm>
            {Object.keys(formInputs).map((key: string) => {
                return (
                    (formInputs[key].label || formInputs[key].value) && (
                        <FormInput
                            key={key}
                            onChange={onChange}
                            onBlur={onBlur}
                            formInput={formInputs[key]}
                            id={key}
                            sales={sales}
                        />
                    )
                );
            })}
        </SemanticForm>
    );
};

export default Form;
