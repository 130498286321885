import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import useUserRole from '../../common/hooks/useUserRole';
import Hotkeys from '../../common/hotkeys';
import { DesignerQuoteState, RootState, UserRoles } from '../../common/types';
import { canRedo, canUndo } from '../../features/stateHistory';
import { redo, undo } from '../../features/stateHistory/stateHistoryActions';
import Button from '../Button';
import Square from '../Square';
import Stack from '../Stack';
import styles from './UndoRedo.module.scss';
import { useAppDispatch } from '../../common/hooks';

const UndoRedo: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    useSelector<RootState, DesignerQuoteState>((state) => state.designerQuote);

    const role = useUserRole();
    const isPlanner = role === UserRoles.planner;

    const handleUndo = () => {
        dispatch(undo());
    }; // undo the last action

    const handleRedo = () => {
        dispatch(redo());
    }; // redo the last action

    useHotkeys(Hotkeys.UNDO.hook, (keyEvent: KeyboardEvent) => {
        keyEvent.preventDefault();
        handleUndo();
    });

    useHotkeys(Hotkeys.REDO.hook, (keyEvent: KeyboardEvent) => {
        keyEvent.preventDefault();
        handleRedo();
    });

    return (
        <Stack
            className={classNames(styles.undoRedo, isPlanner && styles.planner)}
        >
            <Square>
                <Button
                    icon={'undo'}
                    onClick={handleUndo}
                    disabled={!canUndo()}
                    type={'sales'}
                />
            </Square>
            <Square>
                <Button
                    icon={'redo'}
                    onClick={handleRedo}
                    disabled={!canRedo()}
                    type={'sales'}
                />
            </Square>
        </Stack>
    );
};

export default UndoRedo;
