import classNames from 'classnames';
import React, {
    CSSProperties,
    FunctionComponent,
    MouseEventHandler,
    useEffect,
} from 'react';
import { SOUTH_FACING_PANEL_GUTTER } from '../../common/constants';
import { getSlopedWidthOfPanel, mmToPixels } from '../../common/functions';
import { useUi } from '../../common/hooks/useUi';
import useUserRole from '../../common/hooks/useUserRole';
import { Panel, RoofTypes, UserRoles, VoidHandler } from '../../common/types';
import styles from './PanelElement.module.scss';

type Props = {
    style?: CSSProperties;
    className?: string;
    id: string;
    productData: Panel;
    isHidden?: boolean;
    isHorizontal?: boolean;
    facingSide?: string;
    hasGutter?: boolean;
    gutterWidth?: number;
    onMouseDown?: MouseEventHandler<HTMLDivElement>;
    selected?: boolean;
    error?: boolean;
    onReady?: VoidHandler;
    stringKey?: string;
    stringIndex?: number;
    roofType?: RoofTypes;
    ungrouped?: boolean;
};

const PanelElement: FunctionComponent<Props> = ({
    style,
    productData,
    isHidden,
    isHorizontal,
    facingSide,
    hasGutter = false,
    gutterWidth = SOUTH_FACING_PANEL_GUTTER,
    roofType = 'normal',
    id,
    onMouseDown,
    selected,
    error,
    onReady,
    className,
    stringKey,
    stringIndex,
    ungrouped,
}) => {
    const [{ isRenderingScreenshot }] = useUi();

    const productHeight = mmToPixels(productData.length || 0);
    const userRole = useUserRole();
    const showErrorOverlay =
        !isRenderingScreenshot && userRole === UserRoles.planner && error;
    const isFlatRoof = roofType === 'flat';

    const productWidth = isFlatRoof
        ? mmToPixels(getSlopedWidthOfPanel(productData.width || 0))
        : mmToPixels(productData.width || 0);

    useEffect(() => {
        !productData.photos[0]?.url && onReady && onReady();
    }, [productData.photos[0]?.url, onReady]);

    const gutterWidthStyle = hasGutter
        ? ({
              '--gutter-width': mmToPixels(gutterWidth),
          } as CSSProperties)
        : {};

    return (
        <div
            className={classNames('panel', className, styles.root, {
                [styles.isRotated]: isHorizontal,
                [styles.hidden]: isHidden,
                [styles.outline]: !isRenderingScreenshot,
                [styles.error]: showErrorOverlay,
                [styles.selected]: selected,
                hasImage: productData.photos[0]?.url,
                [styles.flatRoofOutline]: selected && isFlatRoof,
                [styles.hasImage]: productData.photos[0]?.url,
                [styles.isUngrouped]: isHorizontal && ungrouped,
            })}
            id={id}
            {...(stringKey &&
                stringIndex !== undefined && {
                    'data-string-index': `${stringKey}${stringIndex}`,
                })}
            style={{
                ...style,
                ...gutterWidthStyle,
                width: productWidth,
                height: productHeight,
                transform: isHorizontal
                    ? `rotate(-90deg) translateX(-${productWidth}px)`
                    : undefined,
            }}
            onMouseDown={onMouseDown}
        >
            {!isHidden && productData.photos[0]?.url && (
                <div
                    className={classNames({
                        [styles.flatRoofImageContainer]: isFlatRoof,
                        [styles.facingLeft]: facingSide === 'left',
                        [styles.facingRight]: facingSide === 'right',
                        [styles.facingTop]: facingSide === 'top',
                        [styles.facingBottom]: facingSide === 'bottom',
                        [styles.hasGutter]: hasGutter && !isRenderingScreenshot,
                        panelImg: isFlatRoof,
                    })}
                >
                    <img
                        draggable={'false'}
                        alt={'panel'}
                        src={productData.photos[0]?.url}
                        style={showErrorOverlay ? { opacity: 0.7 } : undefined}
                        width={'100%'}
                        height={'100%'}
                        onLoad={onReady}
                        onError={onReady}
                    />
                </div>
            )}
        </div>
    );
};

export default PanelElement;
