import { validateObjectData } from '../common/functions';
import { callEigenApiWith } from './index';

type PresignedUrlResponse = {
    url: string;
    error: boolean;
};

function fileNameFromUrl(url: string) {
    const matches: RegExpMatchArray | null = url.match(/\/([^/?#]+)[^/]*$/);
    if (matches && matches.length > 1) {
        return matches[1];
    }
    return null;
}

const getPresignedUrl = async (url: string): Promise<[string?, unknown?]> => {
    const fileName = fileNameFromUrl(url);

    try {
        const res: PresignedUrlResponse = await callEigenApiWith({
            name: 'preSignedUrls',
            middleware: [validateObjectData],
            options: {
                method: 'POST',
                body: JSON.stringify({
                    fileName,
                }),
            },
        });
        return [res.url];
    } catch (e) {
        return [undefined, e];
    }
};

export default getPresignedUrl;
