import {
    NotificationsActionCreator,
    NotificationsActionType,
} from '../../common/types';

export const newNotification: NotificationsActionCreator = (payload) => {
    return {
        type: NotificationsActionType.NEW_NOTIFICATION,
        payload: {
            dismissible: true,
            ...payload,
            bornAt: Date.now(),
        },
    };
};

export const dismissNotification: NotificationsActionCreator = (payload) => {
    return {
        type: NotificationsActionType.DISMISS_NOTIFICATION,
        payload: {
            ...payload,
            dismissedAt: Date.now(),
        },
    };
};
