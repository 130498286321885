import {
    NotificationsActionType,
    NotificationsReducer,
} from '../../common/types';

export const notificationsReducer: NotificationsReducer = (
    state = [],
    action
) => {
    switch (action.type) {
        case NotificationsActionType.NEW_NOTIFICATION:
            return [action.payload, ...state];
        case NotificationsActionType.DISMISS_NOTIFICATION:
            return [
                action.payload,
                ...state.filter(
                    (noti) => noti.bornAt !== action.payload.bornAt
                ),
            ];
        default:
            return state;
    }
};
