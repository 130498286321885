import {
    ModalActionCreator,
    ModalActionPayload,
    ModalActionType,
    ModalCloseActionCreator,
} from '../../common/types';

export const openModal: ModalActionCreator<ModalActionPayload> = (payload) => {
    return {
        type: ModalActionType.OPEN_MODAL,
        payload,
    };
};

export const closeModal: ModalCloseActionCreator = () => {
    return {
        type: ModalActionType.CLOSE_MODAL,
    };
};
