import { Dispatch } from 'redux';
import { dispatchAndSaveToLocalStorage } from '../designerQuote/designerQuoteActions';
import { updateUi } from '../ui/uiActions';
import { StateHistoryActionTypes } from './index';

export const undoAction = { type: StateHistoryActionTypes.UNDO };
export const redoAction = { type: StateHistoryActionTypes.REDO };
export const resetAction = { type: StateHistoryActionTypes.RESET };

export const undo = () => (dispatch: Dispatch) => {
    dispatch(updateUi({ selectedPanelIndices: [] }));
    dispatch<any>(dispatchAndSaveToLocalStorage(undoAction));
};

export const redo = () => (dispatch: Dispatch) => {
    dispatch(updateUi({ selectedPanelIndices: [] }));
    dispatch<any>(dispatchAndSaveToLocalStorage(redoAction));
};

export const reset = () => (dispatch: Dispatch) => {
    dispatch(updateUi({ selectedPanelIndices: [] }));
    dispatch(resetAction);
};
