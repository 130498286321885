import React, { CSSProperties, FunctionComponent } from 'react';
import { Photo, UseCases } from '../../common/types';
import Header from '../Header';
import PhotoGrid from '../PhotoGrid';

type Props = {
    style?: CSSProperties;
    photos: Photo[];
    title?: string;
    description?: string;
    className?: string;
    selectedPictureIds: string[];
    onPictureClick: (photoId: string) => void;
    showPrompt?: boolean;
    pictureClassName?: string;
    disabledPictureIds?: string[];
    useCase?: UseCases;
};

// The PictureSelector is a general component to select pictures;
// these can be imagines, photos or whatever you want to display.
const PictureSelector: FunctionComponent<Props> = ({
    style,
    photos,
    title,
    description,
    className,
    onPictureClick,
    selectedPictureIds,
    pictureClassName,
    disabledPictureIds,
    useCase,
}) => {
    return (
        <>
            {description && (
                <Header variant="h4" className="subtext">
                    {description}
                </Header>
            )}
            <PhotoGrid
                photos={photos}
                onPictureClick={onPictureClick}
                selectedPictureIds={selectedPictureIds}
                pictureClassName={pictureClassName}
                disabledPictureIds={disabledPictureIds}
                useCase={useCase}
            />
        </>
    );
};

export default PictureSelector;
