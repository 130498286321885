import classNames from 'classnames';
import React, { FunctionComponent, ReactElement } from 'react';
import styles from './Switch.module.scss';

type SwitchOptions = {
    name: string | ReactElement;
    id: string;
};
type Props = {
    className?: string;
    switchOptions: SwitchOptions[];
    switchValue: string | undefined;
    handleChange: (id: string) => void;
    variant?: 'toggle';
    size?: 'small';
};

const Switch: FunctionComponent<Props> = ({
    switchOptions,
    switchValue = switchOptions[0].id,
    handleChange,
    variant,
    className,
    size,
}) => {
    const optionOne = switchOptions[0];
    const optionTwo = switchOptions[1];
    return (
        <form
            className={classNames(className, styles.switchForm, {
                [styles.toggle]: variant === 'toggle',
            })}
        >
            <>
                <label
                    htmlFor={optionOne?.id}
                    className={classNames(styles.title, {
                        [styles.titleChecked]: optionOne.id === switchValue,
                    })}
                >
                    {optionOne?.name}
                </label>
                <div
                    className={classNames('switch', styles.switch, {
                        [styles.small]: size && size === 'small',
                    })}
                >
                    <input
                        type="radio"
                        value={optionOne?.id}
                        id={optionOne?.id}
                        name="recordType"
                        className={styles.radios}
                        onChange={() => handleChange(optionOne.id)}
                        checked={optionOne.id === switchValue}
                    />
                    <input
                        type="radio"
                        value={optionTwo?.id}
                        id={optionTwo?.id}
                        name="recordType"
                        className={styles.radios}
                        onChange={() => handleChange(optionTwo.id)}
                        checked={optionTwo.id === switchValue}
                    />
                </div>
                <label
                    htmlFor={optionTwo?.id}
                    className={classNames(styles.title, {
                        [styles.titleChecked]: optionTwo.id === switchValue,
                    })}
                >
                    {optionTwo?.name}
                </label>
            </>
        </form>
    );
};

export default Switch;
