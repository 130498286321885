import { useEffect } from 'react';
import { updateROIValue } from '../../features/designerQuote/designerQuoteActions';
import {
    hideRecalculateButton,
    showRecalculateButton,
} from '../../features/ui/uiActions';
import useDesignerQuote from './useDesignerQuote';
import { useDispatch } from 'react-redux';

export default function useSelfConsumption() {
    const designerQuote = useDesignerQuote();
    const dispatch = useDispatch();
    useEffect(() => {
        if (
            designerQuote.planning.roiValues?.selfConsumptionRate?.machine &&
            designerQuote.salesforce.selfConsumptionRate !==
                designerQuote.planning.roiValues.selfConsumptionRate.machine
        ) {
            if (!designerQuote.planning.roiValues.selfConsumptionRate.manual) {
                dispatch(
                    updateROIValue({
                        roiData: {
                            selfConsumptionRate:
                                designerQuote.planning.roiValues
                                    .selfConsumptionRate.machine,
                        },
                    })
                );
            } else {
                dispatch(showRecalculateButton());
            }
        }
    }, [designerQuote.planning.roiValues?.selfConsumptionRate?.machine]);

    useEffect(() => {
        dispatch(hideRecalculateButton());
    }, [designerQuote.salesforce.selfConsumptionRate]);
}
