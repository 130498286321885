import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Modal as SemanticModal } from 'semantic-ui-react';
import { ModalProps } from '../../common/types';
import Button from '../Button';
import Form from '../Form';
import Header from '../Header';
import styles from './Modal.module.scss';

const Modal: FunctionComponent<ModalProps> = ({
    title,
    description,
    size = 'small',
    onModalClose,
    children,
    formInputs,
    formOnChange,
    buttons = [],
    className,
}) => {
    return (
        <SemanticModal
            size={size}
            open={true}
            onClose={onModalClose}
            className={classNames(styles.modal, className)}
        >
            <Header className={styles.header}>
                <span>{title}</span>
            </Header>
            <SemanticModal.Content>
                <SemanticModal.Description>
                    <p>{description}</p>
                    {formInputs && (
                        <Form
                            formInputs={formInputs}
                            onChange={formOnChange}
                        ></Form>
                    )}
                </SemanticModal.Description>
                {children}
            </SemanticModal.Content>
            <SemanticModal.Actions className={styles.modalActions}>
                {buttons.map(({ text, type, onClick, style }) => {
                    const label =
                        text || (type === 'negative' ? 'Abbrechen' : 'OK');
                    return (
                        <Button
                            key={label}
                            variant={type}
                            onClick={onClick}
                            style={style}
                            className={classNames(
                                styles.modal,
                                className,
                                styles.button
                            )}
                        >
                            {label}
                        </Button>
                    );
                })}
            </SemanticModal.Actions>
        </SemanticModal>
    );
};

export default Modal;
