import { useTranslation } from 'react-i18next';
import {
    dispatchAndSaveToLocalStorage,
    saveOrUpdateQuote,
    setValidMountingSystems,
    updateQuoteName,
} from '../../features/designerQuote/designerQuoteActions';
import { closeModal, openModal } from '../../features/modals/modalActions';
import { newNotification } from '../../features/notifications/notificationsActions';
import {
    allSurfaceDetails,
    allSurfaceHaveDimensions,
    createDefaultQuoteName,
} from '../functions';
import { UserRoles } from '../types';
import useDesignerQuote from './useDesignerQuote';
import useProductAvailability from './useProductAvailability';
import useUserRole from './useUserRole';
import { useAppDispatch } from './index';

export default function useHandleSave(
    finishCallback?: (success: boolean) => void,
    startCallback?: (success: boolean) => void
) {
    const dispatch = useAppDispatch();
    const quote = useDesignerQuote();
    const products = quote.salesforce.Products;
    const { t } = useTranslation('notifications');
    const role = useUserRole();
    const {
        isAllProductsActive,
        isAllProductsVisible,
        isAllSalesActiveAndVisible,
        isAllNonSalesActiveAndVisible,
    } = useProductAvailability();

    // Handlers for Sales Save Modal
    const handleFormChange = (event: any, data: any) => {
        dispatch(
            dispatchAndSaveToLocalStorage(
                updateQuoteName({
                    quoteName: data.value === '' ? undefined : data.value,
                })
            )
        );
    };

    const handleSalesSave = () => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        dispatch(saveOrUpdateQuote(() => {}, false, false));
        dispatch(closeModal());
    };

    const handlePlanningSave = (shouldUpdate: boolean) => {
        startCallback && startCallback(true);
        dispatch(saveOrUpdateQuote(finishCallback, shouldUpdate, false));
        dispatch(closeModal());
    };

    const handleModalClose = () => {
        dispatch(closeModal());
    };

    return (shouldUpdate = false) => {
        const panelAmount = products?.panels.quantity || 0;
        if (panelAmount === 0) {
            dispatch(
                newNotification({
                    type: 'error',
                    message: t('notifications:noPanels'),
                    icon: 'edit',
                })
            );
            return;
        }
        if (!allSurfaceDetails(quote.planning)) {
            dispatch(
                newNotification({
                    type: 'error',
                    message: t('notifications:insertAzimuthAndSlope'),
                    icon: 'edit',
                })
            );
            return;
        }

        dispatch(dispatchAndSaveToLocalStorage(setValidMountingSystems({})));

        if (role === UserRoles.sales) {
            // Sales Version: open saving modal
            if (!products?.inverter) {
                dispatch(
                    newNotification({
                        type: 'error',
                        message: t('notifications:noInverter'),
                        icon: 'edit',
                    })
                );
                return;
            }
            const quoteName = createDefaultQuoteName(quote);

            dispatch(
                dispatchAndSaveToLocalStorage(
                    updateQuoteName({
                        quoteName,
                    })
                )
            );

            // Has the Ability to change the products
            if (
                (!isAllProductsActive || !isAllProductsVisible) &&
                (!isAllSalesActiveAndVisible || isAllNonSalesActiveAndVisible)
            ) {
                dispatch(
                    newNotification({
                        type: 'error',
                        message: t('notifications:existingErrors'),
                    })
                );
                return;
            }

            dispatch(
                openModal({
                    modalProps: {
                        title: t('modals:saveQuote.title'),
                        description: t('modals:saveQuote.description'),
                        className: 'sales',
                        formInputs: {
                            quoteName: {
                                value: quoteName || '',
                                errorMessage: t(
                                    'validations:mustHaveQuoteName'
                                ),
                                inline: false,
                                validation: (val: string) => val.length > 0,
                                size: 'large',
                            },
                        },
                        formOnChange: handleFormChange,
                        buttons: [
                            {
                                text: t('modals:saveQuote.cancel') as string,
                                type: 'negative',
                                onClick: handleModalClose,
                            },
                            {
                                text: t('modals:saveQuote.confirm') as string,
                                type: 'positive',
                                onClick: handleSalesSave,
                            },
                        ],
                        onModalClose: handleModalClose,
                        size: 'large',
                    },
                })
            );
        } else {
            if (!quote.salesforce.Products?.inverter) {
                dispatch(
                    newNotification({
                        type: 'error',
                        message: t('notifications:noInverter'),
                        icon: 'edit',
                    })
                );
                return;
            }

            if (!allSurfaceHaveDimensions(quote.planning)) {
                dispatch(
                    newNotification({
                        type: 'error',
                        message: t('notifications:surfaceDimensionsMissing'),
                    })
                );
                return;
            }

            if (!products?.panels?.quantity || products?.panels.quantity <= 0) {
                dispatch(
                    newNotification({
                        type: 'error',
                        message: t('notifications:noPanels'),
                        icon: 'edit',
                    })
                );
                return;
            }

            const hasElectroMaterial = !!(
                products.electricalMaterial && products.electricalMaterial.id
            );

            if (
                !isAllProductsVisible ||
                !isAllProductsActive ||
                !hasElectroMaterial
            ) {
                const handlePlanningSaveModalFunc = () => {
                    handlePlanningSave(shouldUpdate);
                };
                let modal = 'modals:invisibleProductsPlanner';
                if (!hasElectroMaterial) {
                    modal = 'modals:noElectricalMaterial';
                }

                dispatch(
                    openModal({
                        modalProps: {
                            title: t(`${modal}.title`),
                            description: t(`${modal}.description`),
                            className: 'sales',
                            buttons: [
                                {
                                    text: t(
                                        'modals:saveQuote.cancel'
                                    ) as string,
                                    type: 'negative',
                                    onClick: handleModalClose,
                                },
                                {
                                    text: t(
                                        'modals:saveQuote.confirm'
                                    ) as string,
                                    type: 'positive',
                                    onClick: handlePlanningSaveModalFunc,
                                },
                            ],
                            onModalClose: handleModalClose,
                            size: 'large',
                        },
                    })
                );
            } else {
                handlePlanningSave(shouldUpdate);
            }
        }
    };
}
