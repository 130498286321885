import React, {
    MouseEventHandler,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Point } from '../../common/types';

type Props = {
    className?: string;
    onClick?: MouseEventHandler;
    onSelectionChange?: (
        startPoint: Point | undefined,
        endPoint: Point | undefined
    ) => void;
};
const DragTrackingLayer: React.FC<Props> = ({
    className,
    onClick,
    onSelectionChange,
}) => {
    const [state, setState] = useState({
        isMouseDown: false,
        startPoint: null as Point | undefined | null,
        endPoint: undefined as Point | undefined,
        hasMoved: false,
    });

    useEffect(() => {
        if (!state.isMouseDown) return;
        const handleMouseMove = (e: MouseEvent) => {
            const { startPoint } = state;
            if (!startPoint) return;

            const { clientX, clientY } = e;

            setState((state) => ({
                ...state,
                endPoint: {
                    x: clientX,
                    y: clientY,
                },
                hasMoved: true,
            }));
        };
        const handleMouseUp = () =>
            setState((state) => ({
                ...state,
                isMouseDown: false,
                mouseDownPoint: undefined,
                startPoint: state.startPoint === null ? null : undefined,
                endPoint: undefined,
            }));

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [state.isMouseDown]);

    useEffect(() => {
        if (state.startPoint === null) return;
        onSelectionChange &&
            onSelectionChange(state.startPoint, state.endPoint);
    }, [state.endPoint]);

    const handleClick = useCallback<MouseEventHandler>(
        (e) => {
            !state.hasMoved && onClick && onClick(e);
        },
        [state.hasMoved, onClick]
    );
    const handleMouseDown = useRef<MouseEventHandler>((e) => {
        const { clientX, clientY } = e;
        setState((state) => ({
            ...state,
            isMouseDown: true,
            startPoint: {
                x: clientX,
                y: clientY,
            },
            hasMoved: false,
        }));
    }).current;

    return (
        <div
            className={className}
            onClick={handleClick}
            onMouseDown={handleMouseDown}
        />
    );
};

export default DragTrackingLayer;
