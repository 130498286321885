import { ActionCreator, Dispatch } from 'redux';
import fetchProducts from '../../api/fetchProducts';
import { renameKeys, snakeToCamel } from '../../common/helpers';
import {
    ApiFilter,
    InitialiseProductsAction,
    ProductsAction,
    ProductsActionType,
    ProductsMap,
    RootState,
} from '../../common/types';
import { setDefaultProducts } from '../designerQuote/designerQuoteActions';
import { newNotification } from '../notifications/notificationsActions';
import { reset } from '../stateHistory/stateHistoryActions';
import { updateUi } from '../ui/uiActions';

export const initialiseProducts: InitialiseProductsAction = async (
    dispatch: Dispatch,
    getState: () => RootState
): Promise<void> => {
    const recordType = getState().designerQuote.salesforce.recordType;
    const filters = [];
    recordType &&
        filters.push({ name: 'recordType', value: recordType.id } as ApiFilter);
    dispatch(updateUi({ isInitialisingProducts: true }));
    const [products, error] = await fetchProducts(filters);
    dispatch(updateUi({ isInitialisingProducts: false }));

    if (error) {
        dispatch(
            newNotification({
                type: 'error',
                message: 'Fetching Products failed',
                details: error.message,
            })
        );
        return;
    }
    let camelProducts = {};
    if (products) camelProducts = renameKeys(snakeToCamel, products);
    dispatch(setProducts(camelProducts));
    const productsState = getState().products;
    dispatch<any>(setDefaultProducts({ productsMap: productsState }));
    dispatch<any>(reset());
};

export const setProducts: ActionCreator<ProductsAction> = (
    products: ProductsMap
) => ({
    type: ProductsActionType.PRODUCTS_SET,
    payload: products,
});

export const updateProducts: ActionCreator<ProductsAction> = (
    products: ProductsMap
) => ({
    type: ProductsActionType.PRODUCTS_UPDATE,
    payload: products,
});
