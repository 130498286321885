import React, {
    CSSProperties,
    FunctionComponent,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Point } from '../../../common/types';
import Corners from '../Corners';

type Props = {
    style?: CSSProperties;
    className?: string;
    defaultValue?: number;
    offset?: Point;
    onChange?: (angle: number) => void;
    scaleAdjustment?: number;
};

function calculateAngle(
    pointX: number,
    pointY: number,
    center: Point,
    offset?: Point
) {
    const x = center.x - pointX + (offset ? offset.x : 0);
    const y = center.y - pointY + (offset ? offset.y : 0);

    let acosAngle = Math.acos(x / Math.sqrt(x * x + y * y));
    if (y < 0) acosAngle = 2 * Math.PI - acosAngle;

    return acosAngle;
}

const RotationControls: FunctionComponent<Props> = ({
    style,
    className,
    onChange,
    defaultValue = 0,
    offset,
    scaleAdjustment = 1,
}) => {
    const [state, setState] = useState<{
        angle: number;
        offset?: Point;
        center?: Point;
        dragStartAngleAbsolute: number;
        dragStartAngleRelative?: number;
        handleCornerPress: React.MouseEventHandler;
        handleMouseMove: (e: MouseEvent) => void;
        handleMouseUp: (e: MouseEvent) => void;
    }>({
        angle: defaultValue,
        dragStartAngleAbsolute: defaultValue,
        offset,
        handleMouseMove: (e: MouseEvent) => {
            const { clientX, clientY } = e;

            setState((prevState) => {
                if (
                    !prevState.center ||
                    prevState.dragStartAngleRelative === undefined
                )
                    return prevState;

                const newAngle = calculateAngle(
                    clientX,
                    clientY,
                    prevState.center,
                    prevState.offset
                );

                return {
                    ...prevState,
                    angle:
                        prevState.dragStartAngleAbsolute +
                        newAngle -
                        prevState.dragStartAngleRelative,
                };
            });
        },
        handleMouseUp: (e: MouseEvent) => {
            window.removeEventListener('mousemove', state.handleMouseMove);
            window.removeEventListener('mouseup', state.handleMouseUp);
            window.removeEventListener('mouseleave', state.handleMouseUp);
        },
        handleCornerPress: (e: React.MouseEvent) => {
            const { clientX, clientY } = e;

            window.addEventListener('mousemove', state.handleMouseMove);
            window.addEventListener('mouseup', state.handleMouseUp);
            window.addEventListener('mouseleave', state.handleMouseUp);

            setState((prevState) => {
                const { center } = prevState || {};
                if (!center) return prevState;
                const currentAngle = calculateAngle(
                    clientX,
                    clientY,
                    center,
                    prevState.offset
                );
                return {
                    ...prevState,
                    dragStartAngleRelative: currentAngle,
                    dragStartAngleAbsolute: prevState.angle,
                };
            });
        },
    });

    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        if (!ref || !ref.current) return;

        const bounds: DOMRect = ref.current.getBoundingClientRect();

        const x = bounds.x + bounds.width * 0.5;
        const y = bounds.y + bounds.height * 0.5;

        const center = { x, y };

        setState({
            ...state,
            center,
        });
    }, [ref]);

    useEffect(() => {
        onChange && onChange(state.angle);
    }, [state.angle]);

    useEffect(() => {
        if (!state.center) return;
        setState({
            ...state,
            offset,
        });
    }, [offset]);

    return (
        <div style={style} className={className} ref={ref}>
            {state.center && (
                <Corners
                    onMouseDown={state.handleCornerPress}
                    size={30 / scaleAdjustment}
                    overflow={'100%'}
                    cursors={{
                        all: 'alias',
                    }}
                />
            )}
        </div>
    );
};

export default RotationControls;
