import { Action, Reducer } from 'redux';
import { copyProperties } from '../../common/helpers';
import { StringIndexable } from '../../common/types';

export enum StateHistoryActionTypes {
    UNDO = 'STATE_HISTORY_UNDO',
    REDO = 'STATE_HISTORY_REDO',
    RESET = 'STATE_HISTORY_RESET',
}

const history: any[] = [];
let position = 0;

export const canUndo = () => history.length > -position;
export const canRedo = () => position < -1;

const stateHistory = <S extends StringIndexable, A extends Action>(
    reducer: Reducer<S, A>,
    limit = 100,
    properties: (keyof S)[],
    saveActionTypes: Array<string | [string, (keyof S)[]]>,
    resetActionTypes: string[] = []
): Reducer<S, A> => {
    const _customPropertiesByType: StringIndexable<string[]> = {};
    const _saveActionTypes = saveActionTypes.map((t) => {
        if (typeof t === 'string') return t;
        else {
            _customPropertiesByType[t[0]] = t[1] as string[];
            return t[0];
        }
    });
    const _resetActionTypes = [
        ...resetActionTypes,
        StateHistoryActionTypes.RESET,
    ];

    const _lastActions: [string?, string?] = [];

    return (state, action) => {
        if (state === undefined) return reducer(state, action);
        _lastActions.unshift(action.type);
        _lastActions.length = 2;

        switch (action.type) {
            case StateHistoryActionTypes.UNDO:
                if (!canUndo()) return state;

                if (position === 0) {
                    history.push(state); // the very first undo triggers save of the whole state
                    position--;
                }

                position--;

                return { ...state, ...history[history.length + position] };
            case StateHistoryActionTypes.REDO:
                if (!canRedo()) return state;

                position++;

                return { ...state, ...history[history.length + position] };
            default:
                if (_resetActionTypes.includes(action.type)) {
                    history.length = 0;
                    position = 0;
                    break;
                }

                if (!_saveActionTypes.includes(action.type)) break;

                history.length += position;
                history.push(
                    copyProperties(
                        state,
                        _customPropertiesByType[action.type] || properties
                    )
                );

                if (history.length >= limit + 1) history.shift();

                position = 0;
        }
        return reducer(state, action);
    };
};

export default stateHistory;
