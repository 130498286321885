import classNames from 'classnames';
import React, { FunctionComponent, useState } from 'react';
import Header from '../Header';
import Icon from '../Icon';
import styles from './Collapsable.module.scss';

type Props = {
    className?: string;
    open?: boolean;
    title?: string;
    children?: React.ReactNode;
};

const Collapsable: FunctionComponent<Props> = ({
    children,
    className,
    open = true,
    title,
}) => {
    const [opened, setOpened] = useState(open);

    const handleClick = (state?: boolean) => {
        setOpened(state || !opened);
    };

    return (
        <div className={classNames(className, styles.root)}>
            <div onClick={() => handleClick()} className={styles.title}>
                <div className={styles.openClose}>
                    <Icon
                        id="open-close"
                        stroke={15}
                        className={classNames(styles.icon, {
                            [styles.opened]: opened,
                        })}
                    />
                </div>
                <Header className={styles.header}>{title}</Header>
            </div>
            <div
                className={classNames(styles.content, {
                    [styles.opened]: opened,
                })}
                onFocus={() => handleClick(true)}
            >
                {children}
            </div>
        </div>
    );
};

export default Collapsable;
