import React, { CSSProperties, FunctionComponent } from 'react';
import { Image } from 'semantic-ui-react';
import { PictureSize } from '../../common/types';

type PictureProps = {
    src: string;
    id: string;
    alt?: string;
    selected?: boolean;
    onPictureClick: (pictureId: string) => void;
    size?: PictureSize;
    className?: string;
    style?: CSSProperties;
    disabled?: boolean;
};

const Picture: FunctionComponent<PictureProps> = ({
    src,
    id,
    alt = '',
    onPictureClick,
    selected,
    size = 'small',
    className,
    style,
    disabled = false,
}) => {
    return (
        <Image
            src={src}
            alt={alt}
            key={id}
            onClick={() => onPictureClick(id)}
            size={size}
            style={style}
            bordered={selected}
            className={className}
            disabled={disabled}
        />
    );
};
export default Picture;
