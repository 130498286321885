import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, List } from 'semantic-ui-react';
import useDesignerQuote from '../../common/hooks/useDesignerQuote';
import { useUi } from '../../common/hooks/useUi';
import useUserPermissions from '../../common/hooks/useUserPermissions';
import {
    ButtonType,
    Layer,
    LayerType,
    RoofTypes,
    SurfaceInput,
    UseCases,
    VoidHandler,
} from '../../common/types';
import Button from '../Button';
import Header from '../Header';
import LayerGroup from './LayerGroup';
import styles from './LayersMenu.module.scss';

export type SurfaceDetailChangeHandler = (
    layerId: string,
    propName: SurfaceInput,
    newValue: RoofTypes | string | number
) => void;

type Props = {
    style?: CSSProperties;
    className?: string;
    layersGroups: { layers: Layer[]; name: LayerType }[];
    disableAddPanel?: boolean;
    disableDrawPanels?: boolean;
    disableRotatePanels?: boolean;
    disableRemovePanels?: boolean;
    onLayerItemClick: (layerId: string, type: ButtonType) => void;
    onAddSurfaceClick: VoidHandler;
    onToggleGridVisibility: VoidHandler;
    onSurfaceDetailChange: SurfaceDetailChangeHandler;
    onAddPanelClick?: VoidHandler;
    onDrawPanelsClick?: VoidHandler;
    onRotatePanelsClick?: VoidHandler;
    onRemovePanelsClick?: VoidHandler;
    onShowHidePanelsClick?: VoidHandler;
    isFlatRoofMode?: boolean;
    onFlipPanelsFacingClick?: VoidHandler;
    hasPanels?: boolean;
    onRatioClick?: VoidHandler;
};

const LayersMenu: FunctionComponent<Props> = ({
    layersGroups,
    disableAddPanel,
    disableDrawPanels,
    disableRotatePanels,
    disableRemovePanels,
    onLayerItemClick,
    onAddSurfaceClick,
    onSurfaceDetailChange,
    onAddPanelClick,
    onDrawPanelsClick,
    onRotatePanelsClick,
    onRemovePanelsClick,
    onShowHidePanelsClick,
    isFlatRoofMode,
    onFlipPanelsFacingClick,
    onRatioClick,
}) => {
    const { t } = useTranslation(['common']);
    const permissions = useUserPermissions();
    const [
        {
            isShapeBuilderOn,
            isDrawPanelsOn,
            selectedPanelIndices,
            activeCanvasId,
            activeSurfaceId,
        },
    ] = useUi();
    const planningData = useDesignerQuote().planning;
    const selectedPanels =
        activeCanvasId &&
        activeSurfaceId &&
        planningData.canvases &&
        selectedPanelIndices
            ? planningData?.canvases[activeCanvasId].surfaces[
                  activeSurfaceId
              ].panels.filter((panel, index) =>
                  selectedPanelIndices.includes(index)
              )
            : [];
    const panelSelectionHidden =
        selectedPanels.length > 0 &&
        selectedPanels.length ===
            selectedPanels.filter((panel) => panel.isHidden).length;

    return (
        <div className={styles.layersMenu}>
            <Header variant="h3" className={styles.header}>
                {t('layers')}
            </Header>
            <List horizontal className={styles.list}>
                {permissions.canManipulateSurfaces && (
                    <Button
                        className={classNames(styles.buttonBasic, {
                            [styles.activeShapeBuilder]: isShapeBuilderOn,
                        })}
                        iconSize="small"
                        icon="edit"
                        iconUseCase={UseCases.inLayersMenu}
                        onClick={onAddSurfaceClick}
                        tooltip={t('addSurface') as string}
                        tooltipPosition={'top left'}
                    />
                )}
                {permissions.canAddPanels && (
                    <>
                        <Button
                            className={styles.buttonBasic}
                            icon="panel-add"
                            iconSize="small"
                            iconUseCase={UseCases.inLayersMenu}
                            onClick={onAddPanelClick}
                            tooltip={t('addPanel') as string}
                            disabled={disableAddPanel}
                        />
                        <Button
                            className={classNames(styles.buttonBasic, {
                                [styles.activePanelsDraw]: isDrawPanelsOn,
                            })}
                            icon="panels-create"
                            iconSize="small"
                            iconUseCase={UseCases.inLayersMenu}
                            onClick={onDrawPanelsClick}
                            tooltip={t('addPanels') as string}
                            disabled={disableDrawPanels}
                        />
                    </>
                )}
                <Divider vertical className={styles.divider} />
                {isFlatRoofMode && (
                    <Button
                        className={styles.buttonBasic}
                        icon="panels-flip"
                        iconSize="small"
                        iconUseCase={UseCases.inLayersMenu}
                        onClick={onFlipPanelsFacingClick}
                        tooltip={t('flipPanels') as string}
                        disabled={disableRotatePanels}
                    />
                )}
                {permissions.canMovePanels && (
                    <Button
                        className={styles.buttonBasic}
                        icon="rotate-cw"
                        iconSize="small"
                        iconUseCase={UseCases.inLayersMenu}
                        onClick={onRotatePanelsClick}
                        tooltip={t('rotatePanel') as string}
                        disabled={disableRotatePanels}
                    />
                )}
                <Button
                    className={styles.buttonBasic}
                    icon="eye"
                    iconActive="eye-off"
                    iconSize="small"
                    active={panelSelectionHidden && !disableRemovePanels}
                    iconUseCase={UseCases.inLayersMenu}
                    onClick={onShowHidePanelsClick}
                    tooltip={t('showHideSelectedPanels') as string}
                    disabled={disableRemovePanels}
                />
                <Button
                    className={styles.buttonBasic}
                    icon="trash"
                    iconSize="small"
                    iconUseCase={UseCases.inLayersMenu}
                    onClick={onRemovePanelsClick}
                    tooltip={t('removeSelectedPanels') as string}
                    disabled={disableRemovePanels}
                />
            </List>
            <List className={styles.list}>
                {layersGroups.map((layerGroup) => (
                    <List.Item
                        className={classNames(styles.item)}
                        key={layerGroup.name}
                    >
                        <LayerGroup
                            name={layerGroup.name}
                            layers={layerGroup.layers}
                            onLayerItemClick={onLayerItemClick}
                            onSurfaceDetailChange={onSurfaceDetailChange}
                            className="layer-list"
                            style={{ padding: 0 }}
                            onRatioClick={onRatioClick}
                        />
                    </List.Item>
                ))}
            </List>
        </div>
    );
};

export default LayersMenu;
