import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'semantic-ui-react';
import {
    BasicQuoteProduct,
    modificationDetails,
    SortType,
} from '../../common/types';
import BadgeRecordType from '../BadgeRecordType';
import Header from '../Header';
import Icon from '../Icon';
import styles from './PlanItem.module.scss';

type Props = {
    quoteId: string;
    quoteName: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onClick: Function;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onDoubleClick: Function;
    active?: boolean;
    battery?: BasicQuoteProduct | null;
    panel?: BasicQuoteProduct | null;
    recordType?: string;
    status?: string;
    isSyncing?: boolean;
    modificationDetails?: modificationDetails;
    modifiedBy?: string;
    sortOrder: SortType;
};

const PlanItem: FunctionComponent<Props> = ({
    quoteName,
    quoteId,
    onClick,
    onDoubleClick,
    active = false,
    battery,
    panel,
    recordType,
    status,
    isSyncing,
    modificationDetails,
    modifiedBy,
    sortOrder,
}) => {
    const { t } = useTranslation('startPlanning');

    return (
        <Item
            onClick={() => {
                onClick(quoteId);
            }}
            onDoubleClick={() => onDoubleClick(quoteId)}
            className={classNames(styles.planItem, { [styles.active]: active })}
        >
            <Item.Content className={styles.content}>
                <Header variant="" className={styles.header}>
                    {quoteName}
                </Header>
                <Item.Meta className={styles.meta}>
                    {panel && (
                        <>
                            <Icon id={'panels'} size="small" />
                            <div className={styles.subline}>
                                {panel.quantity + ' x ' + panel.name}
                            </div>
                        </>
                    )}
                </Item.Meta>
                {battery && (
                    <Item.Description className={styles.description}>
                        <Icon id={'battery'} size="small" />
                        <span className={styles.batteryText}>
                            {battery.name + ' (' + battery.power + 'kWh)'}
                        </span>
                    </Item.Description>
                )}
                {recordType && <BadgeRecordType recordType={recordType} />}
                <Item.Description className={styles.subs}>
                    <>
                        <Icon id={'info'} size="small" />
                        <div className={styles.subline}>
                            <span
                                className={classNames({
                                    [styles.isSortType]: sortOrder === 'date',
                                })}
                            >
                                {t('modifiedBy')} <b>{modifiedBy}</b> {t('at')}{' '}
                                <b>
                                    {modificationDetails?.date &&
                                        new Date(
                                            modificationDetails.date
                                        ).toLocaleString()}{' '}
                                </b>
                            </span>
                            <div
                                className={classNames({
                                    [styles.isSortType]: sortOrder === 'status',
                                })}
                            >
                                {status}
                            </div>
                        </div>
                    </>
                </Item.Description>
                {isSyncing && (
                    <Item.Description className={styles.subs}>
                        <Icon id={'logo-salesforce'} size="small" />
                        <div
                            className={classNames(styles.subline, {
                                [styles.isSortType]: sortOrder === 'sync',
                            })}
                        >
                            {t('isSync')}
                        </div>
                    </Item.Description>
                )}
            </Item.Content>
        </Item>
    );
};

export default PlanItem;
