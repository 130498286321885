import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { List, ListItem } from 'semantic-ui-react';
import useUserRole from '../../common/hooks/useUserRole';
import { UserRoles, VoidHandler } from '../../common/types';
import { setRole } from '../../features/user/userActions';
import Button from '../Button';
import Icon from '../Icon';
import IconWithDropdown from '../IconWithDropdown';
import styles from './RoleMenu.module.scss';

type Props = {
    style?: CSSProperties;
    className?: string;
    onClick?: VoidHandler;
    onRoleClick?: VoidHandler;
    children?: React.ReactNode;
};

const RoleMenu: FunctionComponent<Props> = ({ children, className }) => {
    const { t } = useTranslation(['common']);
    const role = useUserRole();
    const roles = Object.keys(UserRoles);
    const dispatch = useDispatch();
    const handleClick = (value: UserRoles) => {
        dispatch(setRole({ role: value }));
    };

    return (
        <IconWithDropdown
            iconId={'logo-sd'}
            iconSize={'big'}
            iconOnly
            className={styles.iconDropDown}
            direction={'left'}
            tooltip={t('change role') as string}
            tooltipPosition={'bottom left'}
            badgeContent={
                <Icon id={'user-' + role} size={'small'} stroke={8} />
            }
            badgeClassName={styles.badge}
            badgeTitle={role}
        >
            <List as="menu" horizontal className={styles.roles}>
                {roles.map((value, index) => {
                    return value !== UserRoles.sales ? (
                        <ListItem
                            key={'id-' + value}
                            as="li"
                            className={classNames(
                                styles.listItem,
                                styles.withSeparator
                            )}
                        >
                            <Button
                                className={className}
                                icon={'user-' + value}
                                iconSize={'small'}
                                iconOnly
                                iconStrokeWidth={10}
                                active={role === value}
                                disabled={role === value}
                                onClick={() => handleClick(value as UserRoles)}
                            >
                                {value}
                            </Button>
                        </ListItem>
                    ) : undefined;
                })}
                {children && <li className={styles.listItem}>{children}</li>}
            </List>
        </IconWithDropdown>
    );
};

export default RoleMenu;
