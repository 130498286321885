import React, { FunctionComponent } from 'react';
import useUserRole from '../../common/hooks/useUserRole';
import { UserRoles, UserRolesMap } from '../../common/types';

type Props = {
    renderOnlyForRole?: UserRoles;
    contentByRole?: UserRolesMap<React.ReactNode>;
    children?: React.ReactNode;
};

const UserRoleSpecific: FunctionComponent<Props> = ({
    renderOnlyForRole,
    contentByRole,
    children,
}) => {
    const currentUserRole = useUserRole();

    return contentByRole ? (
        <>{contentByRole[currentUserRole]}</>
    ) : currentUserRole === renderOnlyForRole ? (
        <>{children}</>
    ) : null;
};

export default UserRoleSpecific;

// TODO: types
export const forRoles =
    (roles: UserRolesMap<boolean>) => (Component: any) => (props: any) => {
        const userRole = useUserRole();
        return roles[userRole] ? <Component {...props} /> : null;
    };
