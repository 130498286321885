import { callSalesforceApiWith } from './index';
import { BasicSalesForceResponse } from '../common/responseTypes';
import { SALESFORCE_TARGET } from '../common/constants';
import { validateObjectData } from '../common/functions';
import { AccessTokenFunction } from '../common/types';

export const createQuoteFile = async (
    quoteId: string,
    accessToken: AccessTokenFunction
) => {
    return await callSalesforceApiWith<BasicSalesForceResponse>({
        name: SALESFORCE_TARGET,
        middleware: [validateObjectData],
        options: {
            method: 'POST',
            body: JSON.stringify({
                Quote__c: quoteId,
                TVA_CFB__Parent_ID__c: quoteId,
            }),
        },
        accessToken,
    });
};

export const createQaReviewFile = async (
    qaReviewId: string,
    accessToken: AccessTokenFunction
) => {
    return await callSalesforceApiWith<BasicSalesForceResponse>({
        name: SALESFORCE_TARGET,
        middleware: [validateObjectData],
        options: {
            method: 'POST',
            body: JSON.stringify({
                Qa_Review__c: qaReviewId,
                TVA_CFB__Parent_ID__c: qaReviewId,
            }),
        },
        accessToken,
    });
};
