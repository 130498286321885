import React, { FunctionComponent } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useUi } from '../../../common/hooks/useUi';
import Hotkeys from '../../../common/hotkeys';
import {
    dispatchAndSaveToLocalStorage,
    togglePanelsVisibility,
} from '../../../features/designerQuote/designerQuoteActions';
import Button from '../../Button';
import Square from '../../Square';
import Stack from '../../Stack';
import styles from './PanelToggle.module.scss';
import { useAppDispatch } from '../../../common/hooks';

const PanelToggle: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const [{ selectedPanelIndices, hidePanels, hideGrids }, updateUi] = useUi();

    const handleShowHideAllPanelsClick = () => {
        updateUi({ hidePanels: !hidePanels, hideGrids: !hideGrids });
    };

    const handleShowHidePanelsClick = () => {
        dispatch(
            dispatchAndSaveToLocalStorage(togglePanelsVisibility({ data: {} }))
        );
    };

    useHotkeys(Hotkeys.HIDE_PANEL.hook, handleShowHidePanelsClick, {}, [
        selectedPanelIndices,
    ]);

    const noSelectedPanels = selectedPanelIndices.length === 0;

    return (
        <Stack className={styles.panelToggle}>
            <Square>
                <Button
                    icon={'view-all'}
                    iconActive={'view-none'}
                    active={!hidePanels}
                    type={'sales'}
                    onClick={handleShowHideAllPanelsClick}
                />
            </Square>
            <Square>
                <Button
                    icon={'eye'}
                    type={'sales'}
                    onClick={handleShowHidePanelsClick}
                    disabled={noSelectedPanels}
                />
            </Square>
        </Stack>
    );
};

export default PanelToggle;
