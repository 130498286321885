import { Dispatch } from 'redux';
import fetchOpportunity from '../../api/fetchOpportunity';
import { isPlanOldVersion, redirectToRoofTool } from '../../common/functions';
import { renameKeys, snakeToCamel } from '../../common/helpers';
import {
    GetOpportunityActionCreator,
    ModalButton,
    OpportunityActionCreator,
    OpportunityActionPayload,
    OpportunityActionType,
    OpportunityState,
    RootState,
    UserRoles,
} from '../../common/types';
import i18n from '../../i18n';
import { closeModal, openModal } from '../modals/modalActions';
import { newNotification } from '../notifications/notificationsActions';

export const requestOpportunity: OpportunityActionCreator<
    OpportunityActionPayload
> = (payload) => {
    return {
        type: OpportunityActionType.REQUEST_OPPORTUNITY,
        payload,
    };
};

export const setOpportunity: OpportunityActionCreator<
    OpportunityActionPayload
> = (payload) => {
    return {
        type: OpportunityActionType.SET_OPPORTUNITY,
        payload,
    };
};

export const fetchOpportunityFailed: OpportunityActionCreator<
    OpportunityActionPayload
> = (payload) => {
    return {
        type: OpportunityActionType.FETCH_OPPORTUNITY_FAILED,
        payload,
    };
};

export const getOpportunity: GetOpportunityActionCreator =
    ({ opportunityId }) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        dispatch(requestOpportunity({ opportunityId }));
        const [opportunity, error] = await fetchOpportunity({ opportunityId });
        const state = getState();
        const role = state.user.role;

        if (error) {
            dispatch(
                newNotification({
                    type: 'error',
                    message: i18n.t('notifications:noOpportunityFetching'),
                    details: error.message,
                })
            );
            dispatch(fetchOpportunityFailed({ opportunityId }));
            return;
        }

        const { planningVersion } = renameKeys(
            snakeToCamel,
            opportunity!
        ) as OpportunityState;

        const dispatchOpportunity = (
            opportunityId: string,
            opportunity: OpportunityState
        ) => {
            dispatch(
                setOpportunity({
                    opportunityId,
                    data: renameKeys(snakeToCamel, opportunity),
                })
            );
        };

        if (isPlanOldVersion(planningVersion!)) {
            const handleCancel = (
                opportunityId: string,
                opportunity: OpportunityState
            ) => {
                dispatchOpportunity(opportunityId, opportunity);
                dispatch(closeModal());
            };

            const handleConfirm = () => {
                redirectToRoofTool(`oppId=${opportunityId}`);
            };

            const buttons: ModalButton[] = [
                {
                    text: i18n.t('modals:oldVersion.confirm') as string,
                    type: 'positive',
                    onClick: () => handleConfirm(),
                },
            ];
            if (role !== UserRoles.sales) {
                buttons.push({
                    text: i18n.t('modals:oldVersion.cancel') as string,
                    type: 'negative',
                    onClick: () => handleCancel(opportunityId, opportunity!),
                });
            }

            dispatch(
                openModal({
                    modalProps: {
                        title: i18n.t('modals:oldVersion.title'),
                        description: i18n.t('modals:oldVersion.description'),
                        onModalClose: () =>
                            handleCancel(opportunityId, opportunity!),
                        buttons: buttons,
                        size: 'large',
                    },
                })
            );
        } else {
            dispatchOpportunity(opportunityId, opportunity!);
        }
    };
