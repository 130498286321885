import React, { FunctionComponent } from 'react';
import { mmToPixels } from '../../common/functions';
import { Rectangle } from '../../common/types';

type Props = {
    // Rectangle measurements must be provided in mm
    rectangle: Rectangle;
    thickness?: number;
    className?: string;
};

const SelectionRectangle: FunctionComponent<Props> = ({
    rectangle,
    thickness,
    className,
}) => {
    const thicknessOutlineParam =
        thickness !== undefined
            ? `${thickness}px`
            : 'calc(2px * (var(--stroke-scale-x) + var(--stroke-scale-y)) / 2)';

    return (
        <div
            className={className}
            style={{
                top: mmToPixels(rectangle.top),
                left: mmToPixels(rectangle.left),
                width: mmToPixels(rectangle.width),
                height: mmToPixels(rectangle.height),
                outline: `${thicknessOutlineParam} dashed currentColor`,
                boxShadow: `0 0 0 ${thicknessOutlineParam} #0006`,
                position: 'absolute',
                backdropFilter: 'invert(1)',
            }}
        />
    );
};

export default SelectionRectangle;
