import { canvasesToBlobs } from '../common/functions/imageOfCanvas';
import { stringPlanningSurfacesToBlob } from '../common/functions/imageOfSurfaces';
import { AccessTokenFunction, RootState } from '../common/types';
import { createQaReviewFile } from './createQuoteFile';
import { getStringPlan, saveStringPlan } from './generateStringPlan';
import { savePlanningPictures } from './savePlanningPirctures';
import {
    deleteStringPlan,
    saveToSalesforce,
    updateQuote,
} from './saveToSalesforce';

// // THIS IS THE ### main main main ###
// // Save the Quote, returns the (new) QuoteId
export const saveQuoteMethod = async (p: {
    rootState: RootState;
    opportunityId: string;
    accessToken: AccessTokenFunction;
}): Promise<[string | undefined, Error?]> => {
    try {
        // ## PHASE I: SAVE THE QUOTE ##
        const quoteId = await saveToSalesforce(p);

        // ## PHASE II: SAVE THE PICTURES ##
        await savePlanningPicturesFromQuote({ ...p, quoteId });

        // // Update sync
        await updateQuote({
            quoteId,
            accessToken: p.accessToken,
            payload: {
                sd_sync__c: true,
            },
        });

        return [quoteId];
    } catch (error) {
        return [undefined, error as Error];
    }
};

export const savePlanningPicturesFromQuote = async (p: {
    rootState: RootState;
    opportunityId: string;
    accessToken: AccessTokenFunction;
    quoteId: string;
}) => {
    // Take screenshots of Canvases
    const blobs = await canvasesToBlobs(p.rootState);

    // save the blobs
    await savePlanningPictures({
        quoteId: p.quoteId,
        accessToken: p.accessToken,
        blobs,
    });
    const opp = p.rootState.opportunity;
    if (opp.qaReviewId) {
        deleteStringPlan(opp.qaReviewId, p.accessToken).then();
        const file = await createQaReviewFile(opp.qaReviewId, p.accessToken);
        const surfacesBlobs = await stringPlanningSurfacesToBlob(p.rootState);
        const stringPlanResponse = await getStringPlan(
            surfacesBlobs,
            p.opportunityId,
            p.rootState,
            file.id
        );
        if (!stringPlanResponse.error) {
            await saveStringPlan(
                {
                    url: stringPlanResponse.url!,
                    eTag: stringPlanResponse.eTag!,
                    bucket: stringPlanResponse.bucket!,
                    size: stringPlanResponse.size!,
                    id: file.id,
                },
                p.accessToken
            );
        }
    }
};
