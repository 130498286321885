import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './BadgeRecordType.module.scss';

type Props = {
    className?: string;
    style?: CSSProperties;
    recordType: string;
};

const BadgeRecordType: FunctionComponent<Props> = ({
    className,
    style,
    recordType,
}) => {
    const { t } = useTranslation(['startPlanning']);
    return (
        <div
            className={classNames(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                className,
                style,
                styles.root,
                styles['type-' + recordType]
            )}
            data-tooltip={'Typ: Solar' + t(recordType)}
            data-position="bottom center"
            data-variation={'mini'}
        >
            {t(`recordType.${recordType}`)}
        </div>
    );
};

export default BadgeRecordType;
