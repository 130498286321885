const getNewAccessToken = async (p: {
    refreshToken: string;
    fetchUrl: string;
    apiToken: string;
}): Promise<[any, Error?]> => {
    try {
        const response = await fetch(`${p.fetchUrl}/refresh`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                Authorization: `Bearer ${p.apiToken}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({ refresh_token: p.refreshToken }),
        });
        const data = await response.json();
        return [data];
    } catch (e) {
        return [undefined, e as Error];
    }
};

export default getNewAccessToken;
