import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    CheckboxProps,
    Form as SemanticForm,
    FormInputProps,
} from 'semantic-ui-react';
import { isBlank, valueInBetween } from '../../common/functions/validations';
import useDesignerQuote from '../../common/hooks/useDesignerQuote';
import useOpportunity from '../../common/hooks/useOpportunity';
import useSystemCharacteristics from '../../common/hooks/useSystemCharacteristics';
import { ConsumptionObject, StringIndexable } from '../../common/types';
import {
    updateConsumptionData,
    updateMonthlyCosts,
    updateTotalAnnualConsumptionValue,
} from '../../features/designerQuote/designerQuoteActions';
import i18n from '../../i18n';
import Checkbox from '../Checkbox';
import FormInput from '../FormInput';
import styles from './EnergyConsumersForm.module.scss';

const EnergyConsumersForm: FunctionComponent = () => {
    const { t } = useTranslation(['energyConsumers']);
    const designerQuote = useDesignerQuote();
    const opp = useOpportunity();
    const values: {
        [key in keyof ConsumptionObject]: [string, number | '', string];
    } = {
        energyConsumptionHouseholdKwh: [
            'household',
            designerQuote.salesforce.energyConsumptionHouseholdKwh || '',
            opp.annualPowerConsumptionKwh?.toString() || '6000',
        ],
        energyConsumptionSaunaKwh: [
            'sauna',
            designerQuote.salesforce.energyConsumptionSaunaKwh || '',
            '500',
        ],
        energyConsumptionPoolKwh: [
            'pool',
            designerQuote.salesforce.energyConsumptionPoolKwh || '',
            '500',
        ],
        energyConsumptionHeatPumpKwh: [
            'heat pump',
            designerQuote.salesforce.energyConsumptionHeatPumpKwh || '',
            '2000',
        ],
        /* energyConsumptionCarKwh: [
            'car',
            designerQuote.salesforce.energyConsumptionCarKwh || '',
            '1500',
        ], */
        energyConsumptionBigConsumersKwh: [
            'others',
            designerQuote.salesforce.energyConsumptionBigConsumersKwh || '',
            '500',
        ],
    };
    const minMax = { min: 0, max: 25000, step: 1 };
    const inputProps: StringIndexable = {};
    const validation = (val: string) =>
        valueInBetween(val, minMax) || isBlank(val);
    Object.keys(values).forEach((id) => {
        const source = values[id as keyof ConsumptionObject]![0];
        const value = values[id as keyof ConsumptionObject]![1];
        inputProps[id] = {
            placeholder: t(source),
            value: value,
            disabled: !value,
            label: {
                basic: true,
                content: <span className="unit">kWh / kWp</span>,
            },
            validation,
            errorMessage: i18n.t('validations:valuesInBetween', minMax),
            type: 'number',
            input: minMax,
        } as FormInputProps;
    });

    const updateConsumptionValue = (id: string, value: string | null) => {
        if (
            !Object.keys(values).includes(id) ||
            (value !== null && !validation(value))
        ) {
            return;
        }
        dispatch(
            updateConsumptionData({
                consumptionData: {
                    [id.toString()]: value === null ? null : parseFloat(value),
                },
            })
        );

        dispatch(updateTotalAnnualConsumptionValue());
        dispatch(updateMonthlyCosts());
    };

    const dispatch = useDispatch();

    const onValueChangedHandler = (
        event: React.FocusEvent,
        data: FormInputProps
    ) => {
        const id = data.id;
        const value = data.value;
        updateConsumptionValue(id as string, value);
    };

    useSystemCharacteristics();

    const handleCheckboxClicked = (props: CheckboxProps, key: string) => {
        if (props.checked) {
            if (!designerQuote.salesforce[key as keyof ConsumptionObject]) {
                updateConsumptionValue(
                    key,
                    values[key as keyof ConsumptionObject]![2]
                );
            }
        } else {
            updateConsumptionValue(key, null);
        }
    };

    return (
        <SemanticForm>
            {Object.keys(inputProps).map((key: string) => {
                return (
                    <div key={key} className={styles.consumerRow}>
                        <FormInput
                            id={key}
                            key={'form-' + key}
                            onBlur={onValueChangedHandler}
                            onClick={() => {
                                const props = { checked: true };
                                handleCheckboxClicked(props, key);
                            }}
                            formInput={inputProps[key]}
                            className={classNames(styles.form, {
                                [styles.closed]: !inputProps[key].value,
                            })}
                        />
                        <Checkbox
                            key={'check-' + key}
                            className={styles.checkbox}
                            checked={!!inputProps[key].value}
                            onChange={(e, props) => {
                                handleCheckboxClicked(props, key);
                            }}
                        />
                    </div>
                );
            })}
        </SemanticForm>
    );
};

export default EnergyConsumersForm;
