import useDesignerQuote from './useDesignerQuote';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updatePanelAmount } from '../../features/designerQuote/designerQuoteActions';
import { getPanelCount } from '../functions';

export default function usePanelCount() {
    const dispatch = useDispatch();
    const designerQuote = useDesignerQuote();
    useEffect(() => {
        const panelCount = getPanelCount(designerQuote.planning);
        if (
            panelCount !==
            (designerQuote.salesforce.Products?.panels?.quantity ?? 0)
        ) {
            dispatch(
                updatePanelAmount({
                    data: {
                        amount: panelCount,
                    },
                })
            );
        }
    }, [designerQuote.planning]);
}
