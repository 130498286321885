import { Dispatch } from 'redux';
import {
    ProcessCodeActionCreator,
    UserActionCreator,
    UserActionType,
    UserRoleActionPayload,
    UserSetTokensPayload,
    UserTokenActionPayload,
} from '../../common/types';

export const setTokens: UserActionCreator<UserSetTokensPayload> = (
    payload
) => ({
    type: UserActionType.SET_TOKENS,
    payload,
});

export const renewAccessToken: UserActionCreator<UserTokenActionPayload> = (
    payload
) => ({
    type: UserActionType.RENEW_ACCESS_TOKEN,
    payload,
});

export const setRole: UserActionCreator<UserRoleActionPayload> = (payload) => ({
    type: UserActionType.SET_USER_ROLE,
    payload,
});

export const fetchAndSetTokenFromCode: ProcessCodeActionCreator =
    ({ code, fetchUrl, apiToken }) =>
    async (dispatch: Dispatch): Promise<void> => {
        const response = await fetch(`${fetchUrl}/callback`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                Authorization: `Bearer ${apiToken}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({ code }),
        });

        const data = await response.json();
        if (!data.access_token) {
            throw new Error(`Authentication failed: ${JSON.stringify(data)} `);
        } else {
            sessionStorage.setItem('auth', JSON.stringify(data));
            dispatch(
                setTokens({
                    accessToken: data.access_token,
                    refreshToken: data.refresh_token,
                })
            );
        }
    };
