import React, {
    CSSProperties,
    FunctionComponent,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { CANVAS_SIZE } from '../../common/constants';
import { useUi } from '../../common/hooks/useUi';
import Hotkeys from '../../common/hotkeys';
import {
    ArrowKey,
    Canvas,
    PanelsUpdateHandler,
    Point,
    Vertices,
    VerticesUpdateHandler,
} from '../../common/types';
import CanvasElement from '../CanvasElement';
import StringCanvasElement from '../StringPlanner/StringCanvasElement/';
import Zoomable from '../Zoomable';
import { ZoomableRef } from '../Zoomable/Zoomable';

type Props = {
    style?: CSSProperties;
    className?: string;
    canvas?: Canvas;
    isShapeBuilderOn?: boolean;
    onFinishedDrawing?: (vertices: Vertices, canvasId: string) => void;
    onPanelsUpdate?: PanelsUpdateHandler;
    onVerticesUpdate?: VerticesUpdateHandler;
    stringPlanner?: boolean;
    children?: React.ReactNode;
};

const Stage: FunctionComponent<Props> = ({
    style,
    className,
    canvas,
    isShapeBuilderOn,
    onFinishedDrawing,
    onPanelsUpdate,
    onVerticesUpdate,
    stringPlanner,
    children,
}) => {
    const [{ isHandToolActive }, updateUi] = useUi();
    const [zoomableState, setZoomableState] = useState<{
        scale: number;
        offset: Point;
    }>();

    const handleZoomableChange = useRef(
        (props: { zoom: number; pan: Point }) => {
            setZoomableState({ scale: props.zoom, offset: props.pan });
        }
    ).current;

    const zoomableRef = useRef<ZoomableRef>(null);

    useEffect(() => {
        if (!isHandToolActive || !zoomableRef.current) return;

        const handleMouseRightClick = (e: MouseEvent) => {
            e.preventDefault();
            zoomableRef.current!.zoom(1 / 1.5);
        };

        const handleMouseDoubleClick = () => {
            zoomableRef.current!.zoom(1.5);
        };

        window.addEventListener('contextmenu', handleMouseRightClick);
        window.addEventListener('dblclick', handleMouseDoubleClick);

        return () => {
            window.removeEventListener('contextmenu', handleMouseRightClick);
            window.removeEventListener('dblclick', handleMouseDoubleClick);
        };
    }, [isHandToolActive, zoomableRef.current]);

    useEffect(() => {
        const reset =
            zoomableRef.current &&
            zoomableState?.scale !== zoomableRef.current?.fitScale
                ? zoomableRef.current.resetZoom
                : undefined;

        updateUi({ resetStageZoom: reset });
    }, [zoomableState?.scale]);

    const handleKeyPress = ({ code }: KeyboardEvent) => {
        if (!zoomableRef.current) return;
        switch (code) {
            case 'Digit0':
                zoomableRef.current.resetZoom();
                break;
            case 'Equal':
            case 'BracketRight':
                zoomableRef.current.zoom(1.2);
                break;
            case 'Minus':
            case 'Slash':
                zoomableRef.current.zoom(1 / 1.2);
                break;
            case ArrowKey.ArrowLeft:
                zoomableRef.current.pan(-20, 0);
                break;
            case ArrowKey.ArrowRight:
                zoomableRef.current.pan(20, 0);
                break;
            case ArrowKey.ArrowUp:
                zoomableRef.current.pan(0, -20);
                break;
            case ArrowKey.ArrowDown:
                zoomableRef.current.pan(0, 20);
                break;
        }
    };

    useHotkeys(
        Hotkeys.ZOOM_AND_PAN.hook,
        (keyEvent: KeyboardEvent) => {
            handleKeyPress(keyEvent);
        },
        { keydown: true },
        []
    );

    useHotkeys(
        Hotkeys.HAND_TOOL.hook,
        (keyEvent: KeyboardEvent) => {
            updateUi({ isHandToolActive: keyEvent.type === 'keydown' });
        },
        { keydown: true, keyup: true },
        []
    );

    const dimensions = canvas?.imageBox.dimensions || CANVAS_SIZE;

    return canvas !== undefined ? (
        stringPlanner ? (
            <StringCanvasElement
                disabled={isHandToolActive}
                size={dimensions}
                key={canvas.id}
                className={'stringCanvas'}
            >
                {children}
            </StringCanvasElement>
        ) : (
            <Zoomable
                refObject={zoomableRef}
                style={style}
                scrollPannable
                panCursor={'grab'}
                dragPannable={isHandToolActive}
                contentSize={dimensions}
                onChange={handleZoomableChange}
                className={className}
            >
                <CanvasElement
                    disabled={isHandToolActive}
                    environmentState={zoomableState}
                    size={dimensions}
                    descriptor={canvas}
                    key={canvas.id}
                    isShapeBuilderOn={isShapeBuilderOn}
                    onFinishedDrawing={onFinishedDrawing}
                    onPanelsUpdate={onPanelsUpdate}
                    onVerticesUpdate={onVerticesUpdate}
                />
            </Zoomable>
        )
    ) : null;
};

export default Stage;
