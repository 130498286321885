import React from 'react';
import { Dimensions, Point } from '../../common/types';

export type State = {
    scale: number;
    defaultScale: number;
    minScale: number;
    maxScale: number;
    pivot: Point;
    offset: Point;
    pan: Point;
    defaultPan: Point;
    startScale: number;
    clientX: number;
    clientY: number;
    isDragging: boolean;
    zoomable: boolean | undefined;
    scrollPannable: boolean | undefined;
    handleMouseMove: (e: React.MouseEvent) => void;
    handleMouseDown: (e: React.MouseEvent) => void;
    handleMouseUp: (e: React.MouseEvent) => void;
};

function calculateScaleAndPan(
    sourceScale: number,
    incrementalScale: number,
    x: number,
    y: number,
    pivot: Point,
    minScale = 0,
    maxScale = 0
) {
    let scale = Math.max(minScale, sourceScale * incrementalScale);
    if (maxScale > 0) scale = Math.min(scale, maxScale);
    return {
        scale,
        pan: {
            x: x - pivot.x * scale,
            y: y - pivot.y * scale,
        },
    };
}

const calculatePivot = (
    x: number,
    y: number,
    position: Point,
    scale: number
) => ({
    x: (x - position.x) / scale,
    y: (y - position.y) / scale,
});

export const gestureStateHandler = (e: Event & { scale: number }) => {
    e.preventDefault();
    return (prevState: State) =>
        prevState.zoomable
            ? {
                  ...prevState,
                  ...calculateScaleAndPan(
                      prevState.startScale,
                      e.scale,
                      prevState.clientX,
                      prevState.clientY,
                      prevState.pivot
                  ),
              }
            : prevState;
};
export const wheelScaleStateHandler = (e: WheelEvent) => (prevState: State) => {
    if (!prevState.zoomable) return prevState;
    const { scale, clientX, clientY, pivot, minScale, maxScale } = prevState;

    const scaleAndPan = calculateScaleAndPan(
        scale,
        1 - e.deltaY * 0.01,
        clientX,
        clientY,
        pivot,
        minScale,
        maxScale
    );
    return {
        ...prevState,
        ...scaleAndPan,
    };
};
export const wheelPanStateHandler =
    (e: WheelEvent, containerSize: Dimensions, contentSize?: Dimensions) =>
    (prevState: State) => {
        if (!prevState.scrollPannable) return prevState;

        const pan = {
            x: prevState.pan.x - e.deltaX,
            y: prevState.pan.y - e.deltaY,
        };

        if (contentSize) {
            const dW =
                containerSize.width - contentSize.width * prevState.scale;
            const dH =
                containerSize.height - contentSize.height * prevState.scale;

            pan.x = Math.max(
                Math.min(pan.x, containerSize.width * 0.5),
                dW - containerSize.width * 0.5
            );
            pan.y = Math.max(
                Math.min(pan.y, containerSize.height * 0.5),
                dH - containerSize.height * 0.5
            );
        }

        return {
            ...prevState,
            pan,
            pivot: calculatePivot(
                prevState.clientX,
                prevState.clientY,
                pan,
                prevState.scale
            ),
        };
    };
export const gestureStartStateHandler = (e: Event) => {
    e.preventDefault();
    return (prevState: State) => ({
        ...prevState,
        startScale: prevState.scale,
    });
};
export const handleMouseDownState = (prevState: State) => ({
    ...prevState,
    isDragging: true,
});
export const handleMouseUpState = (prevState: State) => ({
    ...prevState,
    isDragging: false,
});
export function mouseMoveStateHandler(e: React.MouseEvent) {
    const { clientX, clientY, movementX, movementY } = e;
    return (prevState: State) => ({
        ...prevState,
        pivot: calculatePivot(clientX, clientY, prevState.pan, prevState.scale),
        clientX,
        clientY,
        ...(prevState.isDragging
            ? {
                  pan: {
                      x: prevState.pan.x + movementX,
                      y: prevState.pan.y + movementY,
                  },
              }
            : {}),
    });
}

export function removeContainerListeners(
    containerElement: HTMLDivElement,
    handleWheel: (e: WheelEvent) => void,
    handleGestureStart: (e: any) => void,
    handleGesture: (e: any) => void
) {
    containerElement.removeEventListener('wheel', handleWheel, true);
    containerElement.removeEventListener('gesturestart', handleGestureStart);
    containerElement.removeEventListener('gesturechange', handleGesture);
    containerElement.removeEventListener('gestureend', handleGesture);
}
export function addContainerListeners(
    containerElement: HTMLDivElement,
    handleWheel: (e: WheelEvent) => void,
    handleGestureStart: (e: any) => void,
    handleGesture: (e: any) => void
) {
    containerElement.addEventListener('wheel', handleWheel, {
        passive: false,
        capture: true,
    });
    containerElement.addEventListener('gesturestart', handleGestureStart);
    containerElement.addEventListener('gesturechange', handleGesture);
    containerElement.addEventListener('gestureend', handleGesture);
}

export const handleZoomState = (multiplier: number) => (prevState: State) => {
    if (!prevState.zoomable) return prevState;
    const { scale, clientX, clientY, pivot, minScale, maxScale } = prevState;

    const scaleAndPan = calculateScaleAndPan(
        scale,
        multiplier,
        clientX,
        clientY,
        pivot,
        minScale,
        maxScale
    );
    return {
        ...prevState,
        ...scaleAndPan,
    };
};

export const handleZoomResetState = (prevState: State) => {
    if (!prevState.zoomable) return prevState;
    const { defaultScale, defaultPan } = prevState;

    return {
        ...prevState,
        scale: defaultScale,
        pan: defaultPan,
    };
};
