import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debouncedGetSystemCharacteristics } from '../../api/getSystemCharacteristics';
import { updateMachineROIValueInPlanning } from '../../features/designerQuote/designerQuoteActions';
import {
    allSurfaceDetails,
    getSurfacesOfPlanning,
    getValidPanelsCount,
    hasSurfaceDetailsChanged,
} from '../functions';
import { ModulePlanningData, PlanningData, RootState } from '../types';
import useDesignerQuote from './useDesignerQuote';
import useOpportunity from './useOpportunity';
import useTotalEnergyConsumption from './useTotalEnergyConsumption';

export default function useSystemCharacteristics() {
    const designerQuote = useDesignerQuote();
    const opportunity = useOpportunity();
    const products = designerQuote.salesforce.Products;
    const dispatch = useDispatch();
    const totalConsumption = useTotalEnergyConsumption();
    const planning = useSelector<RootState, PlanningData | undefined>(
        (state) => state.designerQuote.planning
    );
    const [surfaces, setSurfaces] = useState(getSurfacesOfPlanning(planning!));

    const getAndSetSystemCharacteristics = (
        validSurfaces: ModulePlanningData[]
    ) => {
        debouncedGetSystemCharacteristics({
            opportunityId: opportunity.opportunityId,
            surfaces: validSurfaces,
            panelPower: products?.panels?.power || 0,
            ...(products?.battery && {
                batteryPower: products?.battery.power,
            }),
            sauna: designerQuote.salesforce.energyConsumptionSaunaKwh || 0,
            heatPump:
                designerQuote.salesforce.energyConsumptionHeatPumpKwh || 0,
            car: designerQuote.salesforce.energyConsumptionCarKwh || 0,
            pool: designerQuote.salesforce.energyConsumptionPoolKwh || 0,
            household:
                designerQuote.salesforce.energyConsumptionHouseholdKwh || 0,
            others:
                designerQuote.salesforce.energyConsumptionBigConsumersKwh || 0,
        })
            .then(async (promise) => {
                const systemCharacteristics = await promise;

                const ownConsumptionRate: number =
                    systemCharacteristics.with_battery
                        ? parseFloat(
                              (
                                  systemCharacteristics.with_battery
                                      .own_consumption_rate * 100
                              ).toFixed(2)
                          )
                        : parseFloat(
                              (
                                  systemCharacteristics.without_battery
                                      .own_consumption_rate * 100
                              ).toFixed(2)
                          );

                dispatch(
                    updateMachineROIValueInPlanning({
                        roiData: {
                            selfConsumptionRate: ownConsumptionRate,
                        },
                    })
                );
            })
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .catch(() => {});
    };

    const hasValidSurfaces = (
        onTrue: (a: ModulePlanningData[], b: ModulePlanningData[]) => void,
        extraCondition = true
    ) => {
        const newSurfaces = getSurfacesOfPlanning(planning!);
        const validSurfaces = newSurfaces.filter(
            (surface) => getValidPanelsCount(surface.panels) > 0
        );
        if (
            totalConsumption > 0 &&
            allSurfaceDetails(planning!) &&
            validSurfaces.length > 0 &&
            extraCondition
        ) {
            onTrue(newSurfaces, validSurfaces);
        }
    };

    useEffect(() => {
        hasValidSurfaces((newSurfaces, validSurfaces) => {
            getAndSetSystemCharacteristics(validSurfaces);
            setSurfaces(newSurfaces);
        });
    }, [
        products?.panels?.quantity || 0,
        products?.battery,
        products?.panels?.power,
        designerQuote.salesforce.energyConsumptionHouseholdKwh,
        designerQuote.salesforce.energyConsumptionPoolKwh,
        designerQuote.salesforce.energyConsumptionSaunaKwh,
        designerQuote.salesforce.energyConsumptionHeatPumpKwh,
        designerQuote.salesforce.energyConsumptionBigConsumersKwh,
        designerQuote.salesforce.energyConsumptionCarKwh,
    ]);

    useEffect(() => {
        const newSurfaces = getSurfacesOfPlanning(planning!);
        hasValidSurfaces((newSurfaces, validSurfaces) => {
            getAndSetSystemCharacteristics(validSurfaces);
            setSurfaces(newSurfaces);
        }, hasSurfaceDetailsChanged(newSurfaces, surfaces));
    }, [planning]);

    useEffect(() => {
        if (!designerQuote.salesforce.degreeOfSelfSufficiency) {
            hasValidSurfaces((newSurfaces, validSurfaces) => {
                getAndSetSystemCharacteristics(validSurfaces);
                setSurfaces(newSurfaces);
            });
        }
    }, [designerQuote.salesforce.degreeOfSelfSufficiency]);
}
