import { ProductsActionType, ProductsReducer } from '../../common/types';
import { defaultProductsState } from '../../store/defaults';

export const productsReducer: ProductsReducer = (
    state = defaultProductsState,
    action
) => {
    // state.battery
    switch (action.type) {
        case ProductsActionType.PRODUCTS_SET:
            return { ...action.payload };
        case ProductsActionType.PRODUCTS_UPDATE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
