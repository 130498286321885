import React, { CSSProperties, FunctionComponent } from 'react';
import { Divider } from 'semantic-ui-react';
import Hotkeys from '../../common/hotkeys';
import IconWithDropdown from '../IconWithDropdown';
import Stack from '../Stack';
import styles from './ShortcutMenu.module.scss';
import ShortcutMenuItem from './ShortcutMenuItem';

type Props = {
    className?: string;
    style?: CSSProperties;
};

const ShortcutMenu: FunctionComponent<Props> = ({ style }) => {
    const hotkeys = Object.values(Hotkeys);

    return (
        <div className={styles.shortcutMenu}>
            <IconWithDropdown
                iconId="keyboard"
                iconOnly={true}
                direction="left"
                position="top"
                style={style}
            >
                <div className={styles.scroller}>
                    <Stack className={styles.shortcutTable}>
                        {hotkeys.map((hotkey, index) => {
                            return (
                                hotkey.display && (
                                    <ShortcutMenuItem
                                        key={index}
                                        name={hotkey.name}
                                        display={hotkey.display}
                                    />
                                )
                            );
                        })}
                    </Stack>
                </div>
                <Divider className={styles.separator} />
            </IconWithDropdown>
        </div>
    );
};

export default ShortcutMenu;
