import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { ModalState, RootState } from '../../common/types';
import Modal from '../Modal';

const ModalRoot: FunctionComponent = () => {
    const modal = useSelector<RootState, ModalState>((state) => state.modal);
    if (modal) return <Modal {...modal.modalProps} />;
    else return null;
};
export default ModalRoot;
