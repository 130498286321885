import classNames from 'classnames';
import React, {
    CSSProperties,
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import { GeometryState, Point } from '../../common/types';
import styles from './Geometry.module.scss';
import MoveControls from './MoveControls';
import RotationControls from './RotationControls';
import ScaleControls from './ScaleControls';

type Props = {
    style?: CSSProperties;
    className?: string;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    rotatable?: boolean;
    movable?: boolean;
    scalable?: boolean;
    scale?: number;
    flipX?: boolean;
    flipY?: boolean;
    transformOrigin?: { x: string | number; y: string | number };
    offset?: Point;
    environmentState?: { scale: number; offset: Point };
    onChangePositioning?: (props: GeometryState) => void;
    children?: React.ReactNode;
};

function getTransformMatrixString(
    scale1: number,
    scale2: number,
    rotation: number,
    position: Point,
    offset: Point
) {
    const rotCos = Math.cos(rotation);
    const rotSin = Math.sin(rotation);
    const resultingScale = scale1 * scale2;

    return `matrix(${resultingScale * rotCos},${resultingScale * rotSin},${
        resultingScale * -rotSin
    },${resultingScale * rotCos},${position.x + offset.x},${
        position.y + offset.y
    })`;
}

const Geometry: FunctionComponent<Props> = ({
    children,
    style,
    className,
    x = 0,
    y = 0,
    width,
    height,
    rotatable,
    movable,
    scalable,
    scale = 1,
    flipX,
    flipY,
    transformOrigin = { x: '50%', y: '50%' },
    offset = { x: 0, y: 0 },
    environmentState,
    onChangePositioning,
}) => {
    const [rotation, setRotation] = useState<number>(0);
    const [position, setPosition] = useState<Point>({ x, y });
    const [scaleValue, setScaleValue] = useState<number>(1);

    const transformOriginX: string =
        typeof transformOrigin.x === 'number'
            ? transformOrigin.x + 'px'
            : transformOrigin.x;
    const transformOriginY: string =
        typeof transformOrigin.y === 'number'
            ? transformOrigin.y + 'px'
            : transformOrigin.y;

    useEffect(() => {
        onChangePositioning &&
            onChangePositioning({ position, rotation, scaleValue });
    }, [rotation, position.x, position.y, scaleValue]);

    const imgScale = environmentState?.scale
        ? {}
        : ({ '--imgScale': scale } as CSSProperties);

    return (
        <div
            style={{
                ...style,
                transformOrigin: `${transformOriginX} ${transformOriginY}`,
                transform: getTransformMatrixString(
                    scale,
                    scaleValue,
                    rotation,
                    position,
                    offset
                ),
            }}
            className={classNames(styles.root, className)}
        >
            <div
                style={{
                    transform: `scale(${flipX ? '-1' : '1'}, ${
                        flipY ? '-1' : '1'
                    })`,
                    width: width || 'inherit',
                    height: height || 'inherit',
                    ...imgScale,
                }}
            >
                {children}
                {rotatable && (
                    <RotationControls
                        defaultValue={rotation}
                        onChange={setRotation}
                        className={styles.controls}
                        offset={position}
                        scaleAdjustment={scaleValue}
                    />
                )}
                {movable && (
                    <MoveControls
                        defaultValue={position}
                        onChange={setPosition}
                        className={styles.controls}
                        scaleAdjustment={environmentState?.scale || 1}
                    />
                )}
                {scalable && (
                    <ScaleControls
                        defaultValue={scaleValue}
                        onChange={setScaleValue}
                        className={styles.controls}
                        offset={position}
                        environmentState={environmentState}
                    />
                )}
            </div>
        </div>
    );
};

export default Geometry;
