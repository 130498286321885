import { Dispatch } from 'react';
import getPresignedUrl from '../../api/getPresignedUrl';
import getNewAccessToken from '../../api/refreshAccessToken';
import {
    getFacingSideAndDirection,
    getSpacingByConfiguration,
} from '../../components/ModuleElement/functions';
import { calculateTransform } from '../../components/SurfaceElement/functions';
import { renewAccessToken } from '../../features/user/userActions';
import {
    ANGLE_OF_PANEL_RAISED_ON_FLAT_ROOFS,
    CANVAS_SIZE,
    PANEL_SPACING,
    PLANNING_IMAGE_FILE_PREFIX,
    PLANNING_IMAGE_FOLDER_PREFIX,
    SCALE_MAX_HEIGHT,
    SCALE_MAX_WIDTH,
    SOUTH_FACING_PANEL_GUTTER,
} from '../constants';
import { SaveFileResponse } from '../responseTypes';
import { azimuthOptions, slopeOptions } from '../selectOptions';
import {
    AccessTokenFunction,
    BasicQuoteProduct,
    Canvas,
    DesignerQuoteState,
    Dimensions,
    DirectionsPlanningData,
    ElectricalCabinetUpgrade,
    ModulePlanningData,
    MountingSystem,
    ObjectWithNameAndId,
    OpportunityState,
    Panel,
    PanelPlanningData,
    Photo,
    PlanningData,
    PlanningDataRoiValues,
    Product,
    ProductTypes,
    RoiObject,
    RoiValueId,
    RoofToolPlanning,
    RoofTypes,
    RootState,
    SalesforceProductsState,
    SelectItem,
    SideDirections,
    Spacing,
    SurfacePayload,
    UnsetPanelPlanningData,
    Vertex,
    Vertices,
} from '../types';
import { createUUID } from '../uuids';
import { smartLayout } from './smartLayout';
import { isPyramidMountingSystem } from './products';

export const pipe =
    <T>(...methods: Array<(input: any) => Promise<T> | T>) =>
    async (input?: any): Promise<T> => {
        let output = input;
        for (const method of methods) {
            output = await method(output);
        }
        return output;
    };

export const throttle = <T1 extends Array<any>, T2>(
    func: (...args: T1) => T2,
    limit: number
): typeof func => {
    let cached: T2 | undefined;
    const ret = (...args: T1): T2 => {
        if (!cached) {
            cached = func(...args);
            setTimeout(() => {
                cached = undefined;
            }, limit);
        }
        return cached;
    };

    return ret as typeof func;
};

export const debounce = <F extends (...args: any[]) => any>(
    func: F,
    waitFor: number
) => {
    let timeout: NodeJS.Timeout;
    let rejectPromise: ((reason: any) => void) | undefined;
    return (...args: Parameters<F>): Promise<ReturnType<F>> => {
        timeout && clearTimeout(timeout);
        const rej = rejectPromise;
        rejectPromise = undefined;
        rej && rej('debounced');

        // if rejectPromise method is again assigned it means that there was another call triggered by reject
        // therefore we need to reject current one immediately
        if (rejectPromise) return Promise.reject('debounced');

        return new Promise((resolve, reject) => {
            rejectPromise = reject;
            timeout = setTimeout(() => {
                rejectPromise = undefined;
                resolve(func(...args));
            }, waitFor);
        });
    };
};

export const createValidateData =
    (type: string) =>
    (data: unknown): typeof data => {
        if (!data || typeof data !== type) throw new Error('Malformed data');
        return data;
    };

export const validateObjectData = createValidateData('object');

/**
 * Will return an array if IDs that were updated or removed from `previous` array when compared against `current`
 */
export const getUpdatedIds = (
    previous: ObjectWithNameAndId[],
    current: ObjectWithNameAndId[]
) => {
    const result: string[] = [];
    const productsById = current.reduce((res: any, p) => {
        res[p.id] = p;
        return res;
    }, {});
    previous.forEach((p) => {
        const { id } = p;
        if (!productsById[id]) {
            result.push(id);
            return;
        }
        if (JSON.stringify(p) !== JSON.stringify(productsById[id]))
            result.push(id);
    });
    return result;
};

export const getRandomItemFromArray = (array: Array<any>) => {
    return array[Math.floor(Math.random() * array.length)];
};
export const mmToPixels = (mm: number) => mm / 10;

export const getSlopedWidthOfPanel = (width: number) =>
    Math.round(Math.abs(width * Math.cos(ANGLE_OF_PANEL_RAISED_ON_FLAT_ROOFS)));

export const getOrientedDimensions = (
    width: number | undefined,
    height: number | undefined,
    roofType: RoofTypes,
    isHorizontal?: boolean
) => {
    switch (roofType) {
        case RoofTypes.flat: {
            return isHorizontal
                ? [height || 0, getSlopedWidthOfPanel(width || 0)]
                : [getSlopedWidthOfPanel(width || 0), height || 0];
        }
        default: {
            return isHorizontal
                ? [height || 0, width || 0]
                : [width || 0, height || 0];
        }
    }
};

export const getOrientedSpacing = (
    spacing: Spacing,
    isHorizontal?: boolean
): Spacing => (isHorizontal ? { x: spacing.y, y: spacing.x } : spacing);

const getScaledDownDimensions = (dimensions: Dimensions): Dimensions => {
    let scale = 1;

    if (
        dimensions.width > SCALE_MAX_WIDTH ||
        dimensions.height > SCALE_MAX_HEIGHT
    ) {
        if (dimensions.width >= dimensions.height)
            scale = SCALE_MAX_WIDTH / dimensions.width;
        else scale = SCALE_MAX_HEIGHT / dimensions.height;
    }

    return {
        width: dimensions.width * scale,
        height: dimensions.height * scale,
    };
};

const getImageNaturalWidthAndHeight = (src: string): Promise<Dimensions> => {
    return new Promise<Dimensions>((resolve, reject) => {
        const img = new Image();
        img.onload = () =>
            resolve({
                width: img.naturalWidth,
                height: img.naturalHeight,
            });
        img.onerror = () => reject(CANVAS_SIZE);
        img.src = src;
    });
};

export const createCanvasesFromArrayOfPhotos = async (
    arrayOfPhotos: Photo[]
): Promise<PlanningData> => {
    const canvases: PlanningData = { canvases: {} };

    for (const photo of arrayOfPhotos) {
        const id: string = createUUID();
        const dimensions = await getImageNaturalWidthAndHeight(photo.url);
        canvases.canvases[id] = {
            id: id,
            imageBox: {
                crops: [],
                url: photo.url,
                id: photo.id,
                dimensions: getScaledDownDimensions(dimensions),
            },
            surfaces: {},
        };
    }
    return canvases;
};

// A test to see if the plan returned from the api is from the old Roof tool
export const isOldTemplateFormat = (
    planning: RoofToolPlanning[] | PlanningData[]
): boolean => {
    return (
        Array.isArray(planning) &&
        planning.length > 0 &&
        'roofers' in planning[0]
    );
};

// For the time being a way to convert old object to new structure to deal with headache of API work
export const oldTemplateFormatToNewStructure = (
    oldPlanning: RoofToolPlanning[]
): PlanningData => {
    const newPlanningData: PlanningData = {
        canvases: {},
    };

    oldPlanning &&
        oldPlanning[0].roofers.forEach((roofer) => {
            const canvasId = createUUID();
            newPlanningData.canvases[canvasId] = {
                id: canvasId,
                imageBox: roofer.imageBox,
                surfaces: {},
            };
        });

    return newPlanningData;
};

export const getPanelFacing = (
    surface: ModulePlanningData,
    panelIndex: { hozIndex: number; verIndex: number },
    isHorizontal: boolean
) => {
    const getSideDirectionKey = getFacingSideAndDirection(
        panelIndex,
        surface.sideDirections!,
        surface.azimuth!,
        isHorizontal,
        false
    );

    return {
        side: getSideDirectionKey,
        hasGutter: !isAlternatingPanels(surface.azimuth!, isHorizontal, false),
        direction:
            surface.sideDirections![
                getSideDirectionKey as keyof SideDirections
            ],
    };
};

export const getPanelSpacing = (panel: PanelPlanningData): Spacing => {
    return typeof panel.spacing === 'undefined'
        ? PANEL_SPACING.sloped
        : panel.spacing;
};

// Returns the first valid object from collection object
export const getDefaultObjectIdFromCollectionObject = (
    collection: Record<string, any>
) => {
    return Object.keys(collection)[0];
};

export const sortVerticesByYAndThenX = (
    vertices: Array<Vertex>
): Array<Vertex> => {
    const sorted = [...vertices];
    sorted.sort((a, b) => a[1] - b[1]);
    const topVertices = [sorted[0], sorted[1]].sort((a, b) => a[0] - b[0]);
    const bottomVertices = [sorted[2], sorted[3]].sort((a, b) => a[0] - b[0]);
    return [...topVertices, ...bottomVertices];
};

const getDistance = (x1: number, y1: number, x2: number, y2: number) =>
    Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));

export const autoArrangeVertices = (vertices: Vertices) => {
    if (!vertices.length || vertices.length > 4) return vertices;

    if (vertices.length === 3) {
        let arrangedVertices = vertices.sort((a, b) => a[1] - b[1]);
        arrangedVertices = [
            arrangedVertices[0],
            ...arrangedVertices.slice(1).sort((a, b) => a[0] - b[0]),
        ];

        const baseMiddlePoint = {
            x: (arrangedVertices[1][0] + arrangedVertices[2][0]) / 2,
            y: (arrangedVertices[1][1] + arrangedVertices[2][1]) / 2,
        };
        const halfBaseX = baseMiddlePoint.x - arrangedVertices[1][0];
        const h = baseMiddlePoint.y - arrangedVertices[0][1];
        const topLeftVertex: Vertex = [
            arrangedVertices[0][0] - halfBaseX * 0.7,
            arrangedVertices[1][1] - h,
        ];
        const perspectiveT = calculateTransform(
            [0, 0, 1, 0, 0, 1, 1, 1],
            [
                ...topLeftVertex,
                ...arrangedVertices[0],
                ...arrangedVertices[1],
                ...arrangedVertices[2],
            ]
        );

        const topRightVertex = perspectiveT.transform(2, 0);

        return [
            topLeftVertex,
            topRightVertex,
            [...arrangedVertices[1]] as Vertex,
            [...arrangedVertices[2]] as Vertex,
            [...arrangedVertices[0]] as Vertex,
        ];
    }

    let centerX = 0;
    let centerY = 0;
    vertices.forEach((v) => {
        centerX += v[0];
        centerY += v[1];
    });
    centerX /= vertices.length;
    centerY /= vertices.length;

    const verticesAngleInfo = vertices.map((v, index) => {
        const dx = centerX - v[0];
        const dy = centerY - v[1];
        const dist = getDistance(centerX, centerY, ...v);
        let angle = (180 * Math.asin(dx / dist)) / Math.PI;

        // This will make angles increasing counter clock-wise
        if (dy < 0) {
            angle = 180 - angle;
        } else if (dx < 0) {
            angle = 360 + angle;
        }

        return { angle, index };
    });

    // Sorting so the first vertex is the top left hand-side one
    verticesAngleInfo.sort((a, b) => {
        return a.angle - b.angle;
    });

    return [
        vertices[verticesAngleInfo[0].index],
        vertices[verticesAngleInfo[3].index],
        vertices[verticesAngleInfo[1].index],
        vertices[verticesAngleInfo[2].index],
    ];
};

// Applies smart layout to every canvas in a plan
export const applySmartLayoutToAllCanvases = (
    canvases: { [name: string]: Canvas },
    oldPanelProduct: Product,
    newPanelProduct: Product
): { [name: string]: Canvas } => {
    const result: typeof canvases = {};
    Object.keys(canvases).forEach((canvasId) => {
        Object.keys(canvases[canvasId].surfaces).forEach((surfaceId) => {
            const modifiedSurface = canvases[canvasId].surfaces[surfaceId];

            const smartAdjustedPanels = smartLayout(
                {
                    width: modifiedSurface.width,
                    height: modifiedSurface.height,
                },
                modifiedSurface.panels,
                oldPanelProduct,
                newPanelProduct,
                modifiedSurface.roofType!
            );

            result[canvasId] = {
                ...canvases[canvasId],
                surfaces: {
                    ...canvases[canvasId].surfaces,
                    [surfaceId]: {
                        ...canvases[canvasId].surfaces[surfaceId],
                        panels: smartAdjustedPanels,
                    },
                },
            };
        });
    });

    return result;
};

export const panelsInCanvas = (canvas: Canvas): number => {
    return Object.keys(canvas.surfaces).length > 0
        ? Object.keys(canvas.surfaces).reduce(
              (current: number, surfaceId: string) =>
                  getValidPanelsCount(canvas.surfaces[surfaceId].panels) +
                  current,
              0
          )
        : 0;
};
// Count the total number of panels in the whole planning
export const getPanelCount = (planningData: PlanningData): number => {
    return Object.keys(planningData.canvases).reduce(
        (current, canvasId) =>
            panelsInCanvas(planningData.canvases[canvasId]) + current,
        0
    );
};
export const shuffleArray = (array: Array<any>) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
};
export const getValidPanelsCount = (panels: PanelPlanningData[]) =>
    panels.reduce((res, panel) => (panel.isHidden ? res : res + 1), 0);
export const filterOutInvalidPanels = (panels: PanelPlanningData[]) =>
    panels.filter((panel) => !panel.isHidden);
export const getAllPanelsFromSurfaces = (surfaces: ModulePlanningData[]) => {
    return surfaces.reduce((panels: Array<PanelPlanningData>, surface) => {
        return [...panels, ...surface.panels];
    }, []);
};

export const parseSurfacePayload = (
    surfaces: ModulePlanningData[]
): Array<SurfacePayload> => {
    return surfaces.reduce(
        (array: SurfacePayload[], surface: ModulePlanningData) => {
            const panelQuantity = getValidPanelsCount(surface.panels);
            if (panelQuantity > 0) {
                array.push({
                    slope: surface.slope,
                    directions: getPanelsByDirection(surface),
                    yield_reduction: surface.yieldReduction || 0,
                });
            }
            return array;
        },
        []
    );
};

export const getPanelsByDirection = (
    surface: ModulePlanningData
): DirectionsPlanningData => {
    if (surface.roofType === RoofTypes.flat) {
        const data: {
            [key: string]: {
                azimuth: number;
                quantity: number;
            };
        } = {};
        surface.panels.forEach((panel: PanelPlanningData) => {
            if (panel.isHidden) return;
            const key = panel.facing!.direction!.key as string;
            const value = panel.facing!.direction!.value as number;
            data[key] = data[key] || {
                azimuth: value,
                quantity: 0,
            };
            data[key]['quantity']++;
        });
        return Object.values(data);
    }
    return [
        {
            azimuth: surface.azimuth as number,
            quantity: getValidPanelsCount(surface.panels),
        },
    ];
};

export const arePanelsSame = (
    panelsA: PanelPlanningData[],
    panelsB: PanelPlanningData[]
): boolean => JSON.stringify(panelsA) === JSON.stringify(panelsB);

export const createSalesforcePatchBody = (
    saveImageResponse: SaveFileResponse,
    blobSize: number,
    index: number
) => {
    const uploadedFileExtension =
        saveImageResponse.photo.ObjectURL.split('.').pop();
    const folderName = PLANNING_IMAGE_FOLDER_PREFIX;
    const fileName = PLANNING_IMAGE_FILE_PREFIX + (index + 1);

    return {
        TVA_CFB__Cloud_Public_Access_URL__c: saveImageResponse.photo.ObjectURL,
        TVA_CFB__Folder__c: folderName,
        TVA_CFB__File_Path__c: saveImageResponse.photo.ObjectURL,
        TVA_CFB__Bucket_Name__c: saveImageResponse.bucket,
        TVA_CFB__Region__c: 'eu-central-1',
        TVA_CFB__E_Tag__c: saveImageResponse.photo.ETag,
        TVA_CFB__File_Type__c: 'png',
        TVA_CFB__File_Size_in_Bytes__c: blobSize,
        name: `${fileName}.${uploadedFileExtension}`,
    };
};

// Checks if  all Surfaces in the planning have an azimuth and slope.
export const allSurfaceDetails = (planning: PlanningData) => {
    return getSurfacesOfPlanning(planning).every(
        (surface) =>
            (surface.azimuth || surface.azimuth === 0) &&
            (surface.slope || surface.slope === 0)
    );
};

// Checks if  all Surfaces in the planning have a width and height
export const allSurfaceHaveDimensions = (planning: PlanningData) => {
    return getSurfacesOfPlanning(planning).every(
        (surface) => surface.width && surface.height
    );
};

// Checks if  all Surfaces in the planning have panels.
export const allSurfacesHavePanels = (surfaces: ModulePlanningData[]) => {
    return surfaces.every((surface) => getValidPanelsCount(surface.panels) > 0);
};

export const replacePlanningImagesWithPresigned = async (
    designerQuoteState: DesignerQuoteState
) => {
    const canvases = designerQuoteState.planning.canvases;
    const panels = designerQuoteState.salesforce.Products?.panels;

    if (panels?.photos) {
        for (const [i, panelPhoto] of panels.photos.entries()) {
            const [presignedUrl] = await getPresignedUrl(panelPhoto.url);

            if (presignedUrl)
                designerQuoteState.salesforce.Products!.panels!.photos[i].url =
                    presignedUrl;
        }
    }

    for (const canvasKey of Object.keys(canvases)) {
        const [presignedUrl] = await getPresignedUrl(
            canvases[canvasKey].imageBox.url
        );

        if (presignedUrl)
            designerQuoteState.planning.canvases[canvasKey].imageBox.url =
                presignedUrl;
    }

    return designerQuoteState;
};

// Checks if surfaces details have been changed given two surface arrays
export const hasSurfaceDetailsChanged = (
    newPlanning: ModulePlanningData[],
    oldPlanning: ModulePlanningData[]
) => {
    return (
        newPlanning.length !== oldPlanning.length ||
        newPlanning.filter((surface) => {
            const matchingSurface = oldPlanning.find(
                (oldSurface) => oldSurface.id === surface.id
            );

            return !!(
                matchingSurface &&
                (surface.azimuth !== matchingSurface.azimuth ||
                    surface.slope !== matchingSurface?.slope ||
                    surface.roofType !== matchingSurface.roofType ||
                    surface.yieldReduction !== matchingSurface?.yieldReduction)
            );
        }).length > 0
    );
};

// Get all Surfaces from a planning in one array
export const getSurfacesOfPlanning = (
    planningData: PlanningData
): ModulePlanningData[] => {
    return Object.values(planningData.canvases)
        .map((canvas) => Object.values(canvas.surfaces))
        .flat();
};

export const sortPanelsByPosition = (
    panels: Array<PanelPlanningData | UnsetPanelPlanningData>
): [Array<PanelPlanningData>, Array<UnsetPanelPlanningData>] => {
    const panelsGroupedByY: { [key: number]: Array<PanelPlanningData> } = {};
    const keys: Set<number> = new Set();
    const unsetPanels: Array<UnsetPanelPlanningData> = [];
    for (const panel of panels) {
        if (panel.x !== undefined && panel.y !== undefined) {
            const y = panel.y;
            keys.add(y);
            panelsGroupedByY[y] = panelsGroupedByY[y] || [];
            panelsGroupedByY[y].push(panel as PanelPlanningData);
        } else {
            unsetPanels.push(panel);
        }
    }
    const keyArray = [...keys];
    keyArray.sort((a, b) => a - b);
    let setPanels: Array<PanelPlanningData> = [];
    for (const key of keyArray) {
        const panelsWithY = panelsGroupedByY[key];
        panelsWithY.sort((a, b) => a.x - b.x);
        setPanels = [...setPanels, ...panelsWithY];
    }
    return [setPanels, unsetPanels];
};
const getNumberOfRowsInSurface = (panels: Array<PanelPlanningData>) => {
    const ySet = new Set<number>();
    for (const panel of panels) {
        ySet.add(panel.y);
    }
    return ySet.size;
};

const getPanelGutter = (
    surface: ModulePlanningData,
    panel: PanelPlanningData
) => {
    const gutter = surface.gutter || SOUTH_FACING_PANEL_GUTTER;
    return surface.roofType === RoofTypes.flat &&
        panel.facing?.hasGutter &&
        !isAlternatingPanels(surface.azimuth!, !!panel.isHorizontal, false)
        ? gutter
        : 0;
};

const hasSpaceForAnotherPanelOnSameRow = (
    previousPanel: PanelPlanningData,
    panelWidth: number,
    surface: ModulePlanningData
): boolean => {
    const spacing = getSpacingByConfiguration(
        surface,
        !!previousPanel.isHorizontal
    );
    return (
        previousPanel.x +
            getSpaceNeededForPreviousPanel(
                previousPanel,
                surface,
                { panelWidth, panelHeight: 0 },
                spacing
            ).x +
            panelWidth <=
        surface.width - getSurfaceGutter(surface)
    );
};

const getSpaceNeededForPreviousPanel = (
    previousPanel: PanelPlanningData,
    surface: ModulePlanningData,
    panelDimensions: { panelWidth: number; panelHeight: number },
    spacing: Spacing
) => {
    const panelGutter = getPanelGutter(surface, previousPanel);
    return {
        x:
            panelDimensions.panelWidth +
            spacing.x +
            (previousPanel.isHorizontal ? 0 : panelGutter),
        y:
            panelDimensions.panelHeight +
            spacing.y +
            (previousPanel.isHorizontal ? panelGutter : 0),
    };
};

const getIndexesForUnsetPanel = (
    lastSetPanel: PanelPlanningData,
    panelProduct: Panel,
    surface: ModulePlanningData,
    rowCount: number
) => {
    const maxXOnBottomRow = lastSetPanel.x;
    let hozIndex;
    let verIndex;
    const [panelWidth] = getOrientedDimensions(
        panelProduct.width,
        panelProduct.length,
        surface.roofType!,
        !!lastSetPanel.isHorizontal
    );
    const spacing = getSpacingByConfiguration(
        surface,
        !!lastSetPanel.isHorizontal
    );
    const panelGutter = getPanelGutter(surface, lastSetPanel);
    if (!hasSpaceForAnotherPanelOnSameRow(lastSetPanel, panelWidth, surface)) {
        verIndex = rowCount;
        hozIndex = 0;
        rowCount++;
    } else {
        verIndex = rowCount - 1;
        hozIndex =
            Math.floor(
                maxXOnBottomRow / (panelWidth + spacing.x + panelGutter)
            ) + 1;
    }
    return [hozIndex, verIndex];
};

const getPositionForUnsetPanel = (
    lastSetPanel: PanelPlanningData,
    panelProduct: Panel,
    surface: ModulePlanningData,
    rowCount: number
) => {
    const maxXOnBottomRow = lastSetPanel.x;
    const maxY = lastSetPanel.y;
    const [panelWidth, panelHeight] = getOrientedDimensions(
        panelProduct.width,
        panelProduct.length,
        surface.roofType!,
        !!lastSetPanel.isHorizontal
    );
    const spacing = getSpacingByConfiguration(
        surface,
        !!lastSetPanel.isHorizontal
    );
    let x;
    let y;
    if (!hasSpaceForAnotherPanelOnSameRow(lastSetPanel, panelWidth, surface)) {
        x = getSurfaceGutter(surface);
        y =
            maxY +
            getSpaceNeededForPreviousPanel(
                lastSetPanel,
                surface,
                { panelWidth, panelHeight },
                spacing
            ).y;
        rowCount++;
    } else {
        x =
            maxXOnBottomRow +
            getSpaceNeededForPreviousPanel(
                lastSetPanel,
                surface,
                { panelWidth, panelHeight },
                spacing
            ).x;
        y = maxY;
    }
    return [x, y];
};

const getSurfaceGutter = (surface: ModulePlanningData) => {
    return surface.hasGutter ? surface.surfaceGutter! : 0;
};

const getNextPanel = (
    currentPanels: PanelPlanningData[],
    surface: ModulePlanningData,
    panelProduct: Panel
) => {
    const rowCount = getNumberOfRowsInSurface(currentPanels);
    const lastSetPanel = currentPanels[currentPanels.length - 1];
    const [hozIndex, verIndex] = getIndexesForUnsetPanel(
        lastSetPanel,
        panelProduct,
        surface,
        rowCount
    );
    const [x, y] = getPositionForUnsetPanel(
        lastSetPanel,
        panelProduct,
        surface,
        rowCount
    );

    const isHorizontal = !!lastSetPanel.isHorizontal;
    return {
        x,
        y,
        isHorizontal: isHorizontal,
        isHidden: false,
        spacing: getSpacingByConfiguration(surface, isHorizontal),
        ...(surface.roofType === RoofTypes.flat && {
            facing: getPanelFacing(
                surface,
                { hozIndex, verIndex },
                isHorizontal
            ),
        }),
    };
};

const getFirstPanel = (surface: ModulePlanningData) => {
    const gutter = getSurfaceGutter(surface);
    return {
        x: gutter,
        y: gutter,
        isHorizontal: false,
        isHidden: false,
        spacing: getSpacingByConfiguration(surface, false),
        ...(surface.roofType === RoofTypes.flat && {
            facing: getPanelFacing(
                surface,
                { hozIndex: 0, verIndex: 0 },
                false
            ),
        }),
    };
};

/**
 * Modifies the panels array to for a grid.
 * Only panels with unspecified positions will be affected (with x and y set to undefined)).
 * Returns the source array (not a copy)
 * @param panels to modify
 * @param panelProduct info about panel dimensions
 * @param surface
 */
export const autoArrangePanels = (
    panels: UnsetPanelPlanningData[],
    panelProduct: Panel,
    surface: ModulePlanningData
) => {
    const [setPanels, unsetPanels] = sortPanelsByPosition(panels);
    unsetPanels.forEach(() => {
        setPanels.push(
            setPanels.length === 0
                ? getFirstPanel(surface)
                : getNextPanel(setPanels, surface, panelProduct)
        );
    });

    return setPanels as PanelPlanningData[];
};

export const isProductChecked = (
    product: ElectricalCabinetUpgrade | MountingSystem,
    key:
        | ProductTypes.ELECTRICAL_CABINET_UPGRADES
        | ProductTypes.MOUNTING_SYSTEM
        | ProductTypes.ELECTRICAL_LABOR,
    products?: SalesforceProductsState
) => {
    return (
        !!products?.[key] &&
        products[key]!.some((upG) => upG && upG.id === product.id)
    );
};

export const redirectToRoofTool = (paramStr: string) => {
    const roofToolUrl = process.env.REACT_APP_ROOF_TOOL_URL as string;
    window.location.replace(roofToolUrl + '?' + paramStr);
};

export const isPlanOldVersion = (planningVersion: number) => {
    return planningVersion === 1;
};

export const hasProductSameQuantity = (
    product: ElectricalCabinetUpgrade | MountingSystem,
    key:
        | ProductTypes.ELECTRICAL_CABINET_UPGRADES
        | ProductTypes.MOUNTING_SYSTEM,
    products?: SalesforceProductsState
) => {
    if (!products?.[key]) return false;
    const sameProduct = products[key]!.find(
        (upG) => upG.id === product.id
    ) as BasicQuoteProduct;
    if (sameProduct === undefined) return false;

    return !!(
        sameProduct.quantity && sameProduct.quantity === product.quantity
    );
};

export const updateROIValueInPlanning = (
    type: 'machine' | 'manual',
    currentRoiValues: PlanningDataRoiValues | undefined,
    payload: RoiObject
) => {
    const newRoi: PlanningDataRoiValues = currentRoiValues
        ? { ...currentRoiValues }
        : {};
    Object.keys(payload).forEach((key) => {
        newRoi[key as RoiValueId] = {
            ...(newRoi[key as RoiValueId] || {}),
            [type]: payload[key as RoiValueId],
        };
    });
    return newRoi;
};

export const createDefaultQuoteName = (
    designerQuoteState: DesignerQuoteState
) => {
    const today = new Date().toISOString().split('T')[0].replace(/-/g, '');
    const products = designerQuoteState.salesforce.Products;
    const panelAmount = products?.panels!.quantity;

    let defaultName = today;

    if (products) {
        defaultName += ` - ${panelAmount || 0}x ${
            products.panels!.productBrand || ''
        }`;
        if (products.inverter)
            defaultName += ` - ${products.inverter.productBrand!}`;
        const battery = products.battery as BasicQuoteProduct;
        if (battery && battery.productBrand)
            defaultName += ` - ${battery.productBrand}`;
        if (battery && battery.power) defaultName += ` - ${battery.power} kWh`;
        if (products.wallboxes) defaultName += ' - Wallbox';
    }

    return defaultName;
};

const vertexDifferent = (vertA: Vertex, vertB: Vertex): boolean => {
    return vertA[0] !== vertB[0] || vertA[1] !== vertB[1];
};

export const doVerticesDiffer = (verticesA: Vertices, verticesB: Vertices) => {
    return verticesA.some((verts, i) => vertexDifferent(verts, verticesB[i]));
};
export const getUnmodifiedProducts = (
    opportunity: OpportunityState,
    quoteId: string
) =>
    opportunity.quotes?.find((q) => {
        return q.id === quoteId;
    })?.Products;

// Returns true when a product is not undefined or []
export const isValidProduct = (product?: unknown) => {
    return !!(
        product &&
        !(Array.isArray(product) && product.length === 0) && // should not be an empty array
        typeof product === 'object'
    );
};

// returns true when yield is reduced per surface
export const hasYieldReduction = (planningData: PlanningData): boolean => {
    return getSurfacesOfPlanning(planningData).some(
        (surface) => surface.yieldReduction && surface.yieldReduction > 0
    );
};

export const createGetToken = (
    dispatch: Dispatch<any>,
    getState: () => RootState
): AccessTokenFunction => {
    return async (fresh: boolean) => {
        if (!fresh) {
            return getState().user.accessToken;
        } else {
            const fetchUrl = process.env.REACT_APP_AUTH_URL as string;
            const apiToken = process.env.REACT_APP_EIGEN_API_TOKEN as string;
            const refreshToken = getState().user.refreshToken as string;
            const [data, e] = await getNewAccessToken({
                refreshToken,
                fetchUrl,
                apiToken,
            });
            if (e) throw new Error('Refreshing the AccessToken failed');
            if (!data.access_token) {
                throw new Error(
                    `Authentication failed: ${JSON.stringify(data)} `
                );
            } else {
                sessionStorage.setItem(
                    'auth',
                    JSON.stringify({ ...data, refresh_token: refreshToken })
                );
                dispatch(
                    renewAccessToken({
                        accessToken: data.access_token,
                    })
                );
            }
            dispatch(renewAccessToken({ accessToken: data.access_token }));
            return data.access_token;
        }
    };
};

export const getSideDirectionsByAzimuth = (
    azimuth: number,
    azimuthOptions: SelectItem[]
): SideDirections => {
    const positions = ['bottom', 'left', 'top', 'right'];

    const sideAndDirections: { [key: string]: SelectItem } = {};

    const selectedAzimuthIndex = azimuthOptions.findIndex(
        (opt) => opt.value === azimuth
    );

    const positionsToJump = azimuthOptions.length / 4;

    positions.forEach((value, index) => {
        sideAndDirections[value] =
            azimuthOptions[
                (selectedAzimuthIndex + positionsToJump * index) %
                    azimuthOptions.length
            ];
    });

    return sideAndDirections as SideDirections;
};

export const isAlternatingPanels = (
    azimuth: number,
    isHorizontal: boolean,
    isFlipped: boolean
) => {
    const absoluteAzimuth = Math.abs(azimuth);

    let isAlternating = absoluteAzimuth <= 45 || absoluteAzimuth >= 135;
    isAlternating = isHorizontal ? !isAlternating : isAlternating;
    return isFlipped ? !isAlternating : isAlternating;
};

export const getSouthFacingSide = (sideDirections: SideDirections): string => {
    let lowestDegreesSide = {
        key: '',
        value: Infinity,
    };

    Object.keys(sideDirections).forEach((key: string) => {
        const sideDirection = sideDirections[key as keyof SideDirections];

        if (!lowestDegreesSide)
            lowestDegreesSide = { key, value: sideDirection?.value as number };
        else if (
            Math.abs(lowestDegreesSide.value as number) >
            Math.abs(sideDirection?.value as number)
        )
            lowestDegreesSide = { key, value: sideDirection?.value as number };
    });

    return lowestDegreesSide.key;
};

export const getSideFacingOptions = (
    sideAndDirections: SideDirections,
    isHorizontal: boolean
) => {
    return isHorizontal
        ? { bottom: sideAndDirections.bottom, top: sideAndDirections.top }
        : { left: sideAndDirections.left, right: sideAndDirections.right };
};

export const hasPanelsWithGutter = (panels: PanelPlanningData[]): boolean => {
    for (const panel of panels) {
        if (panelHasGutter(panel)) {
            return true;
        }
    }
    return false;
};

export const panelHasGutter = (panel: PanelPlanningData): boolean => {
    return !!(panel.facing && panel.facing.hasGutter);
};

export const sortNumeric = (a: number, b: number) => a - b;

export const getTotalSystemCapacityInKWP = (
    panelQuantity: number,
    panelPower: number
) => {
    return (panelQuantity * panelPower) / 1000;
};

export const hasOfferBeenPresented = (stageName: string) =>
    stageName === 'Offer Presented' || stageName === 'Ready for QA';

export const isAllProductsActive = (products: Product[]) =>
    products.every((product: Product) => product.isActive);

export const isAllProductsVisible = (products: Product[]) =>
    products.every((product: Product) => product.isSolardesignerVisible);

export const getAllProductsNotInSubset = (
    products: Product[],
    subset: string[]
) =>
    products.filter(
        (product) =>
            product.productCategory && !subset.includes(product.productCategory)
    );

export const getAllProductsInSubset = (products: Product[], subset: string[]) =>
    products.filter(
        (product) =>
            product.productCategory && subset.includes(product.productCategory)
    );

export const getAllNonActiveProducts = (products: Product[]) =>
    products.filter((product: Product) => !product.isActive);

export const getAllActiveProducts = (products: Product[]) =>
    products.filter((product: Product) => product.isActive);

export const normaliseProductArray = (products: Product[]) => {
    const newProducts: { [key: string]: Product } = {};
    products.forEach((p) => {
        if (!newProducts[p.id]) {
            newProducts[p.id] = p;
        } else {
            newProducts[p.id] = {
                ...newProducts[p.id],
                quantity: (newProducts[p.id].quantity ?? 1) + (p.quantity ?? 1),
            };
        }
    });
    return Object.values(newProducts);
};

export const getAllNonVisibleProducts = (products: Product[]) =>
    products.filter((product: Product) => !product.isSolardesignerVisible);

export const getFeedInTariff = (
    remainder: number,
    belowAmount: number,
    feedInTarrifOver: number,
    feedInTarrifUnder: number
): number =>
    (remainder * feedInTarrifOver + belowAmount * feedInTarrifUnder) /
    (remainder + belowAmount);

export const getRoofOrientations = (planning: PlanningData): Array<string> => {
    const set: Set<string> = new Set();
    getSurfacesOfPlanning(planning).forEach((surface) => {
        const selectedOrientation = azimuthOptions.find((option) => {
            return option.value === surface.azimuth;
        });
        if (selectedOrientation) {
            set.add(selectedOrientation.sfvalue!);
        }
    });
    return [...set];
};

export const getRoofInclinations = (planning: PlanningData) => {
    const set: Set<string> = new Set();
    getSurfacesOfPlanning(planning).forEach((surface) => {
        const option = slopeOptions.find(
            (option) => option.sfvalue && option.value === (surface.slope ?? 0)
        );
        set.add(
            option && option.sfvalue
                ? option.sfvalue
                : (surface.slope ?? 0) + ' degrees'
        );
    });
    return [...set];
};
export const getMountingSystemColorFromQuote = (quote: DesignerQuoteState) => {
    return quote.salesforce.Products &&
        Array.isArray(quote.salesforce.Products.mountingSystem) &&
        quote.salesforce.Products.mountingSystem[0]
        ? quote.salesforce.Products.mountingSystem[0].color
        : undefined;
};
export const quoteHasPyramidMountingSystems = (quote: DesignerQuoteState) => {
    return !!getPyramidMountingSystemFromQuote(quote);
};
export const getPyramidMountingSystemFromQuote = (
    quote: DesignerQuoteState
) => {
    if (!quote.salesforce.Products || !quote.salesforce.Products.mountingSystem)
        return null;
    for (const system of quote.salesforce.Products.mountingSystem) {
        if (isPyramidMountingSystem(system)) return system;
    }
    return null;
};
