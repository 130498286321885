import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAppInitialised } from '../../features/app/appActions';
import { getCloudQuote } from '../../features/cloudQuote/cloudQuoteActions';
import { newNotification } from '../../features/notifications/notificationsActions';
import {
    fetchAndSetTokenFromCode,
    setRole,
    setTokens,
} from '../../features/user/userActions';
import i18n from '../../i18n';
import { OPP_ID, Q_ID, USER_ROLE_ID } from '../constants';
import { getUrlParams } from '../helpers';
import { clearFromLocalStorage, saveToLocalStorage } from '../localStorage';
import { AppDispatch, RootState, UserRoles } from '../types';

// Use throughout your app instead of plain `useDispatch`
export const useAppDispatch: () => AppDispatch = useDispatch;

export const useIsAuth = (): boolean => {
    const dispatch = useAppDispatch();
    const isAuth = useSelector<RootState, boolean>(
        (state) => state.user.isAuth
    );

    // This effect will fire once the App component is mounted
    useEffect(() => {
        setIsAppInitialised(true);
    }, []);

    useEffect(() => {
        clearFromLocalStorage('auth');
        if (isAuth) return;

        const authUrl = process.env.REACT_APP_AUTH_URL;
        if (!authUrl) {
            dispatch(
                newNotification({
                    type: 'error',
                    message: i18n.t('notifications:noAuthUrl'),
                })
            );
            return;
        }

        const auth = sessionStorage.getItem('auth');
        const params = getUrlParams(window.location.search);
        const code = params.code;
        if (params.qId) {
            saveToLocalStorage(Q_ID, params.qId);
            dispatch(
                getCloudQuote({
                    quoteId: params.qId,
                })
            );
        }
        if (params.oppId) saveToLocalStorage(OPP_ID, params.oppId);
        if (params.role && params.role in UserRoles) {
            dispatch(setRole({ role: params.role }));
            saveToLocalStorage(USER_ROLE_ID, params.role);
        }

        if (auth) {
            dispatch(
                setTokens({
                    accessToken: JSON.parse(auth).access_token,
                    refreshToken: JSON.parse(auth).refresh_token,
                })
            );
        } else if (code) {
            const apiToken = process.env.REACT_APP_EIGEN_API_TOKEN;
            if (!apiToken) {
                dispatch(
                    newNotification({
                        type: 'error',
                        message: i18n.t('notifications:noApiToken'),
                    })
                );
                return;
            }
            dispatch(
                fetchAndSetTokenFromCode({ code, fetchUrl: authUrl, apiToken })
            );
            return;
        } else {
            redirectToSalesforceAuth(params, authUrl);
        }
    }, [isAuth, dispatch]);
    return isAuth;
};

const redirectToSalesforceAuth = (params: any, authUrl: string): void => {
    if (params.id) saveToLocalStorage('id', params.id);
    // redirect to SalesForce Login
    window.location.href = `${authUrl}/login`;
};

export const useIsInitialised = (): boolean => {
    const [isInitialised, setIsInitialised] = useState(false);
    const isAuth = useIsAuth();

    useEffect(() => {
        if (isAuth) {
            // initialise...
            setIsInitialised(true);
        }
    }, [isAuth]);
    return isInitialised;
};
