import { loadFromLocalStorage } from '../localStorage';
import { DesignerQuoteState } from '../types';

// Hook to get LocalChanges based on OpportunityId
export default function useLocalChanges(
    opportunityId?: string
): DesignerQuoteState | undefined {
    return opportunityId
        ? (loadFromLocalStorage(opportunityId) as DesignerQuoteState)
        : undefined;
}
