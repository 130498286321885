import { pipe } from '../common/functions';
import { createFilterParams } from '../common/helpers';
import { AccessTokenFunction, ApiFilter } from '../common/types';

const validateStatus = (res: { status: number }): typeof res => {
    if (res.status !== 200 && res.status !== 201 && res.status !== 204)
        throw new Error('validateStatus: Fetch failed');
    return res;
};

const getJson = (res: Response): Promise<object> => res.json();

const callApi = (props: {
    endpoint: string;
    options: RequestInit;
}): Promise<Response> => fetch(props.endpoint, props.options);

export const callApiBaseWith = (props: {
    baseUrl: string;
    baseOptions: RequestInit;
    name: string;
    options?: RequestInit;
    id?: string;
    filters?: ApiFilter[];
}): Promise<Response> =>
    callApi({
        endpoint: `${props.baseUrl}${props.name}${
            props.id ? '/' + props.id : ''
        }${props.filters ? `${createFilterParams(props.filters)}` : ''}`,
        options: {
            ...props.baseOptions,
            ...(props.options || {}),
        },
    });

const createBaseEigenApiCallWith =
    (props: {
        name: string;
        options?: RequestInit;
        id?: string;
        filters?: ApiFilter[];
    }) =>
    (): Promise<Response> =>
        callApiBaseWith({
            baseUrl: `${process.env.REACT_APP_EIGEN_API_BASE_URL}/${process.env.REACT_APP_EIGEN_API_VERSION}/`,
            baseOptions: {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_EIGEN_API_TOKEN}`,
                    Accept: 'application/json',
                },
            },
            name: props.name,
            options: props.options,
            id: props.id,
            filters: props.filters,
        });

export const callEigenApiWith = <T>(props: {
    name: string;
    options?: RequestInit;
    middleware?: Array<(data: unknown) => any>;
    id?: string;
    filters?: ApiFilter[];
}): Promise<T> =>
    pipe(
        createBaseEigenApiCallWith({
            name: props.name,
            options: props.options,
            id: props.id,
            filters: props.filters,
        }),
        validateStatus,
        getJson,
        ...(props.middleware || [])
    )();

const createBaseSalesforceApiCallWith =
    (props: {
        name: string;
        accessToken: AccessTokenFunction;
        options?: RequestInit;
        id?: string;
        composite?: boolean;
    }) =>
    async (): Promise<Response> => {
        const oldToken = await props.accessToken(false);
        const params = {
            baseUrl: `${process.env.REACT_APP_SF_API_BASE_URL}/${
                process.env.REACT_APP_SF_API_VERSION
            }/${props.composite ? 'composite' : 'sobjects'}/`,
            baseOptions: {
                headers: {
                    Authorization: `Bearer ${oldToken}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            },
            name: props.name,
            options: props.options,
            id: props.id,
        };
        try {
            return await callApiBaseWith(params);
        } catch (e) {
            const freshAccessToken = await props.accessToken(true);
            return await callApiBaseWith({
                ...params,
                baseOptions: {
                    ...params.baseOptions,
                    headers: {
                        ...params.baseOptions.headers,
                        Authorization: `Bearer ${freshAccessToken}`,
                    },
                },
            });
        }
    };

export const callSalesforceApiWith = <T>(props: {
    name: string;
    accessToken: AccessTokenFunction;
    options?: RequestInit;
    middleware?: Array<(data: unknown) => any>;
    id?: string;

    composite?: boolean;
}): Promise<T> =>
    pipe(
        createBaseSalesforceApiCallWith({
            name: props.name,
            options: props.options,
            id: props.id,
            accessToken: props.accessToken,
            composite: props.composite,
        }),
        validateStatus,
        getJson,
        ...(props.middleware || [])
    )();

export const callSalesforceApiWithoutResponse = (props: {
    name: string;
    accessToken: AccessTokenFunction;
    options?: RequestInit;
    middleware?: Array<(data: unknown) => any>;
    id?: string;
}): Promise<void> =>
    pipe(
        createBaseSalesforceApiCallWith({
            name: props.name,
            options: props.options,
            id: props.id,
            accessToken: props.accessToken,
        }),
        validateStatus,
        ...(props.middleware || [])
    )();
