import {
    CloudQuoteActionType,
    CloudQuoteReducer,
    QuoteActionType,
} from '../../common/types';
import { defaultCloudQuoteState } from '../../store/defaults';

export const cloudQuoteReducer: CloudQuoteReducer = (
    state = defaultCloudQuoteState,
    action
) => {
    switch (action.type) {
        case CloudQuoteActionType.REQUEST_QUOTE:
            return {
                ...defaultCloudQuoteState,
                isFetching: true,
                quoteId: action.payload.quoteId,
            };
        case QuoteActionType.SET_QUOTE:
            return {
                ...action.payload.data,
                isFetching: false,
                quoteId: action.payload.quoteId,
            };
        case QuoteActionType.SET_QUOTE_ID: {
            const quoteId = action.payload.quoteId as string;
            return {
                ...state,
                quoteId,
                salesforce: {
                    ...state.salesforce,
                    id: quoteId,
                },
            };
        }
        case CloudQuoteActionType.FETCH_QUOTE_FAILED:
            return {
                ...state,
                isFetching: false,
            };
        case QuoteActionType.RESET_QUOTE: {
            return defaultCloudQuoteState;
        }
        default:
            return state;
    }
};
