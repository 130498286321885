import { UiActionType, UiReducer } from '../../common/types';
import { defaultUiState } from '../../store/defaults';

export const uiReducer: UiReducer = (state = defaultUiState, action) => {
    switch (action.type) {
        case UiActionType.TOGGLE_GRID_VISIBILITY: {
            return {
                ...state,
                hideGrids: !state.hideGrids,
            };
        }
        case UiActionType.SHOW_RECALCULATE_BUTTON: {
            return {
                ...state,
                showRecalculateButton: true,
            };
        }
        case UiActionType.HIDE_RECALCULATE_BUTTON: {
            return {
                ...state,
                showRecalculateButton: false,
            };
        }
        case UiActionType.UPDATE:
            return { ...state, ...action.payload };
        case UiActionType.TOGGLE_ENERGY_FIELDS_VISIBILITY:
            return {
                ...state,
                hideEnergyFields: !state.hideEnergyFields,
            };
        default:
            return state;
    }
};
