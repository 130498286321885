import { STRING_OPTIONS } from '../../common/constants';
import {
    getAllPanelsFromSurfaces,
    getSurfacesOfPlanning,
    getValidPanelsCount,
} from '../../common/functions';
import { isPanelOnAString } from '../../common/functions/getStateWithModifiedPanels';
import {
    BasicQuoteProduct,
    Canvas,
    DesignerQuoteState,
    ElectricalMaterial,
    ModulePlanningData,
    ObjectWithNameAndId,
    PanelPlanningData,
    PlanningData,
    RootState,
    SalesforceProductsState,
} from '../../common/types';

export const getStringIndexForPanel = (
    stringKey: string,
    canvases: Canvas[]
) => {
    let index = 0;
    canvases.forEach((canvas) => {
        Object.values(canvas.surfaces).forEach((surface) => {
            surface.panels.forEach((panel) => {
                if (
                    panel.stringPosition &&
                    panel.stringPosition?.key === stringKey
                ) {
                    index++;
                }
            });
        });
    });
    return index;
};

export const getNumberOfStrings = (
    products: SalesforceProductsState | undefined
) => {
    return products?.electricalMaterial?.isActive
        ? (products?.electricalMaterial as ElectricalMaterial).numberOfStrings
        : 0;
};

export const getStringOptions = (
    numberOfStrings: number
): Array<ObjectWithNameAndId> => {
    return STRING_OPTIONS.slice(0, numberOfStrings);
};

const isStringPlanComplete = (planning: PlanningData) => {
    const surfaces = getSurfacesOfPlanning(planning);
    for (const panel of getAllPanelsFromSurfaces(surfaces)) {
        if (!panel.isHidden && !isPanelOnAString(panel)) return false;
    }
    return true;
};

export const isManualStringPlanNeeded = (designerQuote: DesignerQuoteState) => {
    const planning = designerQuote.planning;
    return (
        (stringPlanExistsOnSurfaces(planning) &&
            !isStringPlanComplete(planning)) ||
        (!stringPlanExistsOnSurfaces(planning) &&
            getProjectedStringPlan(
                getSurfacesOfPlanning(planning),
                getNumberOfStrings(designerQuote.salesforce.Products)
            ).length === 0)
    );
};

export const getStringPlanToSave = (
    rootState: RootState,
    surfaceWithImages: Array<ModulePlanningData & { image: Blob }>
) => {
    if (isManualStringPlanNeeded(rootState.designerQuote)) return [];

    if (stringPlanExistsOnSurfaces(rootState.designerQuote.planning)) {
        return getStringsFromSurfaces(surfaceWithImages);
    }
    return getProjectedStringPlan(
        surfaceWithImages,
        getNumberOfStrings(rootState.designerQuote.salesforce.Products)
    );
};

const stringPlanExistsOnSurfaces = (planning: PlanningData) => {
    const surfaces = getSurfacesOfPlanning(planning);
    const stringPlan = getStringsFromSurfaces(surfaces);
    const panelsOnStringsQuantity = Object.values(stringPlan).reduce(
        (sum, string) => sum + string.quantity,
        0
    );
    return panelsOnStringsQuantity > 0;
};

const groupPanelsByAzimuth = (surfaces: Array<ModulePlanningData>) => {
    return getDistinctAzimuthsFromSurfaces(surfaces).map((azimuth) => {
        let array: Array<PanelPlanningData> = [];
        surfaces.forEach((surface) => {
            if (surface.azimuth === azimuth) {
                array = [...array, ...surface.panels];
            }
        });
        return array;
    });
};

const getDistinctAzimuthsFromSurfaces = (surfaces: ModulePlanningData[]) => {
    return [
        ...surfaces.reduce((set, surface) => {
            set.add(surface.azimuth);
            return set;
        }, new Set()),
    ];
};

export const getProjectedStringPlan = (
    surfaces: Array<ModulePlanningData>,
    numberOfString: number
): Array<{
    id: string;
    name: string;
    quantity: number;
}> => {
    const strings = getStringOptions(2);
    const panelCount = getValidPanelsCount(getAllPanelsFromSurfaces(surfaces));
    const orientationNumber = getDistinctAzimuthsFromSurfaces(surfaces).length;
    if (numberOfString === 1) {
        return [
            {
                id: strings[0].id,
                name: strings[0].name,
                quantity: panelCount,
            },
        ];
    }

    if (numberOfString === 2) {
        if (orientationNumber === 2) {
            const panels = groupPanelsByAzimuth(surfaces);
            return [
                {
                    id: strings[0].id,
                    name: strings[0].name,
                    quantity: getValidPanelsCount(panels[0]),
                },
                {
                    id: strings[1].id,
                    name: strings[1].name,
                    quantity: getValidPanelsCount(panels[1]),
                },
            ];
        }

        if (orientationNumber === 1) {
            const half = Math.round(panelCount / 2);
            return [
                {
                    id: strings[0].id,
                    name: strings[0].name,
                    quantity: half,
                },
                {
                    id: strings[1].id,
                    name: strings[1].name,
                    quantity: panelCount - half,
                },
            ];
        }
    }

    return [];
};

export const getStringsFromSurfaces = (surfaces: Array<ModulePlanningData>) => {
    const strings: {
        [key: string]: { id: string; name: string; quantity: number };
    } = {};
    STRING_OPTIONS.forEach((option) => {
        strings[option.id] = { ...option, quantity: 0 };
    });

    surfaces.forEach((surface) => {
        surface.panels.forEach((panel) => {
            if (panel.stringPosition?.key) {
                strings[panel.stringPosition.key].quantity++;
            }
        });
    });
    return Object.values(strings).filter((string) => string.quantity > 0);
};

export const getSortedStringSurfaceIndex = (canvases: {
    [key: string]: Canvas;
}) => {
    const strings: {
        [key: string]: Array<{
            surfaceIndex: number;
            stringIndex: number;
        }>;
    } = {};
    let surfaces = 0;
    Object.values(canvases).forEach((canvas, cindex) => {
        Object.values(canvas.surfaces).forEach((surface, sindex) => {
            surfaces = surfaces + sindex + cindex;
            surface.panels.forEach((panel) => {
                if (
                    panel.stringPosition &&
                    panel.stringPosition.key &&
                    panel.stringPosition.index !== undefined
                ) {
                    strings[panel.stringPosition.key] =
                        strings[panel.stringPosition.key] || [];
                    strings[panel.stringPosition.key].push({
                        stringIndex: panel.stringPosition.index,
                        surfaceIndex: surfaces,
                    });
                }
            });
        });
    });
    Object.keys(strings).forEach((stringKey) => {
        strings[stringKey] = strings[stringKey].sort(
            (a, b) => a.stringIndex - b.stringIndex
        );
    });

    return strings;
};
export const hasOptimizer = (products?: BasicQuoteProduct | undefined) => {
    return !!(
        products &&
        Array.isArray(products.dependencies) &&
        products.dependencies.find((dep) => dep.isOptimizer)
    );
};
