import { UserPermissionIds, UserPermissions, UserRoles } from './types';

const plannerPermissions: UserPermissions = {
    [UserPermissionIds.canAddPanels]: true,
    [UserPermissionIds.canManipulateElectricalCabinetUpgrades]: true,
    [UserPermissionIds.canManipulateAdditionalLabor]: true,
    [UserPermissionIds.canManipulateElectricalLabor]: true,
    [UserPermissionIds.canManipulateScaffolding]: true,
    [UserPermissionIds.canManipulateSurfaces]: true,
    [UserPermissionIds.canMovePanels]: true,
    [UserPermissionIds.canStringPanels]: false,
    [UserPermissionIds.canOverwriteQuotes]: true,
    [UserPermissionIds.canStartNewPlanning]: true,
    [UserPermissionIds.canUseTemplate]: true,
    [UserPermissionIds.canSeeGrids]: true,
    [UserPermissionIds.canSeeGutter]: true,
    [UserPermissionIds.canChangeRecordType]: true,
    [UserPermissionIds.canSeeSurfaceOutline]: true,
    [UserPermissionIds.canSeeDraftQuotes]: true,
};
const salesPermissions: UserPermissions = {
    [UserPermissionIds.canAddPanels]: false,
    [UserPermissionIds.canManipulateElectricalCabinetUpgrades]: false,
    [UserPermissionIds.canManipulateAdditionalLabor]: false,
    [UserPermissionIds.canManipulateElectricalLabor]: false,
    [UserPermissionIds.canManipulateScaffolding]: false,
    [UserPermissionIds.canManipulateSurfaces]: false,
    [UserPermissionIds.canMovePanels]: false,
    [UserPermissionIds.canStringPanels]: false,
    [UserPermissionIds.canOverwriteQuotes]: false,
    [UserPermissionIds.canStartNewPlanning]: false,
    [UserPermissionIds.canUseTemplate]: false,
    [UserPermissionIds.canSeeGrids]: false,
    [UserPermissionIds.canSeeGutter]: false,
    [UserPermissionIds.canChangeRecordType]: true,
    [UserPermissionIds.canSeeSurfaceOutline]: false,
    [UserPermissionIds.canSeeDraftQuotes]: false,
};
const stringPlannerPermissions: UserPermissions = {
    [UserPermissionIds.canAddPanels]: false,
    [UserPermissionIds.canManipulateElectricalCabinetUpgrades]: true,
    [UserPermissionIds.canManipulateAdditionalLabor]: true,
    [UserPermissionIds.canManipulateElectricalLabor]: true,
    [UserPermissionIds.canManipulateScaffolding]: true,
    [UserPermissionIds.canManipulateSurfaces]: true,
    [UserPermissionIds.canMovePanels]: true,
    [UserPermissionIds.canStringPanels]: true,
    [UserPermissionIds.canOverwriteQuotes]: true,
    [UserPermissionIds.canStartNewPlanning]: false,
    [UserPermissionIds.canUseTemplate]: false,
    [UserPermissionIds.canSeeGrids]: true,
    [UserPermissionIds.canSeeGutter]: false,
    [UserPermissionIds.canChangeRecordType]: true,
    [UserPermissionIds.canSeeSurfaceOutline]: true,
    [UserPermissionIds.canSeeDraftQuotes]: true,
    [UserPermissionIds.canSeeGutter]: false,
};

const roles = {
    [UserRoles.planner]: plannerPermissions,
    [UserRoles.sales]: salesPermissions,
    [UserRoles.stringPlanner]: stringPlannerPermissions,
};
export default roles;
