import { Dispatch } from 'redux';
import fetchQuote from '../../api/fetchQuote';
import {
    getDefaultObjectIdFromCollectionObject,
    isPlanOldVersion,
    oldTemplateFormatToNewStructure,
    redirectToRoofTool,
} from '../../common/functions';
import {
    hasDefaultQuoteNameFormat,
    renameKeys,
    snakeToCamel,
} from '../../common/helpers';
import { clearFromLocalStorage } from '../../common/localStorage';
import {
    ApiQuote,
    CloudQuoteActionCreator,
    CloudQuoteActionPayload,
    CloudQuoteActionType,
    CloudQuoteThunkActionCreator,
    GetQuoteActionCreator,
    ModalButton,
    PlanningData,
    QuoteActionType,
    RoofToolPlanning,
    RootState,
    SalesforceState,
    UserRoles,
} from '../../common/types';
import i18n from '../../i18n';
import { closeModal, openModal } from '../modals/modalActions';
import { newNotification } from '../notifications/notificationsActions';
import { updateUi } from '../ui/uiActions';

export const requestQuote: CloudQuoteActionCreator<CloudQuoteActionPayload> = (
    payload
) => {
    return {
        type: CloudQuoteActionType.REQUEST_QUOTE,
        payload,
    };
};

export const setQuote: CloudQuoteThunkActionCreator<CloudQuoteActionPayload> =
    (payload) => async (dispatch: Dispatch) => {
        dispatch({
            type: QuoteActionType.SET_QUOTE,
            payload: renameKeys(snakeToCamel, payload),
        });

        const activeCanvasId = getDefaultObjectIdFromCollectionObject(
            payload.data.planning.canvases
        );

        const isUserSelectedQuoteName = payload.data.salesforce
            ? !hasDefaultQuoteNameFormat(payload.data.salesforce.name)
            : false;

        dispatch(
            updateUi({
                activeCanvasId,
                isUserSelectedQuoteName,
            })
        );
    };

export const setQuoteId: CloudQuoteActionCreator<CloudQuoteActionPayload> = (
    payload
) => {
    return {
        type: QuoteActionType.SET_QUOTE_ID,
        payload,
    };
};

export const fetchQuoteFailed: CloudQuoteActionCreator<
    CloudQuoteActionPayload
> = (payload) => {
    return {
        type: CloudQuoteActionType.FETCH_QUOTE_FAILED,
        payload,
    };
};

export const getCloudQuote: GetQuoteActionCreator =
    ({ quoteId }) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const state = getState();
        const oppId = state.opportunity.opportunityId;
        dispatch(requestQuote({ quoteId }));
        const [quote, error] = await fetchQuote({ quoteId });
        const role = state.user.role;

        if (error) {
            dispatch(
                newNotification({
                    type: 'error',
                    message: i18n.t('notifications:fetchQuoteFailed', {
                        quoteId: quoteId,
                    }),
                    details: error.toString(),
                })
            );
            dispatch(fetchQuoteFailed({ quoteId }));
            return;
        }

        if (quote) {
            clearFromLocalStorage(oppId);
            const { quoteData, planningData, planningVersion, ...salesforce } =
                renameKeys(snakeToCamel, quote) as ApiQuote;

            const dispatchQuote = (
                salesforce: SalesforceState,
                planningData: PlanningData[]
            ) => {
                dispatch<any>(
                    setQuote({
                        quoteId,
                        data: {
                            salesforce,
                            planning: planningData[0],
                        },
                    })
                );
                dispatch(updateUi({ openAfterSavingQuoteId: undefined }));
            };

            if (isPlanOldVersion(planningVersion!)) {
                const handleCancel = (salesforce: SalesforceState) => {
                    dispatchQuote(salesforce, [
                        oldTemplateFormatToNewStructure(
                            quoteData as RoofToolPlanning[]
                        ),
                    ]);
                    dispatch(closeModal());
                };

                const handleConfirm = () => {
                    redirectToRoofTool(`id=${quoteId}`);
                };

                const buttons: ModalButton[] = [
                    {
                        text: i18n.t('modals:oldVersion.confirm') as string,
                        type: 'positive',
                        onClick: () => handleConfirm(),
                    },
                ];
                if (role !== UserRoles.sales) {
                    buttons.push({
                        text: i18n.t('modals:oldVersion.cancel') as string,
                        type: 'negative',
                        onClick: () => handleCancel(salesforce),
                    });
                }

                dispatch(
                    openModal({
                        modalProps: {
                            title: i18n.t('modals:oldVersion.title'),
                            description: i18n.t(
                                'modals:oldVersion.description'
                            ),
                            onModalClose: () => handleCancel(salesforce),
                            size: 'large',
                            buttons: buttons,
                        },
                    })
                );
            } else {
                dispatchQuote(salesforce, quoteData as PlanningData[]);
            }
        }
    };
