import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { loadState } from '../common/localStorage';
import { DesignerQuoteActionType, RootState } from '../common/types';
import { appReducer } from '../features/app/appReducer';
import { cloudQuoteReducer } from '../features/cloudQuote/cloudQuoteReducer';
import { designerQuoteReducer } from '../features/designerQuote/designerQuoteReducer';
import lastAction from '../features/lastAction/lastActionReducer';
import { modalReducer } from '../features/modals/modalReducer';
import { notificationsReducer } from '../features/notifications/notificationsReducer';
import { opportunityReducer } from '../features/opportunity/opportunityReducer';
import { productsReducer } from '../features/products/productsReducer';
import stateHistory from '../features/stateHistory';
import { uiReducer } from '../features/ui/uiReducer';
import { userReducer } from '../features/user/userReducer';
import {
    defaultAppState,
    defaultCloudQuoteState,
    defaultDesignerQuoteState,
    defaultNotificationsState,
    defaultOpportunityState,
    defaultProductsState,
    defaultUiState,
    defaultUserState,
} from './defaults';

const persistedState = loadState();

const rootReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    opportunity: opportunityReducer,
    cloudQuote: cloudQuoteReducer,
    designerQuote: stateHistory(
        designerQuoteReducer,
        20,
        ['planning'],
        [
            DesignerQuoteActionType.SET_PANELS,
            DesignerQuoteActionType.ADD_PANEL,
            DesignerQuoteActionType.REMOVE_PANELS,
            DesignerQuoteActionType.ROTATE_PANELS,
            DesignerQuoteActionType.ADD_SURFACE,
            DesignerQuoteActionType.UPDATE_SURFACE,
            DesignerQuoteActionType.DELETE_SURFACE,
            DesignerQuoteActionType.SET_VERTICES,
            DesignerQuoteActionType.TOGGLE_PANELS_VISIBILITY,
            DesignerQuoteActionType.UPDATE_PRODUCT,
            DesignerQuoteActionType.DELETE_CANVAS,
            [
                DesignerQuoteActionType.UPDATE_PRODUCT,
                ['planning', 'salesforce'],
            ],
        ],
        [DesignerQuoteActionType.ADD_PHOTOS]
    ),
    products: productsReducer,
    modal: modalReducer,
    notifications: notificationsReducer,
    ui: uiReducer,
    lastAction,
});

export const initialState: RootState = {
    app: defaultAppState,
    user: defaultUserState,
    products: defaultProductsState,
    opportunity: defaultOpportunityState,
    cloudQuote: defaultCloudQuoteState,
    designerQuote: defaultDesignerQuoteState,
    notifications: defaultNotificationsState,
    modal: null,
    ui: defaultUiState,
    lastAction: undefined,
};

export const configureStore = (state?: RootState): Store<RootState> => {
    const middlewares = [thunk];
    const middlewareEnhancer = applyMiddleware<ThunkMiddleware>(...middlewares);
    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools({
        trace: true,
    });
    return createStore(
        rootReducer,
        state || persistedState || initialState,
        composedEnhancers(...enhancers)
    );
};
