import i18n from 'i18next';
import { callEigenApiWith, callSalesforceApiWithoutResponse } from '.';
import { SALESFORCE_TARGET, STRING_PLAN_FOLDER } from '../common/constants';
import { validateObjectData } from '../common/functions';
import { azimuthOptions } from '../common/selectOptions';
import {
    AccessTokenFunction,
    BasicQuoteProduct,
    Canvas,
    ModulePlanningData,
    RootState,
    SalesforceProductsState,
} from '../common/types';
import { getStringPlanToSave } from '../components/StringPlanner/functions';

export const sortSurfacesByLeftMost = <T>(
    surfaces: Array<ModulePlanningData & T>,
    canvases: Canvas[]
) => {
    const newSurfaces: Array<ModulePlanningData & T> = [];
    canvases.forEach((canvas) => {
        const canvasSurfaces = Object.values(canvas.surfaces);
        canvasSurfaces.sort((a, b) => a.vertices![0][0] - b.vertices![0][0]);
        canvasSurfaces.forEach((canvasSurface) => {
            newSurfaces.push(
                surfaces.find((surface) => surface.id === canvasSurface.id)!
            );
        });
    });
    return newSurfaces;
};

const getStringPlanName = (
    panelPower: string,
    battery: BasicQuoteProduct | null
) => {
    return (
        i18n.t('stringPlan:name', {
            power: panelPower,
        }) +
        (battery && battery.power
            ? ' ' +
              i18n.t('stringPlan:includingBattery', {
                  batteryPower: (
                      battery as BasicQuoteProduct
                  ).power!.toLocaleString('de-DE', {
                      maximumFractionDigits: 2,
                  }),
              })
            : '')
    );
};

export const getStringPlan = async (
    surfacesWithImages: Array<ModulePlanningData & { image: Blob }>,
    opportunityId: string,
    rootState: RootState,
    fileId: string
) => {
    const { panels, battery, inverter, secondInverter } = rootState
        .designerQuote.salesforce.Products as SalesforceProductsState;
    const inverters = [];
    if (inverter) inverters.push(inverter);
    if (secondInverter) inverters.push(secondInverter);
    const panelPower = (
        ((panels?.quantity ?? 0) * (panels?.power ?? 0)) /
        1000
    ).toLocaleString('de-DE', { maximumFractionDigits: 2 });

    return await callEigenApiWith<{
        error: boolean;
        url?: string;
        errorMessage?: string;
        eTag?: string;
        size?: number;
        bucket?: string;
    }>({
        name: 'stringplan',
        id: opportunityId,
        middleware: [validateObjectData],
        options: {
            method: 'POST',
            body: JSON.stringify({
                fileName: fileId,
                battery,
                name: getStringPlanName(panelPower, battery),
                panel: {
                    ...panels,
                    openCircuitVoltage: panels?.openCircuitVoltage || 0,
                    shortCircuitCurrent: panels?.shortCircuitCurrent || 0,
                },
                inverters,
                strings: getStringPlanToSave(rootState, surfacesWithImages).map(
                    (string) => ({
                        ...string,
                        label: `String ${string.name}`,
                    })
                ),
                surfaces: sortSurfacesByLeftMost(
                    surfacesWithImages,
                    Object.values(rootState.designerQuote.planning.canvases)
                ).map((surface) => ({
                    ...surface,
                    direction:
                        azimuthOptions[
                            azimuthOptions.findIndex((azimuthOption) => {
                                return surface.azimuth === azimuthOption.value;
                            })
                        ].text,
                })),
            }),
        },
    });
};

export const saveStringPlan = async (
    file: {
        id: string;
        url: string;
        eTag: string;
        bucket: string;
        size: number;
    },
    accessToken: AccessTokenFunction
) => {
    await callSalesforceApiWithoutResponse({
        name: SALESFORCE_TARGET,
        id: file.id,
        middleware: [validateObjectData],
        accessToken,
        options: {
            method: 'PATCH',
            body: JSON.stringify({
                TVA_CFB__Cloud_Public_Access_URL__c: file.url,
                TVA_CFB__Folder__c: STRING_PLAN_FOLDER,
                TVA_CFB__File_Path__c: file.url,
                TVA_CFB__Bucket_Name__c: file.bucket,
                TVA_CFB__Region__c: 'eu-central-1',
                TVA_CFB__E_Tag__c: file.eTag,
                TVA_CFB__File_Type__c: 'png',
                TVA_CFB__File_Size_in_Bytes__c: file.size,
                name: 'String Plan.png',
            }),
        },
    });
};
