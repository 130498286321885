import React, { CSSProperties, FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_MOUNTING_SYSTEM_COLOR } from '../../common/constants';
import { RootState, SalesforceProductsState } from '../../common/types';
import {
    dispatchAndSaveToLocalStorage,
    setValidMountingSystems,
} from '../../features/designerQuote/designerQuoteActions';
import Float from '../Float';
import SalesCanvasSelector from './SalesCanvasSelector';
import SalesMenu from './SalesMenu';
import SalesTools from './SalesTools';
import { useAppDispatch } from '../../common/hooks';

const floatStyle: CSSProperties = {
    pointerEvents: 'all',
};

const SalesUi: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const products = useSelector<
        RootState,
        SalesforceProductsState | undefined
    >((state) => state.designerQuote.salesforce.Products);

    const startingMountingSystemColour =
        products?.mountingSystem &&
        products?.mountingSystem[0] &&
        products.mountingSystem[0].color
            ? products.mountingSystem[0].color
            : DEFAULT_MOUNTING_SYSTEM_COLOR;

    const [mountingColor, setMountingColor] = useState(
        startingMountingSystemColour
    );

    const handleMountingSystemChange = (data: string) => {
        setMountingColor(data);
        dispatch(
            dispatchAndSaveToLocalStorage(
                setValidMountingSystems({ color: data })
            )
        );
    };

    return (
        <>
            <Float bottom left style={floatStyle}>
                <SalesTools mountingColor={mountingColor} />
            </Float>
            <Float bottom right style={floatStyle}>
                <SalesCanvasSelector />
            </Float>
            <Float top right style={floatStyle}>
                <SalesMenu
                    handleMountingSystemChange={handleMountingSystemChange}
                    mountingColor={mountingColor}
                />
            </Float>
        </>
    );
};

export default SalesUi;
