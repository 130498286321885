import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import Icon from '../Icon';
import Square from '../Square';
import styles from './IconToggle.module.scss';

type Props = {
    icon: string;
    className?: string;
    value?: boolean;
    defaultValue?: boolean;
    hoverLabel?: string;
    onClick?: (e: React.MouseEvent, value: boolean) => unknown;
};

const IconToggle: React.FC<Props> = ({
    className,
    value,
    defaultValue,
    hoverLabel,
    icon,
    onClick,
}) => {
    const [isOn, setIsOn] = useState(
        defaultValue !== undefined ? defaultValue : !!value
    );

    const currentValue = value !== undefined ? value : isOn;

    const handleClick = useCallback<React.MouseEventHandler>(
        (e) => {
            setIsOn(!currentValue);
            onClick && onClick(e, !currentValue);
        },
        [isOn]
    );

    return (
        <Square className={classNames(styles.root, className)}>
            <div className={styles.iconContainer} onClick={handleClick}>
                <Icon
                    id={icon}
                    className={classNames(styles.icon, {
                        [styles.isOn]: currentValue,
                    })}
                    size={'small'}
                />
                {hoverLabel && (
                    <span className={styles.hoverLabel}>{hoverLabel}</span>
                )}
            </div>
        </Square>
    );
};

export default IconToggle;
