import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { panelsInCanvas } from '.';
import CanvasElement from '../../components/CanvasElement';
import { updateUi } from '../../features/ui/uiActions';
import { configureStore } from '../../store/configureStore';
import { CANVAS_SIZE } from '../constants';
import { Canvas, RootState } from '../types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const domtoimage = require('dom-to-image');

const timeout = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const takeShotOfCanvas = async (
    canvasDescriptor: Canvas,
    rootState: RootState
) => {
    const store = configureStore(rootState);
    // Hide the grids before taking the shot
    store.dispatch(
        updateUi({
            hideGrids: true,
            activeSurfaceId: undefined,
            activeCanvasId: undefined,
            isRenderingScreenshot: true,
            lockedSurfaceIds: [],
        })
    );

    let resolvePromise: (value: Blob) => void;
    const promise = new Promise((resolve) => (resolvePromise = resolve));

    const takeScreenshot = async () => {
        await timeout(123); // I will count till 3 and then you're ready
        const screenshotDom = document.getElementById(canvasDescriptor.id);
        if (!screenshotDom) throw new Error("Couldn't find canvas dom element");

        const blob = await (
            domtoimage as {
                toBlob: (el: Element, options: any) => Promise<Blob>;
            }
        ).toBlob(screenshotDom, {
            width: screenshotDom.clientWidth,
            height: screenshotDom.clientHeight,
        });

        const toRemoveContainer = document.getElementById('snapshot');
        if (toRemoveContainer !== null) {
            ReactDOM.unmountComponentAtNode(toRemoveContainer);
        }

        resolvePromise(blob);
    };

    const canvasWrapper = (
        <Provider store={store}>
            <CanvasElement
                id={canvasDescriptor.id}
                descriptor={canvasDescriptor}
                size={canvasDescriptor.imageBox.dimensions || CANVAS_SIZE}
                key={canvasDescriptor.id}
                onCanvasReady={takeScreenshot}
            />
        </Provider>
    );
    ReactDOM.render(canvasWrapper, document.getElementById('snapshot'));
    return promise as Promise<Blob>;
};

export const canvasesToBlobs = async (rootState: RootState) => {
    const blobs = [];
    const canvases = Object.values(rootState.designerQuote.planning.canvases);
    const filledCanvases = canvases.filter(
        (canvas) => panelsInCanvas(canvas) > 0
    );
    for (const canvas of filledCanvases) {
        // TODO: introduce a timeout just in case
        const blob = await takeShotOfCanvas(canvas, rootState);
        blobs.push(blob);
    }
    return blobs;
};
