import React, { CSSProperties, FunctionComponent, useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import {
    autoArrangeVertices,
    sortVerticesByYAndThenX,
} from '../../common/functions';
import useDesignerQuote from '../../common/hooks/useDesignerQuote';
import useOpportunity from '../../common/hooks/useOpportunity';
import { useUi } from '../../common/hooks/useUi';
import useUnsavedChangesModal from '../../common/hooks/useUnsavedChangesModal';
import useUserRole from '../../common/hooks/useUserRole';
import Hotkeys from '../../common/hotkeys';
import {
    PanelsUpdateHandler,
    UserRoles,
    Vertices,
    VerticesUpdateHandler,
} from '../../common/types';
import { reload } from '../../features/app/appActions';
import {
    addSurface,
    applyRatio,
    dispatchAndSaveToLocalStorage,
    setPanels,
    setVertices,
} from '../../features/designerQuote/designerQuoteActions';
import Logo from '../Logo';
import Stage from '../Stage';
import StartPlanningWizard from '../StartPlanningWizard';
import StringPlanner from '../StringPlanner';
import UiOverlay from '../UiOverlay';
import styles from './BasePage.module.scss';
import { useAppDispatch } from '../../common/hooks';

const startPlanningWizard: CSSProperties = {
    width: '90vw',
};

const BasePage: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const opportunity = useOpportunity();
    const quote = useDesignerQuote();

    const [{ activeCanvasId, isShapeBuilderOn, hidePanels }, updateUiFromHook] =
        useUi();
    const role = useUserRole();

    const { canvases } = quote.planning;
    const activeCanvas = activeCanvasId ? canvases[activeCanvasId] : undefined;
    const handleLeaveQuote = useUnsavedChangesModal();
    const handleLogoClick = () => {
        handleLeaveQuote(reload);
    };

    const handleFinishedDrawing = (vertices: Vertices, canvasId: string) => {
        const addSurfaceAction = addSurface({
            data: {
                width: 0,
                height: 0,
                hasGutter: true,
                surface: {
                    vertices: sortVerticesByYAndThenX(
                        autoArrangeVertices(vertices)
                    ),
                    panels: [],
                },
                canvasId,
            },
        });
        dispatch(dispatchAndSaveToLocalStorage(addSurfaceAction));
        updateUiFromHook({
            isShapeBuilderOn: false,
            activeSurfaceId: addSurfaceAction.payload.data.surface.id, // Selects newly created surface
        });
    };
    const handleRatio = (vertices: Vertices) => {
        dispatch(
            dispatchAndSaveToLocalStorage(
                applyRatio({
                    data: vertices,
                })
            )
        );
    };

    const handlePanelsUpdate: PanelsUpdateHandler = useCallback((panels) => {
        dispatch(dispatchAndSaveToLocalStorage(setPanels({ data: panels })));
    }, []);

    const handleVerticesUpdate: VerticesUpdateHandler = useCallback(
        (vertices) => {
            dispatch(
                dispatchAndSaveToLocalStorage(setVertices({ data: vertices }))
            );
            handleRatio(vertices);
        },
        []
    );

    useHotkeys(
        Hotkeys.HIDE_SURFACES.hook,
        (key: KeyboardEvent) => {
            switch (key.type) {
                case 'keydown':
                    if (!hidePanels)
                        updateUiFromHook({
                            hidePanels: true,
                            hideGrids: true,
                        });
                    break;
                case 'keyup':
                    updateUiFromHook({ hidePanels: false, hideGrids: false });
            }
        },
        { keydown: true, keyup: true },
        [hidePanels]
    );

    return activeCanvas && Object.keys(canvases).length > 0 ? (
        role === UserRoles.stringPlanner ? (
            <StringPlanner className={styles.stringPlanner}>
                <Stage
                    className={styles.stage}
                    canvas={activeCanvas}
                    isShapeBuilderOn={isShapeBuilderOn}
                    onFinishedDrawing={handleFinishedDrawing}
                    onPanelsUpdate={handlePanelsUpdate}
                    onVerticesUpdate={handleVerticesUpdate}
                    stringPlanner
                />
                <UiOverlay />
                <Logo onClick={handleLogoClick} />
            </StringPlanner>
        ) : (
            <>
                <Stage
                    className={styles.stage}
                    canvas={activeCanvas}
                    isShapeBuilderOn={isShapeBuilderOn}
                    onFinishedDrawing={handleFinishedDrawing}
                    onPanelsUpdate={handlePanelsUpdate}
                    onVerticesUpdate={handleVerticesUpdate}
                />
                <Logo
                    onClick={handleLogoClick}
                    recordType={quote.salesforce.recordType?.name}
                />
                <UiOverlay />
            </>
        )
    ) : opportunity ? (
        <StartPlanningWizard style={startPlanningWizard} />
    ) : (
        <>Nothing to display</>
    );
};

export default BasePage;
