import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Label } from 'semantic-ui-react';
import Icon from '../Icon';
import styles from './ProductBadge.module.scss';

type Props = {
    className?: string;
    iconId: string;
    labelContent: string;
};

const ProductBadge: FunctionComponent<Props> = ({
    className,
    iconId,
    labelContent,
}) => {
    return (
        <Label className={classNames(className, styles.root)}>
            <Icon className={styles.icon} id={iconId} stroke={5} />
            {labelContent}
        </Label>
    );
};

export default ProductBadge;
