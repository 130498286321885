import { callEigenApiWith, callSalesforceApiWithoutResponse } from '.';
import { SALESFORCE_TARGET } from '../common/constants';
import {
    createSalesforcePatchBody,
    validateObjectData,
} from '../common/functions';
import { SaveFileResponse } from '../common/responseTypes';
import { AccessTokenFunction } from '../common/types';
import { createQuoteFile } from './createQuoteFile';

// SAVING THE SNAPSHOT
export const saveOneBlob = async (p: {
    quoteId: string;
    accessToken: AccessTokenFunction;
    blob: Blob;
    index: number;
}) => {
    // 1. POST to salesforce and get an id
    const salesforceCreation = await createQuoteFile(p.quoteId, p.accessToken);

    //  2. Save the image to S3 bucket via EigenAPI
    const imageId = salesforceCreation.id;
    const formData = new FormData();
    formData.append('file', p.blob, imageId);
    formData.append('name', imageId);

    const saveImageResponse = await callEigenApiWith<SaveFileResponse>({
        name: 'files',
        middleware: [validateObjectData],
        options: { method: 'POST', body: formData },
    });

    // 3. Update the file in SalesForce
    const patchBody = createSalesforcePatchBody(
        saveImageResponse,
        p.blob.size,
        p.index
    );

    await callSalesforceApiWithoutResponse({
        name: SALESFORCE_TARGET,
        id: imageId,
        middleware: [validateObjectData],
        accessToken: p.accessToken,
        options: {
            method: 'PATCH',
            body: JSON.stringify(patchBody),
        },
    });
};

// // Save blobs, one after the other
export const savePlanningPictures = async (p: {
    quoteId: string;
    accessToken: AccessTokenFunction;
    blobs: Blob[];
}) => {
    for (const [i, blob] of p.blobs.entries()) {
        await saveOneBlob({
            quoteId: p.quoteId,
            accessToken: p.accessToken,
            blob: blob,
            index: i,
        });
    }
};
