import { RoofTypes } from './types';

export const MIN_SPACE_BETWEEN_PANELS = 30; // mm
export const ALTERNATING_PANELS_SPACE_BETWEEN_X = 100;
export const SURFACE_DEFAULT_ALLOWED_DISTANCE_TO_EDGE: {
    [key in RoofTypes]: number;
} = {
    flat: 600,
    slope: 200,
};
export const SOUTH_FACING_PANEL_GUTTER = 650; // mm
export const ANGLE_OF_PANEL_RAISED_ON_FLAT_ROOFS = 10; //degrees

export const PANEL_SPACING = {
    sloped: {
        x: MIN_SPACE_BETWEEN_PANELS,
        y: MIN_SPACE_BETWEEN_PANELS,
    },
    alternating: {
        x: ALTERNATING_PANELS_SPACE_BETWEEN_X,
        y: MIN_SPACE_BETWEEN_PANELS,
    },
    southFacing: {
        x: 0,
        y: MIN_SPACE_BETWEEN_PANELS,
    },
};

export const FEED_IN_TARRIF_OVER_10_KWP = 0.071;
export const FEED_IN_TARRIF_UNDER_10_KWP = 0.082;

export const OPP_ID = 'oppId';
export const Q_ID = 'qId';
export const USER_ROLE_ID = 'role';
export const CANVAS_SIZE = { width: 1920, height: 1080 };
export const PLANNING_IMAGE_FOLDER_PREFIX = 'Rendering Photos';
export const PLANNING_IMAGE_FILE_PREFIX = 'Rendering Photo ';
export const SALESFORCE_TARGET = 'TVA_CFB__Cloud_Files__c';
export const DEFAULT_MOUNTING_SYSTEM_COLOR = 'Silver';
export const NO_PRODUCT = 'noProduct';
export const SOLARDESIGNER_PLANNINGVERSION = 2;
export const SCALE_MAX_HEIGHT = 1080;
export const SCALE_MAX_WIDTH = 1920;
export const MAX_SELECTION_WIDTH = 20000;
export const MAX_PICTURE_AMOUNT = 4;
export const SURFACE_SIZE_MIN = 500;
export const SURFACE_SIZE_MAX = 50000;
export const REGEX_DEFAULT_NAME_FORMAT = /^\d{8} - \d+x \w+/g;
export const QUOTE_STATUS_DRAFT = 'Draft';
export const QUOTE_STATUS_READY = 'Ready to Present';
export const PYRAMID_MOUNTING_SYSTEM_SUPPLEMENTARY = 'Pyramidenbelegung';
export const BETA_PLATTE_MOUNTING_SYSTEM_ACCESSORY_SUPPLEMENTARY =
    'Beta-Platte';
export const MISCHBELEGUNG_MOUNTING_SYSTEM_SUPPLEMENTARY = 'Mischbelegung';
export const STRING_OPTIONS = [
    { id: 'a', name: 'A' },
    { id: 'b', name: 'B' },
    { id: 'c', name: 'C' },
    { id: 'd', name: 'D' },
    { id: 'e', name: 'E' },
];

export const EMPTY_RECTANGLE = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
};

export const STRING_PLAN_FOLDER = 'String Plan';
export const stringKeyColors = {
    a: '#227C9D',
    b: '#6dce71',
    c: '#FFCB77',
    d: '#1AC3B2',
    e: '#DDAEFD',
};
