import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';
import { UseCases } from '../../common/types';
import Icon from '../Icon';
import styles from './Button.module.scss';

type Props = {
    className?: string;
    style?: CSSProperties;
    type?: 'planner' | 'sales';
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    variant?: 'primary' | 'secondary' | 'positive' | 'negative';
    disabled?: boolean;
    icon?: string;
    iconActive?: string;
    iconSize?: 'small' | 'big';
    iconUseCase?: UseCases;
    iconOnly?: boolean;
    iconStrokeWidth?: number;
    active?: boolean;
    childrenActive?: ChildNode;
    tooltip?: string;
    tooltipPosition?: string;
    tooltipDelay?: boolean;
    filled?: boolean;
    children?: React.ReactNode;
};

const iconStyle = { marginRight: '.5rem' };

const Button: FunctionComponent<Props> = ({
    children,
    childrenActive,
    className = '',
    type = 'planner',
    variant,
    onClick,
    disabled,
    icon,
    iconActive,
    iconSize,
    iconUseCase,
    iconOnly,
    iconStrokeWidth,
    active,
    style,
    tooltip,
    tooltipPosition,
    tooltipDelay = false,
    filled,
}) => {
    const activeToggle = iconActive && active;

    const label = !activeToggle ? children : childrenActive;

    return (
        <SemanticButton
            active={active}
            className={classNames(className, styles.button, {
                [styles.primary]: variant === 'primary',
                [styles.secondary]: variant === 'secondary',
                [styles.positive]: variant === 'positive',
                [styles.negative]: variant === 'negative',
                [styles.sales]: type === 'sales',
                [styles.filled]: filled,
                [styles.iconOnly]: iconOnly,
                [styles[`icon-${iconSize}`]]: iconSize !== undefined,
            })}
            style={{ ...style }}
            onClick={onClick}
            disabled={disabled}
            basic={iconOnly}
            data-tooltip={tooltip}
            data-tooltip-position={tooltipPosition}
            data-position={tooltipPosition}
            data-tooltip-delay={tooltip && tooltipDelay}
            primary={variant === 'primary'}
            secondary={variant === 'secondary'}
        >
            {icon && !activeToggle && (
                <Icon
                    id={icon}
                    size={iconSize}
                    style={type !== 'sales' && children ? iconStyle : {}}
                    useCase={iconUseCase}
                    stroke={iconStrokeWidth}
                />
            )}
            {iconActive && activeToggle && (
                <Icon
                    id={iconActive}
                    size={iconSize}
                    style={type !== 'sales' && children ? iconStyle : {}}
                    useCase={iconUseCase}
                    stroke={iconStrokeWidth}
                />
            )}
            {label && (
                <span className={classNames('text', styles.text)}>
                    {label as string}
                </span>
            )}
        </SemanticButton>
    );
};

export default Button;
