import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List } from 'semantic-ui-react';
import {
    SOUTH_FACING_PANEL_GUTTER,
    SURFACE_DEFAULT_ALLOWED_DISTANCE_TO_EDGE,
} from '../../common/constants';
import { hasPanelsWithGutter } from '../../common/functions';
import useAnnualYieldHours from '../../common/hooks/useAnnualYieldHours';
import useOpportunity from '../../common/hooks/useOpportunity';
import { useUi } from '../../common/hooks/useUi';
import {
    azimuthOptions,
    gutterOptionsFlat,
    gutterOptionsSlope,
    slopeOptions,
} from '../../common/selectOptions';
import {
    FlatRoofModes,
    ModulePlanningData,
    RoofTypes,
    SurfaceInput,
    UseCases,
    VoidHandler,
} from '../../common/types';
import Button from '../Button';
import Icon from '../Icon';
import Input from '../Input';
import InputNumber from '../InputNumber';
import Select from '../Select';
import Switch from '../Switch';
import { SurfaceDetailChangeHandler } from './LayersMenu';
import styles from './SurfaceLayerMenu.module.scss';

type SurfaceLayerMenuProps = {
    disabled?: boolean;
    onSurfaceDetailChange: SurfaceDetailChangeHandler;
    layerId: string;
    data?: ModulePlanningData;
    onRatioClick?: VoidHandler;
};

const SurfaceLayerMenu: FunctionComponent<SurfaceLayerMenuProps> = ({
    disabled,
    onSurfaceDetailChange,
    data,
    layerId,
    onRatioClick,
}) => {
    const { t } = useTranslation(['common']);
    const { windZone } = useOpportunity();
    const [{ activeSurfaceId }] = useUi();
    let surfaceYield: string | undefined = useAnnualYieldHours().toString();

    if (surfaceYield === '0.00') surfaceYield = undefined;

    const roofTypeOptions = [
        {
            id: RoofTypes.slope.toString(),
            name: (
                <>
                    {t('type slope')}&nbsp;
                    <Icon id="rooftype-slope" />
                </>
            ),
        },
        {
            id: RoofTypes.flat.toString(),
            name: (
                <>
                    <Icon id="rooftype-flat" />
                    &nbsp;
                    {t('type flat')}
                </>
            ),
        },
    ];

    const flatRoofOptions = [
        {
            id: FlatRoofModes.normal.toString(),
            name: (
                <>
                    {t('flat roof normal')}&nbsp;
                    <Icon id="flatroof-normal" />
                </>
            ),
        },
        {
            id: FlatRoofModes.alternate.toString(),
            name: (
                <>
                    <Icon id="flatroof-alternate" />
                    &nbsp;{t('flat roof alternate')}
                </>
            ),
        },
    ];
    const roofType = data?.roofType;
    const flatRoofMode = data?.flatRoofMode;
    const isFlatRoof = data?.roofType === 'flat';
    const [ratioIsset, setRatio] = useState(data?.ratios !== undefined);
    const [surfaceDimensions, setSurfaceDimensions] = useState({
        width: data && data.width && Math.round(data.width),
        height: data && data.height && Math.round(data.height),
    });

    const handleRatioClick = () => {
        setRatio(!ratioIsset);
        if (ratioIsset) {
            setSurfaceDimensions({
                width: data && data.width && Math.round(data.width),
                height: data && data.height && Math.round(data.height),
            });
        }
        onRatioClick && onRatioClick();
    };

    const onSurfaceRoofTypeChange = (
        layerId: string,
        property: SurfaceInput,
        value: string
    ) => {
        onSurfaceDetailChange(layerId, property, value);
        if (data?.surfaceGutter && data.surfaceGutter > 0) {
            onSurfaceDetailChange(
                layerId,
                SurfaceInput.surfaceGutter,
                value === RoofTypes.flat
                    ? SURFACE_DEFAULT_ALLOWED_DISTANCE_TO_EDGE.flat
                    : SURFACE_DEFAULT_ALLOWED_DISTANCE_TO_EDGE.slope
            );
        }
    };

    useEffect(() => {
        if (isFlatRoof) onSurfaceDetailChange(layerId, SurfaceInput.slope, 0);
    }, [isFlatRoof]);

    useEffect(() => {
        setSurfaceDimensions({
            width: data && data.width && Math.round(data.width),
            height: data && data.height && Math.round(data.height),
        });
    }, [onSurfaceDetailChange]);

    if (activeSurfaceId !== layerId) return null;

    const gutterMinAndMax = {
        min: 650,
        max: 1000,
    };

    const gutterOptions =
        data?.roofType === RoofTypes.flat
            ? gutterOptionsFlat
            : gutterOptionsSlope;

    const windZoneSpacing =
        data?.roofType === RoofTypes.flat && windZone > 2
            ? gutterOptions[2].value
            : gutterOptions[1].value;

    return (
        <>
            <List as="ul" className={styles.root}>
                <List.Item as="li" className={styles.item}>
                    <span className={styles.title}>{t('size')}</span>
                    <Input
                        className={styles.input}
                        disabled={ratioIsset || disabled}
                        size="mini"
                        type={'int'}
                        defaultValue={surfaceDimensions.width?.toString()}
                        onChange={(e, data) =>
                            onSurfaceDetailChange(
                                layerId,
                                SurfaceInput.width,
                                data.value as string
                            )
                        }
                        placeholder={t('width') as string}
                    />
                    {'x'}
                    <Input
                        className={styles.input}
                        disabled={ratioIsset || disabled}
                        size="mini"
                        type={'int'}
                        defaultValue={surfaceDimensions.height?.toString()}
                        onChange={(e, data) =>
                            onSurfaceDetailChange(
                                layerId,
                                SurfaceInput.height,
                                data.value as string
                            )
                        }
                        placeholder={t('height') as string}
                    />
                    {'mm'}
                    <Button
                        className={classNames(styles.buttonBasic, {
                            [styles.ratioIsSet]: ratioIsset,
                        })}
                        icon="dimensions-lock"
                        iconSize={'small'}
                        iconUseCase={UseCases.inLayersMenu}
                        iconActive={'dimensions-lock'}
                        active={ratioIsset}
                        tooltip={t('setScale') as string}
                        iconStrokeWidth={2}
                        onClick={handleRatioClick}
                        disabled={
                            (data && !data.width) || (data && !data.height)
                        }
                    />
                </List.Item>
                <List.Item as="li" className={styles.item}>
                    <span className={styles.title}>{t('orientation')}</span>
                    <Select
                        disabled={disabled}
                        className={classNames(
                            SurfaceInput.azimuth,
                            styles.dropdown,
                            styles.dropAzimuth
                        )}
                        placeholder={t(SurfaceInput.azimuth) as string}
                        value={data?.azimuth}
                        onChange={(e, data) => {
                            onSurfaceDetailChange(
                                layerId,
                                SurfaceInput.azimuth,
                                data.value as string
                            );
                        }}
                        options={azimuthOptions}
                    />
                    <Select
                        disabled={isFlatRoof || disabled}
                        className={classNames(
                            SurfaceInput.slope,
                            styles.dropdown
                        )}
                        placeholder={t(SurfaceInput.slope) as string}
                        value={data?.slope}
                        onChange={(e, data) =>
                            onSurfaceDetailChange(
                                layerId,
                                SurfaceInput.slope,
                                data.value as string
                            )
                        }
                        options={slopeOptions}
                    />
                </List.Item>
                {process.env.REACT_APP_ENABLE_FLAT_ROOF === '1' && (
                    <List.Item as="li" className={styles.item}>
                        <span className={styles.title}>{t('roof type')}</span>
                        <Switch
                            size="small"
                            className={styles.switchStyle}
                            switchOptions={roofTypeOptions}
                            switchValue={roofType}
                            handleChange={(data) =>
                                onSurfaceRoofTypeChange(
                                    layerId,
                                    SurfaceInput.roofType,
                                    data as RoofTypes
                                )
                            }
                        />
                    </List.Item>
                )}
                {isFlatRoof && (
                    <List.Item
                        as="li"
                        className={classNames(styles.item, styles.appear)}
                    >
                        <span className={styles.title}>
                            {t('installation type')}
                        </span>
                        <Switch
                            size="small"
                            className={styles.switchStyle}
                            switchOptions={flatRoofOptions}
                            switchValue={flatRoofMode}
                            handleChange={(data) =>
                                onSurfaceDetailChange(
                                    layerId,
                                    SurfaceInput.flatRoofMode,
                                    data as FlatRoofModes
                                )
                            }
                        />
                    </List.Item>
                )}
                <List.Item as="li" className={classNames(styles.item)}>
                    <span className={styles.title}>{t('with gutter')}</span>
                    <>
                        <Select
                            className={classNames(
                                SurfaceInput.slope,
                                styles.dropdown
                            )}
                            value={data?.surfaceGutter || windZoneSpacing}
                            options={gutterOptions}
                            onChange={(e, data) => {
                                onSurfaceDetailChange(
                                    layerId,
                                    SurfaceInput.surfaceGutter,
                                    data.value as number
                                );
                            }}
                        />
                        {t('safety area')}
                    </>
                </List.Item>
                {data && hasPanelsWithGutter(data.panels) ? (
                    <List.Item as="li" className={styles.item}>
                        <span className={styles.title}>{t('panelgutter')}</span>
                        <InputNumber
                            id={'gutter-' + layerId}
                            value={data?.gutter || SOUTH_FACING_PANEL_GUTTER}
                            className={SurfaceInput.gutter}
                            title={
                                t('panelgutter') +
                                ' ' +
                                t('between') +
                                ' ' +
                                gutterMinAndMax.min +
                                ' - ' +
                                gutterMinAndMax.max +
                                ' mm'
                            }
                            min={gutterMinAndMax.min}
                            max={gutterMinAndMax.max}
                            step={10}
                            onChange={(number) =>
                                onSurfaceDetailChange(
                                    layerId,
                                    SurfaceInput.gutter,
                                    number
                                )
                            }
                        />
                        mm
                    </List.Item>
                ) : null}
                <List.Item as="li" className={styles.item}>
                    <span className={styles.title}>{t('yield_reduction')}</span>
                    <InputNumber
                        id={'yield-' + layerId}
                        title={
                            t('yield_reduction') +
                            ' ' +
                            t('between') +
                            ' 0 - 95%'
                        }
                        min={0}
                        max={95}
                        step={5}
                        value={data?.yieldReduction || 0}
                        className={SurfaceInput.yieldReduction}
                        onChange={(number) => {
                            onSurfaceDetailChange(
                                layerId,
                                SurfaceInput.yieldReduction,
                                number.toString()
                            );
                        }}
                    />
                    %
                    <span className={styles.yield}>
                        {'≈ '}
                        {surfaceYield &&
                            Math.round(Number(surfaceYield?.toString()) * 10) /
                                10}{' '}
                        kWh/kWp
                    </span>
                </List.Item>
            </List>
        </>
    );
};

export default SurfaceLayerMenu;
