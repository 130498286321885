import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { CANVAS_SIZE } from '../../common/constants';
import useDesignerQuote from '../../common/hooks/useDesignerQuote';
import {
    Canvas,
    PanelsUpdateHandler,
    Point,
    Vertices,
    VerticesUpdateHandler,
    VoidHandler,
} from '../../common/types';
import Geometry from '../Geometry';
import ModuleElement from '../ModuleElement';
import ShapeBuilder from '../ShapeBuilder';
import styles from './CanvasElement.module.scss';

type Props = {
    id?: string;
    className?: string;
    disabled?: boolean;
    descriptor: Canvas;
    size: { width: number; height: number };
    environmentState?: { scale: number; offset: Point };
    isShapeBuilderOn?: boolean;
    onFinishedDrawing?: (vertices: Vertices, canvasId: string) => void;
    onCanvasReady?: VoidHandler;
    onPanelsUpdate?: PanelsUpdateHandler;
    onVerticesUpdate?: VerticesUpdateHandler;
};

const CanvasElement: FunctionComponent<Props> = (props) => {
    const {
        id,
        descriptor,
        disabled,
        size = CANVAS_SIZE,
        environmentState = { scale: 1, offset: { x: 0, y: 0 } },
        isShapeBuilderOn = false,
        onFinishedDrawing,
        className,
        onCanvasReady,
        onPanelsUpdate,
        onVerticesUpdate,
    } = props;

    const [geometryState, setGeometryState] = useState({
        position: { x: 0, y: 0 },
        scaleValue: 1,
        rotation: 0,
    });

    const panelProduct = useDesignerQuote().salesforce.Products?.panels;

    const handleChangePositioning = (p: {
        position: Point;
        rotation: number;
        scaleValue: number;
    }) => {
        setGeometryState({ ...p });
    };

    const [elementsLoadingCount, setElementsLoadingCount] = useState(
        Object.keys(descriptor.surfaces).length + 1 // surfaces + 1 image
    );

    const handleElementReady = useMemo(
        () =>
            onCanvasReady
                ? () => {
                      setElementsLoadingCount((count) => count - 1);
                  }
                : undefined,
        [onCanvasReady]
    );

    useEffect(() => {
        elementsLoadingCount === 0 && onCanvasReady && onCanvasReady();
    }, [elementsLoadingCount]);

    return (
        <div
            id={id}
            className={classNames(styles.root, className, {
                [styles.disabled]: disabled,
            })}
            style={size}
        >
            <Geometry
                className={styles.geometry}
                environmentState={environmentState}
                onChangePositioning={handleChangePositioning}
            >
                <div className={styles.imageWrapper}>
                    <img
                        draggable={false}
                        src={descriptor.imageBox.url}
                        width={size.width}
                        alt={'imageBox'}
                        onLoad={handleElementReady}
                        onError={handleElementReady}
                        className={styles.canvasImage}
                    />
                </div>
                {panelProduct &&
                    Object.values(descriptor.surfaces).map((surface) => (
                        <ModuleElement
                            key={surface.id}
                            planningData={surface}
                            panelProduct={panelProduct}
                            disabled={disabled || isShapeBuilderOn}
                            environmentScale={
                                environmentState.scale *
                                geometryState.scaleValue
                            }
                            onReady={handleElementReady}
                            onPanelsUpdate={onPanelsUpdate}
                            onVerticesUpdate={onVerticesUpdate}
                        />
                    ))}
                {isShapeBuilderOn && (
                    <ShapeBuilder
                        className={styles.shapeBuilder}
                        environmentState={{
                            scaleValue:
                                environmentState.scale *
                                geometryState.scaleValue,
                            position: {
                                x:
                                    environmentState.offset.x +
                                    geometryState.position.x *
                                        environmentState.scale,
                                y:
                                    environmentState.offset.y +
                                    geometryState.position.y *
                                        environmentState.scale,
                            },
                            rotation: 0,
                        }}
                        onReady={(vertices) => {
                            onFinishedDrawing &&
                                onFinishedDrawing(vertices, descriptor.id);
                        }}
                    />
                )}
            </Geometry>
        </div>
    );
};

export default CanvasElement;
