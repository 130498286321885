import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { ObjectWithNameAndId } from '../../common/types';
import styles from './RadioButtons.module.scss';

type Props = {
    className?: string;
    radioTitle?: string;
    radioOptions: ObjectWithNameAndId[];
    radioValue: string | undefined;
    inputVisible?: boolean;
    activeColorClass?: string;
    disabled?: boolean;
    onChange: (id: string) => void;
    onClick?: (id: string) => void;
};

const RadioButtons: FunctionComponent<Props> = ({
    className,
    radioTitle,
    radioOptions,
    radioValue /*  = radioOptions[0].id */,
    inputVisible = false,
    activeColorClass,
    disabled,
    onChange,
    onClick,
}) => {
    return (
        <div className={classNames(classNames(styles.radioButtons, className))}>
            {radioTitle && <div className={styles.header}>{radioTitle}</div>}
            <form
                className={classNames(styles.radioForm, activeColorClass, {})}
            >
                {radioOptions.map((radio) => {
                    return (
                        <label
                            key={radioValue + radio?.id}
                            htmlFor={radio?.id}
                            className={classNames('label', styles.title, {
                                checked: radio.id === radioValue,
                                [styles.titleChecked]: radio.id === radioValue,
                                [styles.noRadios]: !inputVisible,
                            })}
                        >
                            <input
                                type="radio"
                                value={radio?.id}
                                id={radio?.id}
                                name={radioTitle}
                                disabled={disabled}
                                className={classNames(styles.radios, {
                                    isVisible: inputVisible,
                                })}
                                onChange={() => onChange(radio.id)}
                                onClick={() => onClick && onClick(radio.id)}
                                checked={radio.id === radioValue}
                            />
                            {radio?.name}
                        </label>
                    );
                })}
            </form>
        </div>
    );
};

export default RadioButtons;
