import classNames from 'classnames';
import React, {
    ChangeEvent,
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Form, List, Select } from 'semantic-ui-react';
import { QUOTE_STATUS_DRAFT, QUOTE_STATUS_READY } from '../../common/constants';
import useElectricalMaterials from '../../common/hooks/useElectricalMaterials';
import useEmergencyPower from '../../common/hooks/useEmergencyPower';
import useHandleSave from '../../common/hooks/useHandleSave';
import { useUi } from '../../common/hooks/useUi';
import useUserPermissions from '../../common/hooks/useUserPermissions';
import {
    BasicQuoteProduct,
    DesignerQuoteState,
    Product,
    ProductsState,
    ProductTypes,
    RootState,
    SalesforceProductsState,
} from '../../common/types';
import {
    addOrUpdateProductInArray,
    dispatchAndSaveToLocalStorage,
    setDefaultQuoteName,
    setQuoteStatus,
    updateQuoteName,
} from '../../features/designerQuote/designerQuoteActions';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Collapsable from '../Collapsable/Collapsable';
import EnergyConsumersForm from '../EnergyConsumersForm';
import FormInput from '../FormInput';
import Icon from '../Icon';
import Input from '../Input';
import ProductOverview from '../ProductOverview';
import ROIForm from '../ROIForm';
import styles from './ProductsWizard.module.scss';
import { useAppDispatch } from '../../common/hooks';
import useElectricalCabinetUpgrades from '../../common/hooks/useElectricalCabinetUpgrades';
import Scaffolding from './Scaffolding';
import ElectricalCabinets from './ElectricalCabinets';
import MountingSystem from './MountingSystem';

type Props = {
    open?: boolean;
};

const ProductsWizard: FunctionComponent<Props> = ({ open }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [{ isUserSelectedQuoteName }, updateUi] = useUi();
    const permissions = useUserPermissions();
    const products = useSelector<
        RootState,
        SalesforceProductsState | undefined
    >((state) => state.designerQuote.salesforce.Products);

    const { addEmergencyPower, emergencyPowerPossible, toggleEmergencyPower } =
        useEmergencyPower(products);

    const availableProducts = useSelector<RootState, ProductsState>(
        (state) => state.products
    );

    const quote = useSelector<RootState, DesignerQuoteState>(
        (state) => state.designerQuote
    );
    const recordType = quote.salesforce.recordType;
    const upgrades = useSelector<RootState, Product[] | undefined>(
        (state) => state.products.electricalCabinetUpgrades
    );

    const quoteIsReady =
        quote.salesforce.status === 'Ready To Present' || false;

    const [asDraft, setAsDraft] = useState(false);
    const electricalLabor = useSelector<RootState, Product[] | undefined>(
        (state) => state.products.electricalLabor
    );

    const { t } = useTranslation(['productsWizard', 'common']);

    const saveOptions = [
        asDraft
            ? {
                  key: 'save',
                  value: false,
                  text: t('save'),
              }
            : {
                  key: 'draft',
                  value: true,
                  text: t('Draft'),
              },
    ];

    const dispatch = useAppDispatch();
    useElectricalCabinetUpgrades();
    const handleSaveFinished = () => setIsSaving(false);
    const handleSaveStarted = () => setIsSaving(true);

    const handleClickSave = useHandleSave(
        handleSaveFinished,
        handleSaveStarted
    );

    const handleInputChange = (event: ChangeEvent, data: any) => {
        if (data) {
            dispatch(
                dispatchAndSaveToLocalStorage(
                    updateQuoteName({ quoteName: data.value })
                )
            );

            updateUi({ isUserSelectedQuoteName: true });
        }
    };

    const handleInputBlur = () => {
        if (quote.salesforce.name?.trim() === '') {
            updateUi({ isUserSelectedQuoteName: false });
            dispatch(setDefaultQuoteName({}));
        }
    };

    const handleSaveDraft = (value: any) => {
        const status = value ? QUOTE_STATUS_DRAFT : QUOTE_STATUS_READY;
        setAsDraft(value);
        dispatch(setQuoteStatus({ status: status }));
    };

    useEffect(() => {
        !isUserSelectedQuoteName &&
            dispatch<any>(
                dispatchAndSaveToLocalStorage(setDefaultQuoteName({}))
            );
    }, [
        products?.battery,
        products?.inverter,
        products?.wallboxes,
        products?.panels,
    ]);

    const handleProductArrayChange = (
        productType: ProductTypes,
        product: Product,
        data: any
    ) => {
        let quantity = parseInt(data.value);

        if (Number.isNaN(quantity)) quantity = 0;

        dispatch(
            addOrUpdateProductInArray({
                productType,
                product,
                quantity,
            })
        );
    };

    const productsToArrayOfInputs = (productType: ProductTypes) => {
        return (
            Array.isArray(availableProducts[productType]) &&
            availableProducts[productType]!.map((product: Product) => {
                const quoteProducts = products![productType];

                let indexOfExistingQuoteProduct = -1;
                let defaultQuantity = 0;

                if (Array.isArray(quoteProducts)) {
                    indexOfExistingQuoteProduct = quoteProducts.findIndex(
                        (existingProduct) => existingProduct.id === product.id
                    );

                    if (
                        indexOfExistingQuoteProduct !== -1 &&
                        indexOfExistingQuoteProduct !== undefined
                    ) {
                        const existingProduct = quoteProducts[
                            indexOfExistingQuoteProduct
                        ] as BasicQuoteProduct;
                        defaultQuantity = existingProduct.quantity!;
                    }
                }

                const label = product.unit
                    ? {
                          type: 'number',
                          basic: true,
                          content: <span className="unit">{product.unit}</span>,
                      }
                    : false;

                return (
                    <div key={product.id}>
                        <span>
                            <FormInput
                                onChange={(ev, value) => {
                                    handleProductArrayChange(
                                        productType,
                                        product,
                                        value
                                    );
                                }}
                                formInput={{
                                    label,
                                    placeholder: product.name,
                                    value: defaultQuantity,
                                }}
                                id={product.id}
                            />
                        </span>
                        <br />
                    </div>
                );
            })
        );
    };

    useElectricalMaterials();

    return (
        <div
            className={classNames(styles.root, {
                [styles.saving]: isSaving,
            })}
        >
            <div className={styles.quoteName}>
                <div className={styles.iconCol}>
                    <Icon className={styles.icon} id="edit" />
                </div>
                <Input
                    className={styles.input}
                    id="new-quote-name"
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    placeholder={t('please enter quote name') as string}
                    value={quote.salesforce.name}
                    maxLength={80}
                    size="big"
                />
            </div>

            {products && availableProducts && (
                <List className={styles.productList}>
                    <List.Item>
                        <ProductOverview
                            productType={ProductTypes.PANEL}
                            productTitle={t('solarpanels') as string}
                            product={products.panels}
                            isOpen={true}
                        />
                    </List.Item>
                    <List.Item>
                        <ProductOverview
                            productType={ProductTypes.INVERTER}
                            productTitle={t('inverter') as string}
                            product={products?.inverter || []}
                            isOpen={true}
                            children={
                                emergencyPowerPossible && (
                                    <Checkbox
                                        label={t('emergencyPower')}
                                        onChange={toggleEmergencyPower}
                                        checked={addEmergencyPower}
                                    />
                                )
                            }
                        />
                    </List.Item>
                    <List.Item>
                        <ProductOverview
                            productType={ProductTypes.SECOND_INVERTER}
                            productOptionsType={ProductTypes.INVERTER}
                            productTitle={t('secondInverter') as string}
                            product={products?.secondInverter || []}
                            secondInstance={true}
                            isOpen={true}
                            hasEmptyOption={true}
                            isDisabled={!products?.inverter}
                        />
                    </List.Item>
                    {availableProducts.electricalMaterial &&
                        availableProducts.electricalMaterial.length > 0 && (
                            <List.Item>
                                <ProductOverview
                                    productType={
                                        ProductTypes.ELECTRICAL_MATERIAL
                                    }
                                    productTitle={
                                        t('electricalMaterial') as string
                                    }
                                    product={products?.electricalMaterial || []}
                                />
                            </List.Item>
                        )}
                    {availableProducts.battery &&
                        availableProducts.battery.length > 0 && (
                            <List.Item>
                                <ProductOverview
                                    productType={ProductTypes.BATTERY}
                                    productTitle={t('battery') as string}
                                    product={products?.battery || []}
                                    hasEmptyOption={true}
                                    isOpen={true}
                                />
                            </List.Item>
                        )}
                    {availableProducts.wallboxes &&
                        availableProducts.wallboxes.length > 0 && (
                            <List.Item>
                                <ProductOverview
                                    productType={ProductTypes.WALLBOXES}
                                    productTitle={t('wallbox') as string}
                                    product={products?.wallboxes || []}
                                    hasEmptyOption={true}
                                />
                            </List.Item>
                        )}
                    <ElectricalCabinets
                        availableProducts={availableProducts}
                        products={products}
                        quote={quote}
                        electricalLabor={electricalLabor}
                        upgrades={upgrades}
                    />
                    <MountingSystem
                        availableProducts={availableProducts}
                        products={products}
                        open={open}
                    />
                    {permissions.canManipulateScaffolding && (
                        <Scaffolding
                            availableProducts={availableProducts}
                            products={products}
                            quote={quote}
                        />
                    )}
                    {permissions.canManipulateAdditionalLabor &&
                        availableProducts.additionalLabor &&
                        availableProducts.additionalLabor.length > 0 && (
                            <List.Item>
                                <ProductOverview
                                    productType={ProductTypes.ADDITIONAL_LABOR}
                                    productTitle={
                                        t('additionalLabor') as string
                                    }
                                    product={[]}
                                >
                                    <Form>
                                        {productsToArrayOfInputs(
                                            ProductTypes.ADDITIONAL_LABOR
                                        )}
                                    </Form>
                                </ProductOverview>
                            </List.Item>
                        )}
                    <List.Item>
                        <Collapsable
                            title={t('consumption data') as string}
                            open={recordType && recordType.name === 'rent'}
                        >
                            <ROIForm />
                        </Collapsable>
                    </List.Item>
                    <List.Item>
                        <Collapsable
                            title={t('energy consumers') as string}
                            open={false}
                        >
                            <EnergyConsumersForm />
                        </Collapsable>
                    </List.Item>
                </List>
            )}
            <div className={styles.buttonGroup}>
                <Button
                    onClick={() => handleClickSave()}
                    icon={'save'}
                    iconSize={'small'}
                >
                    {asDraft ? t('common:Draft') : t('common:save')}
                </Button>
                {!quoteIsReady && (
                    <Select
                        options={saveOptions}
                        className={styles.draftDropdown}
                        value={asDraft}
                        onChange={(e, data) => handleSaveDraft(data.value)}
                    />
                )}
            </div>
        </div>
    );
};

export default ProductsWizard;
