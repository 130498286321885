import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent, useState } from 'react';
import Icon from '../Icon';
import styles from './SideBar.module.scss';

type Props = {
    style?: CSSProperties;
    stackStyle?: CSSProperties;
    stackGroupType?: string;
    hidden?: boolean;
    defaultHidden?: boolean;
    placement?: 'left' | 'right';
    full?: boolean;
    className?: string;
    onChange?: (open: boolean) => void;
    onFocus?: (open: boolean) => void;
    openInstantly?: boolean;
    children?: React.ReactNode;
};

const SideBar: FunctionComponent<Props> = ({
    children,
    style,
    placement = 'left',
    full = false,
    hidden,
    defaultHidden,
    className,
    onChange,
    onFocus,
    openInstantly = false,
}) => {
    const [internalHidden, setInternalHidden] = useState(defaultHidden);
    const resultingHidden = hidden !== undefined ? hidden : internalHidden;
    const positionStyle: CSSProperties = resultingHidden
        ? {
              transform: `translateX(${placement === 'left' ? '-' : ''}100%)`,
          }
        : {};

    const handleBarClick = () => {
        onChange && onChange(internalHidden!);
        setInternalHidden(!internalHidden);
    };

    const handleFocus = () => {
        onFocus && onFocus(true);
        setInternalHidden(false);
    };

    return (
        <div
            className={classNames(className, styles.root, {
                [styles.right]: placement === 'right',
                [styles.left]: placement === 'left',
                [styles.open]: !resultingHidden,
                [styles.openInstantly]: openInstantly,
                [styles.full]: full,
            })}
            style={{ ...style, ...positionStyle }}
            onFocus={handleFocus}
        >
            {children}
            {hidden === undefined && (
                <div
                    className={classNames(styles.opener, {
                        [styles.open]: !resultingHidden,
                    })}
                    onClick={handleBarClick}
                    style={{ [placement]: '100%' }}
                >
                    <Icon
                        id="open-close"
                        stroke={8}
                        className={classNames('inSideBar', styles.icon, {
                            [styles.opened]: !resultingHidden,
                        })}
                    />
                </div>
            )}
        </div>
    );
};

export default SideBar;
