import classNames from 'classnames';
import {
    CSSProperties,
    default as React,
    FunctionComponent,
    useEffect,
} from 'react';
import { stringKeyColors } from '../../../common/constants';
import { mmToPixels } from '../../../common/functions';
import { useUi } from '../../../common/hooks/useUi';
import { azimuthOptions } from '../../../common/selectOptions';
import {
    ModulePlanningData,
    PanelPlanningData,
    Product,
    StringsUpdateHandler,
} from '../../../common/types';
import Header from '../../Header';
import styles from './StringModuleElement.module.scss';

type Props = {
    className?: string;
    planningData: ModulePlanningData;
    activeStringIndex?: number;
    panelProduct: Product;
    canvasId: string;
    canvasIndex: number;
    surfaceId: string;
    surfaceIndex: number;
    onStringsUpdate: StringsUpdateHandler;
    isRenderingScreenshot?: boolean;
    order?: number;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onReady?: Function;
    panelHasOptimizer?: boolean;
    children?: React.ReactNode;
};

const surfaceStyle: CSSProperties = {
    backgroundColor: '#ddd',
    borderRadius: '1em',
    boxShadow: '0 0 0 1rem #ddd',
    width: '100%',
    height: '100%',
};

const StringModuleElement: FunctionComponent<Props> = ({
    planningData,
    activeStringIndex,
    panelProduct,
    canvasId,
    surfaceId,
    canvasIndex,
    surfaceIndex,
    children,
    onStringsUpdate,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onReady = () => {},
    order,
    panelHasOptimizer = false,
}) => {
    const { width = 9000, height = 4500, azimuth } = planningData;

    const [{ isStringToolActive, stringKey, isRenderingScreenshot }] = useUi();

    useEffect(() => {
        onReady && onReady();
    }, []);

    const state = {
        modifiedData: { ...planningData },
        selection: {
            x: 0,
            y: 0,
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            width: 0,
            height: 0,
            mouseGrabPoint: { x: 0, y: 0 },
            indices: [],
            positions: [],
        },
        conflictingPanels: [],
        isDragging: undefined,
        panelProduct,
    };

    const productWidth = mmToPixels(panelProduct.width || 0);
    const productHeight = mmToPixels(panelProduct.length || 0);
    const directionText = azimuthOptions
        .map((el) => {
            return el.value === azimuth ? el.text : undefined;
        })
        .filter((x) => x !== undefined)[0];
    const panelColor = panelHasOptimizer ? '#030' : '#000';
    const panelsMap = { x1: [0], y1: [0], x2: [width], y2: [height] };

    state.modifiedData.panels.forEach((panel) => {
        const panelDimensions = {
            x1: panel.x,
            y1: panel.y,
            x2: panel.isHorizontal
                ? panelProduct.length + panel.x
                : panelProduct.width + panel.x,
            y2: panel.isHorizontal
                ? panelProduct.width + panel.y
                : panelProduct.length + panel.y,
        };

        if (panelDimensions.x1 < 0) panelsMap.x1.push(panelDimensions.x1);
        if (panelDimensions.y1 < 0) panelsMap.y1.push(panelDimensions.y1);
        if (panelDimensions.x2 > width) panelsMap.x2.push(panelDimensions.x2);
        if (panelDimensions.y2 > height) panelsMap.y2.push(panelDimensions.y2);
    });
    const newSurfaceDims = {
        width: [Math.min(...panelsMap.x1), Math.max(...panelsMap.x2)],
        height: [Math.min(...panelsMap.y1), Math.max(...panelsMap.y2)],
    };

    const renderPanel = (panel: PanelPlanningData, i: number) => {
        const pid = `c${canvasId}-s${surfaceId}-p${i}`;
        const disabled = !!(
            (panel.stringPosition &&
                panel.stringPosition.key &&
                panel.stringPosition.key !== stringKey.toString()) ||
            (panel.stringPosition &&
                panel.stringPosition.index &&
                panel.stringPosition.index !== activeStringIndex) ||
            (panel.stringPosition &&
                panel.stringPosition.index === 0 &&
                activeStringIndex &&
                activeStringIndex > 0)
        );
        const panelActive = !!(
            isStringToolActive &&
            stringKey !== 'none' &&
            !disabled
        );

        return panel.x !== undefined &&
            panel.y !== undefined &&
            !panel.isHidden ? (
            <rect
                id={pid}
                key={pid}
                xlinkHref="#icon-panel"
                x={mmToPixels(panel.x)}
                y={mmToPixels(panel.y)}
                rx={10}
                ry={10}
                width={panel.isHorizontal ? productHeight : productWidth}
                height={panel.isHorizontal ? productWidth : productHeight}
                fill={panelColor}
                stroke="white"
                strokeWidth={1}
                onMouseDown={
                    panelActive
                        ? (e) =>
                              onStringsUpdate &&
                              onStringsUpdate(
                                  e,
                                  i,
                                  canvasId,
                                  surfaceId,
                                  canvasIndex,
                                  surfaceIndex
                              )
                        : undefined
                }
                className={classNames({
                    [styles.isActive]: panelActive,
                    [styles.isLast]:
                        panel.stringPosition &&
                        panel.stringPosition.index === activeStringIndex,
                })}
                data-index={i}
            />
        ) : undefined;
    };

    const transitionOffset = 20;
    const labelFontSize =
        mmToPixels(width) + transitionOffset * 2 > 1000
            ? mmToPixels(width) + transitionOffset * 2 > 2000
                ? '3em'
                : '2em'
            : '1em';
    const labelSize = {
        '--label-size': labelFontSize,
    } as CSSProperties;
    const strokeWidth = {
        '--stroke-width': 6 + parseInt(labelFontSize),
    } as CSSProperties;

    return (
        <div
            key={`c-${canvasId}`}
            id={`c-${(canvasId + surfaceId).split('-')[4]}`}
            className={styles.module}
            style={{ order: order }}
        >
            <svg
                key={`s-${surfaceId}`}
                id={`s-${surfaceId}`}
                className="stringModule"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="100%"
                height="100%"
                viewBox={`${
                    mmToPixels(newSurfaceDims.width[0]) - transitionOffset
                } ${
                    mmToPixels(newSurfaceDims.height[0]) - transitionOffset
                } ${Math.round(
                    mmToPixels(
                        -newSurfaceDims.width[0] + newSurfaceDims.width[1]
                    ) +
                        transitionOffset * 2
                )} ${Math.round(
                    mmToPixels(
                        -newSurfaceDims.height[0] + newSurfaceDims.height[1]
                    ) +
                        transitionOffset * 2
                )}`}
                strokeWidth="var(--stroke-width, 5)"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ ...surfaceStyle, ...labelSize, ...strokeWidth }}
            >
                <style>
                    {`
                        .label-container {
                            font-size: clamp(1ex, 2rem, var(--label-size, 1em));
                        }
                        
                        .label-id {
                            font-size: 2.2em;
                        }
                        
                        .label-string {
                            font-size: 2em;
                            opacity: .8;
                        }
                    `}
                </style>
                <defs>
                    <marker
                        id="marker-a"
                        orient="auto"
                        viewBox="0 0 25 50"
                        refX="0"
                        refY="25"
                        markerWidth="4"
                        markerHeight="4"
                    >
                        <polygon
                            points="0,0 25,25 0,50 "
                            fill={stringKeyColors.a}
                            strokeWidth={'var(--stroke-width, 5)'}
                        />
                    </marker>
                    <marker
                        id="marker-b"
                        orient="auto"
                        viewBox="0 0 13 50"
                        refX="0"
                        refY="25"
                        markerWidth="4"
                        markerHeight="4"
                    >
                        <polygon
                            points="0,0 25,25 0,50 "
                            fill={stringKeyColors.b}
                            strokeWidth={'var(--stroke-width, 5)'}
                        />
                    </marker>
                    <marker
                        id="marker-c"
                        orient="auto"
                        viewBox="0 0 13 50"
                        refX="0"
                        refY="25"
                        markerWidth="4"
                        markerHeight="4"
                    >
                        <polygon
                            points="0,0 25,25 0,50 "
                            fill={stringKeyColors.c}
                            strokeWidth={'var(--stroke-width, 5)'}
                        />
                    </marker>
                    <marker
                        id="marker-d"
                        orient="auto"
                        viewBox="0 0 13 50"
                        refX="0"
                        refY="25"
                        markerWidth="4"
                        markerHeight="4"
                    >
                        <polygon
                            points="0,0 25,25 0,50 "
                            fill={stringKeyColors.d}
                            strokeWidth={'var(--stroke-width, 5)'}
                        />
                    </marker>
                    <marker
                        id="marker-e"
                        orient="auto"
                        viewBox="0 0 13 50"
                        refX="0"
                        refY="25"
                        markerWidth="4"
                        markerHeight="4"
                    >
                        <polygon
                            points="0,0 25,25 0,50 "
                            fill={stringKeyColors.e}
                            strokeWidth={'var(--stroke-width, 5)'}
                        />
                    </marker>
                    <marker
                        id="marker-minus"
                        viewBox="0 0 50 50"
                        refX="25"
                        refY="-50"
                        markerWidth="4"
                        markerHeight="4"
                        opacity={0.8}
                    >
                        <line
                            fill="none"
                            stroke="#aaa"
                            strokeWidth={10}
                            x1={10}
                            x2={40}
                            y1={25}
                            y2={25}
                        />
                    </marker>
                    <marker
                        id="marker-plus"
                        viewBox="0 0 50 50"
                        refX="25"
                        refY="-50"
                        markerWidth="4"
                        markerHeight="4"
                        opacity={0.8}
                    >
                        <line
                            fill="none"
                            stroke="#aaa"
                            strokeWidth={10}
                            x1={10}
                            x2={40}
                            y1={25}
                            y2={25}
                        />
                        <line
                            fill="none"
                            stroke="#aaa"
                            strokeWidth={10}
                            y1={10}
                            y2={40}
                            x1={25}
                            x2={25}
                        />{' '}
                    </marker>
                </defs>
                <g className={'panels'} key={'g' + surfaceId}>
                    {state.modifiedData.panels.map(renderPanel)}
                </g>
                {children}
            </svg>
            <div
                key={surfaceIndex + canvasIndex}
                className={classNames('surfaceLabel', styles.surfaceText)}
                style={{
                    display: isRenderingScreenshot ? 'none' : 'block',
                }}
            >
                <Header
                    className={styles.header}
                    key={'h' + surfaceIndex + canvasIndex}
                >
                    <div
                        className={styles.title}
                        key={'d' + surfaceIndex + canvasIndex}
                    >
                        {'surface ' + (surfaceIndex + 1)}
                    </div>
                    <div
                        className={styles.arrow}
                        key={'dir' + surfaceIndex + canvasIndex}
                    >
                        {directionText}
                    </div>
                </Header>
            </div>
        </div>
    );
};

export default StringModuleElement;
