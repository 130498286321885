import React, { FunctionComponent, useEffect, useState } from 'react';
import { PlanningData } from '../../common/types';
import styles from './ProductsWizard.module.scss';
import Checkbox from '../Checkbox';
import InputNumber from '../InputNumber';
import {
    dispatchAndSaveToLocalStorage,
    setValidMountingSystems,
} from '../../features/designerQuote/designerQuoteActions';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../common/hooks';
import useDesignerQuote from '../../common/hooks/useDesignerQuote';
import {
    getPyramidMountingSystemFromQuote,
    getSurfacesOfPlanning,
    quoteHasPyramidMountingSystems,
} from '../../common/functions';
import { getOrientationCount } from '../../common/helpers';

type Props = {
    planning: PlanningData | undefined;
    open: boolean | undefined;
};

const PyramidMountingSystems: FunctionComponent<Props> = ({
    planning,
    open,
}) => {
    const { t } = useTranslation(['productsWizard', 'common']);
    const dispatch = useAppDispatch();
    const quote = useDesignerQuote();
    const [checked, setChecked] = useState(
        quoteHasPyramidMountingSystems(quote)
    );
    const horizontalCount = planning
        ? getOrientationCount(getSurfacesOfPlanning(planning)).Horizontal
        : 0;
    const [pyramidAmount, setPyramidAmount] = useState(
        getPyramidMountingSystemFromQuote(quote)?.quantity ?? horizontalCount
    );

    useEffect(() => {
        if (pyramidAmount > horizontalCount) {
            setPyramidAmount(horizontalCount);
        }
    }, [horizontalCount]);

    useEffect(() => {
        open &&
            dispatch(
                dispatchAndSaveToLocalStorage(
                    setValidMountingSystems({
                        pyramidPanelsAmount: checked ? pyramidAmount : 0,
                    })
                )
            );
    }, [open, planning, checked, pyramidAmount]);

    return (
        <ul className={styles.pyramid}>
            <li key={1}>
                <Checkbox
                    label={t('pyramidArrangement') as string}
                    onChange={(event, props) => {
                        setChecked(!!props.checked);
                    }}
                    checked={checked}
                />
            </li>
            {checked && (
                <li key={2}>
                    <span className={styles.title}>
                        {t('panelAmount') as string}
                    </span>
                    <InputNumber
                        className={styles.inputNumber}
                        id={'panelAmount'}
                        value={pyramidAmount}
                        max={horizontalCount}
                        onChange={(number) => {
                            setPyramidAmount(number);
                        }}
                    />
                </li>
            )}
        </ul>
    );
};

export default PyramidMountingSystems;
