import { Facing, SideDirections } from '../../common/types';

const getOppositeSide = (side: string) => {
    switch (side) {
        case 'left':
            return 'right';
        case 'right':
            return 'left';
        case 'top':
            return 'bottom';
        case 'bottom':
            return 'top';
        default:
            return side;
    }
};

export const getOppositeFacing = (
    currentFacing: Facing,
    surfaceSideDirections: SideDirections
): Facing => {
    const oppositeSide = getOppositeSide(currentFacing.side);
    return {
        ...currentFacing,
        side: oppositeSide,
        direction: surfaceSideDirections[oppositeSide as keyof SideDirections],
    };
};
