import { useEffect, useRef } from 'react';
import { BasicQuoteProduct, Product, ProductTypes, RootState } from '../types';
import { useSelector } from 'react-redux';
import {
    addOrUpdateProductInArray,
    dispatchAndSaveToLocalStorage,
    toggleUpgrade,
} from '../../features/designerQuote/designerQuoteActions';
import useDesignerQuote from './useDesignerQuote';
import { closeModal, openModal } from '../../features/modals/modalActions';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from './index';
import { newNotification } from '../../features/notifications/notificationsActions';

export default function useElectricalCabinetUpgrades() {
    const allUpgrades = useSelector<RootState, Product[] | undefined>(
        (state) => state.products.electricalCabinetUpgrades
    );
    const allWallboxes =
        useSelector<RootState, Product[] | undefined>(
            (state) => state.products.wallboxes
        ) || [];
    const allElectricalCabinets =
        useSelector<RootState, Product[] | undefined>(
            (state) => state.products.electricalCabinets
        ) || [];
    const allElectricalLabor =
        useSelector<RootState, Product[] | undefined>(
            (state) => state.products.electricalLabor
        ) || [];
    const quote = useDesignerQuote();
    const electricalCabinet = !quote.salesforce.Products?.electricalCabinets
        ? null
        : allElectricalCabinets.find((product) => {
              return (
                  product.id ===
                  (
                      quote.salesforce.Products
                          ?.electricalCabinets as BasicQuoteProduct
                  ).id
              );
          });
    const wallbox = !quote.salesforce.Products?.wallboxes
        ? null
        : allWallboxes.find((product) => {
              return product.id === quote.salesforce.Products?.wallboxes?.id;
          });
    const electricalLaborProductsWithPeerDependencies = (
        quote.salesforce.Products?.electricalLabor ?? []
    )
        .map((labor) => {
            return allElectricalLabor.find((product) => {
                return product.id === labor.id;
            });
        })
        .filter((labor) => {
            return !!labor && (labor.peerDependencies ?? []).length > 0;
        });
    const dispatch = useAppDispatch();
    const selectedUpgrades =
        quote.salesforce.Products?.electricalCabinetUpgrades || [];
    const getDependenciesSelectedAsElectricalCabinetUpgrades = (
        deps: Array<string>
    ): Array<string> => {
        return [...deps].filter((depId) => {
            return !selectedUpgrades.some(
                (upgrade) => (upgrade as Product).id === depId
            );
        });
    };
    const getElectricalCabinetUpgradeById = (id: string): Product | null => {
        return (
            allUpgrades?.find((upgrade) => {
                return upgrade.id === id;
            }) || null
        );
    };
    const getPeerDependenciesFromProducts = (
        products: Array<Product | undefined | null>
    ) => {
        const peerDeps: Set<string> = new Set();
        products.forEach((product) => {
            if (product && Array.isArray(product.peerDependencies)) {
                product.peerDependencies.forEach((dep) => peerDeps.add(dep.id));
            }
        });
        return [...peerDeps];
    };
    const possiblePeerDependencies = getPeerDependenciesFromProducts([
        ...allWallboxes,
        ...allElectricalCabinets,
        ...allElectricalLabor,
    ]);
    const isProductUsuallyAPeerDependency = (product: Product) => {
        return possiblePeerDependencies.some((peerId: string) => {
            return product.id === peerId;
        });
    };
    const { t } = useTranslation(['modals']);

    const previousCabinet = useRef(electricalCabinet);
    const previousWallbox = useRef(wallbox);
    const previousElectricalLaborProductCount = useRef(
        electricalLaborProductsWithPeerDependencies.length
    );

    useEffect(() => {
        const peerDeps = getPeerDependenciesFromProducts([
            wallbox,
            electricalCabinet,
            ...electricalLaborProductsWithPeerDependencies,
        ]);

        // add upgrade if found as a peer dependency for the selected wallbox or electrical cabinet or electrical labor
        if (
            (previousCabinet.current?.id !== electricalCabinet?.id &&
                !!electricalCabinet?.id) ||
            (previousWallbox.current?.id !== wallbox?.id && !!wallbox?.id) ||
            previousElectricalLaborProductCount.current <
                electricalLaborProductsWithPeerDependencies.length
        ) {
            getDependenciesSelectedAsElectricalCabinetUpgrades(
                peerDeps
            ).forEach((depId: string) => {
                const foundUpgrade = getElectricalCabinetUpgradeById(depId);
                if (foundUpgrade) {
                    dispatch(
                        dispatchAndSaveToLocalStorage(
                            addOrUpdateProductInArray({
                                productType:
                                    ProductTypes.ELECTRICAL_CABINET_UPGRADES,
                                product: foundUpgrade,
                                quantity: 1,
                            })
                        )
                    );
                    dispatch(
                        newNotification({
                            type: 'success',
                            message: '',
                            details: t('notifications:peerDependencyAdded', {
                                name: foundUpgrade.name,
                            }) as string,
                        })
                    );
                }
            });
        }
        previousCabinet.current = electricalCabinet;
        previousWallbox.current = wallbox;
        previousElectricalLaborProductCount.current =
            electricalLaborProductsWithPeerDependencies.length;

        // if an upgrade is selected and would usually be a peer dependency of a wallbox
        // or an electrical cabinet but is a peer dependency of neither the current wallbox
        // nor the current cabinet, throw a warning
        selectedUpgrades.forEach((upgrade) => {
            const isInCurrentListOfPeerDependencies = peerDeps.some(
                (peerDep) => peerDep === (upgrade as BasicQuoteProduct).id
            );

            if (
                isProductUsuallyAPeerDependency(upgrade) &&
                !isInCurrentListOfPeerDependencies
            ) {
                // ask if we should remove the peer dependency upgrade
                dispatch(
                    openModal({
                        modalProps: {
                            title: t('modals:peerDependencyFound.title'),
                            description: t(
                                'modals:peerDependencyFound.description',
                                {
                                    productName: (upgrade as Product).name,
                                }
                            ),
                            className: 'sales',
                            buttons: [
                                {
                                    text: t(
                                        'modals:peerDependencyFound.cancel'
                                    ) as string,
                                    type: 'negative',
                                    onClick: () => dispatch(closeModal()),
                                },
                                {
                                    text: t(
                                        'modals:peerDependencyFound.confirm'
                                    ) as string,
                                    type: 'positive',
                                    onClick: () => {
                                        dispatch(
                                            dispatchAndSaveToLocalStorage(
                                                toggleUpgrade({
                                                    product: upgrade as Product,
                                                })
                                            )
                                        );
                                        dispatch(closeModal());
                                    },
                                },
                            ],
                            onModalClose: () => dispatch(closeModal()),
                            size: 'large',
                        },
                    })
                );
            }
        });
    }, [
        electricalCabinet,
        wallbox,
        electricalLaborProductsWithPeerDependencies.length,
    ]);
}
