import { RatedInverterCamel } from '../responseTypes';
import {
    BasicQuoteProduct,
    MountingSystemOrientation,
    Product,
} from '../types';
import { isPyramidMountingSystem } from './products';

export const getValidMountingSystems = (
    mountingSystems: Product[],
    orientationCounts: { [value in MountingSystemOrientation]: number },
    color: string
) => {
    return getValidProductsArray(mountingSystems, {
        orientation: getValidOrientations(orientationCounts),
    }).filter(
        (product) =>
            product.orientation === MountingSystemOrientation.flat ||
            product.color === color
    );
};

export const selectMountingSystems = (
    availableMountingSystem: Array<
        Pick<Product, 'id' | 'name' | 'orientation' | 'color' | 'supplementary'>
    >,
    color: string,
    pyramidPanelsAmount: number,
    orientationCount: { [value in MountingSystemOrientation]: number }
) => {
    if (availableMountingSystem.length === 0) return [];

    const mountingSystems = getValidMountingSystems(
        availableMountingSystem as Product[],
        orientationCount,
        color
    );
    const getMountingSystem = (orientation: string, isPyramid: boolean) => {
        return mountingSystems.find((system) => {
            return (
                system.orientation === orientation &&
                isPyramid === isPyramidMountingSystem(system)
            );
        });
    };
    const mountingSystemsWithQuantity: BasicQuoteProduct[] = [];
    for (const [orientation, count] of Object.entries(orientationCount)) {
        if (count === 0) continue;
        if (
            orientation !== MountingSystemOrientation.horizontal ||
            pyramidPanelsAmount === 0
        ) {
            mountingSystemsWithQuantity.push({
                ...(getMountingSystem(orientation, false) as Product),
                quantity: count,
            });
        } else {
            if (count - pyramidPanelsAmount > 0) {
                mountingSystemsWithQuantity.push({
                    ...(getMountingSystem(orientation, false) as Product),
                    quantity: count - pyramidPanelsAmount,
                });
            }
            if (pyramidPanelsAmount > 0) {
                mountingSystemsWithQuantity.push({
                    ...(getMountingSystem(orientation, true) as Product),
                    quantity: pyramidPanelsAmount,
                });
            }
        }
    }
    return mountingSystemsWithQuantity as BasicQuoteProduct[];
};

export const getValidOrientations = (orientationCounts: {
    [value in MountingSystemOrientation]: number;
}): string[] => {
    const validAttributes: string[] = [];
    Object.keys(orientationCounts).forEach((key) => {
        if (orientationCounts[key as MountingSystemOrientation] > 0)
            validAttributes.push(key);
    });

    return validAttributes;
};

export const isProductValid = (
    product: Product,
    test: { [attribute: string]: string[] | number[] }
) => {
    let isValid = true;
    Object.keys(test).forEach((attribute: string) => {
        if (test[attribute].length > 0) {
            isValid =
                isValid &&
                test[attribute].some(
                    (element: string | number) =>
                        element === product[attribute as keyof Product]
                );
        } else isValid = false;
    });

    return isValid;
};

export const getValidProductsArray = (
    products: Product[],
    haystack: { [attribute: string]: string[] | number[] }
) => {
    const validProducts: Product[] = [];
    products.forEach((product: Product) => {
        if (isProductValid(product, haystack)) {
            validProducts.push(product);
        }
    });

    return validProducts;
};

export const getFirstRecommendedInverter = (
    recommendedInverters: RatedInverterCamel[],
    inverters: Product[]
): Product | undefined => {
    let recommendedInverter;
    if (recommendedInverters.length > 0 && inverters) {
        recommendedInverter = inverters.find(
            (inv: Product) => inv.id === recommendedInverters[0].id
        );

        return recommendedInverter;
    }

    return;
};
