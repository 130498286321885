import {
    applySmartLayoutToAllCanvases,
    autoArrangePanels,
    getPanelCount,
    getSideDirectionsByAzimuth,
    isOldTemplateFormat,
    oldTemplateFormatToNewStructure,
    updateROIValueInPlanning,
} from '../../common/functions';
import getStateWithModifiedPanels, {
    removeGapsOnStringsFromPlanning,
} from '../../common/functions/getStateWithModifiedPanels';
import {
    adjustPanelsFacing,
    smartLayout,
} from '../../common/functions/smartLayout';
import { getQuoteRecordTypeFromOpportunity } from '../../common/helpers';
import { azimuthOptions } from '../../common/selectOptions';
import {
    BasicQuoteProduct,
    Canvas,
    DesignerQuoteActionType,
    DesignerQuoteReducer,
    ElectricalCabinetUpgrade,
    ElectricalLabor,
    ModulePlanningData,
    OpportunityActionType,
    Panel,
    Product,
    ProductTypes,
    QuoteActionType,
    RoiObject,
} from '../../common/types';
import { getOppositeFacing } from '../../components/PanelElement/functions';
import { getStringIndexForPanel } from '../../components/StringPlanner/functions';
import { defaultDesignerQuoteState } from '../../store/defaults';

export const designerQuoteReducer: DesignerQuoteReducer = (
    state = defaultDesignerQuoteState,
    action
) => {
    switch (action.type) {
        case OpportunityActionType.SET_OPPORTUNITY: {
            const opportunity = action.payload.data;
            return {
                ...state,
                salesforce: {
                    ...state.salesforce,
                    recordType: getQuoteRecordTypeFromOpportunity(opportunity),
                    selfConsumptionKwh: opportunity.annualPowerConsumptionKwh,
                    energyConsumptionHouseholdKwh:
                        opportunity.annualPowerConsumptionKwh,
                    electricityCostKwh: opportunity.electricityCostKwh,
                    monthlyElectricityCosts:
                        opportunity.monthlyElectricityCosts,
                },
            };
        }
        case QuoteActionType.SET_QUOTE:
            return {
                ...action.payload.data,
                salesforce: {
                    ...action.payload.data.salesforce,
                    recordType:
                        state.salesforce.recordType ||
                        action.payload.data.salesforce.recordType,
                },
            };
        case QuoteActionType.SET_QUOTE_ID: {
            const quoteId = action.payload.quoteId;
            return {
                ...state,
                quoteId,
                salesforce: {
                    ...state.salesforce,
                    id: quoteId,
                },
            };
        }
        case DesignerQuoteActionType.UPDATE:
            return {
                ...state,
                ...action.payload.data,
            };
        case DesignerQuoteActionType.SET_IS_SAVED:
            return {
                ...state,
                quoteId: action.payload.quoteId,
                savedAt: action.payload.data.timeStamp,
            };

        case DesignerQuoteActionType.ADD_PHOTOS: {
            return {
                ...state,
                planning: {
                    ...state.planning,
                    canvases: {
                        ...state.planning.canvases,
                        ...action.payload.data.canvases,
                    },
                },
            };
        }

        case DesignerQuoteActionType.REMOVE_ALL_PANELS_FROM_REMOVED_STRINGS: {
            const currentStrings = action.payload.data.currentStrings;
            const canvases: { [name: string]: Canvas } = {};
            Object.keys(state.planning.canvases).forEach((canvasId) => {
                const canvas = state.planning.canvases[canvasId];
                canvases[canvasId] = { ...canvas };
                Object.keys(canvas.surfaces).forEach((surfaceId) => {
                    const surface = canvas.surfaces[surfaceId];
                    if (surface.panels.length > 0) {
                        surface.panels = [...surface.panels].map((panel) => {
                            if (
                                panel.stringPosition?.key &&
                                !currentStrings.includes(
                                    panel.stringPosition.key
                                )
                            ) {
                                const { stringPosition, ...rest } = panel;
                                return rest;
                            }
                            return panel;
                        });
                    }
                });
            });
            return {
                ...state,
                planning: {
                    canvases,
                },
            };
        }

        case DesignerQuoteActionType.REMOVE_PANEL_FROM_STRING: {
            const { panelIndex, canvasId, surfaceId } = action.payload.data;
            const newState = { ...state };
            const clickedPanel =
                newState.planning.canvases[canvasId].surfaces[surfaceId].panels[
                    panelIndex
                ];
            const { stringPosition, ...panel } = clickedPanel;
            newState.planning.canvases[canvasId].surfaces[surfaceId].panels[
                panelIndex
            ] = panel;
            return removeGapsOnStringsFromPlanning(newState);
        }

        case DesignerQuoteActionType.ADD_PANEL_TO_STRING: {
            const { panelIndex, canvasId, surfaceId, stringKey } =
                action.payload.data;
            const canvas = state.planning.canvases[canvasId];
            const surface = canvas.surfaces[surfaceId];
            const panels = [...surface.panels];
            if (!panels[panelIndex].stringPosition) {
                panels[panelIndex] = {
                    ...panels[panelIndex],
                    stringPosition: {
                        key: stringKey,
                        index: getStringIndexForPanel(
                            stringKey,
                            Object.values(state.planning.canvases)
                        ),
                    },
                };
            }
            return {
                ...state,
                planning: {
                    canvases: {
                        ...state.planning.canvases,
                        [canvasId]: {
                            ...canvas,
                            surfaces: {
                                ...canvas.surfaces,
                                [surfaceId]: {
                                    ...surface,
                                    panels: [...panels],
                                },
                            },
                        },
                    },
                },
            };
        }
        case DesignerQuoteActionType.UPDATE_MONTHLY_COSTS: {
            return {
                ...state,
                salesforce: {
                    ...state.salesforce,
                    monthlyElectricityCosts: parseFloat(
                        (
                            ((state.salesforce.selfConsumptionKwh || 0) *
                                (state.salesforce.electricityCostKwh || 0)) /
                            12
                        ).toFixed(2)
                    ),
                },
            };
        }
        case DesignerQuoteActionType.SET_PLANNING_DATA: {
            // TODO We need to change the response from both get Opp and Quote endpoints stop Arraying the planning data
            return {
                ...state,
                planning: isOldTemplateFormat(action.payload.data)
                    ? oldTemplateFormatToNewStructure(action.payload.data)
                    : action.payload.data[0],
            };
        }
        case DesignerQuoteActionType.SET_DEFAULT_PRODUCTS: {
            let defaultProducts = {};
            const productKeysForDefault = [ProductTypes.PANEL];
            const products = state.salesforce.Products;

            productKeysForDefault.forEach((type: ProductTypes) => {
                const productsPayload = action.payload.productsMap?.[type];
                if (
                    Array.isArray(productsPayload) &&
                    productsPayload.length > 0
                ) {
                    if (
                        typeof products === 'undefined' ||
                        !Object.prototype.hasOwnProperty.call(products, type)
                    ) {
                        defaultProducts = {
                            ...defaultProducts,
                            [type]: productsPayload[0],
                        };
                    }
                }
            });

            return {
                ...state,
                salesforce: {
                    ...state.salesforce,
                    Products: {
                        ...defaultProducts,
                        ...products,
                    },
                },
            };
        }
        case DesignerQuoteActionType.DELETE_CANVAS: {
            const {
                [action.payload.data.canvasId]: deletedCanvas,
                ...newPlanning
            } = state.planning.canvases;

            const newState = {
                ...state,
                planning: {
                    ...state.planning,
                    canvases: { ...newPlanning },
                },
            };

            return removeGapsOnStringsFromPlanning(newState);
        }
        case DesignerQuoteActionType.TOGGLE_UPGRADE: {
            const upgrades =
                state.salesforce.Products?.electricalCabinetUpgrades || [];
            const upgrade = action.payload.product as Product;
            let newUpgrades: ElectricalCabinetUpgrade[] = [];

            if (upgrade && upgrade.id) {
                newUpgrades =
                    upgrades.filter((up) => up && upgrade.id === up.id)
                        .length !== 0
                        ? (upgrades.filter(
                              (up) => up && up.id !== upgrade.id
                          ) as ElectricalCabinetUpgrade[])
                        : [
                              ...(upgrades as ElectricalCabinetUpgrade[]),
                              upgrade,
                          ];
            }
            return {
                ...state,
                salesforce: {
                    ...state.salesforce,
                    Products: {
                        ...state.salesforce.Products,
                        electricalCabinetUpgrades: newUpgrades,
                    },
                },
            };
        }
        case DesignerQuoteActionType.TOGGLE_ELECTRICAL_LABOR: {
            const labors = state.salesforce.Products?.electricalLabor || [];
            const labor = action.payload.product as Product;
            let newLabors: ElectricalLabor[] = [];

            if (labor && labor.id) {
                newLabors =
                    labors.filter((up) => up && labor.id === up.id).length !== 0
                        ? (labors.filter(
                              (up) => up && up.id !== labor.id
                          ) as ElectricalLabor[])
                        : [...(labors as ElectricalLabor[]), labor];
            }
            return {
                ...state,
                salesforce: {
                    ...state.salesforce,
                    Products: {
                        ...state.salesforce.Products,
                        electricalLabor: newLabors,
                    },
                },
            };
        }
        case DesignerQuoteActionType.ADD_OR_UPDATE_PRODUCT_IN_ARRAY: {
            const product = action.payload.product as Product;
            const productType = action.payload.productType;
            let products =
                (state.salesforce.Products![
                    productType!
                ] as BasicQuoteProduct[]) || ([] as BasicQuoteProduct[]);

            const quantity = action.payload.quantity;

            if (product && product.id) {
                if (quantity === 0) {
                    products = products.filter((p) => {
                        return p.id !== product.id;
                    });
                } else {
                    const productsIndex = products.findIndex(
                        (p) => p.id === product.id
                    );

                    if (productsIndex === -1) {
                        products.push({ ...product, quantity });
                    } else {
                        products[productsIndex] = {
                            ...product,
                            quantity,
                        };
                    }
                }
            }

            return {
                ...state,
                salesforce: {
                    ...state.salesforce,
                    Products: {
                        ...state.salesforce.Products,
                        [productType as string]: products,
                    },
                },
            };
        }
        case DesignerQuoteActionType.ADD_SURFACE: {
            const canvasToAdd =
                state.planning.canvases[action.payload.data.canvasId];
            const fulledSurfaces = {
                ...canvasToAdd.surfaces,
            };
            fulledSurfaces[action.payload.data.surface.id] =
                action.payload.data.surface;

            const fulledCanvases = { ...state.planning.canvases };
            fulledCanvases[action.payload.data.canvasId] = {
                ...canvasToAdd,
                surfaces: fulledSurfaces,
            };
            return {
                ...state,
                planning: {
                    ...state.planning,
                    canvases: fulledCanvases,
                },
            };
        }
        case DesignerQuoteActionType.UPDATE_MOUNTING_SYSTEM_ACCESSORY: {
            const product = action.payload.product as Product | null;
            const supplementary = action.payload.supplementary;
            const mountingSystemAccessories = [
                ...(state.salesforce.Products?.mountingSystemAccessories ?? []),
            ];
            const hasProduct = mountingSystemAccessories.some(
                (p) => p.supplementary === supplementary
            );
            if (product) {
                if (!hasProduct) {
                    mountingSystemAccessories.push(product);
                } else {
                    mountingSystemAccessories[
                        mountingSystemAccessories.findIndex(
                            (p) => p.supplementary === supplementary
                        )
                    ] = product;
                }
            } else if (hasProduct) {
                mountingSystemAccessories.splice(
                    mountingSystemAccessories.findIndex(
                        (p) => p.supplementary === supplementary
                    ),
                    1
                );
            }

            return {
                ...state,
                salesforce: {
                    ...state.salesforce,
                    Products: {
                        ...state.salesforce.Products,
                        mountingSystemAccessories,
                    },
                },
            };
        }
        case DesignerQuoteActionType.UPDATE_SURFACE: {
            const activeCanvasId = action.payload.canvasId;
            const canvasToUpdate = state.planning.canvases[activeCanvasId!];
            const surfaceToUpdate = action.payload.surfaceId
                ? canvasToUpdate.surfaces[action.payload.surfaceId]
                : Object.values(canvasToUpdate.surfaces)[0];

            const fulledSurfaces = { ...canvasToUpdate.surfaces };
            fulledSurfaces[surfaceToUpdate.id] = {
                ...surfaceToUpdate,
                ...action.payload.data,
            };

            const fulledCanvases = { ...state.planning.canvases };
            fulledCanvases[activeCanvasId!] = {
                ...canvasToUpdate,
                surfaces: fulledSurfaces,
            };
            return {
                ...state,
                planning: {
                    ...state.planning,
                    canvases: fulledCanvases,
                },
            };
        }
        case DesignerQuoteActionType.UPDATE_MULTIPLE_PRODUCTS: {
            const productsState = {
                ...state.salesforce.Products,
                ...action.payload.products,
            };

            return {
                ...state,
                salesforce: {
                    ...state.salesforce,
                    Products: productsState,
                },
            };
        }
        case DesignerQuoteActionType.UPDATE_SURFACE_ANNUAL_YIELD_HOURS: {
            const canvasId = action.payload.canvasId as string;
            const surfaceId = action.payload.surfaceId as string;
            const canvas = state.planning.canvases[canvasId];
            const surfaces = canvas.surfaces;
            const surface = surfaces[surfaceId];
            if (surface) {
                return {
                    ...state,
                    planning: {
                        ...state.planning,
                        canvases: {
                            ...state.planning.canvases,
                            [canvasId]: {
                                ...canvas,
                                surfaces: {
                                    ...surfaces,
                                    [surfaceId]: {
                                        ...surface,
                                        annualYieldHours:
                                            action.payload.annualYieldHours,
                                    },
                                },
                            },
                        },
                    },
                };
            }
            return { ...state };
        }

        case DesignerQuoteActionType.UPDATE_PRODUCT: {
            let newCanvases = state.planning.canvases;
            const product = action.payload.product as Product;

            if (action.payload.productType === ProductTypes.PANEL) {
                newCanvases = applySmartLayoutToAllCanvases(
                    newCanvases,
                    state.salesforce.Products?.panels || ({} as Panel),
                    product
                );
                product.quantity = getPanelCount(state.planning);
            }

            const productsState = {
                ...state.salesforce.Products,
                [action.payload.productType as string]: product,
            };

            return {
                ...state,
                salesforce: {
                    ...state.salesforce,
                    Products: productsState,
                },
                planning: {
                    ...state.planning,
                    canvases: {
                        ...state.planning.canvases,
                        ...newCanvases,
                    },
                },
            };
        }
        case DesignerQuoteActionType.DELETE_SURFACE: {
            const canvas =
                action.payload.canvasId &&
                state.planning.canvases[action.payload.canvasId];

            if (canvas) {
                const {
                    [action.payload.surfaceId!]: deletedSurface,
                    ...newSurfaces
                } = canvas.surfaces;
                const newPlanning = { ...state.planning };
                newPlanning.canvases = {
                    ...newPlanning.canvases,
                    [canvas.id]: {
                        ...canvas,
                        surfaces: newSurfaces,
                    },
                };
                const newState = {
                    ...state,
                    planning: newPlanning,
                };
                return removeGapsOnStringsFromPlanning(newState);
            }
            return removeGapsOnStringsFromPlanning(state);
        }

        case DesignerQuoteActionType.UPDATE_PANEL_AMOUNT: {
            return {
                ...state,
                salesforce: {
                    ...state.salesforce,
                    Products: {
                        ...state.salesforce.Products,
                        panels: {
                            ...state.salesforce.Products?.panels,
                            quantity: action.payload.data.amount,
                        },
                    },
                },
            };
        }

        case DesignerQuoteActionType.ADD_PANEL: {
            const activeCanvasId = action.payload.data.activeCanvasId;
            const activeSurfaceId = action.payload.data.activeSurfaceId;
            const activeCanvas = state.planning.canvases[activeCanvasId];
            const surfaceToModify = activeCanvas.surfaces[activeSurfaceId];
            const panelProduct = state.salesforce.Products?.panels || {};

            const modifiedSurface: ModulePlanningData = {
                ...surfaceToModify,
                panels: autoArrangePanels(
                    [...surfaceToModify.panels, {}],
                    panelProduct as Panel,
                    surfaceToModify
                ),
            };

            return {
                ...state,
                planning: {
                    ...state.planning,
                    canvases: {
                        ...state.planning.canvases,
                        [activeCanvasId]: {
                            ...activeCanvas,
                            surfaces: {
                                ...activeCanvas.surfaces,
                                [activeSurfaceId]: modifiedSurface,
                            },
                        },
                    },
                },
            };
        }
        case DesignerQuoteActionType.TOGGLE_SURFACE_GUTTER: {
            const activeCanvasId = action.payload.data.activeCanvasId;
            const activeSurfaceId = action.payload.data.activeSurfaceId;
            const canvases = state.planning.canvases;
            const canvas = state.planning.canvases[activeCanvasId];
            const surface =
                state.planning.canvases[activeCanvasId].surfaces[
                    activeSurfaceId
                ];
            const hasGutter = surface.surfaceGutter !== 0;

            return {
                ...state,
                planning: {
                    ...state.planning,
                    canvases: {
                        ...canvases,
                        [activeCanvasId]: {
                            ...canvas,
                            surfaces: {
                                ...canvas.surfaces,
                                [activeSurfaceId]: {
                                    ...surface,
                                    hasGutter,
                                },
                            },
                        },
                    },
                },
            };
        }
        case DesignerQuoteActionType.ROTATE_PANELS: {
            const activeCanvasId = action.payload.data.activeCanvasId;
            const activeSurfaceId = action.payload.data.activeSurfaceId;
            const activeCanvas = state.planning.canvases[activeCanvasId];
            const surfaceToModify = activeCanvas.surfaces[activeSurfaceId];
            const panelProduct = state.salesforce.Products?.panels;

            // Apply smart layout and rotate
            const smartAdjustedPanels = smartLayout(
                {
                    width: surfaceToModify.width,
                    height: surfaceToModify.height,
                },
                surfaceToModify.panels,
                panelProduct!,
                panelProduct!,
                surfaceToModify.roofType!,
                action.payload.data.selectedPanels
            );

            if (typeof surfaceToModify.sideDirections === 'undefined') {
                surfaceToModify.sideDirections = getSideDirectionsByAzimuth(
                    surfaceToModify.azimuth!,
                    azimuthOptions
                );
            }

            const smartAdjustedPanelsFacing = adjustPanelsFacing(
                {
                    ...surfaceToModify,
                    panels: smartAdjustedPanels,
                },
                panelProduct!
            );

            const modifiedSurface = {
                ...surfaceToModify,
                panels: smartAdjustedPanelsFacing,
            };

            const modifiedCanvas = {
                ...state.planning.canvases[activeCanvasId],
                surfaces: {
                    ...activeCanvas.surfaces,
                    [activeSurfaceId]: modifiedSurface,
                },
            };
            return {
                ...state,
                planning: {
                    ...state.planning,
                    canvases: {
                        ...state.planning.canvases,
                        [activeCanvasId]: modifiedCanvas,
                    },
                },
            };
        }
        case DesignerQuoteActionType.REMOVE_PANELS: {
            return removeGapsOnStringsFromPlanning(
                getStateWithModifiedPanels(
                    action.payload.data,
                    state,
                    (panels) =>
                        panels.filter(
                            (p, i) =>
                                !action.payload.data.selectedPanelIndices.includes(
                                    i
                                )
                        )
                )
            );
        }
        case DesignerQuoteActionType.TOGGLE_PANEL_FACING: {
            return getStateWithModifiedPanels(
                action.payload.data,
                state,
                (panels) =>
                    panels.map((p, i) =>
                        action.payload.data.selectedPanelIndices.includes(i)
                            ? {
                                  ...p,
                                  facing: {
                                      ...getOppositeFacing(
                                          p.facing!,
                                          action.payload.data
                                              .activeSurfaceSideDirections
                                      ),
                                      isset: true,
                                  },
                              }
                            : p
                    )
            );
        }
        case DesignerQuoteActionType.TOGGLE_PANELS_VISIBILITY: {
            return getStateWithModifiedPanels(
                action.payload.data,
                state,
                (panels) =>
                    panels.map((p, i) => {
                        return action.payload.data.selectedPanelIndices.includes(
                            i
                        )
                            ? {
                                  ...p,
                                  isHidden: !p.isHidden,
                              }
                            : p;
                    })
            );
        }
        case DesignerQuoteActionType.SET_PANELS: {
            return getStateWithModifiedPanels(
                action.payload.data,
                state,
                () => action.payload.data.panels
            );
        }
        case DesignerQuoteActionType.SET_VERTICES: {
            const { activeCanvasId, activeSurfaceId, vertices } =
                action.payload.data;
            const activeCanvas = state.planning.canvases[activeCanvasId];
            const surfaceToModify = activeCanvas.surfaces[activeSurfaceId];

            const modifiedSurface = {
                ...surfaceToModify,
                vertices,
            };

            return {
                ...state,
                planning: {
                    ...state.planning,
                    canvases: {
                        ...state.planning.canvases,
                        [activeCanvasId]: {
                            ...activeCanvas,
                            surfaces: {
                                ...activeCanvas.surfaces,
                                [activeSurfaceId]: modifiedSurface,
                            },
                        },
                    },
                },
            };
        }
        case DesignerQuoteActionType.UPDATE_ROI: {
            return {
                ...state,
                salesforce: {
                    ...state.salesforce,
                    ...action.payload.roiData,
                },
            };
        }
        case DesignerQuoteActionType.UPDATE_TOTAL_ANNUAL_CONSUMPTION: {
            const sum =
                (state.salesforce.energyConsumptionHouseholdKwh || 0) +
                (state.salesforce.energyConsumptionPoolKwh || 0) +
                (state.salesforce.energyConsumptionSaunaKwh || 0) +
                (state.salesforce.energyConsumptionHeatPumpKwh || 0) +
                (state.salesforce.energyConsumptionCarKwh || 0) +
                (state.salesforce.energyConsumptionBigConsumersKwh || 0);
            return {
                ...state,
                salesforce: {
                    ...state.salesforce,
                    selfConsumptionKwh: sum,
                },
            };
        }
        case DesignerQuoteActionType.UPDATE_ENERGY_CONSUMPTION: {
            return {
                ...state,
                salesforce: {
                    ...state.salesforce,
                    ...action.payload.consumptionData,
                },
            };
        }
        case DesignerQuoteActionType.SET_QUOTE_NAME: {
            return {
                ...state,
                salesforce: {
                    ...state.salesforce,
                    name: action.payload.quoteName,
                },
            };
        }
        case DesignerQuoteActionType.SET_QUOTE_STATUS: {
            return {
                ...state,
                salesforce: {
                    ...state.salesforce,
                    status: action.payload.status,
                },
            };
        }
        case DesignerQuoteActionType.SET_RECORD_TYPE: {
            return {
                ...state,
                salesforce: {
                    ...state.salesforce,
                    recordType: action.payload.recordType,
                },
            };
        }
        case QuoteActionType.RESET_QUOTE: {
            return defaultDesignerQuoteState;
        }

        case DesignerQuoteActionType.UPDATE_MACHINE_ROI_VALUE: {
            return {
                ...state,
                planning: {
                    ...state.planning,
                    roiValues: updateROIValueInPlanning(
                        'machine',
                        state.planning.roiValues,
                        action.payload.roiData as RoiObject
                    ),
                },
            };
        }

        case DesignerQuoteActionType.UPDATE_MANUAL_ROI_VALUE: {
            return {
                ...state,
                planning: {
                    ...state.planning,
                    roiValues: updateROIValueInPlanning(
                        'manual',
                        state.planning.roiValues,
                        action.payload.roiData as RoiObject
                    ),
                },
            };
        }

        case DesignerQuoteActionType.RESET_PRODUCT_SELECTION: {
            return {
                ...state,
                salesforce: {
                    ...state.salesforce,
                    Products: {},
                },
            };
        }

        case DesignerQuoteActionType.TOGGLE_SURFACE_RATIO: {
            return state;
        }

        case DesignerQuoteActionType.APPLY_SURFACE_RATIO: {
            const activeCanvasId = action.payload.canvasId;
            const canvasToUpdate = state.planning.canvases[activeCanvasId!];
            const surfaceToUpdate = action.payload.surfaceId
                ? canvasToUpdate.surfaces[action.payload.surfaceId]
                : Object.values(canvasToUpdate.surfaces)[0];

            const fulledSurfaces = { ...canvasToUpdate.surfaces };
            fulledSurfaces[surfaceToUpdate.id] = {
                ...surfaceToUpdate,
                ...action.payload.data,
            };

            const fulledCanvases = { ...state.planning.canvases };
            fulledCanvases[activeCanvasId!] = {
                ...canvasToUpdate,
                surfaces: fulledSurfaces,
            };

            return {
                ...state,
                planning: {
                    ...state.planning,
                    canvases: fulledCanvases,
                },
            };
        }

        default:
            return state;
    }
};
