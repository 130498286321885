import { HotkeyItem } from './types';
const SELECT_ITEM = [{ translate: 'selection' }, 'dashed'];

const Hotkeys = {
    DELETE_PANEL: {
        name: 'delete_panel',
        hook: 'backspace, Delete',
        display: [SELECT_ITEM, ['Backspace / Del']],
    },
    DELETE_SURFACE: {
        name: 'delete_surface',
        hook: 'alt+backspace, alt+Delete',
        display: [SELECT_ITEM, ['Alt'], ['Backspace / Del']],
    },
    HIDE_PANEL: {
        name: 'hide_panel',
        hook: 'alt+h',
        display: [SELECT_ITEM, ['Alt'], ['H']],
    },
    HIDE_SURFACES: {
        name: 'hide_surfaces',
        hook: 'h',
        display: [['H']],
    },
    ROTATE_PANELS: {
        name: 'rotate_panels',
        hook: 'alt+r',
        display: [SELECT_ITEM, ['Alt'], ['R']],
    },
    DRAW_ROTATED_PANELS: {
        name: 'draw_rotated_panels',
        hook: 'r',
        display: [['R']],
    },
    SELECT_ADJACENT_PANELS: {
        name: 'select_adjacent_panels',
        hook: 'alt',
        display: [['Alt'], [{ translate: 'select panel' }]],
    },
    TOGGLE_GRID: {
        name: 'toggle_grid',
        hook: 'alt+g',
        display: [['Alt'], ['G']],
    },
    TOGGLE_BUILD_TOOL: {
        name: 'toggle_build_tool',
        hook: 'alt+b',
        display: [['Alt'], ['B']],
    },
    TOGGLE_DRAG_TOOL: {
        name: 'toggle_drag_tool',
        hook: 'alt+d',
        display: [['Alt'], ['D']],
    },
    ADD_PANEL: {
        name: 'add_panel',
        hook: 'alt+p',
        display: [['Alt'], ['P']],
    },
    TOGGLE_PANEL_FACING: {
        name: 'toggle_panel_facing',
        hook: 's',
        display: [['S']],
    },
    MOVE_PANEL: {
        name: 'move_panel',
        hook: 'left, right, up, down, shift+left, shift+right, shift+up, shift+down',
        display: [[{ translate: 'arrow keys' }]],
    },
    UNDO: {
        name: 'undo',
        hook: 'command+z, control+z, alt+z',
        display: [['Alt'], ['Z']],
    },
    REDO: {
        name: 'redo',
        hook: 'command+shift+z, control+shift+z, alt+shift+z',
        display: [['Alt'], ['Shift'], ['Z']],
    },
    LOCK_ALL_SURFACES: {
        name: 'lock_all_surfaces',
        hook: 'alt+l',
        display: [['Alt'], ['L']],
    },
    SELECT_ALL_PANELS: {
        name: 'select_all_panels',
        hook: 'alt+a',
        display: [['Alt'], ['A']],
    },
    ZOOM_AND_PAN: {
        name: 'zoom_and_pan',
        hook: 'alt+0, alt+=, alt+-, alt+*, alt++, alt+left, alt+right, alt+up, alt+down',
    },
    HAND_TOOL: {
        name: 'hand_tool',
        hook: 'space',
        display: [['Space']],
    },
};

type HotkeysType = Record<keyof typeof Hotkeys, HotkeyItem>;

export default Hotkeys as HotkeysType;
