import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCloudQuote } from '../../features/cloudQuote/cloudQuoteActions';
import { DesignerQuoteState, RootState } from '../types';
import { useAppDispatch } from './index';

// Quote hook to get the Quote:
export default function useDesignerQuote(quoteId?: string): DesignerQuoteState {
    const dispatch = useAppDispatch();
    const quote = useSelector<RootState, DesignerQuoteState>(
        (state) => state.designerQuote
    );
    useEffect(() => {
        if (quoteId && (!quote || quote.quoteId !== quoteId)) {
            dispatch(getCloudQuote({ quoteId }));
        }
    }, [quote, quoteId]);

    return quote;
}
