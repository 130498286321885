import classNames from 'classnames';
import React, { FunctionComponent, useState } from 'react';
import { Checkbox as SemanticCheckbox, CheckboxProps } from 'semantic-ui-react';
import styles from './Checkbox.module.scss';

type Props = {
    id?: string;
    className?: string;
    label?: CheckboxProps['label'];
    onChange?: CheckboxProps['onChange'];
    defaultChecked?: boolean;
    checked?: boolean;
    disabled?: boolean;
};

const Checkbox: FunctionComponent<Props> = ({
    id,
    className,
    onChange,
    label,
    defaultChecked,
    checked,
    disabled,
}) => {
    const [uncontrolledChecked, setUncontrolledChecked] = useState(
        defaultChecked !== undefined ? defaultChecked : !!checked
    );
    const handleChange: CheckboxProps['onChange'] = (e, props) => {
        const newChecked = !!props.checked;
        setUncontrolledChecked(newChecked);
        onChange && onChange(e, props);
    };
    return (
        <SemanticCheckbox
            id={id}
            onChange={handleChange}
            label={label}
            disabled={disabled}
            className={classNames(className, styles.root, {
                [styles.checked]: uncontrolledChecked,
            })}
            checked={checked !== undefined ? checked : uncontrolledChecked}
        />
    );
};

export default Checkbox;
