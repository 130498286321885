import classNames from 'classnames';
import React, {
    CSSProperties,
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import { Point } from '../../../common/types';
import styles from './../Geometry.module.scss';

type Props = {
    style?: CSSProperties;
    className?: string;
    defaultValue?: Point;
    onChange?: (position: Point) => void;
    scaleAdjustment?: number;
};

const MoveControls: FunctionComponent<Props> = ({
    style,
    className,
    defaultValue = { x: 0, y: 0 },
    scaleAdjustment = 1,
    onChange,
}) => {
    const [state, setState] = useState<{
        scaleAdjustment: number;
        position: Point;
        dragStartPositionAbsolute: Point;
        dragStartPositionRelative?: Point;
        handleMouseDown: (e: React.MouseEvent) => void;
        handleMouseMove: (e: MouseEvent) => void;
        handleMouseUp: (e: MouseEvent) => void;
    }>({
        scaleAdjustment,
        position: defaultValue,
        dragStartPositionAbsolute: defaultValue,
        handleMouseMove: (e: MouseEvent) => {
            const { clientX, clientY } = e;
            setState((prevState) => {
                if (!prevState.dragStartPositionRelative) return prevState;

                return {
                    ...prevState,
                    position: {
                        x:
                            prevState.dragStartPositionAbsolute.x +
                            (clientX - prevState.dragStartPositionRelative.x) /
                                prevState.scaleAdjustment,
                        y:
                            prevState.dragStartPositionAbsolute.y +
                            (clientY - prevState.dragStartPositionRelative.y) /
                                prevState.scaleAdjustment,
                    },
                };
            });
        },
        handleMouseUp: (e: MouseEvent) => {
            window.removeEventListener('mousemove', state.handleMouseMove);
            window.removeEventListener('mouseup', state.handleMouseUp);
            window.removeEventListener('mouseleave', state.handleMouseUp);
        },
        handleMouseDown: (e: React.MouseEvent) => {
            const { clientX, clientY } = e;

            window.addEventListener('mousemove', state.handleMouseMove);
            window.addEventListener('mouseup', state.handleMouseUp);
            window.addEventListener('mouseleave', state.handleMouseUp);

            setState((prevState) => {
                const currentPosition = {
                    x: clientX,
                    y: clientY,
                };
                return {
                    ...prevState,
                    dragStartPositionRelative: currentPosition,
                    dragStartPositionAbsolute: prevState.position,
                };
            });
        },
    });

    useEffect(() => {
        onChange && onChange(state.position);
    }, [state.position]);

    useEffect(() => {
        setState({ ...state, scaleAdjustment });
    }, [scaleAdjustment]);

    return (
        <div
            style={style}
            className={classNames(className, styles.moveControls)}
            onMouseDown={state.handleMouseDown}
        />
    );
};

export default MoveControls;
