import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationsState, RootState } from '../../common/types';
import { dismissNotification } from '../../features/notifications/notificationsActions';
import NotificationElement from '../NotificationElement';
import styles from './Notifier.module.scss';

const Notifier: FunctionComponent = () => {
    const notifications = useSelector<RootState, NotificationsState>(
        (state) => state.notifications
    );
    const dispatch = useDispatch();

    const freshNotifications = notifications.filter(
        (noti) => !noti.dismissedAt
    );
    if (freshNotifications.length > 0) {
        return (
            <div className={styles.notification}>
                {freshNotifications.map((noti) => {
                    return (
                        <NotificationElement
                            key={noti.bornAt}
                            {...noti}
                            onDismiss={
                                noti.dismissible
                                    ? () => {
                                          if (noti.onDismiss) {
                                              noti.onDismiss();
                                          }
                                          dispatch(dismissNotification(noti));
                                      }
                                    : undefined
                            }
                        />
                    );
                })}
            </div>
        );
    } else return null;
};
export default Notifier;
