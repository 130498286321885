import React, { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import styles from './app.module.scss';
import { AppRoot } from './AppRoot';
import { initRoles } from './common/functions/userRoles';
import ModalRoot from './components/ModalRoot';
import Notifier from './components/Notifier';
import './scss/main.scss';
import { configureStore } from './store/configureStore';

const App: FunctionComponent = () => {
    const store = configureStore();
    initRoles(store.getState);

    return (
        <>
            <div id="app" className={styles.app}>
                <Provider store={store}>
                    <Notifier />
                    <AppRoot />
                    <ModalRoot />
                </Provider>
            </div>
            <div id={'snapshot'} />
        </>
    );
};

export default App;
