import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent, useState } from 'react';
import { Direction, Position } from '../../common/types';
import Badge from '../Badge';
import Button from '../Button';
import styles from './IconWithDropdown.module.scss';

type Props = {
    iconId?: string;
    iconOnly?: boolean;
    iconSize?: 'big' | 'small';
    buttonType?: 'sales' | 'planner';
    style?: CSSProperties;
    direction?: Direction;
    position?: Position;
    badgeContent?: React.ReactNode;
    badgeClassName?: string;
    badgeTitle?: string;
    className?: string;
    tooltip?: string;
    isOpen?: boolean;
    tooltipPosition?: string;
    children: React.ReactNode;
};

const IconWithDropdown: FunctionComponent<Props> = ({
    iconId,
    iconOnly,
    iconSize = 'small',
    buttonType,
    children,
    style,
    direction = 'left',
    position = 'bottom',
    badgeContent,
    badgeClassName,
    badgeTitle,
    className,
    isOpen = false,
    tooltip,
    tooltipPosition,
}) => {
    const [isActive, setIsActive] = useState(isOpen);
    const handleMenuClick = () => {
        setIsActive(!isActive);
    };

    return (
        <menu
            className={classNames(
                styles.root,
                className,
                styles[direction],
                styles[position],
                {
                    [styles.open]: isActive,
                    [styles.iconOnly]: isActive && iconOnly,
                }
            )}
            style={style}
            data-tooltip={isActive ? undefined : tooltip}
            data-position={tooltipPosition}
        >
            {!isActive && badgeContent && (
                <Badge
                    title={badgeTitle}
                    className={badgeClassName}
                    content={badgeContent}
                    float={'corner'}
                    bold
                    size={'mediumSquare'}
                />
            )}
            <Button
                className={styles.button}
                type={buttonType}
                icon={iconId}
                iconSize={iconSize}
                onClick={handleMenuClick}
                active={isActive}
                iconOnly={iconOnly}
            />
            <div className={classNames('subMenu', styles.subMenu)}>
                {children}
            </div>
        </menu>
    );
};

export default IconWithDropdown;
