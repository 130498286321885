import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { newNotification } from '../../features/notifications/notificationsActions';
import { getOpportunity } from '../../features/opportunity/opportunityActions';
import { OPP_ID } from '../constants';
import { loadFromLocalStorage } from '../localStorage';
import { OpportunityState, RootState } from '../types';
import { useAppDispatch } from './index';

// Opportunity hook to get the opportunity:
export default function useOpportunity(): OpportunityState {
    const dispatch = useAppDispatch();
    const opportunity = useSelector<RootState, OpportunityState>(
        (state) => state.opportunity
    );
    const { t } = useTranslation(['notifications']);

    useEffect(() => {
        if (!opportunity?.opportunityId) {
            const oppId = loadFromLocalStorage(OPP_ID);
            if (!oppId) {
                dispatch(
                    newNotification({
                        type: 'error',
                        message: t('noOpportunityId'),
                    })
                );
                return;
            }
            dispatch(getOpportunity({ opportunityId: oppId as string }));
        }
    }, [dispatch, opportunity]);

    return opportunity;
}
