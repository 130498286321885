import { PerspectiveTransform, Vertex, Vertices } from '../../common/types';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const PerspT = require('perspective-transform');

export const calculateTransform = (source: number[], destination: number[]) => {
    return PerspT(source, destination) as PerspectiveTransform;
};
export const coefficientsToMatrix = (coefficients: number[]): number[] => [
    coefficients[0],
    coefficients[3],
    0,
    coefficients[6],
    coefficients[1],
    coefficients[4],
    0,
    coefficients[7],
    0,
    0,
    1,
    0,
    coefficients[2],
    coefficients[5],
    0,
    coefficients[8],
];
export const coordinatesToTransformMatrix3d = (
    src: number[],
    dst: number[]
): string => {
    const coefficients = calculateTransform(src, dst).coeffs;
    let matrix = coefficientsToMatrix(coefficients);
    matrix = matrix.map((c) => Math.round(c * 1000000) / 1000000);

    return `matrix3d(${matrix.join(', ')})`;
};
export const dimensionsToVertices = (w: number, h: number): Vertices => [
    [0, 0],
    [w, 0],
    [0, h],
    [w, h],
];
export const verticesToCoordinates = (vertices: Vertices): number[] =>
    vertices.reduce((result: number[], vertex) => [...result, ...vertex], []);

export const verticesToTransform = (
    vertices: Vertices,
    width: number,
    height: number
) =>
    calculateTransform(
        [...vertices[0], ...vertices[1], ...vertices[2], ...vertices[3]],
        // TODO: apparently this "* 10" was a quick fix for a mixed up scale issue. To be looked at later :)
        [0, 0, width * 10, 0, 0, height * 10, width * 10, height * 10]
    );

export const createDefaultVertices = (
    vertices: Vertices | undefined,
    width: number,
    height: number
): Vertices => {
    if (!vertices) return dimensionsToVertices(width, height);
    return [...vertices.map((v) => [...v] as Vertex)];
};

export const getPoint = (
    vertices: Vertices,
    order: number[],
    position: Vertex,
    sourceVertices = [0, 0, 1, 0, 0, 1, 1, 1]
): [number, number] => {
    const perspectiveT = calculateTransform(
        sourceVertices,
        order.reduce((res, index) => {
            res.push(...vertices[index]);
            return res;
        }, [] as number[])
    );

    return perspectiveT.transform(...position);
};

export const moveVertex = (
    vertices: Vertex[],
    vertexIndex: number,
    dx: number,
    dy: number
) => {
    const newVertices: Vertices = [...vertices.map((v) => [...v] as Vertex)];
    newVertices[vertexIndex][0] += dx;
    newVertices[vertexIndex][1] += dy;

    if (vertices.length === 5) {
        if (vertexIndex === 4) {
            newVertices[1][0] += dx;
            newVertices[1][1] += dy;

            newVertices[0] = getPoint(newVertices, [4, 1, 2, 3], [-1, 0]);
        } else if (vertexIndex === 0) {
            newVertices[1] = getPoint(newVertices, [0, 4, 2, 3], [2, 0]);
        } else if (vertexIndex === 1) {
            newVertices[0] = getPoint(newVertices, [4, 1, 2, 3], [-1, 0]);
        } else if (vertexIndex === 2)
            newVertices[1] = getPoint(newVertices, [0, 4, 2, 3], [2, 0]);
        else newVertices[0] = getPoint(newVertices, [4, 1, 2, 3], [-1, 0]);
    }
    return newVertices;
};

export const createVertexIdFromIndex = (i: number) => 'vertex_' + i;
export const getVertexIndexFromId = (id: string) => Number(id.split('_')[1]);
