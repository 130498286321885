import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUi } from '../../features/ui/uiActions';
import { RootState, UiActionPayload, UiState } from '../types';

export const useUi = (): [UiState, (payload: UiActionPayload) => void] => {
    const ui = useSelector<RootState, UiState>((state) => state.ui);
    const dispatch = useDispatch();
    const update = useRef((payload: UiActionPayload) =>
        dispatch(updateUi(payload))
    ).current;
    return [ui, update];
};
