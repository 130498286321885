import classNames from 'classnames';
import { Property } from 'csstype';
import React, {
    CSSProperties,
    FunctionComponent,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Dimensions, Point } from '../../common/types';
import Geometry from '../Geometry';
import {
    addContainerListeners,
    gestureStartStateHandler,
    gestureStateHandler,
    handleMouseDownState,
    handleMouseUpState,
    handleZoomResetState,
    handleZoomState,
    mouseMoveStateHandler,
    removeContainerListeners,
    State,
    wheelPanStateHandler,
    wheelScaleStateHandler,
} from './functions';
import styles from './Zoomable.module.scss';
import { useUi } from '../../common/hooks/useUi';

export type ZoomableRef = {
    zoom: (multiplier: number) => void;
    resetZoom: () => void;
    pan: (x: number, y: number) => void;
    fitScale?: number;
} | null;

type Props = {
    style?: CSSProperties;
    className?: string;
    onChange?: (payload: { zoom: number; pan: Point }) => void;
    zoomable?: boolean;
    scrollPannable?: boolean;
    dragPannable?: boolean;
    panCursor?: Property.Cursor;
    minScale?: number;
    maxScale?: number;
    contentSize?: Dimensions;
    refObject?: React.MutableRefObject<ZoomableRef>;
    isStringPlanner?: boolean;
    children: React.ReactNode;
};

const Zoomable: FunctionComponent<Props> = ({
    children,
    onChange,
    style,
    zoomable = true,
    scrollPannable,
    dragPannable,
    panCursor,
    minScale = 0.1,
    maxScale = 4,
    contentSize,
    className,
    refObject,
    isStringPlanner,
}) => {
    const [state, setState] = useState(
        (): State => ({
            scale: 1,
            defaultScale: 1,
            minScale,
            maxScale,
            pivot: { x: 0, y: 0 },
            offset: { x: 0, y: 0 },
            pan: { x: 0, y: 0 },
            defaultPan: { x: 0, y: 0 },
            startScale: 1,
            clientX: 0,
            clientY: 0,
            isDragging: false,
            zoomable,
            scrollPannable,
            handleMouseMove: (e: React.MouseEvent) =>
                setState(mouseMoveStateHandler(e)),
            handleMouseDown: () => setState(handleMouseDownState),
            handleMouseUp: () => setState(handleMouseUpState),
        })
    );

    const containerRef = useRef<HTMLDivElement>(null);

    const { scale, pan, handleMouseMove, handleMouseDown, handleMouseUp } =
        state;

    useEffect(() => {
        if (!containerRef.current) return;
        const containerElement = containerRef.current;
        const { clientWidth, clientHeight } = containerElement;

        if (contentSize) {
            const fitScale =
                0.9 *
                Math.min(
                    clientWidth / contentSize.width,
                    clientHeight / contentSize.height
                );
            const centerPan = {
                x: (clientWidth - fitScale * contentSize.width) * 0.5,
                y: (clientHeight - fitScale * contentSize.height) * 0.5,
            };

            if (refObject && refObject.current)
                refObject.current.fitScale = fitScale;

            setState((state) => ({
                ...state,
                clientX: clientWidth * 0.5,
                clientY: clientHeight * 0.5,
                pan: centerPan,
                scale: fitScale,
                defaultScale: fitScale,
                defaultPan: centerPan,
            }));
        }

        const handleWheel = (e: WheelEvent) => {
            e.stopPropagation();
            e.preventDefault();

            if (e.ctrlKey) {
                setState(wheelScaleStateHandler(e));
            } else {
                setState(
                    wheelPanStateHandler(
                        e,
                        { width: clientWidth, height: clientHeight },
                        contentSize
                    )
                );
            }
        };

        containerRef.current.onwheel = handleWheel;

        const handleGestureStart = (e: Event) =>
            setState(gestureStartStateHandler(e));
        const handleGesture = (e: Event & { scale: number }) =>
            setState(gestureStateHandler(e));

        addContainerListeners(
            containerElement,
            handleWheel,
            handleGestureStart,
            handleGesture
        );

        return () =>
            removeContainerListeners(
                containerElement,
                handleWheel,
                handleGestureStart,
                handleGesture
            );
    }, [containerRef.current, contentSize]);

    useEffect(() => {
        setState({
            ...state,
            zoomable,
            scrollPannable,
        });
    }, [zoomable, scrollPannable]);

    const [ui] = useUi();

    useEffect(() => {
        if (refObject)
            refObject.current = {
                zoom: (multiplier) => {
                    setState(handleZoomState(multiplier));
                },
                resetZoom: () => {
                    setState(handleZoomResetState);
                },
                pan: (x, y) => {
                    if (ui.selectedPanelIndices.length === 0) {
                        setState((prevState) => ({
                            ...prevState,
                            pan: {
                                x: prevState.pan.x + x,
                                y: prevState.pan.y + y,
                            },
                        }));
                    }
                },
            };
    }, [refObject, ui.selectedPanelIndices]);

    useEffect(() => onChange && onChange({ zoom: scale, pan }), [scale, pan]);

    return (
        <div
            ref={containerRef}
            style={{
                ...style,
                cursor: dragPannable ? panCursor : undefined,
            }}
            onMouseMove={handleMouseMove}
            onMouseDown={dragPannable ? handleMouseDown : undefined}
            onMouseUp={handleMouseUp}
            className={classNames(className, styles.root)}
        >
            <div
                style={
                    !isStringPlanner
                        ? {
                              transform: `translate(${pan.x}px, ${pan.y}px)`,
                          }
                        : {}
                }
            >
                <Geometry
                    scale={scale}
                    transformOrigin={{ x: 0, y: 0 }}
                    className={styles.fillStyle}
                >
                    <div
                        className={classNames(styles.fillStyle, {
                            [styles.dragPannable]: dragPannable,
                        })}
                    >
                        {children}
                    </div>
                </Geometry>
            </div>
        </div>
    );
};

export default Zoomable;
