import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { sortSurfacesByLeftMost } from '../../../api/generateStringPlan';
import useDesignerQuote from '../../../common/hooks/useDesignerQuote';
import { useUi } from '../../../common/hooks/useUi';
import {
    Canvas,
    PlanningData,
    StringPathIndexes,
    StringsUpdateHandler,
    Vertices,
} from '../../../common/types';
import {
    addPanelToString,
    dispatchAndSaveToLocalStorage,
    removePanelFromString,
} from '../../../features/designerQuote/designerQuoteActions';
import { getStringIndexForPanel, hasOptimizer } from '../functions';
import StringModuleElement from '../StringModuleElement';
import StringPath from '../StringPath';
import styles from './StringCanvasElement.module.scss';
import { useAppDispatch } from '../../../common/hooks';

type Props = {
    id?: string;
    className?: string;
    disabled?: boolean;
    planningData?: PlanningData;
    size: { width: number; height: number };
    isShapeBuilderOn?: boolean;
    onFinishedDrawing?: (vertices: Vertices, canvasId: string) => void;
    children?: React.ReactNode;
};

const StringCanvasElement: FunctionComponent<Props> = (props) => {
    const { id, disabled, className, children } = props;
    const planningData = useDesignerQuote().planning;
    const planningCanvases = planningData?.canvases;
    const [{ stringKey }] = useUi();
    const localStringKeys: string[] = ['a', 'b', 'c', 'd', 'e'];
    const stringIndexes: StringPathIndexes = {
        a: -1,
        b: -1,
        c: -1,
        d: -1,
        e: -1,
    };
    planningData &&
        localStringKeys.forEach((sk) => {
            stringIndexes[sk as keyof typeof stringIndexes] =
                getStringIndexForPanel(sk, Object.values(planningCanvases)) - 1;
        });

    const panelProduct = useDesignerQuote().salesforce.Products?.panels;
    const products = useDesignerQuote().salesforce.Products?.inverter;
    const panelHasOptimizer = !!products && hasOptimizer(products);
    const dispatch = useAppDispatch();
    const handleStringsUpdate: StringsUpdateHandler = (
        e,
        i,
        canvasId,
        surfaceId
    ) => {
        const panel = planningCanvases[canvasId].surfaces[surfaceId].panels[i];
        const data = {
            panelIndex: i,
            canvasId: canvasId,
            surfaceId: surfaceId,
            stringKey,
        };

        /* only remove if last of string */
        const remAdd =
            panel.stringPosition &&
            panel.stringPosition.key === stringKey &&
            panel.stringPosition.index ===
                stringIndexes[
                    panel.stringPosition!.key as keyof typeof stringIndexes
                ];

        dispatch(
            dispatchAndSaveToLocalStorage(
                remAdd
                    ? removePanelFromString({ data })
                    : addPanelToString({ data })
            )
        );
    };

    let offset = 0;
    let previousCanvas: Canvas;

    return (
        <div
            id={id}
            key={id}
            data-string-key={stringKey}
            className={classNames(styles.root, className, {
                [styles.disabled]: disabled,
            })}
        >
            {children}
            <div className={styles.innerBox} key={'inner' + id}>
                {panelProduct &&
                    planningCanvases &&
                    Object.values(planningCanvases).map((canvas, cindx) => {
                        if (cindx > 0) {
                            offset += Object.values(
                                previousCanvas.surfaces
                            ).length;
                        }
                        previousCanvas = canvas;
                        const unsortedSurfaces = Object.values(canvas.surfaces);

                        return unsortedSurfaces.length ? (
                            <section
                                id={`canvas-${cindx + canvas.id}`}
                                key={`canvas-${cindx}`}
                                className={styles.roofSection}
                            >
                                {sortSurfacesByLeftMost(unsortedSurfaces, [
                                    canvas,
                                ]).map((sortedSurface) => {
                                    const sindx = unsortedSurfaces.findIndex(
                                        (unSortedSurface) =>
                                            unSortedSurface.id ===
                                            sortedSurface.id
                                    );
                                    return (
                                        sortedSurface.panels.length && (
                                            <StringModuleElement
                                                key={sortedSurface.id}
                                                planningData={sortedSurface}
                                                panelProduct={panelProduct}
                                                canvasId={canvas.id}
                                                surfaceId={sortedSurface.id}
                                                canvasIndex={cindx}
                                                surfaceIndex={sindx + offset}
                                                onStringsUpdate={
                                                    handleStringsUpdate
                                                }
                                                panelHasOptimizer={
                                                    panelHasOptimizer
                                                }
                                                activeStringIndex={
                                                    stringIndexes[
                                                        stringKey as keyof typeof stringIndexes
                                                    ]
                                                }
                                            >
                                                <StringPath
                                                    key={
                                                        'sp-' + sortedSurface.id
                                                    }
                                                    surfacePlanningData={
                                                        sortedSurface
                                                    }
                                                    planningData={planningData}
                                                    panelProduct={panelProduct}
                                                    highestStringIndexes={
                                                        stringIndexes
                                                    }
                                                />
                                            </StringModuleElement>
                                        )
                                    );
                                })}
                            </section>
                        ) : (
                            <></>
                        );
                    })}
            </div>
        </div>
    );
};

export default StringCanvasElement;
