import useDesignerQuote from './useDesignerQuote';

export default function useTotalEnergyConsumption() {
    const quote = useDesignerQuote();
    return (
        (quote.salesforce.energyConsumptionCarKwh || 0) +
        (quote.salesforce.energyConsumptionHeatPumpKwh || 0) +
        (quote.salesforce.energyConsumptionSaunaKwh || 0) +
        (quote.salesforce.energyConsumptionPoolKwh || 0) +
        (quote.salesforce.energyConsumptionHouseholdKwh || 0) +
        (quote.salesforce.energyConsumptionBigConsumersKwh || 0)
    );
}
