import React, { FunctionComponent } from 'react';
import { List } from 'semantic-ui-react';
import ProductOverview from '../ProductOverview';
import styles from './ProductsWizard.module.scss';
import {
    PanelPlanningData,
    PlanningData,
    Product,
    ProductsMap,
    ProductTypes,
    RootState,
    SalesforceProductsState,
} from '../../common/types';
import {
    dispatchAndSaveToLocalStorage,
    setValidMountingSystems,
    updateMountingSystemAccessory,
} from '../../features/designerQuote/designerQuoteActions';
import { useAppDispatch } from '../../common/hooks';
import { useTranslation } from 'react-i18next';
import Switch from '../Switch';
import {
    DEFAULT_MOUNTING_SYSTEM_COLOR,
    MISCHBELEGUNG_MOUNTING_SYSTEM_SUPPLEMENTARY,
} from '../../common/constants';
import { useSelector } from 'react-redux';
import {
    filterOutInvalidPanels,
    getAllPanelsFromSurfaces,
    getMountingSystemColorFromQuote,
} from '../../common/functions';
import PyramidMountingSystems from './PyramidMountingSystems';
import useDesignerQuote from '../../common/hooks/useDesignerQuote';
import InputNumber from '../InputNumber';
import BetaPlatten from './BetaPlatten';

type Props = {
    availableProducts: ProductsMap;
    products: SalesforceProductsState;
    open?: boolean;
};

const MountingSystem: FunctionComponent<Props> = ({
    availableProducts,
    products,
    open,
}) => {
    const { t } = useTranslation(['productsWizard', 'common']);
    const dispatch = useAppDispatch();
    const quote = useDesignerQuote();
    const planning = useSelector<RootState, PlanningData | undefined>(
        (state) => state.designerQuote.planning
    );

    const availableMischbelegung = (
        availableProducts.mountingSystemAccessories ?? []
    ).filter(
        (product) =>
            product.supplementary ===
            MISCHBELEGUNG_MOUNTING_SYSTEM_SUPPLEMENTARY
    );

    let validPanels: PanelPlanningData[] = [];
    Object.values(planning?.canvases ?? {}).forEach((canvas) => {
        validPanels = [
            ...validPanels,
            ...filterOutInvalidPanels(
                getAllPanelsFromSurfaces(Object.values(canvas.surfaces ?? {}))
            ),
        ];
    });

    if (
        availableProducts.mountingSystem &&
        availableProducts.mountingSystem.length > 0
    ) {
        return (
            <List.Item>
                <ProductOverview
                    productType={ProductTypes.MOUNTING_SYSTEM}
                    productTitle={t('mountingSystem') as string}
                    product={(products?.mountingSystem as Product[]) || []}
                >
                    <Switch
                        switchValue={
                            getMountingSystemColorFromQuote(quote) ??
                            DEFAULT_MOUNTING_SYSTEM_COLOR
                        }
                        switchOptions={[
                            {
                                id: DEFAULT_MOUNTING_SYSTEM_COLOR,
                                name: t(
                                    DEFAULT_MOUNTING_SYSTEM_COLOR
                                ) as string,
                            },
                            { id: 'Black', name: t('Black') as string },
                        ]}
                        handleChange={(color) => {
                            dispatch(
                                dispatchAndSaveToLocalStorage(
                                    setValidMountingSystems({
                                        color,
                                    })
                                )
                            );
                        }}
                        variant="toggle"
                    />

                    <PyramidMountingSystems planning={planning} open={open} />

                    {products.mountingSystem && (
                        <ul className={styles.mountingSystems}>
                            {products.mountingSystem.map(
                                (mountingSystem, index) => (
                                    <li key={index}>
                                        {`${mountingSystem.quantity} x  ${mountingSystem.name}`}
                                    </li>
                                )
                            )}
                            <BetaPlatten
                                availableProducts={availableProducts}
                                planning={planning}
                            />
                            {availableMischbelegung && (
                                <li className={styles.mischbelegung}>
                                    <span className={styles.title}>
                                        {t('mixPanelArrangement')}
                                        <br />({t('mixPanelArrangementNote')})
                                    </span>
                                    <InputNumber
                                        className={styles.inputNumber}
                                        id={'mischbelegungAmount'}
                                        min={0}
                                        value={
                                            products.mountingSystemAccessories?.find(
                                                (product) =>
                                                    product.supplementary ===
                                                    MISCHBELEGUNG_MOUNTING_SYSTEM_SUPPLEMENTARY
                                            )?.quantity ?? 0
                                        }
                                        onChange={(number) => {
                                            if (number === 0) {
                                                dispatch(
                                                    updateMountingSystemAccessory(
                                                        {
                                                            product: null,
                                                            supplementary:
                                                                MISCHBELEGUNG_MOUNTING_SYSTEM_SUPPLEMENTARY,
                                                        }
                                                    )
                                                );
                                            } else {
                                                dispatch(
                                                    updateMountingSystemAccessory(
                                                        {
                                                            product: {
                                                                ...(products.mountingSystemAccessories?.find(
                                                                    (product) =>
                                                                        product.supplementary ===
                                                                        MISCHBELEGUNG_MOUNTING_SYSTEM_SUPPLEMENTARY
                                                                ) ??
                                                                    availableProducts.mountingSystemAccessories?.find(
                                                                        (
                                                                            product
                                                                        ) =>
                                                                            product.supplementary ===
                                                                            MISCHBELEGUNG_MOUNTING_SYSTEM_SUPPLEMENTARY
                                                                    )),
                                                                quantity:
                                                                    number,
                                                            } as Product,
                                                            supplementary:
                                                                MISCHBELEGUNG_MOUNTING_SYSTEM_SUPPLEMENTARY,
                                                        }
                                                    )
                                                );
                                            }
                                        }}
                                    />
                                </li>
                            )}
                        </ul>
                    )}
                </ProductOverview>
            </List.Item>
        );
    }
    return null;
};

export default MountingSystem;
