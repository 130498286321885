import { throttle, validateObjectData } from '../common/functions';
import { ApiQuote } from '../common/types';
import { callEigenApiWith } from './index';

const fetchQuote = async (p: {
    quoteId: string;
}): Promise<[ApiQuote?, Error?]> => {
    try {
        const res = await callEigenApiWith<ApiQuote>({
            name: 'quotes',
            middleware: [validateObjectData],
            id: p.quoteId,
        });
        return [res];
    } catch (e) {
        return [undefined, e as Error];
    }
};

export default throttle(fetchQuote, 5000);
