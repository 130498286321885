import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { UserRoles } from '../../common/types';
import PlannerUi from '../PlannerUi';
import SalesUi from '../SalesUi';
import StringPlannerUi from '../StringPlanner/StringUi';
import UserRoleSpecific from '../UserRoleSpecific';
import styles from './UiOverlay.module.scss';
import usePanelCount from '../../common/hooks/usePanelCount';

const UiOverlay: FunctionComponent = () => {
    usePanelCount();
    return (
        <div
            className={classNames(styles.uiOverlay, {
                [styles.stringPlanner]: UserRoles.stringPlanner,
            })}
        >
            <UserRoleSpecific
                contentByRole={{
                    [UserRoles.planner]: <PlannerUi />,
                    [UserRoles.sales]: <SalesUi />,
                    [UserRoles.stringPlanner]: <StringPlannerUi />,
                }}
            />
        </div>
    );
};

export default UiOverlay;
