import React, { FunctionComponent } from 'react';
import Corner from './Corner';

type CornerPositionId = '00' | '01' | '11' | '10' | 'all';
type CursorsMap = {
    [key in CornerPositionId]?: string;
};
const CORNER_ID_ARRAY: CornerPositionId[] = ['00', '01', '11', '10'];

const renderCorners = (
    onMouseDown?: React.MouseEventHandler,
    size?: number,
    overflow?: string,
    cursors: CursorsMap = {}
) => {
    return CORNER_ID_ARRAY.map((c) => (
        <Corner
            key={c}
            size={size}
            overflow={overflow}
            positionX={c[0] as '0' | '1'}
            positionY={c[1] as '0' | '1'}
            cursor={cursors.all || cursors[c]}
            onMouseDown={onMouseDown}
        />
    ));
};

const Corners: FunctionComponent<{
    size?: number;
    overflow?: string;
    onMouseDown?: React.MouseEventHandler;
    cursors?: CursorsMap;
}> = ({ onMouseDown, size, overflow, cursors = {} }) => {
    return <>{renderCorners(onMouseDown, size, overflow, cursors)}</>;
};

export default Corners;
