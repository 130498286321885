import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import { Grid, Label } from 'semantic-ui-react';
import { MAX_PICTURE_AMOUNT } from '../../common/constants';
import { ColumnSize, Photo, UseCases, VoidHandler } from '../../common/types';
import Picture from '../Picture';
import styles from './PhotoGrid.module.scss';

type Props = {
    photos: Photo[];
    onPictureClick: (pictureId: string) => void;
    selectedPictureIds: string[];
    pictureClassName?: string;
    columnAmount?: ColumnSize;
    style?: CSSProperties;
    className?: string;
    onAddPictureClick?: VoidHandler;
    disabledPictureIds?: string[];
    onDeleteClick?: (id: string) => void;
    useCase?: UseCases;
};

const PhotoGrid: FunctionComponent<Props> = ({
    style,
    className,
    photos,
    onPictureClick,
    selectedPictureIds,
    pictureClassName,
    columnAmount = 3,
    onAddPictureClick,
    disabledPictureIds,
    onDeleteClick,
    useCase,
}) => {
    return (
        <Grid
            as="article"
            columns={columnAmount}
            className={classNames(className, styles.root)}
            style={style}
        >
            {photos.map((photo: Photo) => (
                <Grid.Column
                    as="div"
                    key={photo.id}
                    className={classNames(styles.item, styles.itemImage)}
                >
                    <Picture
                        src={photo.url}
                        alt={photo.name}
                        id={photo.id}
                        selected={selectedPictureIds.includes(photo.id)}
                        onPictureClick={onPictureClick}
                        className={classNames(
                            'photo-grid-item',
                            useCase && styles[useCase],
                            {
                                [styles.active]: selectedPictureIds.includes(
                                    photo.id
                                ),
                            },
                            styles.image,
                            pictureClassName
                        )}
                        disabled={
                            disabledPictureIds &&
                            disabledPictureIds.includes(photo.id)
                        }
                    />
                    {onDeleteClick && (
                        <Label
                            className={styles.remove}
                            as="a"
                            circular
                            floating
                            onClick={() => onDeleteClick(photo.id)}
                        />
                    )}
                </Grid.Column>
            ))}
            {photos.length < MAX_PICTURE_AMOUNT && onAddPictureClick && (
                <Grid.Column
                    as="div"
                    className={classNames(styles.item, styles.addPhotoBox)}
                    onClick={onAddPictureClick}
                >
                    <div className={styles.contentArea}>
                        <div className={styles.plus}>+</div>
                    </div>
                </Grid.Column>
            )}
        </Grid>
    );
};

export default PhotoGrid;
