import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownProps, Grid, Segment } from 'semantic-ui-react';
import { useUi } from '../../common/hooks/useUi';
import {
    SalesforceState,
    SelectItem,
    SortType,
    UserPermissions,
} from '../../common/types';
import Header from '../Header';
import PlanItemGroup from '../PlanItemGroup';
import RoofPhotoSelector from '../RoofPhotoSelector';
import Select from '../Select';
import styles from './StartPlanningWizard.module.scss';

type Props = {
    sdQuotes: SalesforceState[];
    selectedQuote?: SalesforceState;
    selectedPhotoIds: string[];
    permissions: UserPermissions;
    onQuoteClick: (quote: SalesforceState, openInstantly?: boolean) => void;
    onPictureClick: (photoId: string) => void;
    sortOrder: SortType;
};

const PromptChildren: FunctionComponent<Props> = ({
    sdQuotes,
    permissions,
    onQuoteClick,
    onPictureClick,
    selectedQuote,
    selectedPhotoIds,
    sortOrder,
}) => {
    const { t } = useTranslation(['common', 'startPlanning', 'modals']);

    const sortOptions: SelectItem[] = [
        { value: 'date', text: t('startPlanning:SortType.date') },
        { value: 'sync', text: t('startPlanning:SortType.sync') },
        { value: 'status', text: t('startPlanning:SortType.status') },
    ];
    const [{ quoteSorting }, updateUi] = useUi();

    const handleSortChange = (
        event: React.SyntheticEvent<HTMLElement> | undefined,
        data: DropdownProps
    ) => {
        data && updateUi({ quoteSorting: data.value as SortType });
    };

    return (
        <Grid.Row
            columns={
                sdQuotes.length > 0 && permissions.canStartNewPlanning ? 2 : 1
            }
            className={styles.row}
            centered
        >
            {sdQuotes.length > 0 && (
                <Grid.Column className={`${styles.quotes} ${styles.column}`}>
                    <Header
                        variant="h2"
                        textAlign="center"
                        attached="top"
                        className={styles.columnHeader}
                    >
                        <div>{t('startPlanning:quotesHeader')}</div>
                        <div className={styles.sortbox}>
                            {t('startPlanning:sorting')}
                            <Select
                                options={sortOptions}
                                value={quoteSorting}
                                className={styles.sortSelect}
                                onChange={handleSortChange}
                            />
                        </div>
                    </Header>
                    <Segment attached className={styles.segment}>
                        <article className={styles.option}>
                            <PlanItemGroup
                                quotes={sdQuotes}
                                sortOrder={sortOrder}
                                onClick={onQuoteClick}
                                onDoubleClick={(quote: SalesforceState) =>
                                    onQuoteClick(quote, true)
                                }
                                activeId={selectedQuote?.id}
                                className={classNames(
                                    styles.quoteList,
                                    'startupQuoteList'
                                )}
                            />
                        </article>
                    </Segment>
                </Grid.Column>
            )}
            {permissions.canStartNewPlanning && (
                <Grid.Column className={styles.column}>
                    <Header
                        variant="h2"
                        textAlign="center"
                        attached="top"
                        className={styles.columnHeader}
                    >
                        {t('startPlanning:customerPhotosHeader')}
                    </Header>
                    <Segment attached className={styles.segment}>
                        <article className={styles.option}>
                            <RoofPhotoSelector
                                selectedPhotoIds={selectedPhotoIds}
                                onClick={onPictureClick}
                            />
                        </article>
                    </Segment>
                </Grid.Column>
            )}
        </Grid.Row>
    );
};

export default PromptChildren;
