import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { debouncedGetRecommendedInverters } from '../../api/getRecommenedInverters';
import { setRecommendedInverters } from '../../features/designerQuote/designerQuoteActions';
import { newNotification } from '../../features/notifications/notificationsActions';
import {
    allSurfaceDetails,
    getSurfacesOfPlanning,
    getValidPanelsCount,
    hasSurfaceDetailsChanged,
} from '../functions';
import { InverterResponse } from '../responseTypes';
import {
    ModulePlanningData,
    RootState,
    SalesforceProductsState,
} from '../types';
import useDesignerQuote from './useDesignerQuote';
import useOpportunity from './useOpportunity';
import useUserRole from './useUserRole';
import { useAppDispatch } from './index';

const getInverterArray = (inverterResponse: any, index: number) => {
    return inverterResponse.inverters[index]
        ? inverterResponse.inverters[index].recommended
        : [];
};

export default function useRecommendedInverters() {
    const quote = useDesignerQuote();
    const opportunity = useOpportunity();
    const products = useSelector<
        RootState,
        SalesforceProductsState | undefined
    >((state) => state.designerQuote.salesforce.Products);
    const role = useUserRole();
    const recordType = quote.salesforce.recordType;
    const dispatch = useAppDispatch();
    const [lastAnnualYieldHours, setLastAnnualYieldHours] = useState(0);
    const { t } = useTranslation(['productsWizard', 'common']);
    const debouncedGetAndSetRecommendedInverters = (
        validSurfaces: ModulePlanningData[]
    ) => {
        debouncedGetRecommendedInverters({
            opportunityId: opportunity.opportunityId,
            surfaces: validSurfaces,
            panelPower: products?.panels?.power || 0,
            role,
            recordType,
        })
            .then(async (promise) => {
                const inverterResponse: InverterResponse = await promise;

                let annualYieldHours: number | null =
                    inverterResponse.annual_yield_hours;

                if (annualYieldHours === lastAnnualYieldHours) {
                    annualYieldHours = null;
                }

                const reducedAnnualYieldHours: number | null =
                    inverterResponse.reduced_annual_yield_hours;

                dispatch(
                    setRecommendedInverters({
                        data: {
                            recommendedInverters: getInverterArray(
                                inverterResponse,
                                0
                            ),
                            secondRecommendedInverters: getInverterArray(
                                inverterResponse,
                                1
                            ),
                            annualYieldHours,
                            reducedAnnualYieldHours,
                        },
                    })
                );

                setLastAnnualYieldHours(inverterResponse.annual_yield_hours);
            })
            .catch((prom) => {
                if (prom !== 'debounced') {
                    dispatch(
                        newNotification({
                            type: 'error',
                            message: t('notifications:failSales'),
                            details: t('notifications:tryAgainSales') as string,
                        })
                    );
                }
            });
    };

    const planning = quote.planning;
    const [surfaces, setSurfaces] = useState(getSurfacesOfPlanning(planning!));

    useEffect(() => {
        const newSurfaces = getSurfacesOfPlanning(planning!);
        const validSurfaces = newSurfaces.filter(
            (surface) => getValidPanelsCount(surface.panels) > 0
        );

        /* Checks if all surface details are valid, and if the azimuth or slope has changed. 
        If either of these things have happened, then it triggers the API call to get the recommended inverters */
        if (
            allSurfaceDetails(planning!) &&
            validSurfaces.length > 0 &&
            hasSurfaceDetailsChanged(newSurfaces, surfaces)
        ) {
            debouncedGetAndSetRecommendedInverters(validSurfaces);
            setSurfaces(newSurfaces);
        }
    }, [planning]);

    useEffect(() => {
        const newSurfaces = getSurfacesOfPlanning(planning!);
        const validSurfaces = newSurfaces.filter(
            (surface) => getValidPanelsCount(surface.panels) > 0
        );
        if (allSurfaceDetails(planning!) && validSurfaces.length > 0) {
            debouncedGetAndSetRecommendedInverters(validSurfaces);
        }
    }, [products?.panels]);
}
