import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import styles from './Stack.module.scss';

type Props = {
    style?: CSSProperties;
    className?: string;
    orientation?: 'horizontal' | 'vertical';
    spaced?: boolean;
    children: React.ReactNode;
};

const Stack: FunctionComponent<Props> = ({
    children,
    style,
    orientation = 'horizontal',
    spaced = false,
    className,
}) => {
    return (
        <div
            className={classNames(className, styles.stack, {
                [styles.horizontal]: orientation === 'horizontal',
                [styles.vertical]: orientation === 'vertical',
                [styles.spacedHorizontally]:
                    spaced && orientation === 'horizontal',
                [styles.spacedVertically]: spaced && orientation === 'vertical',
            })}
            style={style}
        >
            {children}
        </div>
    );
};

export default Stack;
