import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import {
    DropdownProps,
    Select as SemanticSelect,
    SelectProps,
} from 'semantic-ui-react';
import { PropType, SelectItem } from '../../common/types';
import styles from './Select.module.scss';

type Props = {
    className?: string;
    placeholder?: string;
    options: SelectItem[];
    disabled?: boolean;
    direction?: 'up' | 'down';
    onChange?: (
        event: React.SyntheticEvent<HTMLElement> | undefined,
        data: DropdownProps
    ) => void;
    value?: PropType<SelectProps, 'value'>;
};

const Select: FunctionComponent<Props> = ({
    className,
    placeholder = '',
    options,
    disabled,
    direction,
    onChange,
    value,
}) => {
    return (
        <SemanticSelect
            disabled={disabled}
            className={classNames(className, styles.dropdown, {
                [styles.upward]: direction === 'up',
            })}
            onChange={onChange}
            placeholder={placeholder}
            options={options}
            value={value}
        />
    );
};

export default Select;
