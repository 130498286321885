import { QUOTE_STATUS_READY, USER_ROLE_ID } from '../common/constants';
import { loadFromLocalStorage } from '../common/localStorage';
import {
    AppState,
    CloudQuoteState,
    DesignerQuoteState,
    NotificationsState,
    OpportunityState,
    ProductsState,
    UiState,
    UserState,
} from '../common/types';

export const defaultAppState: AppState = {
    isInitialised: false,
};

export const defaultUiState: UiState = {
    isDrawPanelsOn: false,
    isShapeBuilderOn: false,
    activeCanvasId: undefined,
    selectedPanelIndices: [],
    lockedSurfaceIds: [],
    hideGrids: false,
    hidePanels: false,
    isRenderingScreenshot: false,
    isUserSelectedQuoteName: false,
    isInitialisingProducts: false,
    hideEnergyFields: true,
    stringKey: 'none',
    isStringToolActive: false,
    quoteSorting: 'date',
};

export const defaultUserState: UserState = {
    isAuth: false,
    username: '',
    name: '',
    email: '',
    accessToken: undefined,
    refreshToken: undefined,
    role: loadFromLocalStorage(USER_ROLE_ID) || 'planner', // saves a lot of code writing // TODO: try the same for OPP_ID
};
export const defaultCloudQuoteState: CloudQuoteState = {
    isFetching: false,
    quoteId: '',
    salesforce: {
        id: '',
        status: QUOTE_STATUS_READY,
    },
    planning: {
        canvases: {},
    },
};
export const defaultDesignerQuoteState: DesignerQuoteState = {
    quoteId: '',
    salesforce: {
        id: '',
        status: QUOTE_STATUS_READY,
    },
    planning: {
        canvases: {},
    },
};

export const defaultOpportunityState: OpportunityState = {
    isFetching: false,
    opportunityId: '',
    windZone: 0,
};
export const defaultProductsState: ProductsState = {};

export const defaultNotificationsState: NotificationsState = [];
