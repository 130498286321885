import { getSideDirectionsByAzimuth, getSurfacesOfPlanning } from '.';
import { azimuthOptions } from '../selectOptions';
import {
    DesignerQuoteState,
    ModulePlanningData,
    PanelPlanningData,
} from '../types';
import { adjustPanelsFacing } from './smartLayout';

const getStateWithModifiedPanels = (
    payload: {
        activeCanvasId: string;
        activeSurfaceId: string;
    },
    state: DesignerQuoteState,
    handlePanels: (panels: PanelPlanningData[]) => PanelPlanningData[]
) => {
    const { activeCanvasId, activeSurfaceId } = payload;
    const activeCanvas = state.planning.canvases[activeCanvasId];
    const surfaceToModify = activeCanvas.surfaces[activeSurfaceId];
    const panelProduct = state.salesforce.Products?.panels;

    // Fall back for surfaces made prior to flat roof implementation
    if (typeof surfaceToModify.sideDirections === 'undefined') {
        surfaceToModify.sideDirections = getSideDirectionsByAzimuth(
            surfaceToModify.azimuth as number,
            azimuthOptions
        );
    }

    const modifiedPanels = adjustPanelsFacing(
        {
            ...surfaceToModify,
            panels: handlePanels(surfaceToModify.panels),
        },
        panelProduct
    );

    const modifiedSurface = {
        ...surfaceToModify,
        panels: modifiedPanels,
    };

    return {
        ...state,
        planning: {
            ...state.planning,
            canvases: {
                ...state.planning.canvases,
                [activeCanvasId]: {
                    ...activeCanvas,
                    surfaces: {
                        ...activeCanvas.surfaces,
                        [activeSurfaceId]: modifiedSurface,
                    },
                },
            },
        },
    };
};

export const isPanelOnAString = (panel: PanelPlanningData) => {
    return (
        panel.stringPosition &&
        panel.stringPosition?.key &&
        panel.stringPosition?.index !== undefined
    );
};

const getSortedStringsFromSurfaces = (surfaces: ModulePlanningData[]) => {
    const strings: {
        [key: string]: Array<{
            stringIndex: number;
            surfaceId: string;
            panelIndex: number;
        }>;
    } = {};
    surfaces.forEach((surface) => {
        const panels = surface.panels;
        panels.forEach((panel, index) => {
            if (isPanelOnAString(panel) && panel.stringPosition) {
                strings[panel.stringPosition.key] =
                    strings[panel.stringPosition.key] || [];
                strings[panel.stringPosition.key].push({
                    stringIndex: panel.stringPosition.index,
                    surfaceId: surface.id,
                    panelIndex: index,
                });
            }
        });
    });
    Object.keys(strings).forEach((stringKey) => {
        strings[stringKey] = strings[stringKey].sort(
            (a, b) => a.stringIndex - b.stringIndex
        );
    });
    return strings;
};

const getPanelNewStringIndex = (
    sortedPanels: Array<{
        surfaceId: string;
        panelIndex: number;
    }>,
    surfaceId: string,
    panelIndex: number
) => {
    for (const [newStringIndex, sortedPanel] of sortedPanels.entries()) {
        if (
            sortedPanel.surfaceId === surfaceId &&
            sortedPanel.panelIndex === panelIndex
        ) {
            return newStringIndex;
        }
    }
    return 0;
};

export const removeGapsOnStringsFromPlanning = (
    state: DesignerQuoteState
): DesignerQuoteState => {
    const newSurfaces = removeGapsOnStringsFromSurfaces(
        getSurfacesOfPlanning(state.planning)
    );
    const newCanvases = { ...state.planning.canvases };
    Object.values(state.planning.canvases).forEach((canvas) => {
        Object.values(canvas.surfaces).forEach((surface) => {
            newCanvases[canvas.id].surfaces[surface.id] =
                newSurfaces[surface.id];
        });
    });
    return {
        ...state,
        planning: {
            ...state.planning,
            canvases: newCanvases,
        },
    };
};

export const removeGapsOnStringsFromSurfaces = (
    surfaces: ModulePlanningData[]
) => {
    const newSurfaces: { [key: string]: ModulePlanningData } = {};
    const sortedStrings = getSortedStringsFromSurfaces(surfaces);
    surfaces.forEach((surface) => {
        const panels: PanelPlanningData[] = [];
        surface.panels.forEach((panel, index) => {
            const newPanel = { ...panel };
            if (isPanelOnAString(panel)) {
                newPanel.stringPosition!.index = getPanelNewStringIndex(
                    sortedStrings[panel.stringPosition!.key],
                    surface.id,
                    index
                );
            }
            panels.push(newPanel);
        });
        newSurfaces[surface.id] = { ...surface, panels };
    });
    return newSurfaces;
};

export default getStateWithModifiedPanels;
