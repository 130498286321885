import i18n from 'i18next';
import { REGEX_DEFAULT_NAME_FORMAT } from './constants';
import { filterOutInvalidPanels } from './functions';
import {
    ApiFilter,
    BatteryBrands,
    ModulePlanningData,
    OpportunityState,
    PanelPlanningData,
    Product,
    ProductTypes,
    RoofTypes,
    StringIndexable,
    Vertices,
    WallboxBrands,
} from './types';

export const snakeToCamel = (s: string): string =>
    s.replace(/_./g, (x) => x.toUpperCase()[1]);

export const renameKeys = (
    keysMap: (key: string) => string,
    obj: StringIndexable
): object =>
    Object.keys(obj).reduce(
        (acc, key) => ({
            ...acc,
            ...{
                [keysMap(key)]: isRealObject(obj[key])
                    ? renameKeys(keysMap, obj[key])
                    : isArrayWithObject(obj[key])
                    ? obj[key].map((o: StringIndexable) =>
                          renameKeys(keysMap, o)
                      )
                    : obj[key],
            },
        }),
        {}
    );

export const isRealObject = (thing: unknown): boolean => {
    return (
        typeof thing === 'object' &&
        !Array.isArray(thing) &&
        thing !== null &&
        thing !== undefined
    );
};

export const isArrayWithObject = (thing: unknown): boolean => {
    return Array.isArray(thing) && thing.every((o) => isRealObject(o));
};

export const getUrlParams = (searchString: string): any =>
    searchString
        .replace('?', '')
        .split('&')
        .map((param) => param.split('='))
        .reduce((values: any, [key, value]) => {
            values[key] = decodeURIComponent(value);
            return values;
        }, {});
export const getOrientationCount = (
    surfaces: ModulePlanningData[]
): { Horizontal: number; Vertical: number; Flat: number } => {
    let Horizontal = 0;
    let Vertical = 0;
    let Flat = 0;

    surfaces.forEach((surface: ModulePlanningData) => {
        if (surface.roofType === RoofTypes.flat) {
            Flat += surface.panels.length;
        } else {
            filterOutInvalidPanels(surface.panels).forEach(
                (panel: PanelPlanningData) => {
                    panel.isHorizontal ? Horizontal++ : Vertical++;
                }
            );
        }
    });

    return {
        Horizontal,
        Vertical,
        Flat,
    };
};

export const validateNumber = (number: unknown) => {
    return Number(number) === number;
};

export const getBrandMatchingInverter = (
    inverterBrand: string,
    productType: string
): Array<string> => {
    let map: StringIndexable<BatteryBrands[] | WallboxBrands[] | []> = {};
    if (productType === ProductTypes.BATTERY) {
        map = {
            Kostal: [BatteryBrands.BYD],
            SolarEdge: [
                BatteryBrands.BYD,
                BatteryBrands.LG,
                BatteryBrands.SolarEdge,
            ],
            'RCT Power': [BatteryBrands.RCTPower],
            Sungrow: [BatteryBrands.Axitec, BatteryBrands.Sungrow],
        };
    }

    if (productType === ProductTypes.WALLBOXES) {
        map = {
            Kostal: [WallboxBrands.EGO, WallboxBrands.Kostal],
            SolarEdge: [WallboxBrands.EGO],
            'RCT Power': [WallboxBrands.EGO, WallboxBrands.RCTPower],
            Sungrow: [WallboxBrands.EGO],
        };
    }

    return map[inverterBrand] || [];
};

export const getProductsWithSameBrand = (
    inverters: Product[] | undefined,
    brandName?: string
) => {
    return inverters
        ? inverters.filter(
              (inverter) =>
                  brandName === '' || inverter.productBrand === brandName
          )
        : [];
};

export const getRightUnits = (
    productType: ProductTypes
):
    | {
          unit: string;
          measure: string;
      }
    | undefined => {
    switch (productType) {
        case ProductTypes.PANEL:
            return {
                unit: 'Wp',
                measure: i18n.t('productsWizard:power'),
            };
        case ProductTypes.INVERTER:
            return {
                unit: 'kW',
                measure: i18n.t('productsWizard:power'),
            };
        case ProductTypes.BATTERY:
            return {
                unit: 'kW',
                measure: i18n.t('productsWizard:capacity'),
            };
    }
};

export const getQuoteRecordTypeFromOpportunity = (
    opportunity: Pick<OpportunityState, 'recordType' | 'validQuoteRecordTypes'>
) => {
    if (!opportunity.recordType) return;
    return opportunity.validQuoteRecordTypes?.find(
        (type) => type.name === opportunity.recordType?.name
    );
};

export const copyProperties = <S extends StringIndexable>(
    obj: S,
    properties: (keyof S)[]
) =>
    Object.keys(obj).reduce((res, key) => {
        if (properties.includes(key)) res[key] = obj[key];
        return res;
    }, {} as StringIndexable);

export const hasDefaultQuoteNameFormat = (quoteName: string) => {
    return !!quoteName.match(REGEX_DEFAULT_NAME_FORMAT);
};

export const createFilterParams = (filters: ApiFilter[]): string => {
    return `?${filters
        .map((filter) => `filter[${filter.name}]=${filter.value}&`)
        .join('')
        .slice(0, -1)}`;
};

export const dimsInPx = (vertices: Vertices) => {
    return {
        x: vertices[1][0] - vertices[0][0],
        y: vertices[2][1] - vertices[0][1],
    };
};
