import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ShortcutDisplayItem } from '../../common/types';
import styles from './ShortcutMenu.module.scss';

type Props = {
    name: string;
    display: ShortcutDisplayItem[];
};

const ShortcutMenuItem: FunctionComponent<Props> = ({ name, display }) => {
    const { t } = useTranslation(['shortcuts']);
    const exists = name !== t(name);

    return exists ? (
        <div className={styles.tableRow}>
            <div className={styles.text}>{t(name)}</div>
            <div className={styles.shortcut}>
                {display.map((val, index) => {
                    const [textOrTranslationObject, styling] = val;
                    const shortcut =
                        typeof textOrTranslationObject !== 'string'
                            ? t(textOrTranslationObject.translate)
                            : textOrTranslationObject;
                    return (
                        <span
                            key={index}
                            className={classNames({
                                [styles.dashed]: styling === 'dashed',
                            })}
                        >
                            {shortcut}
                        </span>
                    );
                })}
            </div>
        </div>
    ) : null;
};

export default ShortcutMenuItem;
