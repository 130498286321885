import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent, useCallback } from 'react';
import useDesignerQuote from '../../../common/hooks/useDesignerQuote';
import { useUi } from '../../../common/hooks/useUi';
import Picture from '../../Picture';
import Stack from '../../Stack';
import styles from './SalesCanvasSelector.module.scss';

type Props = {
    style?: CSSProperties;
    className?: string;
};

const SalesCanvasSelector: FunctionComponent<Props> = ({ style }) => {
    const [{ activeCanvasId }, updateUi] = useUi();
    const {
        planning: { canvases },
    } = useDesignerQuote();

    const handleImageClick = useCallback((id: any) => {
        updateUi({ activeCanvasId: id });
    }, []);

    const canvasObjects = Object.values(canvases);

    if (canvasObjects.length <= 1) return null;

    return (
        <Stack className={styles.root}>
            {canvasObjects.map((c) => {
                return (
                    <Picture
                        selected={c.id === activeCanvasId}
                        key={c.id}
                        id={c.id}
                        src={c.imageBox.url}
                        onPictureClick={handleImageClick}
                        style={style}
                        className={classNames(styles.picture, {
                            [styles.selected]: c.id === activeCanvasId,
                        })}
                    />
                );
            })}
        </Stack>
    );
};

export default SalesCanvasSelector;
