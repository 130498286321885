import React from 'react';
import { Provider } from 'react-redux';
import {
    getStringIndexForPanel,
    hasOptimizer,
} from '../../components/StringPlanner/functions';
import StringModuleElement from '../../components/StringPlanner/StringModuleElement';
import StringPath from '../../components/StringPlanner/StringPath';
import { updateUi } from '../../features/ui/uiActions';
import { configureStore } from '../../store/configureStore';
import {
    Canvas,
    ModulePlanningData,
    Panel,
    RootState,
    StringPathIndexes,
} from '../types';
import ReactDOM from 'react-dom';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const domtoimage = require('dom-to-image');

const timeout = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

const takeShotOfSurface = (
    surface: ModulePlanningData,
    rootState: RootState,
    canvas: Canvas
) => {
    const store = configureStore(rootState);
    // Hide the grids before taking the shot
    store.dispatch(
        updateUi({
            hideGrids: true,
            activeSurfaceId: undefined,
            activeCanvasId: undefined,
            isRenderingScreenshot: true,
            lockedSurfaceIds: [],
        })
    );

    const planningData = rootState.designerQuote.planning;

    const inverter = rootState.designerQuote.salesforce.Products?.inverter;

    let resolvePromise: (value: string) => void;
    let rejectPromise: () => void;
    const promise = new Promise((resolve, reject) => {
        resolvePromise = resolve;
        rejectPromise = reject;
    });

    const takeScreenshot = async () => {
        await timeout(123); // I will count till 3 and then you're ready
        const screenshotDom = document.getElementById('snapshot');
        if (!screenshotDom) throw new Error("Couldn't find canvas dom element");
        try {
            const blob = await domtoimage.toPng(screenshotDom);
            resolvePromise(blob);
        } catch (e) {
            rejectPromise();
        }

        const toRemoveContainer = document.getElementById('snapshot');

        if (toRemoveContainer !== null) {
            ReactDOM.unmountComponentAtNode(toRemoveContainer);
        }
    };

    const panelProduct = rootState.designerQuote.salesforce.Products
        ?.panels as Panel;

    // This is repeated code, we need a better way FIX ASAP
    const localStringKeys: string[] = ['a', 'b', 'c', 'd', 'e'];
    const stringIndexes: StringPathIndexes = {
        a: -1,
        b: -1,
        c: -1,
        d: -1,
        e: -1,
    };
    planningData &&
        localStringKeys.forEach((sk) => {
            stringIndexes[sk as keyof typeof stringIndexes] =
                getStringIndexForPanel(
                    sk,
                    Object.values(planningData.canvases)
                ) - 1;
        });

    const canvasWrapper = (
        <Provider store={store}>
            <StringModuleElement
                key={canvas.id}
                canvasId={canvas.id}
                canvasIndex={1}
                surfaceId={surface.id}
                surfaceIndex={1}
                planningData={surface}
                panelProduct={panelProduct}
                /* eslint-disable-next-line @typescript-eslint/no-empty-function */
                onStringsUpdate={() => {}}
                onReady={takeScreenshot}
                panelHasOptimizer={!!inverter && hasOptimizer(inverter)}
            >
                <StringPath
                    key={'sp-' + surface.id}
                    planningData={planningData}
                    surfacePlanningData={surface}
                    panelProduct={panelProduct}
                    highestStringIndexes={stringIndexes}
                />
            </StringModuleElement>
        </Provider>
    );
    ReactDOM.render(canvasWrapper, document.getElementById('snapshot'));
    return promise as Promise<Blob>;
};

export const stringPlanningSurfacesToBlob = async (rootState: RootState) => {
    const surfacesWithImage = [];
    const canvases = rootState.designerQuote.planning.canvases;
    const canvasKeys = Object.keys(canvases);

    for (const canvasKey of canvasKeys) {
        for (const surfaceKey of Object.keys(canvases[canvasKey].surfaces)) {
            if (canvases[canvasKey].surfaces[surfaceKey].panels.length > 0) {
                const blob = await takeShotOfSurface(
                    canvases[canvasKey].surfaces[surfaceKey],
                    rootState,
                    canvases[canvasKey]
                );

                surfacesWithImage.push({
                    ...canvases[canvasKey].surfaces[surfaceKey],
                    image: blob,
                });
            }
        }
    }

    return surfacesWithImage;
};
