import { STRING_PLAN_FOLDER } from '../common/constants';
import {
    getAllActiveProducts,
    getRoofInclinations,
    getRoofOrientations,
    isValidProduct,
    normaliseProductArray,
    validateObjectData,
} from '../common/functions';
import { validateNumber } from '../common/helpers';
import {
    BasicSalesForceResponse,
    DeleteFilesResponse,
} from '../common/responseTypes';
import productTypeOrder from '../common/sortOrder';
import {
    AccessTokenFunction,
    AddingStack,
    ConsumptionObject,
    DesignerQuoteState,
    ElectricalServices,
    Package,
    PlanningData,
    Product,
    ProductTypes,
    RootState,
    SalesforceProductsState,
    SalesforceState,
} from '../common/types';
import fetchPackages from './fetchPackages';
import {
    callEigenApiWith,
    callSalesforceApiWith,
    callSalesforceApiWithoutResponse,
} from './index';
import { bulkPostQuoteLineItems } from './salesforceBulk';
import { enrichDependency, productTypesToConsider } from './updateToSalesforce';

const roiValues = {
    reducedAnnualYieldHours: 'Annual_Yield_Hours_Kwh_Kwp__c',
    selfConsumptionKwh: 'Self_Consumption_kWh__c',
    monthlyElectricityCosts: 'currentMonthlyPowerCostOfTheCustomer__c',
    electricityCostKwh: 'Electricity_cost_kWh__c',
    selfConsumptionRate: 'Self_Consumption_Rate__c',
    electricityBoughtAtInitialRate: 'Electricity_Bought_At_Initial_Rate__c',
    degreeOfSelfSufficiency: 'DegreeOfSelfSufficientcyWithoutBattery__c',
};

const consumptionValues: {
    [key in keyof ConsumptionObject]: string;
} = {
    energyConsumptionSaunaKwh: 'Energy_Consumption_Sauna_kWh__c',
    energyConsumptionPoolKwh: 'Energy_Consumption_Pool_kWh__c',
    energyConsumptionHeatPumpKwh: 'Energy_Consumption_Heat_Pump_kWh__c',
    energyConsumptionCarKwh: 'Energy_Consumption_Auto_kWh__c',
    energyConsumptionHouseholdKwh: 'Energy_Consumption_Household_kWh__c',
    energyConsumptionBigConsumersKwh: 'Energy_Consumption_Big_Consumers_kWh__c',
};

export const constructPayload = (
    designerQuoteState: DesignerQuoteState,
    values: { [key: string]: string }
): { [key: string]: number } => {
    const roiPayload: { [key: string]: number } = {};

    Object.keys(values).forEach((key: string) => {
        const roiValue: number = designerQuoteState.salesforce[
            key as keyof SalesforceState
        ] as number;

        if (validateNumber(roiValue)) {
            roiPayload[values[key]] = roiValue;
        }
    });

    return roiPayload;
};

// Get Base Package
const getBasePackage = async (): Promise<Package> => {
    // GET all packages
    const [packages] = await fetchPackages();
    if (!packages) throw new Error('Fetching available packages failed');
    return packages.Base[0];
};

const deleteQuote = async (p: {
    quoteId: string;
    accessToken: AccessTokenFunction;
}): Promise<BasicSalesForceResponse> => {
    return await callSalesforceApiWith<BasicSalesForceResponse>({
        name: 'Quote',
        middleware: [validateObjectData],
        options: {
            method: 'DELETE',
        },
        accessToken: p.accessToken,
    });
};

export const deleteQuoteFiles = async (p: {
    quoteId: string;
    accessToken: AccessTokenFunction;
}) => {
    // Step 1: get the files (with their ID)
    const files = await callEigenApiWith<DeleteFilesResponse>({
        name: 'files',
        middleware: [validateObjectData],
        options: { method: 'GET' },
        filters: [{ name: 'quote_id', value: p.quoteId }],
    });
    // Step 2: delete the files
    return await deleteFiles(files.cloudFiles, p.accessToken);
};

export const deleteStringPlan = async (
    qaReviewId: string,
    accessToken: AccessTokenFunction
) => {
    // Step 1: get the files (with their ID)
    const files = await callEigenApiWith<DeleteFilesResponse>({
        name: 'files',
        middleware: [validateObjectData],
        options: { method: 'GET' },
        filters: [{ name: 'qa_review_id', value: qaReviewId }],
    });
    // Step 2: delete the files
    return await deleteFiles(
        files.cloudFiles.filter(({ folder }) => {
            return folder === STRING_PLAN_FOLDER;
        }),
        accessToken
    );
};

const deleteFiles = async (
    filesToDelete: Array<{ id: string }>,
    accessToken: AccessTokenFunction
) => {
    if (filesToDelete.length === 0) return [];
    // Step 2: delete the files
    const parameters = `?ids=${filesToDelete
        .map(({ id }) => id)
        .join()}&allOrNone=true`;

    return await callSalesforceApiWith<BasicSalesForceResponse[]>({
        name: `sobjects${parameters}`,
        middleware: [validateObjectData],
        options: {
            method: 'DELETE',
        },
        accessToken,
        composite: true,
    });
};

// POST a Quote to Salesforce
export const createQuote = async (p: {
    quote: DesignerQuoteState;
    opportunityId: string;
    accessToken: AccessTokenFunction;
    suitablePackage: Package;
    roiValues: { [key: string]: number };
    consumptionValues: { [key: string]: number };
    planningData: PlanningData;
    status: string;
}): Promise<BasicSalesForceResponse> => {
    const roofInclinations = getRoofInclinations(p.planningData);
    const payload = {
        Name: p.quote.salesforce.name,
        OpportunityId: p.opportunityId,
        RecordTypeId: p.quote.salesforce.recordType?.id,
        Package__c: p.suitablePackage.sfid,
        planning_data__c: JSON.stringify(p.planningData),
        ...p.roiValues,
        ...p.consumptionValues,
        Status: p.status,
        roof_inclination__c: roofInclinations[0] ?? 0,
        roof_orientation__c: getRoofOrientations(p.planningData).join(';'),
    };
    return await callSalesforceApiWith<BasicSalesForceResponse>({
        name: 'Quote',
        middleware: [validateObjectData],
        options: {
            method: 'POST',
            body: JSON.stringify(payload),
        },
        accessToken: p.accessToken,
    });
};

export const updateQuote = async (p: {
    quoteId: string;
    payload: { [key: string]: string | number | boolean };
    accessToken: AccessTokenFunction;
}) => {
    await callSalesforceApiWithoutResponse({
        name: 'Quote',
        id: p.quoteId,
        middleware: [validateObjectData],
        options: {
            method: 'PATCH',
            body: JSON.stringify(p.payload),
        },
        accessToken: p.accessToken,
    });
};

// We need a better way to match the amount of inverters to the correct product, but for now it's based on price
export const getCorrectElectricalServiceBasedOnPrice = (
    electricalServices: ElectricalServices[],
    max: boolean
) => {
    return max
        ? electricalServices.reduce((serviceA, serviceB) =>
              serviceA.price > serviceB.price ? serviceA : serviceB
          )
        : electricalServices.reduce((serviceA, serviceB) =>
              serviceA.price < serviceB.price ? serviceA : serviceB
          );
};

export const getSeparateServices = (
    electricalServices: ElectricalServices[],
    hasSecondInverter: boolean
) => {
    const electricalServiceWithBattery =
        getCorrectElectricalServiceBasedOnPrice(
            electricalServices.filter((service) => service.batteryLabor),
            hasSecondInverter
        );
    const electricalServiceWithoutBattery =
        getCorrectElectricalServiceBasedOnPrice(
            electricalServices.filter((service) => !service.batteryLabor),
            hasSecondInverter
        );

    const serviceWithBattery = {
        ...electricalServiceWithBattery,
        sortOrder: productTypeOrder[ProductTypes.ELECTRICAL_SERVICES],
    };
    const serviceWithoutBattery = {
        ...electricalServiceWithoutBattery,
        sortOrder: productTypeOrder[ProductTypes.ELECTRICAL_SERVICES],
    };
    return [serviceWithBattery, serviceWithoutBattery];
};

export const findDuplicateIndex = (
    productArray: Product[],
    productId: string
) => {
    return productArray.findIndex((product) => product.id === productId);
};

// add product and dependencies when there
export const processOneProduct = (
    stack: AddingStack,
    product: Product,
    productType: ProductTypes,
    panelAmount: number
) => {
    if (productType === ProductTypes.ADDITIONAL_LABOR && product.quantity === 0)
        return stack;

    //Check if there are multiple products that are the same
    const duplicateIndex = findDuplicateIndex(stack.toAdd, product.id);

    const quantity =
        !product.quantity || Number.isNaN(product.quantity)
            ? 1
            : product.quantity;

    duplicateIndex === -1
        ? stack.toAdd.push({
              ...product,
              quantity,
              productCategory: productType,
              sortOrder: productTypeOrder[productType],
          })
        : stack.toAdd[duplicateIndex].quantity!++;

    // add dependencies when there
    product.dependencies &&
        productType !== ProductTypes.SECOND_INVERTER &&
        product.dependencies.forEach((dep) => {
            stack.toAdd.push(
                enrichDependency(productType, dep, product, panelAmount)
            );
        });
    return stack;
};

// Add the product and its dependencies to the toAdd-array
export const addProducts = (stack: AddingStack, productType: ProductTypes) => {
    const product = stack.products[productType] as Product;
    if (!isValidProduct(product)) return stack;
    const panelAmount = stack.products[ProductTypes.PANEL].quantity || 1;
    if (Array.isArray(product)) {
        // fill the stack for every product
        return product.reduce(
            (enhancedStack, product) =>
                processOneProduct(
                    enhancedStack,
                    product,
                    productType,
                    panelAmount
                ),
            stack
        );
    } else {
        // Don't add scaffolding when quantity = 0
        if (
            productType === ProductTypes.ADDITIONAL_LABOR &&
            product.quantity === 0
        )
            return stack;

        // fill the stack and return
        return processOneProduct(stack, product, productType, panelAmount);
    }
};

export const getProductsToPost = (p: {
    products: SalesforceProductsState | undefined;
    productTypesToProcess: ProductTypes[];
    stackFillFunction: (
        stack: AddingStack,
        productType: ProductTypes
    ) => AddingStack;
}): AddingStack => {
    const startStack = { toAdd: [], products: p.products } as AddingStack;
    return !p.products
        ? startStack
        : p.productTypesToProcess.reduce(
              (value, productType) => p.stackFillFunction(value, productType),
              startStack
          );
};

export const saveToSalesforce = async (p: {
    rootState: RootState;
    opportunityId: string;
    accessToken: AccessTokenFunction;
}) => {
    // Step 1: Create Quote
    const basePackage = await getBasePackage();
    const createdQuote = await createQuote({
        quote: p.rootState.designerQuote,
        opportunityId: p.opportunityId,
        accessToken: p.accessToken,
        suitablePackage: basePackage,
        planningData: p.rootState.designerQuote.planning,
        roiValues: constructPayload(p.rootState.designerQuote, roiValues),
        consumptionValues: constructPayload(
            p.rootState.designerQuote,
            consumptionValues as { [key: string]: string }
        ),
        status: p.rootState.designerQuote.salesforce.status,
    });

    if (createdQuote.errors.length > 0) {
        throw new Error(
            `POST to Salesforce failed: ${createdQuote.errors.join(', ')}`
        );
    }

    // Step 2: put all products (with dependencies) in one big array to POST
    const planningProducts: SalesforceProductsState | undefined =
        p.rootState.designerQuote.salesforce.Products;

    const { toAdd } = getProductsToPost({
        products: planningProducts,
        productTypesToProcess: productTypesToConsider,
        stackFillFunction: addProducts,
    });

    const secondInverter =
        p.rootState.designerQuote.salesforce.Products?.secondInverter;

    // Add the right Electrical Service to the bulk post
    const electricalServices = p.rootState.products
        .electricalServices as ElectricalServices[];
    const [serviceWithBattery, serviceWithoutBattery] = getSeparateServices(
        electricalServices,
        !!secondInverter
    );

    const battery = p.rootState.designerQuote.salesforce.Products?.battery;
    if (electricalServices.length > 0) {
        isValidProduct(battery)
            ? toAdd.push(serviceWithBattery)
            : toAdd.push(serviceWithoutBattery);
    }

    // Step 3: bulk post quoteLineItems
    const quoteId = createdQuote.id;
    const [, error] = await bulkPostQuoteLineItems({
        quoteId: quoteId,
        acceptableProducts: normaliseProductArray(getAllActiveProducts(toAdd)),
        accessToken: p.accessToken,
    });
    if (error) {
        try {
            await deleteQuote({
                quoteId: quoteId,
                accessToken: p.accessToken,
            });
        } catch (e) {
            throw new Error(
                `Saving QuotelineItems and deleting the Quote properly: ${error}`
            );
        }
        throw new Error(`Saving QuotelineItems to Salesforce failed: ${error}`);
    }

    return quoteId;
};
