import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import styles from './Square.module.scss';

type Props = {
    style?: CSSProperties;
    className?: string;
    children?: React.ReactNode;
};

/**
 * Will occupy container's width and will have height adjusted to keep square proportions
 */
const Square: FunctionComponent<Props> = ({ className, children }) => {
    return (
        <div className={classNames(className, styles.square)}>{children}</div>
    );
};

export default Square;
