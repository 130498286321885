import React, { FunctionComponent } from 'react';
import { ReactComponent as SvgIcons } from 'solardesigner-icons/public/svgs/es-icons.svg';

const IconHost: FunctionComponent = () => {
    return (
        <div id="svgs" hidden>
            <SvgIcons />
        </div>
    );
};

export default IconHost;
