import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductTypes } from '../../common/types';
import Header from '../Header';
import ProductSelector from '../ProductSelector';
import styles from './PanelManager.module.scss';

type Props = {
    productType: ProductTypes;
};

const PanelManager: FunctionComponent<Props> = ({ productType }) => {
    const { t } = useTranslation(['common']);
    return (
        <>
            <Header>{t('panel type')}</Header>
            <article className={styles.panelSelect}>
                <ProductSelector
                    direction="up"
                    productCategory={productType}
                    hasEmptyOption={false}
                />
            </article>
        </>
    );
};
export default PanelManager;
