import React, { CSSProperties, FunctionComponent } from 'react';
import { useUi } from '../../common/hooks/useUi';
import useUserPermissions from '../../common/hooks/useUserPermissions';
import { ButtonType, Layer, LayerType, VoidHandler } from '../../common/types';
import styles from './LayerGroup.module.scss';
import LayerItem from './LayerItem';
import { SurfaceDetailChangeHandler } from './LayersMenu';
import SurfaceLayerMenu from './SurfaceLayerMenu';

type LayerGroupProps = {
    style?: CSSProperties;
    className?: string;
    name: LayerType;
    layers: Layer[];
    onLayerItemClick: (layerId: string, type: ButtonType) => void;
    onSurfaceDetailChange: SurfaceDetailChangeHandler;
    onRatioClick?: VoidHandler;
};

const LayerGroup: FunctionComponent<LayerGroupProps> = ({
    name,
    layers,
    onLayerItemClick,
    onSurfaceDetailChange,
    onRatioClick,
}) => {
    const permissions = useUserPermissions();
    const [{ activeSurfaceId, lockedSurfaceIds }] = useUi();
    return (
        <>
            {layers.map((layer) => (
                <React.Fragment key={layer.id}>
                    <LayerItem
                        layerDescriptor={layer}
                        hideDeleteButton={!permissions.canManipulateSurfaces}
                        locked={lockedSurfaceIds.includes(layer.id)}
                        active={activeSurfaceId === layer.id}
                        onButtonClick={onLayerItemClick}
                        className={styles.item}
                    />
                    {name === LayerType.surface && (
                        <SurfaceLayerMenu
                            disabled={!permissions.canManipulateSurfaces}
                            data={layer.data}
                            onSurfaceDetailChange={onSurfaceDetailChange}
                            layerId={layer.id}
                            onRatioClick={onRatioClick}
                        />
                    )}
                </React.Fragment>
            ))}
        </>
    );
};

export default LayerGroup;
