import { useEffect } from 'react';
import useOpportunity from './useOpportunity';
import { useUi } from './useUi';
import { updateActiveSurfaceYield } from '../../features/designerQuote/designerQuoteActions';
import useDesignerQuote from './useDesignerQuote';
import { debouncedGetAnnualYieldHours } from '../../api/getAnnualYieldHours';
import { getValidPanelsCount } from '../functions';
import { useAppDispatch } from './index';

export default function useAnnualYieldHours() {
    const opp = useOpportunity();
    const dispatch = useAppDispatch();
    const designerQuote = useDesignerQuote();

    const [{ activeSurfaceId, activeCanvasId }] = useUi();

    useEffect(() => {
        if (activeCanvasId && activeSurfaceId) {
            const activeSurface =
                designerQuote.planning.canvases[activeCanvasId].surfaces[
                    activeSurfaceId
                ];
            if (
                activeSurface &&
                getValidPanelsCount(activeSurface.panels) > 0
            ) {
                debouncedGetAnnualYieldHours(activeSurface, opp.opportunityId)
                    .then(async (promise) => {
                        const result = await promise;
                        if (!result.error) {
                            dispatch(updateActiveSurfaceYield(result.yield));
                        }
                    })
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    .catch(() => {});
            }
        }
    }, [
        activeCanvasId,
        activeSurfaceId,
        designerQuote.planning.canvases[activeCanvasId || '']?.surfaces[
            activeSurfaceId || ''
        ]?.azimuth,
        designerQuote.planning.canvases[activeCanvasId || '']?.surfaces[
            activeSurfaceId || ''
        ]?.slope,
        designerQuote.planning.canvases[activeCanvasId || '']?.surfaces[
            activeSurfaceId || ''
        ]?.yieldReduction,
        designerQuote.planning.canvases[activeCanvasId || '']?.surfaces[
            activeSurfaceId || ''
        ]?.panels,
    ]);

    return (
        designerQuote.planning.canvases[activeCanvasId || '']?.surfaces[
            activeSurfaceId || ''
        ]?.annualYieldHours || 0
    ).toFixed(2);
}
