import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import Icon from '../Icon';
import styles from './OrientationBox.module.scss';

type Props = {
    style?: CSSProperties;
    className?: string;
    azimuth: number;
};

const PanelElement: FunctionComponent<Props> = ({
    style,
    className,
    azimuth,
}) => {
    return (
        <div
            className={classNames(styles.orientation, className)}
            style={style}
        >
            <Icon
                id="orientation"
                stroke={4}
                style={
                    {
                        '--orientation': Number(azimuth - azimuth * 2),
                    } as CSSProperties
                }
            />
        </div>
    );
};

export default PanelElement;
