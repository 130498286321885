import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DEFAULT_MOUNTING_SYSTEM_COLOR } from '../../../common/constants';
import { Direction, RootState, UiState } from '../../../common/types';
import EnergyConsumersForm from '../../EnergyConsumersForm';
import IconWithDropdown from '../../IconWithDropdown';
import ROIForm from '../../ROIForm';
import Scaffolding from '../../Scaffolding';
import Switch from '../../Switch';
import styles from './ROIDropdown.module.scss';

type Props = {
    style?: CSSProperties;
    direction?: Direction;
    badgeContent?: React.ReactNode;
    handleMountingSystemChange: (data: string) => void;
    mountingColor: string;
};

const ROIDropdown: FunctionComponent<Props> = ({
    style,
    direction,
    badgeContent,
    handleMountingSystemChange,
    mountingColor,
}) => {
    const { t } = useTranslation(['productsWizard']);
    const hideEnergyFields = useSelector<RootState, UiState>(
        (state) => state.ui
    ).hideEnergyFields;
    const colorOptions = [
        {
            id: DEFAULT_MOUNTING_SYSTEM_COLOR,
            name: t(DEFAULT_MOUNTING_SYSTEM_COLOR),
        },
        { id: 'Black', name: t('Black') },
    ];

    return (
        <IconWithDropdown
            iconId={'settings'}
            buttonType="sales"
            style={style}
            direction={direction}
            badgeContent={badgeContent}
        >
            <ROIForm />
            <div
                className={classNames(styles.dropdown, {
                    [styles.open]: !hideEnergyFields,
                })}
            >
                <EnergyConsumersForm />
            </div>

            <Scaffolding />
            <hr className={styles.separator} />
            <div className={styles.mounting}>
                <div className={styles.part}>{t('mountingSystem')}</div>
                <div className={styles.part}>
                    <Switch
                        switchValue={mountingColor}
                        switchOptions={colorOptions}
                        handleChange={handleMountingSystemChange}
                        variant="toggle"
                    />
                </div>
            </div>
        </IconWithDropdown>
    );
};

export default ROIDropdown;
