import React, { FunctionComponent } from 'react';
import { List } from 'semantic-ui-react';
import ProductOverview from '../ProductOverview';
import {
    DesignerQuoteState,
    ElectricalCabinetUpgrade,
    Product,
    ProductsMap,
    ProductTypes,
    SalesforceProductsState,
} from '../../common/types';
import {
    dispatchAndSaveToLocalStorage,
    toggleElectricalLabor,
    toggleUpgrade,
} from '../../features/designerQuote/designerQuoteActions';
import { useAppDispatch } from '../../common/hooks';
import { useTranslation } from 'react-i18next';
import Checkbox from '../Checkbox';
import { isProductChecked } from '../../common/functions';
import useUserPermissions from '../../common/hooks/useUserPermissions';

type Props = {
    availableProducts: ProductsMap;
    products: SalesforceProductsState;
    quote: DesignerQuoteState;
    electricalLabor?: Product[];
    upgrades?: Product[];
};

const ElectricalCabinets: FunctionComponent<Props> = ({
    availableProducts,
    products,
    quote,
    upgrades,
    electricalLabor,
}) => {
    const permissions = useUserPermissions();
    const { t } = useTranslation(['productsWizard', 'common']);
    const dispatch = useAppDispatch();
    const handleUpgradeSelection = (upgrade: ElectricalCabinetUpgrade) => {
        dispatch(
            dispatchAndSaveToLocalStorage(
                toggleUpgrade({
                    product: upgrade,
                })
            )
        );
    };
    const recordType = quote.salesforce.recordType;
    if (
        availableProducts.electricalCabinets &&
        availableProducts.electricalCabinets.length > 0
    ) {
        return (
            <List.Item>
                <ProductOverview
                    productType={ProductTypes.ELECTRICAL_CABINETS}
                    productTitle={t('counterCabinet') as string}
                    product={products?.electricalCabinets || []}
                    isOpen={recordType && recordType.name === 'rent'}
                >
                    {upgrades &&
                        permissions.canManipulateElectricalCabinetUpgrades && (
                            <ul>
                                {upgrades.map((upgrade) => {
                                    return (
                                        <li key={upgrade.id}>
                                            <Checkbox
                                                label={upgrade.name}
                                                onChange={() =>
                                                    handleUpgradeSelection(
                                                        upgrade
                                                    )
                                                }
                                                checked={isProductChecked(
                                                    upgrade,
                                                    ProductTypes.ELECTRICAL_CABINET_UPGRADES,
                                                    products
                                                )}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    <hr />
                    {electricalLabor &&
                        permissions.canManipulateElectricalLabor && (
                            <ul>
                                {electricalLabor.map((labor) => {
                                    return (
                                        <li key={labor.id}>
                                            <Checkbox
                                                label={labor.name}
                                                onChange={() =>
                                                    dispatch(
                                                        dispatchAndSaveToLocalStorage(
                                                            toggleElectricalLabor(
                                                                {
                                                                    product:
                                                                        labor,
                                                                }
                                                            )
                                                        )
                                                    )
                                                }
                                                checked={isProductChecked(
                                                    labor,
                                                    ProductTypes.ELECTRICAL_LABOR,
                                                    products
                                                )}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    <hr />
                </ProductOverview>
            </List.Item>
        );
    }
    return null;
};

export default ElectricalCabinets;
