import { callEigenApiWith } from './index';
import { parseSurfacePayload, validateObjectData } from '../common/functions';
import { debounce } from '../common/functions';
import { ModulePlanningData } from '../common/types';

type RealAnnualYieldResponse = {
    error: boolean;
    yield: number;
};

const getAnnualYieldHours = async (
    surface: ModulePlanningData,
    opportunityId: string
) => {
    return await callEigenApiWith<RealAnnualYieldResponse>({
        name: 'annualYieldHours',
        middleware: [validateObjectData],
        options: {
            method: 'POST',
            body: JSON.stringify({
                opportunity_id: opportunityId,
                ...parseSurfacePayload([surface])[0],
            }),
        },
    });
};

export const debouncedGetAnnualYieldHours = debounce(getAnnualYieldHours, 4000);
