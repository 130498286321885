import React, {
    CSSProperties,
    FunctionComponent,
    MouseEventHandler,
} from 'react';
import { useTranslation } from 'react-i18next';
import styles from './VertexPoint.module.scss';

type Props = {
    id: string;
    style?: CSSProperties;
    className?: string;
    x: number;
    y: number;
    scale?: number;
    onClick?: MouseEventHandler;
    onMouseDown?: MouseEventHandler;
};

const VertexPoint: FunctionComponent<Props> = ({
    id,
    x,
    y,
    scale = 1,
    onClick,
    onMouseDown,
}) => {
    const { t } = useTranslation('common');
    return (
        <div
            id={id}
            onClick={onClick}
            onMouseDown={onMouseDown}
            className={styles.root}
            title={t('dragVertex') || ''}
            style={{
                transform: `translate(${x}px,${y}px) scale(${1 / scale})`,
            }}
        />
    );
};

export default VertexPoint;
