import { SelectItem } from './types';

export const slopeOptions: SelectItem[] = [
    {
        key: '0 ',
        value: 0,
        text: ' 0° ',
        sfvalue: '0 degrees (flat roof)',
    },
    {
        key: '5 ',
        value: 5,
        text: ' 5° ',
    },
    {
        key: '10',
        value: 10,
        text: ' 10°',
    },
    {
        key: '15',
        value: 15,
        text: '15° ',
    },
    {
        key: '20',
        value: 20,
        text: ' 20° ',
    },
    {
        key: '25',
        value: 25,
        text: ' 25°',
    },
    {
        key: '30',
        value: 30,
        text: ' 30°',
    },
    {
        key: '35',
        value: 35,
        text: ' 35° ',
    },
    {
        key: '40',
        value: 40,
        text: ' 40°',
    },
    {
        key: '45',
        value: 45,
        text: ' 45°',
    },
    {
        key: '50',
        value: 50,
        text: ' 50°',
    },
];

export const azimuthOptions: SelectItem[] = [
    {
        key: '-90 ',
        value: -90,
        text: '→ O',
        description: '-90°',
        sfvalue: 'East',
    },
    {
        key: '-67,5',
        value: -67.5,
        text: '↘ OSO',
        description: '-67,5°',
        sfvalue: 'South-East',
    },
    {
        key: '-45',
        value: -45,
        text: '↘ SO',
        description: '-45°',
        sfvalue: 'South-East',
    },
    {
        key: '-22,5',
        value: -22.5,
        text: '↘ SSO',
        description: '-22,5°',
        sfvalue: 'South-East',
    },
    {
        key: '0',
        value: 0,
        text: '↓ S',
        description: '0°',
        sfvalue: 'South',
    },
    {
        key: '22,5',
        value: 22.5,
        text: '↙ SSW',
        description: '22,5°',
        sfvalue: 'South-West',
    },
    {
        key: '45',
        value: 45,
        text: '↙ SW',
        description: '45°',
        sfvalue: 'South-West',
    },
    {
        key: '67,5',
        value: 67.5,
        text: '↙ WSW',
        description: '67,5°',
        sfvalue: 'South-West',
    },
    {
        key: '90',
        value: 90,
        text: '← W',
        description: '90°',
        sfvalue: 'West',
    },
    {
        key: '112,5',
        value: 112.5,
        text: '↖ WNW',
        description: '112,5°',
        sfvalue: 'North-West',
    },
    {
        key: '135',
        value: 135,
        text: '↖ NW',
        description: '135°',
        sfvalue: 'North-West',
    },
    {
        key: '157,5',
        value: 157.5,
        text: '↖ NNW',
        description: '157,5°',
        sfvalue: 'North-West',
    },
    {
        key: '180',
        value: 180,
        text: '↑ N',
        description: '180°',
        sfvalue: 'North',
    },
    {
        key: '-157,5 ',
        value: -157.5,
        text: '↗ NNO',
        description: '-157.5°',
        sfvalue: 'North-East',
    },
    {
        key: '-135 ',
        value: -135,
        text: '↗ NO',
        description: '-135°',
        sfvalue: 'North-East',
    },
    {
        key: '-112,5 ',
        value: -112.5,
        text: '↗ ONO',
        description: '-112,5°',
        sfvalue: 'North-East',
    },
];

export const gutterOptionsSlope: SelectItem[] = [
    {
        key: '- keiner -',
        value: 0,
        text: '- keiner -',
    },
    {
        key: '200 mm',
        value: 200,
        text: '200 mm',
    },
    {
        key: '500 mm',
        value: 500,
        text: '500 mm',
    },
];

export const gutterOptionsFlat: SelectItem[] = [
    {
        key: '- keiner -',
        value: 0,
        text: '- keiner -',
    },
    {
        key: '600 mm',
        value: 600,
        text: '600 mm',
    },
];
