import classNames from 'classnames';
import React, { FunctionComponent, MouseEventHandler } from 'react';
import { Message } from 'semantic-ui-react';
import { Notification } from '../../common/types';
import Icon from '../Icon';
import styles from './NotificationElement.module.scss';

type Props = Notification & {
    onDismiss?: MouseEventHandler;
};

const NotificationElement: FunctionComponent<Props> = ({
    type = 'info',
    message,
    details,
    icon = 'gear',
    spinning = false,
    onDismiss,
    children,
}) => {
    return (
        <Message {...{ [type]: true }} className={styles.message}>
            <Icon
                id={icon}
                className={classNames(styles.icon, {
                    [styles.spinning]: spinning,
                })}
                size={'small'}
                stroke={10}
            />
            {message && <div className={styles.header}>{message}</div>}
            {details && <div className={styles.content}>{details}</div>}
            {children}
            {onDismiss && (
                <Icon
                    id={'cross'}
                    className={styles.close}
                    stroke={15}
                    onClick={onDismiss}
                />
            )}
        </Message>
    );
};

export default NotificationElement;
