import { RootState, UserRolesMap } from '../types';

let getState: (() => RootState) | undefined;

export const initRoles = (getStateFunc: () => RootState) => {
    getState = getStateFunc;
};

export const byRole = (content: UserRolesMap) => {
    if (!getState || !Object.keys(content).length) return;

    const currentRole = getState().user.role;

    return content[currentRole];
};
