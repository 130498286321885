import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { addProducts, getProductsToPost } from '../../api/saveToSalesforce';
import { productTypesToConsider } from '../../api/updateToSalesforce';
import {
    Product,
    ProductTypes,
    RootState,
    SalesforceProductsState,
} from '../types';

import {
    getAllNonActiveProducts,
    getAllNonVisibleProducts,
    getAllProductsInSubset,
    getAllProductsNotInSubset,
    isAllProductsActive,
    isAllProductsVisible,
} from '../functions/';

const salesAvailableProducts = [
    ProductTypes.BATTERY,
    ProductTypes.INVERTER,
    ProductTypes.PANEL,
    ProductTypes.WALLBOXES,
];

export default function useProductAvailability(): {
    isAllProductsActive: boolean;
    isAllProductsVisible: boolean;
    isAllSalesActiveAndVisible: boolean;
    isAllNonSalesActiveAndVisible: boolean;
    nonActiveProducts: Product[];
    nonVisibleProducts: Product[];
} {
    const [state, setState] = useState<{
        isAllProductsActive: boolean;
        isAllProductsVisible: boolean;
        isAllSalesActiveAndVisible: boolean;
        isAllNonSalesActiveAndVisible: boolean;
        nonActiveProducts: Product[];
        nonVisibleProducts: Product[];
    }>({
        isAllProductsActive: true,
        isAllProductsVisible: true,
        isAllSalesActiveAndVisible: true,
        isAllNonSalesActiveAndVisible: true,
        nonActiveProducts: [],
        nonVisibleProducts: [],
    });

    const products = useSelector<
        RootState,
        SalesforceProductsState | undefined
    >((state) => state.designerQuote.salesforce.Products);

    useEffect(() => {
        const { toAdd } = getProductsToPost({
            products,
            productTypesToProcess: productTypesToConsider,
            stackFillFunction: addProducts,
        });

        const allProductsNotConfigurableInSales = getAllProductsNotInSubset(
            toAdd,
            salesAvailableProducts
        );

        const allProductsConfigurableInSales = getAllProductsInSubset(
            toAdd,
            salesAvailableProducts
        );

        setState({
            isAllProductsActive: isAllProductsActive(toAdd),
            isAllProductsVisible: isAllProductsVisible(toAdd),
            isAllSalesActiveAndVisible:
                isAllProductsActive(allProductsConfigurableInSales) &&
                isAllProductsVisible(allProductsConfigurableInSales),
            isAllNonSalesActiveAndVisible:
                isAllProductsActive(allProductsNotConfigurableInSales) &&
                isAllProductsVisible(allProductsNotConfigurableInSales),
            nonActiveProducts: getAllNonActiveProducts(toAdd),
            nonVisibleProducts: getAllNonVisibleProducts(toAdd),
        });
    }, [products]);

    return state;
}
