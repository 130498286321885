import i18next from 'i18next';
import React, { CSSProperties, useCallback, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SURFACE_SIZE_MAX, SURFACE_SIZE_MIN } from '../../common/constants';
import {
    getSideDirectionsByAzimuth,
    panelsInCanvas,
} from '../../common/functions';
import { useAppDispatch } from '../../common/hooks';
import useDesignerQuote from '../../common/hooks/useDesignerQuote';
import usePanelCount from '../../common/hooks/usePanelCount';
import useProductAvailability from '../../common/hooks/useProductAvailability';
import { useUi } from '../../common/hooks/useUi';
import Hotkeys from '../../common/hotkeys';
import { azimuthOptions } from '../../common/selectOptions';
import {
    ButtonType,
    Canvas,
    Layer,
    LayerType,
    OpportunityState,
    Panel,
    RoofTypes,
    RootState,
    SurfaceInput,
} from '../../common/types';
import {
    addPanel,
    addPhotos,
    deleteCanvas,
    deleteSurface,
    dispatchAndSaveToLocalStorage,
    removePanels,
    rotatePanels,
    togglePanelFacing,
    togglePanelsVisibility,
    toggleSurfaceGutter,
    toggleSurfaceRatio,
    updateSurface,
} from '../../features/designerQuote/designerQuoteActions';
import { closeModal, openModal } from '../../features/modals/modalActions';
import { newNotification } from '../../features/notifications/notificationsActions';
import {
    toggleGridVisibility,
    toggleLockAllSurfaces,
} from '../../features/ui/uiActions';
import Button from '../Button';
import Float from '../Float';
import PictureSelector from '../PictureSelector';
import PlanningDrawer from '../PlanningDrawer/PlanningDrawer';
import PlanningInfo from '../PlanningInfo';
import ProductsWizard from '../ProductsWizard';
import ShortcutMenu from '../ShortcutMenu';
import SideBar from '../SideBar';
import Square from '../Square';
import Stack from '../Stack';
import UndoRedo from '../UndoRedo';
import WarningDropdown from '../WarningDropdown';
import styles from './PlannerUi.module.scss';

const floatStyle: CSSProperties = {
    pointerEvents: 'all',
    display: 'flex',
};

const PlannerUi: (p: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    updateUi?: Function;
    // eslint-disable-next-line @typescript-eslint/ban-types
    rotatePanels?: Function;
    // eslint-disable-next-line @typescript-eslint/ban-types
    removePanels?: Function;
    // eslint-disable-next-line @typescript-eslint/ban-types
    togglePanelsVisibility?: Function;
}) => JSX.Element = (p) => {
    const { t } = useTranslation(['notifications', 'modals']);
    const quote = useDesignerQuote();
    const { isAllProductsActive, isAllProductsVisible } =
        useProductAvailability();
    const [
        {
            activeCanvasId,
            activeSurfaceId,
            isShapeBuilderOn,
            selectedPanelIndices,
            lockedSurfaceIds,
            isDrawPanelsOn,
            hideGrids,
        },
        updateUi,
    ] = useUi();
    const mainUpdateUi = p.updateUi || updateUi;

    const opportunity = useSelector<RootState, OpportunityState | undefined>(
        (state) => state.opportunity
    );
    const photos = opportunity?.photos || [];
    const { canvases } = quote.planning;
    const photoIds = Object.keys(canvases).map(
        (id) => canvases[id].imageBox.id
    );
    const activeCanvas = activeCanvasId ? canvases[activeCanvasId] : undefined;

    const isFlatRoofMode =
        (activeCanvasId &&
            activeSurfaceId &&
            quote.planning.canvases[activeCanvasId].surfaces[activeSurfaceId]
                .roofType === RoofTypes.flat) ||
        false;
    const activeHasPanels =
        (activeCanvasId &&
            activeSurfaceId &&
            quote.planning.canvases[activeCanvasId].surfaces[activeSurfaceId]
                .panels.length > 0) ||
        false;

    const [isProductWizardOpen, setIsProductWizardOpen] = useState(false);

    const [openProductWizardInstantly, setProductWizardOpenInstantly] =
        useState(false);

    let surfaceLayers: Layer[] = [];

    if (activeCanvas) {
        let offset = 0;
        for (const canvas of Object.values(canvases)) {
            if (canvas.id === activeCanvas.id) {
                break;
            }
            offset += Object.values(canvas.surfaces).length;
        }
        surfaceLayers = Object.keys(activeCanvas.surfaces).map(
            (surfaceId: string, i) => {
                return {
                    name: 'surface ' + (offset + i + 1),
                    data: activeCanvas.surfaces[surfaceId],
                    id: surfaceId,
                };
            }
        );
    }
    const dispatch = useAppDispatch();
    const handleAddPanelClick = useCallback(() => {
        dispatch(dispatchAndSaveToLocalStorage(addPanel({ data: {} })));
    }, []);
    const handleDrawPanelsClick = useCallback(() => {
        const activeSurface = activeSurfaceId
            ? activeCanvas?.surfaces[activeSurfaceId]
            : undefined;
        if (
            !isDrawPanelsOn &&
            activeSurface &&
            (!activeSurface.width || !activeSurface.height)
        ) {
            dispatch(
                newNotification({
                    type: 'error',
                    message: i18next.t(
                        'notifications:surfaceDimensionsMissing'
                    ),
                })
            );
            return;
        }

        if (
            !isDrawPanelsOn &&
            activeSurface &&
            activeSurface.azimuth === undefined
        ) {
            dispatch(
                newNotification({
                    type: 'error',
                    message: i18next.t('notifications:azimuthIsMissing'),
                })
            );
            return;
        }
        mainUpdateUi({ isDrawPanelsOn: !isDrawPanelsOn });
    }, [isDrawPanelsOn, activeSurfaceId, activeCanvas]);
    const handleRotatePanelsClick = useCallback(() => {
        p.rotatePanels
            ? p.rotatePanels({ data: {} })
            : dispatch(
                  dispatchAndSaveToLocalStorage(rotatePanels({ data: {} }))
              );
    }, []);
    const handleRemovePanelsClick = useCallback(() => {
        p.removePanels
            ? p.removePanels({ data: {} })
            : dispatch(
                  dispatchAndSaveToLocalStorage(removePanels({ data: {} }))
              );
    }, [selectedPanelIndices]);
    const handleShowHidePanelsClick = useCallback(() => {
        p.togglePanelsVisibility
            ? p.togglePanelsVisibility({ data: {} })
            : dispatch(
                  dispatchAndSaveToLocalStorage(
                      togglePanelsVisibility({ data: {} })
                  )
              );
    }, [selectedPanelIndices]);

    const handleSurfaceDetailsChange = (
        layerId: string,
        propName: SurfaceInput,
        newValue: string | number | RoofTypes
    ) => {
        let parsedValue: any;

        if (
            [SurfaceInput.roofType, SurfaceInput.flatRoofMode].includes(
                propName
            )
        ) {
            parsedValue = newValue.toString();
        } else {
            parsedValue = Number(newValue);

            if ([SurfaceInput.width, SurfaceInput.height].includes(propName)) {
                if (parsedValue > SURFACE_SIZE_MAX)
                    parsedValue = SURFACE_SIZE_MAX;
                else if (parsedValue < SURFACE_SIZE_MIN)
                    parsedValue = SURFACE_SIZE_MIN;
            }
        }

        dispatch(
            dispatchAndSaveToLocalStorage(
                updateSurface({
                    surfaceId: layerId,
                    data: { [propName]: parsedValue },
                })
            )
        );

        if (propName === SurfaceInput.azimuth) {
            dispatch(
                dispatchAndSaveToLocalStorage(
                    updateSurface({
                        surfaceId: layerId,
                        data: {
                            [SurfaceInput.sideDirections]:
                                getSideDirectionsByAzimuth(
                                    parsedValue,
                                    azimuthOptions
                                ),
                        },
                    })
                )
            );
        }

        if (propName === SurfaceInput.surfaceGutter) {
            dispatch(toggleSurfaceGutter({}));
        }
    };
    const handleCanvasClick = (id: string) =>
        mainUpdateUi({
            activeCanvasId: id,
        });

    const handleToggleGridVisibility = () => {
        dispatch(toggleGridVisibility({}));
    };
    const handleToggleVisibility = (id: string) => {
        console.log('hide this: ', id);
    };

    const handleLockSurface = (id: string) => {
        const updatedLockedSurfaceIds = lockedSurfaceIds.includes(id)
            ? lockedSurfaceIds.filter((sid) => sid !== id)
            : [...lockedSurfaceIds, id];
        mainUpdateUi({
            lockedSurfaceIds: updatedLockedSurfaceIds,
            activeSurfaceId: undefined,
        });
    };
    const handleDeleteSurface = (id: string) => {
        dispatch(
            dispatchAndSaveToLocalStorage(deleteSurface({ surfaceId: id }))
        );
    };
    const handleSurfaceSelect = (id: string) => {
        lockedSurfaceIds.includes(id)
            ? mainUpdateUi({
                  lockedSurfaceIds: lockedSurfaceIds.filter(
                      (sid) => sid !== id
                  ),
                  activeSurfaceId: id,
              })
            : mainUpdateUi({
                  activeSurfaceId: id === activeSurfaceId ? undefined : id,
                  isShapeBuilderOn: false,
              });
    };

    const handleAddSurfaceClick = () => {
        mainUpdateUi({
            activeSurfaceId: undefined,
            isShapeBuilderOn: !isShapeBuilderOn,
        });
    };

    const handleLockAllSurfaces = () => {
        dispatch(toggleLockAllSurfaces({}));
    };

    const handleFlipPanelsFacingClick = () => {
        dispatch(
            dispatchAndSaveToLocalStorage(togglePanelFacing({ data: {} }))
        );
    };

    const handleRatioClick = () => {
        dispatch(
            dispatchAndSaveToLocalStorage(toggleSurfaceRatio({ data: {} }))
        );
    };

    // Handle hotkeys
    useHotkeys(Hotkeys.TOGGLE_GRID.hook, handleToggleGridVisibility);

    useHotkeys(Hotkeys.DELETE_PANEL.hook, handleRemovePanelsClick, {}, [
        selectedPanelIndices,
    ]);

    useHotkeys(
        Hotkeys.DELETE_SURFACE.hook,
        () => {
            if (activeSurfaceId) {
                handleDeleteSurface(activeSurfaceId);
            }
        },
        {},
        [activeSurfaceId]
    );

    useHotkeys(Hotkeys.HIDE_PANEL.hook, handleShowHidePanelsClick, {}, [
        selectedPanelIndices,
    ]);

    useHotkeys(Hotkeys.ROTATE_PANELS.hook, handleRotatePanelsClick);

    useHotkeys(Hotkeys.TOGGLE_BUILD_TOOL.hook, handleAddSurfaceClick, {}, [
        isShapeBuilderOn,
    ]);

    useHotkeys(Hotkeys.TOGGLE_DRAG_TOOL.hook, handleDrawPanelsClick, {}, [
        isDrawPanelsOn,
        activeSurfaceId,
        activeCanvas,
    ]);

    useHotkeys(Hotkeys.ADD_PANEL.hook, handleAddPanelClick);

    useHotkeys(Hotkeys.LOCK_ALL_SURFACES.hook, handleLockAllSurfaces);

    const handleLayerItemClick = (id: string, buttonType: ButtonType) => {
        switch (buttonType) {
            case ButtonType.visibility:
                return handleToggleVisibility(id);
            case ButtonType.lock:
                return handleLockSurface(id);
            case ButtonType.delete:
                return handleDeleteSurface(id);
            case ButtonType.label:
                return handleSurfaceSelect(id);
            default:
                return;
        }
    };

    const deleteCanvasAndClose = (id: string) => {
        dispatch(deleteCanvas({ data: { canvasId: id } }));
        dispatch(closeModal());
    };

    const closeTheModal = () => dispatch(closeModal());

    const handleDeleteClick = (id: string) => {
        // check if the canvas is empty:
        const description =
            Object.keys(canvases[id].surfaces).length > 0
                ? t('modals:deleteCanvas.description.nonEmpty')
                : t('modals:deleteCanvas.description.empty');
        dispatch(
            openModal({
                modalProps: {
                    title: t('modals:deleteCanvas.title'),
                    description: description,
                    buttons: [
                        {
                            text: t('modals:deleteCanvas.cancel') as string,
                            type: 'negative',
                            onClick: closeTheModal,
                        },
                        {
                            text: t('modals:deleteCanvas.confirm') as string,
                            type: 'positive',
                            onClick: () => deleteCanvasAndClose(id),
                        },
                    ],
                    onModalClose: closeTheModal,
                    size: 'large',
                },
            })
        );
    };

    const handleClickPictureSelector = (id: string) => {
        if (!photoIds.includes(id)) {
            dispatch(
                addPhotos({
                    data: [photos.find((photo) => photo.id === id)],
                })
            );
            dispatch(closeModal());
        }
    };

    const handleAddPictureClick = () => {
        dispatch(
            openModal({
                modalProps: {
                    title: t('modals:addPicture.title'),
                    description: t('modals:addPicture.description'),
                    buttons: [
                        {
                            text: t('modals:addPicture.cancel') as string,
                            type: 'negative',
                            onClick: closeTheModal,
                        },
                    ],
                    onModalClose: closeTheModal,
                    size: 'large',
                    children: (
                        <PictureSelector
                            photos={photos}
                            selectedPictureIds={[]}
                            onPictureClick={handleClickPictureSelector}
                            disabledPictureIds={photoIds}
                        />
                    ),
                },
            })
        );
    };

    const handleProductsWizardSidebarChange = (open: boolean) => {
        setIsProductWizardOpen(open);
    };

    const handleProductsWizardSidebarFocus = (open: boolean) => {
        setProductWizardOpenInstantly(open);
        setIsProductWizardOpen(open);
    };
    usePanelCount();
    return (
        <>
            <div className={styles.sideBarsWrapper}>
                <SideBar className={styles.leftBar}>
                    <PlanningDrawer
                        canvases={Object.values(canvases)}
                        activeCanvas={activeCanvas}
                        onCanvasClick={handleCanvasClick}
                        onAddPictureClick={handleAddPictureClick}
                        onDeleteClick={handleDeleteClick}
                        disableAddPanel={!activeSurfaceId}
                        disableDrawPanels={!activeSurfaceId}
                        disableRotatePanels={!selectedPanelIndices.length}
                        disableRemovePanels={!selectedPanelIndices.length}
                        layersGroups={[
                            {
                                name: LayerType.surface,
                                layers: surfaceLayers,
                            },
                        ]}
                        onLayerItemClick={handleLayerItemClick}
                        onAddSurfaceClick={handleAddSurfaceClick}
                        onToggleGridVisibility={handleToggleGridVisibility}
                        onSurfaceDetailChange={handleSurfaceDetailsChange}
                        onAddPanelClick={handleAddPanelClick}
                        onDrawPanelsClick={handleDrawPanelsClick}
                        onRotatePanelsClick={handleRotatePanelsClick}
                        onRemovePanelsClick={handleRemovePanelsClick}
                        onShowHidePanelsClick={handleShowHidePanelsClick}
                        isFlatRoofMode={isFlatRoofMode}
                        onFlipPanelsFacingClick={handleFlipPanelsFacingClick}
                        hasPanels={activeHasPanels}
                        onRatioClick={handleRatioClick}
                    />
                </SideBar>
                <SideBar
                    defaultHidden={true}
                    placement={'right'}
                    full={true}
                    onChange={handleProductsWizardSidebarChange}
                    onFocus={() => handleProductsWizardSidebarFocus(true)}
                    openInstantly={openProductWizardInstantly}
                >
                    <ProductsWizard open={isProductWizardOpen} />
                </SideBar>
            </div>
            <Float top right>
                {(!isAllProductsActive || !isAllProductsVisible) && (
                    <WarningDropdown className={styles.button} />
                )}
            </Float>
            <Float bottom right>
                <PlanningInfo
                    totalPanelAmount={
                        quote.salesforce.Products?.panels?.quantity || 0
                    }
                    canvasPanelAmount={panelsInCanvas(activeCanvas as Canvas)}
                    panel={quote.salesforce.Products?.panels as Panel}
                />
            </Float>
            <Float bottom left style={floatStyle}>
                <Stack orientation="vertical">
                    <Square className={styles.showGridSquare}>
                        <Button
                            icon={'grid'}
                            iconActive={'grid-off'}
                            active={hideGrids}
                            className={styles.button}
                            onClick={handleToggleGridVisibility}
                            type={'sales'}
                        />
                    </Square>
                    <Stack orientation="horizontal">
                        <UndoRedo />
                        <ShortcutMenu />
                    </Stack>
                </Stack>
            </Float>
        </>
    );
};

export default PlannerUi;
