import React, { FunctionComponent } from 'react';
import { Item } from 'semantic-ui-react';
import { SalesforceState, SortType } from '../../common/types';
import PlanItem from '../PlanItem';

type Props = {
    quotes: SalesforceState[];
    // eslint-disable-next-line @typescript-eslint/ban-types
    onClick: Function;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onDoubleClick: Function;
    activeId?: string;
    className?: string;
    sortOrder: SortType;
};

const PlanItemGroup: FunctionComponent<Props> = ({
    quotes,
    onClick,
    onDoubleClick,
    activeId,
    className,
    sortOrder,
}) => {
    return (
        <Item.Group link className={className}>
            {quotes.map((quote) => (
                <PlanItem
                    quoteName={quote.name!}
                    quoteId={quote.id}
                    key={quote.id}
                    onClick={() => {
                        onClick(quote);
                    }}
                    onDoubleClick={() => {
                        onDoubleClick(quote);
                    }}
                    active={activeId === quote.id}
                    panel={quote.Products?.panels}
                    battery={quote.Products?.battery}
                    recordType={quote.recordType?.name}
                    status={quote.status}
                    isSyncing={quote.isSyncing}
                    modificationDetails={quote.modificationDetails}
                    modifiedBy={quote.lastModifiedBy}
                    sortOrder={sortOrder}
                />
            ))}
        </Item.Group>
    );
};

export default PlanItemGroup;
