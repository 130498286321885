import classNames from 'classnames';
import React, {
    CSSProperties,
    FunctionComponent,
    MouseEventHandler,
} from 'react';
import { UseCases } from '../../common/types';
import styles from './Icon.module.scss';

type Props = {
    id: string;
    className?: string;
    style?: CSSProperties;
    stroke?: number;
    filled?: boolean;
    size?: 'small' | 'medium' | 'big';
    onClick?: MouseEventHandler;
    useCase?: UseCases;
};

const Icon: FunctionComponent<Props> = ({
    id,
    className = '',
    style,
    stroke,
    filled,
    size,
    onClick,
    useCase,
}) => {
    const strokeStyle = { '--stroke-width': stroke } as CSSProperties;

    return (
        <svg
            onClick={onClick}
            className={classNames(
                'sdIcon',
                className,
                useCase && styles[useCase],
                `${styles.esIcon} icon-${id}`,
                {
                    [styles.filled]: filled,
                    [styles.big]: size === 'big',
                    [styles.medium]: size === 'medium',
                    [styles.small]: size === 'small',
                }
            )}
            data-stroke={stroke}
            style={stroke ? { ...strokeStyle, ...style } : style}
        >
            <use xlinkHref={'#icon-' + id} />
        </svg>
    );
};

export default Icon;
