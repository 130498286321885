import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Form as SemanticForm,
    FormInputProps,
    Label,
    LabelProps,
} from 'semantic-ui-react';
import Checkbox from '../Checkbox';
import Input from '../Input';
import styles from './FormInput.module.scss';

type Props = {
    onClick?: (event: React.MouseEvent, data: any) => void;
    onChange?: (event: React.ChangeEvent, data: any) => void;
    onBlur?: (event: React.FocusEvent, data: any) => void;
    formInput: FormInputProps;
    id: string | undefined;
    sales?: boolean;
    className?: string;
};

const defaultInputs: FormInputProps = {
    errorMessage: 'Default Error Message',
    errorMessagePosition: 'left',
    value: '',
    placeholder: '',
    size: 'large',
    label: false,
    labelPosition: 'right',
    validation: () => true,
    error: false,
    heading: true,
    inline: true,
    style: null,
    disabled: false,
};

const FormInput: FunctionComponent<Props> = ({
    onClick,
    onChange,
    onBlur,
    formInput,
    id,
    className,
}) => {
    const {
        value,
        placeholder,
        validation,
        errorMessage,
        errorMessagePosition,
        size,
        label,
        heading,
        type,
        input,
        style,
        disabled,
    }: FormInputProps = {
        ...defaultInputs,
        ...formInput,
    };

    const [error, setError] = useState(formInput.error);
    const validate = (val: string) => setError(!validation(val));
    const [val, setValue] = useState(value);

    useEffect(() => {
        setValue(value);
    }, [value]);

    let child;

    switch (type) {
        case 'boolean':
            child = (
                <Checkbox
                    id={id}
                    key={id}
                    checked={value}
                    onChange={(event: any, data) => {
                        onBlur && onBlur(event, data);
                    }}
                    className={styles.checkbox}
                    label={label && (label as LabelProps).content}
                />
            );
            break;

        case 'info':
            child = (
                <>
                    <span className={styles.value}>{val}</span>
                    {label && (label as LabelProps).content}
                </>
            );
            break;

        default:
            child = (
                <div className={styles.inputWrapper}>
                    <Input
                        input={input}
                        type={type}
                        error={error}
                        value={val}
                        size={size}
                        onChange={(event, data) => {
                            onChange && validate(data.value);
                            onChange && onChange(event, data);
                            setValue(data.value);
                        }}
                        onBlur={(event, data) => {
                            onBlur && validate(data.value);
                            onBlur && onBlur(event, data);
                        }}
                        id={id}
                        style={style}
                        disabled={disabled}
                    />
                    {label && (label as LabelProps).content}
                </div>
            );
            break;
    }

    return (
        <SemanticForm.Field
            className={classNames('formInput', className, {
                [styles.disabled]: disabled,
            })}
            onClick={onClick}
        >
            {heading && placeholder && <div>{placeholder}</div>}
            <div
                className={classNames({
                    [styles.checkboxWrapper]: type === 'boolean',
                    [styles.info]: type === 'info',
                })}
            >
                {child}
            </div>
            {error && (
                <Label basic color="red" pointing={errorMessagePosition}>
                    {errorMessage}
                </Label>
            )}
        </SemanticForm.Field>
    );
};

export default FormInput;
