import { default as React, FunctionComponent, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch } from 'react-redux';
import useDesignerQuote from '../../../common/hooks/useDesignerQuote';
import useProductAvailability from '../../../common/hooks/useProductAvailability';
import { useUi } from '../../../common/hooks/useUi';
import Hotkeys from '../../../common/hotkeys';
import { ObjectWithNameAndId } from '../../../common/types';
import { toggleGridVisibility } from '../../../features/ui/uiActions';
import Float from '../../Float';
import WarningDropdown from '../../WarningDropdown';
import { getNumberOfStrings, getStringOptions } from '../functions';
import StringDrawer from '../StringDrawer';
import styles from './StringUi.module.scss';

const StringUi: FunctionComponent = () => {
    const quote = useDesignerQuote();
    const [{ stringKey, isStringToolActive }, updateUi] = useUi();
    const dispatch = useDispatch();

    const handleToggleGridVisibility = () => {
        dispatch(toggleGridVisibility({}));
    };

    const { isAllProductsActive, isAllProductsVisible } =
        useProductAvailability();

    const handleStringToolClick = () => {
        const newStringKey = stringKey === 'none' ? 'a' : stringKey;

        updateUi({
            stringKey: newStringKey,
            isStringToolActive: !isStringToolActive,
        });
        setStringValue(newStringKey);
    };

    // Handle hotkeys
    useHotkeys(Hotkeys.TOGGLE_GRID.hook, handleToggleGridVisibility);

    // TODO: to be optimized
    const [stringValue, setStringValue] = useState(stringKey || 'all');
    const stringOptions: ObjectWithNameAndId[] = [
        ...getStringOptions(getNumberOfStrings(quote.salesforce.Products)),
    ];

    const handleStringChange = (string: any) => {
        updateUi({
            stringKey: string,
            isStringToolActive: string !== 'all',
        });
        setStringValue(string || 'all');
    };

    return (
        <>
            <div className={styles.stringUi}>
                <StringDrawer
                    onStringToolClick={handleStringToolClick}
                    stringValue={stringValue}
                    stringOptions={stringOptions}
                    onStringChange={handleStringChange}
                    onStringClick={handleStringChange}
                />
            </div>
            <Float right top>
                {(!isAllProductsActive || !isAllProductsVisible) && (
                    <WarningDropdown />
                )}
            </Float>
            <Float left bottom className={styles.undoRedo}>
                {/* <UndoRedo /> */}
            </Float>
            <Float top right>
                {(!isAllProductsActive || !isAllProductsVisible) && (
                    <WarningDropdown />
                )}
            </Float>
        </>
    );
};

export default StringUi;
