import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent, ReactNode } from 'react';
import { List } from 'semantic-ui-react';
import Button from '../Button';
import Header from '../Header';
import styles from './Prompt.module.scss';

type Props = {
    title?: string;
    right?: boolean;
    description: string;
    style?: CSSProperties;
    className?: string;
    onClickAffirm?: React.MouseEventHandler<HTMLButtonElement>;
    onClickCancel?: React.MouseEventHandler<HTMLButtonElement>;
    affirmButtonDisabled?: boolean;
    dialogChildren?: ReactNode;
    additionalButton?: ReactNode;
    children?: React.ReactNode;
};

const Prompt: FunctionComponent<Props> = ({
    title,
    description,
    style,
    className = '',
    right,
    onClickCancel,
    onClickAffirm,
    affirmButtonDisabled,
    children,
    dialogChildren,
    additionalButton,
}) => {
    return (
        <div
            style={style}
            className={classNames(styles.root, className, {
                [styles.right]: right,
            })}
        >
            {title && (
                <Header className={'title'} variant={'h3'}>
                    {title}
                </Header>
            )}
            {description}
            {children}
            <List className="buttons dialog">
                {onClickAffirm && (
                    <List.Item>
                        <Button
                            variant={'primary'}
                            onClick={onClickAffirm}
                            disabled={affirmButtonDisabled}
                            icon="check"
                        >
                            OK
                        </Button>
                    </List.Item>
                )}
                {onClickCancel && (
                    <List.Item>
                        <Button variant={'secondary'} onClick={onClickCancel}>
                            Abbrechen
                        </Button>
                    </List.Item>
                )}
                {dialogChildren && (
                    <List.Item className={styles.dialogChildren}>
                        {dialogChildren}
                    </List.Item>
                )}
            </List>
        </div>
    );
};

export default Prompt;
