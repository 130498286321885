import { callEigenApiWith } from '.';
import {
    debounce,
    parseSurfacePayload,
    validateObjectData,
} from '../common/functions';
import {
    ModulePlanningData,
    RecordType,
    SurfacePayload,
    UserRoles,
} from '../common/types';

type SystemCharacteristicsResponse = {
    is_error: boolean;
    output_by_direction: { [direction: string]: number };
    yield_by_direction: { [direction: string]: number };
    without_battery: IndependenceEvaluationArray;
    with_battery?: IndependenceEvaluationArray;
    total_yield: number;
    yields_by_surface: {
        [key: string]: number;
    };
};

type IndependenceEvaluationArray = {
    self_sufficiency: number;
    self_sufficiency_rate: number;
    self_sufficiency_remainder: number;
    self_sufficiency_remainder_rate: number;
    grid_feed: number;
    grid_feed_rate: number;
    own_consumption: number;
    own_consumption_rate: number;
};

export const getSystemCharacteristics = async (p: {
    opportunityId: string;
    surfaces: ModulePlanningData[];
    panelPower: number;
    batteryPower?: number;
    role?: UserRoles;
    recordType?: RecordType;
    sauna?: number;
    pool?: number;
    household?: number;
    heatPump?: number;
    car?: number;
    others?: number;
}) => {
    const systems: SurfacePayload[] = parseSurfacePayload(p.surfaces);

    // This will not happen but is an security check for future developers
    if (systems.length === 0)
        throw new Error(
            'Aufruf nicht möglich: Keine der Oberflächen enthält Platten.'
        );

    const payload = {
        opportunity_id: p.opportunityId,
        panel_power: p.panelPower,
        household: p.household,
        sauna: p.sauna,
        pool: p.pool,
        heat_pump: p.heatPump,
        others: p.others,
        car: p.car,
        systems,
    };

    return await callEigenApiWith<SystemCharacteristicsResponse>({
        name: 'systemcharacteristics',
        middleware: [validateObjectData],
        options: {
            method: 'POST',
            body: JSON.stringify({
                ...payload,
                ...(p.batteryPower && { battery_power: p.batteryPower }),
            }),
        },
    });
};

export const debouncedGetSystemCharacteristics = debounce(
    getSystemCharacteristics,
    4000
);
