import React, { CSSProperties, FunctionComponent } from 'react';
import {
    ButtonType,
    Canvas,
    Layer,
    LayerType,
    ProductTypes,
    VoidHandler,
} from '../../common/types';
import CanvasManager from '../CanvasManager';
import LayersMenu from '../LayersMenu';
import { SurfaceDetailChangeHandler } from '../LayersMenu/LayersMenu';
import PanelManager from '../PanelManager';
import styles from './PlanningDrawer.module.scss';

type Props = {
    style?: CSSProperties;
    className?: string;
    layersGroups: { layers: Layer[]; name: LayerType }[];
    activeCanvas?: Canvas;
    canvases: Canvas[];
    onCanvasClick: (id: string) => void;
    onAddPictureClick?: VoidHandler;
    onDeleteClick?: (id: string) => void;
    disableAddPanel?: boolean;
    disableDrawPanels?: boolean;
    disableRotatePanels?: boolean;
    disableRemovePanels?: boolean;
    onLayerItemClick: (layerId: string, type: ButtonType) => void;
    onAddSurfaceClick: VoidHandler;
    onToggleGridVisibility: VoidHandler;
    onSurfaceDetailChange: SurfaceDetailChangeHandler;
    onAddPanelClick?: VoidHandler;
    onDrawPanelsClick?: VoidHandler;
    onRotatePanelsClick?: VoidHandler;
    onRemovePanelsClick?: VoidHandler;
    onShowHidePanelsClick?: VoidHandler;
    isFlatRoofMode?: boolean;
    onFlipPanelsFacingClick?: VoidHandler;
    hasPanels?: boolean;
    onRatioClick?: VoidHandler;
};

const PlanningDrawer: FunctionComponent<Props> = ({
    layersGroups,
    canvases,
    activeCanvas,
    onCanvasClick,
    onAddPictureClick,
    onDeleteClick,
    disableAddPanel,
    disableDrawPanels,
    disableRotatePanels,
    disableRemovePanels,
    onLayerItemClick,
    onAddSurfaceClick,
    onToggleGridVisibility,
    onSurfaceDetailChange,
    onAddPanelClick,
    onDrawPanelsClick,
    onRotatePanelsClick,
    onRemovePanelsClick,
    onShowHidePanelsClick,
    isFlatRoofMode,
    onFlipPanelsFacingClick,
    hasPanels,
    onRatioClick,
}) => {
    return (
        <div className={styles.root}>
            {activeCanvas && (
                <CanvasManager
                    canvases={Object.values(canvases)}
                    activeCanvasId={activeCanvas.id}
                    onCanvasClick={onCanvasClick}
                    onAddPictureClick={onAddPictureClick}
                    onDeleteClick={onDeleteClick}
                />
            )}
            <PanelManager productType={ProductTypes.PANEL} />
            <LayersMenu
                disableAddPanel={disableAddPanel}
                disableDrawPanels={disableDrawPanels}
                disableRotatePanels={disableRotatePanels}
                disableRemovePanels={disableRemovePanels}
                layersGroups={layersGroups}
                onLayerItemClick={onLayerItemClick}
                onAddSurfaceClick={onAddSurfaceClick}
                onToggleGridVisibility={onToggleGridVisibility}
                onSurfaceDetailChange={onSurfaceDetailChange}
                onAddPanelClick={onAddPanelClick}
                onDrawPanelsClick={onDrawPanelsClick}
                onRotatePanelsClick={onRotatePanelsClick}
                onRemovePanelsClick={onRemovePanelsClick}
                onShowHidePanelsClick={onShowHidePanelsClick}
                isFlatRoofMode={isFlatRoofMode}
                onFlipPanelsFacingClick={onFlipPanelsFacingClick}
                hasPanels={hasPanels}
                onRatioClick={onRatioClick}
            />
        </div>
    );
};

export default PlanningDrawer;
