import React, { FunctionComponent } from 'react';
import { List } from 'semantic-ui-react';
import ProductOverview from '../ProductOverview';
import {
    DesignerQuoteState,
    Product,
    ProductsMap,
    ProductTypes,
    SalesforceProductsState,
} from '../../common/types';
import InputNumber from '../InputNumber';
import styles from './ProductsWizard.module.scss';
import {
    addOrUpdateProductInArray,
    dispatchAndSaveToLocalStorage,
} from '../../features/designerQuote/designerQuoteActions';
import { useAppDispatch } from '../../common/hooks';
import { useTranslation } from 'react-i18next';

type Props = {
    availableProducts: ProductsMap;
    products: SalesforceProductsState;
    quote: DesignerQuoteState;
};

const Scaffolding: FunctionComponent<Props> = ({
    availableProducts,
    products,
    quote,
}) => {
    const { t } = useTranslation(['productsWizard', 'common']);
    const dispatch = useAppDispatch();
    if (
        availableProducts.scaffolding &&
        availableProducts.scaffolding.length > 0
    ) {
        return (
            <List.Item>
                <ProductOverview
                    productType={ProductTypes.SCAFFOLDING}
                    productTitle={t('scaffolding') as string}
                    product={products?.scaffolding}
                    children={
                        (availableProducts.scaffoldingAddOns?.length ?? 0) >
                            0 && (
                            <ul className={styles.scaffoldingAddOns}>
                                {availableProducts.scaffoldingAddOns?.map(
                                    (scaffoldingAddOn: Product) => {
                                        const quantity =
                                            (
                                                (quote.salesforce.Products
                                                    ?.scaffoldingAddOns as Array<Product>) ??
                                                []
                                            ).find(
                                                (addOn: Product) =>
                                                    addOn.id ===
                                                    scaffoldingAddOn.id
                                            )?.quantity ?? 0;
                                        return (
                                            <li key={scaffoldingAddOn.id}>
                                                <span className={styles.title}>
                                                    {scaffoldingAddOn.name}
                                                </span>
                                                <InputNumber
                                                    className={
                                                        styles.inputNumber
                                                    }
                                                    id={
                                                        'scaffoldingAddOn-' +
                                                        scaffoldingAddOn.id
                                                    }
                                                    value={quantity}
                                                    onChange={(number) => {
                                                        dispatch(
                                                            dispatchAndSaveToLocalStorage(
                                                                addOrUpdateProductInArray(
                                                                    {
                                                                        productType:
                                                                            ProductTypes.SCAFFOLDING_ADD_ONS,
                                                                        product:
                                                                            scaffoldingAddOn,
                                                                        quantity:
                                                                            number,
                                                                    }
                                                                )
                                                            )
                                                        );
                                                    }}
                                                />
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                        )
                    }
                />
            </List.Item>
        );
    }
    return null;
};

export default Scaffolding;
