import classNames from 'classnames';
import React, {
    FocusEventHandler,
    FunctionComponent,
    MouseEventHandler,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import {
    EMPTY_RECTANGLE,
    SOUTH_FACING_PANEL_GUTTER,
} from '../../common/constants';
import {
    arePanelsSame,
    getValidPanelsCount,
    mmToPixels,
    panelHasGutter,
} from '../../common/functions';
import { grouping } from '../../common/functions/smartLayout';
import { useAppDispatch } from '../../common/hooks';
import { useUi } from '../../common/hooks/useUi';
import useUserPermissions from '../../common/hooks/useUserPermissions';
import Hotkeys from '../../common/hotkeys';
import {
    ArrowKey,
    FlatRoofModes,
    ModulePlanningData,
    Panel,
    PanelPlanningData,
    PanelsUpdateHandler,
    PerspectiveTransform,
    Point,
    VerticesUpdateHandler,
    VoidHandler,
} from '../../common/types';
import {
    dispatchAndSaveToLocalStorage,
    togglePanelFacing,
} from '../../features/designerQuote/designerQuoteActions';
import DragTrackingLayer from '../DragTrackingLayer';
import PanelElement from '../PanelElement';
import SelectionRectangle from '../SelectionRectangle';
import SurfaceElement from '../SurfaceElement';
import {
    arrowKeyModuleStateHandler,
    createPanelIdFromIndex,
    findConflictingPanels,
    getModuleStateOnMouseDown,
    getPanelIndexFromId,
    getPanelsFitToSelection,
    getStateOnDragSelection,
    mouseMoveModuleStateHandler,
    panelsChangeStateHandler,
    selectedPanelsChangeStateHandler,
    updatePanelPositions,
} from './functions';
import styles from './ModuleElement.module.scss';
import { ModuleState } from './types';

type Props = {
    className?: string;
    planningData: ModulePlanningData;
    environmentScale?: number;
    disabled?: boolean;
    id?: string;
    panelProduct: Panel;
    canvasId?: string;
    onReady?: VoidHandler;
    onPanelsUpdate?: PanelsUpdateHandler;
    onVerticesUpdate?: VerticesUpdateHandler;
    cindx?: number;
    sindx?: number;
    onStringPanelMouseDown?: MouseEventHandler;
};

const ModuleElement: FunctionComponent<Props> = ({
    planningData,
    id = '1',
    environmentScale = 1,
    disabled,
    panelProduct,
    onReady,
    onPanelsUpdate,
    onVerticesUpdate,
    canvasId,
    cindx = 0,
    sindx = 0,
}) => {
    const {
        panels,
        width = 9000,
        height = 4500,
        vertices,
        hasGutter = false,
        azimuth,
        roofType,
        sideDirections,
        flatRoofMode,
    } = planningData;

    const [isFocused, setIsFocused] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [isRotatedPanelsDraw, setIsRotatedPanelsDraw] = useState(false);
    const [isTogglePanelFacing, setIsTogglePanelFacing] = useState(false);
    const permissions = useUserPermissions();
    const dispatch = useAppDispatch();

    const [
        {
            selectedPanelIndices: uiSelectedPanelIndices,
            lockedSurfaceIds,
            activeSurfaceId,
            isDrawPanelsOn,
            hideGrids: uiHideGrids,
            hidePanels,
            isStringToolActive,
        },
        updateUi,
    ] = useUi();

    const hideGrids = !permissions.canSeeGrids ? true : uiHideGrids;

    const gutterWidth =
        hasGutter && permissions.canSeeGutter ? planningData.surfaceGutter! : 0;

    const locked = lockedSurfaceIds.includes(planningData.id);

    const isActive =
        !locked && !disabled && planningData.id === activeSurfaceId;

    const isStringPlanning = permissions.canStringPanels;

    const activeSelectedPanelIndices = !isActive ? [] : uiSelectedPanelIndices;

    const containerMouseGrabPoint = useRef<Point | undefined>();

    const [state, setState] = useState<ModuleState>({
        modifiedData: { ...planningData },
        selection: {
            x: 0,
            y: 0,
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            width: 0,
            height: 0,
            mouseGrabPoint: { x: 0, y: 0 },
            indices: [],
            positions: [],
        },
        conflictingPanels: [],
        isDragging: undefined,
        panelProduct,
    });

    const panelGroups = grouping(panels, panelProduct, roofType!);

    const ungroupedPanelIds: number[] = [];
    panelGroups.forEach((key) => {
        key.length < 2 && ungroupedPanelIds.push(key[0]);
    });

    const environmentScaleRef = useRef(1);
    environmentScaleRef.current = environmentScale;

    const perspectiveRef = useRef<PerspectiveTransform>();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isReady && !getValidPanelsCount(panels)) setIsReady(true);
        setState(panelsChangeStateHandler(panels));
    }, [panels]);

    useEffect(() => {
        isReady && onReady && onReady();
    }, [isReady]);

    useEffect(() => {
        setState((state) => ({ ...state, panelProduct }));
    }, [panelProduct]);

    useEffect(() => {
        setState(
            selectedPanelsChangeStateHandler(
                activeSelectedPanelIndices,
                roofType!
            )
        );
    }, [activeSelectedPanelIndices.join(), panels]);

    useEffect(() => {
        updateUi({
            selectedPanelIndices: [],
        });
    }, [panels.length]);

    useEffect(() => {
        const perspective = perspectiveRef.current;
        if (!perspective) return;

        const handleMouseMove = (e: MouseEvent) => {
            if (!containerMouseGrabPoint.current) return;
            if (!state.isDragging) {
                setState((state) => {
                    const selectionGrabPoint: Point =
                        containerMouseGrabPoint.current
                            ? {
                                  x:
                                      containerMouseGrabPoint.current.x -
                                      state.selection.x,
                                  y:
                                      containerMouseGrabPoint.current.y -
                                      state.selection.y,
                              }
                            : { x: 0, y: 0 };

                    return {
                        ...state,
                        selection: {
                            ...state.selection,
                            mouseGrabPoint: selectionGrabPoint,
                        },
                        isDragging: true,
                    };
                });
            }
            const { shiftKey, clientX, clientY } = e;
            const containerBoundaries =
                containerRef.current?.getBoundingClientRect() ||
                EMPTY_RECTANGLE;

            setState(
                mouseMoveModuleStateHandler(
                    perspective,
                    clientX,
                    clientY,
                    containerBoundaries,
                    shiftKey,
                    state.selection.indices,
                    width,
                    height,
                    environmentScaleRef.current,
                    roofType!,
                    gutterWidth
                )
            );
        };

        const handleMouseUp = () => {
            containerMouseGrabPoint.current = undefined;
            if (!state.isDragging) return;
            setState((state) => ({ ...state, isDragging: false }));
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [
        containerMouseGrabPoint.current?.x,
        containerMouseGrabPoint.current?.y,
        state?.isDragging,
    ]);

    useEffect(() => {
        if (!isActive) return;

        const handleMouseDown = (e: MouseEvent) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(e.target as Node) &&
                (!containerRef.current.parentElement ||
                    containerRef.current.parentElement.contains(
                        e.target as Node
                    ))
            ) {
                updateUi({ activeSurfaceId: undefined });
            }
        };

        window.addEventListener('mousedown', handleMouseDown);

        return () => {
            containerRef.current && containerRef.current.blur();
            window.removeEventListener('mousedown', handleMouseDown);
        };
    }, [isActive]);

    useEffect(() => {
        if (state) {
            setState((state: ModuleState) => {
                const newModifiedData = { ...state.modifiedData };
                newModifiedData.panels = updatePanelPositions(
                    state.selection.indices,
                    state.modifiedData.panels,
                    state.selection.x,
                    state.selection.y,
                    state.selection.positions
                );
                const conflictingPanels = findConflictingPanels(
                    newModifiedData.panels,
                    width,
                    height,
                    gutterWidth,
                    state.panelProduct.width,
                    state.panelProduct.length,
                    roofType!,
                    planningData.gutter
                );
                return {
                    ...state,
                    modifiedData: newModifiedData,
                    conflictingPanels,
                };
            });
        }
    }, [state?.selection?.x, state?.selection?.y, planningData]);

    useEffect(() => {
        !state?.isDragging &&
            onPanelsUpdate &&
            !arePanelsSame(state.modifiedData.panels, panels) &&
            onPanelsUpdate(state.modifiedData.panels);
    }, [state?.modifiedData?.panels, state?.isDragging]);

    const handlePanelMouseDown = useCallback<MouseEventHandler>(
        (e) => {
            const perspective = perspectiveRef.current;
            if (!perspective) return;

            const index = getPanelIndexFromId(e.currentTarget.id);
            const containerBoundaries =
                containerRef.current?.getBoundingClientRect() ||
                EMPTY_RECTANGLE;

            const res = getModuleStateOnMouseDown({
                state,
                perspective,
                mouseX: e.clientX,
                mouseY: e.clientY,
                containerBoundaries,
                index,
                shiftKey: e.shiftKey,
                altKey: e.altKey,
                roofType: roofType!,
                environmentScale: environmentScaleRef.current,
            });

            if (!res) return;

            if (!e.shiftKey && !e.altKey && permissions.canMovePanels)
                containerMouseGrabPoint.current = res.mouseGrabPoint;
            else containerMouseGrabPoint.current = undefined;

            if (
                res.updatedSelectedPanels.join() !==
                state.selection.indices.join()
            ) {
                updateUi({
                    selectedPanelIndices: res.updatedSelectedPanels,
                });
            }
            if (
                isTogglePanelFacing &&
                !!state.modifiedData.panels[index].facing?.side
            ) {
                dispatch(
                    dispatchAndSaveToLocalStorage(
                        togglePanelFacing({ data: {} })
                    )
                );
            }
        },
        [state, isTogglePanelFacing]
    );

    const handleSelectAll = () => {
        updateUi({
            selectedPanelIndices: Object.keys(panels).map((key) => Number(key)),
        });
    };

    const handleContainerFocus: FocusEventHandler = useCallback(() => {
        if (locked) return;
        if (activeSurfaceId !== planningData.id)
            updateUi({
                activeSurfaceId: planningData.id,
            });
        setIsFocused(true);
    }, [isActive, locked]);
    const handleContainerBlur: FocusEventHandler = useCallback(() => {
        setIsFocused(false);
    }, []);

    const handleSelectionAreaClick: MouseEventHandler = useCallback(() => {
        if (activeSelectedPanelIndices.length)
            updateUi({ selectedPanelIndices: [] });
    }, [activeSelectedPanelIndices]);

    const handleDragSelectionChange = useCallback(
        (startPoint: Point | undefined, endPoint: Point | undefined) => {
            const perspective = perspectiveRef.current;
            if (!perspective) return;

            if (!startPoint || !endPoint) {
                if (
                    state.dragSelection &&
                    isDrawPanelsOn &&
                    onPanelsUpdate &&
                    sideDirections
                ) {
                    updateUi({ isDrawPanelsOn: false });
                    onPanelsUpdate([
                        ...state.modifiedData.panels,
                        ...getPanelsFitToSelection(
                            state.dragSelection,
                            panelProduct,
                            planningData,
                            isRotatedPanelsDraw,
                            gutterWidth,
                            planningData.gutter || SOUTH_FACING_PANEL_GUTTER
                        ),
                    ]);
                }
                setState((state) => ({ ...state, dragSelection: undefined }));
                return;
            }

            const containerBoundaries =
                containerRef.current?.getBoundingClientRect() ||
                EMPTY_RECTANGLE;

            setState(
                getStateOnDragSelection(
                    state,
                    startPoint,
                    endPoint,
                    perspective,
                    containerBoundaries,
                    environmentScaleRef.current,
                    roofType!,
                    isDrawPanelsOn
                        ? undefined // rectangle will not select panels
                        : (indices: number[]) =>
                              updateUi({
                                  selectedPanelIndices: indices,
                              })
                )
            );
        },
        [
            isDrawPanelsOn,
            state,
            state?.dragSelection,
            panelProduct,
            isRotatedPanelsDraw,
        ]
    );

    const handlePanelReady = useCallback(() => setIsReady(true), []);

    const renderPanel = (panel: PanelPlanningData, i: number) => {
        const hasGutter = panelHasGutter(panel);
        return panel.x !== undefined && panel.y !== undefined ? (
            <PanelElement
                key={i}
                id={createPanelIdFromIndex(i)}
                style={{
                    visibility: hidePanels ? 'hidden' : undefined,
                    position: 'absolute',
                    left: mmToPixels(panel.x),
                    top: mmToPixels(panel.y),
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                isHidden={panel.isHidden}
                isHorizontal={panel.isHorizontal}
                productData={panelProduct}
                onMouseDown={
                    isStringPlanning
                        ? isStringToolActive
                            ? () => {
                                  updateUi({
                                      selectedPanelIndices: [i],
                                      activeSurfaceId: planningData.id,
                                      activeCanvasId: canvasId,
                                  });
                              }
                            : undefined
                        : isActive
                        ? handlePanelMouseDown
                        : undefined
                }
                error={state.conflictingPanels.includes(i)}
                selected={state.selection.indices.includes(i)}
                onReady={
                    !isReady && !panel.isHidden ? handlePanelReady : undefined
                }
                facingSide={panel.facing && panel.facing.side}
                hasGutter={hasGutter}
                gutterWidth={
                    hasGutter
                        ? planningData.gutter
                            ? planningData.gutter
                            : SOUTH_FACING_PANEL_GUTTER
                        : 0
                }
                roofType={roofType}
                ungrouped={
                    flatRoofMode === FlatRoofModes.alternate &&
                    ungroupedPanelIds.includes(i)
                }
                data-string-position={`c${cindx} s${sindx} p${createPanelIdFromIndex(
                    i
                )}`}
            />
        ) : undefined;
    };

    const handleArrowKeyPress = (keyEvent: KeyboardEvent) => {
        if (!permissions.canMovePanels || !isFocused || state.isDragging)
            return;

        setState(
            arrowKeyModuleStateHandler(
                keyEvent.key as ArrowKey,
                keyEvent.shiftKey
            )
        );
    };

    useHotkeys(
        Hotkeys.MOVE_PANEL.hook,
        (keyEvent: KeyboardEvent) => {
            handleArrowKeyPress(keyEvent);
        },
        { keydown: true },
        [isFocused, state?.isDragging, permissions.canMovePanels]
    );

    useHotkeys(
        Hotkeys.TOGGLE_PANEL_FACING.hook,
        (key: KeyboardEvent) => {
            switch (key.type) {
                case 'keydown':
                    setIsTogglePanelFacing(true);
                    break;
                case 'keyup':
                    setIsTogglePanelFacing(false);
            }
        },
        { keydown: true, keyup: true },
        [isTogglePanelFacing]
    );

    useHotkeys(
        Hotkeys.DRAW_ROTATED_PANELS.hook,
        (key: KeyboardEvent) => {
            switch (key.type) {
                case 'keydown':
                    setIsRotatedPanelsDraw(true);
                    break;
                case 'keyup':
                    setIsRotatedPanelsDraw(false);
            }
        },
        { keydown: true, keyup: true },
        [isRotatedPanelsDraw]
    );

    useHotkeys(Hotkeys.SELECT_ALL_PANELS.hook, handleSelectAll, [panels]);

    return (
        <div
            id={id}
            ref={containerRef}
            className={classNames(styles.root, {
                active: isActive,
                [styles.locked]: locked,
            })}
            onFocus={handleContainerFocus}
            onBlur={handleContainerBlur}
            tabIndex={0}
        >
            <SurfaceElement
                environmentScale={environmentScale}
                width={mmToPixels(width)}
                height={mmToPixels(height)}
                gutter={mmToPixels(gutterWidth)}
                vertices={vertices}
                editable={permissions.canManipulateSurfaces && isActive}
                active={isActive}
                perspectiveRef={perspectiveRef}
                showGrid={!hideGrids}
                onVerticesChange={onVerticesUpdate}
                roofType={roofType}
                flatRoofMode={flatRoofMode}
                azimuth={azimuth}
            >
                {state && state.modifiedData.panels.map(renderPanel)}
                {isDrawPanelsOn &&
                    state.dragSelection &&
                    sideDirections &&
                    getPanelsFitToSelection(
                        state.dragSelection,
                        panelProduct,
                        planningData,
                        isRotatedPanelsDraw,
                        gutterWidth,
                        planningData.gutter || SOUTH_FACING_PANEL_GUTTER
                    ).map(renderPanel)}
                {state?.dragSelection && (
                    <SelectionRectangle
                        rectangle={state.dragSelection}
                        className={
                            isDrawPanelsOn
                                ? styles.colorDrawPanelsOn
                                : styles.colorSelection
                        }
                    />
                )}
                {isActive && (
                    <DragTrackingLayer
                        className={styles.dragTrackingLayer}
                        onClick={isActive && handleSelectionAreaClick}
                        onSelectionChange={
                            isActive && handleDragSelectionChange
                        }
                    />
                )}
            </SurfaceElement>
        </div>
    );
};

export default ModuleElement;
