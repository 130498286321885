import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import { List } from 'semantic-ui-react';
import useDesignerQuote from '../../common/hooks/useDesignerQuote';
import { Scaffolding as ScaffoldingProduct } from '../../common/types';
import styles from './Scaffolding.module.scss';

type Props = {
    style?: CSSProperties;
    className?: string;
};
const Scaffolding: FunctionComponent<Props> = ({ style, className }) => {
    const designerQuote = useDesignerQuote();
    const scaffolding = designerQuote.salesforce.Products
        ?.scaffolding as ScaffoldingProduct;

    return scaffolding ? (
        <List
            className={classNames(styles.scaffolding, className)}
            style={style}
        >
            <List.Item className={styles.line}>
                <span className={styles.label}>{scaffolding.name}</span>
                <span className={styles.value}>{scaffolding.quantity}</span>
            </List.Item>
        </List>
    ) : null;
};

export default Scaffolding;
