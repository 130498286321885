import { ModalActionType, ModalReducer } from '../../common/types';

export const modalReducer: ModalReducer = (state = null, action) => {
    switch (action.type) {
        case ModalActionType.OPEN_MODAL:
            return action.payload;
        case ModalActionType.CLOSE_MODAL:
            return null;
        default:
            return state;
    }
};
