import React, { CSSProperties, FunctionComponent } from 'react';
import { getRightUnits } from '../../common/helpers';
import { Product, ProductTypes } from '../../common/types';
import Collapsable from '../Collapsable/Collapsable';
import ProductBadge from '../ProductBadge/ProductBadge';
import ProductSelector from '../ProductSelector';
import styles from './ProductOverview.module.scss';

type Props = {
    style?: CSSProperties;
    productType: ProductTypes;
    productOptionsType?: ProductTypes;
    product: Product | Product[] | null;
    productTitle?: string;
    hasEmptyOption?: boolean;
    isOpen?: boolean;
    secondInstance?: boolean;
    isDisabled?: boolean;
    children?: React.ReactNode;
};

const ProductOverview: FunctionComponent<Props> = ({
    productType,
    product,
    productTitle = productType,
    productOptionsType = productType,
    children,
    isOpen = false,
    secondInstance = false,
    hasEmptyOption = false,
    isDisabled = false,
}) => {
    let items;
    if (!Array.isArray(product) && product) {
        const units = getRightUnits(productType);
        items = [];
        units &&
            product.power !== undefined &&
            items.push({
                iconId: 'power',
                description: product.power + units.unit,
            });
        product.productBrand &&
            items.push({
                iconId: 'truck',
                description: product.productBrand,
            });
        Number(product.quantity) !== product.quantity || product.quantity === 1
            ? items.push({
                  iconId: 'document',
                  description: product.price.toLocaleString('de-DE') + '€',
              })
            : items.push({
                  iconId: 'document',
                  description: `${product.price.toLocaleString('de-DE')}  /  ${(
                      product.price * product.quantity!
                  ).toLocaleString('de-DE')} €`,
              });
    }

    const childrenAfterBadges: React.ReactNode[] = [];

    const content = (productType: ProductTypes) => {
        switch (productType) {
            case ProductTypes.PANEL:
                return (
                    !Array.isArray(product) &&
                    product && (
                        <div>{`${product.quantity || 0} x ${
                            product.name
                        }`}</div>
                    )
                );
            case ProductTypes.ELECTRICAL_CABINETS:
                return (
                    <>
                        <ProductSelector productCategory={productType} />
                        {children}
                    </>
                );
            case ProductTypes.MOUNTING_SYSTEM:
                return <>{children}</>;
            case ProductTypes.ADDITIONAL_LABOR:
                return <>{children}</>;
            default:
                childrenAfterBadges.push(
                    <div
                        key={'children-after-badges'}
                        style={{ marginTop: '1ex' }}
                    >
                        {children}
                    </div>
                );
                return (
                    <ProductSelector
                        productCategory={productType}
                        secondInstance={secondInstance}
                        productOptionsType={productOptionsType}
                        hasEmptyOption={hasEmptyOption}
                        isDisabled={isDisabled}
                    />
                );
        }
    };

    return (
        <Collapsable title={productTitle} className={styles.root} open={isOpen}>
            {content(productType)}
            {items &&
                items?.map((item, i) => (
                    <ProductBadge
                        key={`${item.iconId}-${i}`}
                        className={styles.badge}
                        iconId={item.iconId}
                        labelContent={item.description}
                    />
                ))}
            {childrenAfterBadges}
        </Collapsable>
    );
};

export default ProductOverview;
