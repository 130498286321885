import React, { CSSProperties, ReactElement } from 'react';
import { Action, Dispatch, Reducer } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
    DropdownProps,
    FormInputProps,
    ImageProps,
    InputProps,
    ModalProps as SemanticModalProps,
    SemanticICONS,
    StrictGridProps,
} from 'semantic-ui-react';
import { StateHistoryActionTypes } from '../features/stateHistory';

// Products
export type ObjectWithNameAndId = {
    name: string;
    id: string;
};

export type ObjectWithWidthAndLength = {
    width: number;
    length: number;
};

export type ApiQuote = SalesforceState & {
    quoteData: RoofToolPlanning[] | PlanningData[];
    planningData: PlanningData;
};

export type GenericProduct = ObjectWithNameAndId & {
    photos: Photo[];
};

export type Photo = ObjectWithNameAndId & {
    url: string;
};

export enum MountingSystemOrientation {
    flat = 'Flat',
    horizontal = 'Horizontal',
    vertical = 'Vertical',
}

export type Product = GenericProduct &
    ObjectWithWidthAndLength & {
        power?: number;
        productCraft?: string | null;
        productType?: string | null;
        productCategory?: string | null;
        productBrand?: string | null;
        supplementary?: string | null;
        frameWidth?: number;
        dataSheetUrl?: string | null;
        description?: string | null;
        isActive?: boolean;
        isSolardesignerVisible?: boolean;
        pricebookEntryId: string;
        price: number;
        quantity?: number;
        dependencies?: InverterDependency[];
        peerDependencies?: Product[];
        compatibleWith?: Product[];
        color?: string;
        sortOrder?: number;
        typeOfCraft?: string | null;
        orientation?: MountingSystemOrientation | null;
        isOptimizer?: boolean;
        unit?: string;
        category?: string;
    };

export type BasicQuoteProduct = Product & {
    quantity?: number;
};

export type InverterDependency = Product & {
    isOptimizer: boolean;
    orientation: string;
    typeOfCraft: string;
    isPowerSwitch?: boolean;
};

export type SpecificQuoteProduct<T> = T extends 'panels'
    ? BasicQuoteProduct &
          ObjectWithWidthAndLength & {
              photos: Photo[];
              openCircuitVoltage: number;
              shortCircuitCurrent: number;
          }
    : BasicQuoteProduct;

export type AdditionalLabor = Product;
export type Battery = Product;
export type Inverter = Product;
export type Panel = Product & {
    openCircuitVoltage: number;
    shortCircuitCurrent: number;
};
export type Scaffolding = Product;
export type ScaffoldingAddOns = Product;
export type MountingSystemAccessories = Product;
export type Grounding = Product;
export type ElectricalMaterial = Product & { numberOfStrings: number };
export type ElectricalLabor = Product;
export type ElectricalCabinets = Product;
export type ElectricalCabinetUpgrade = Product;
export type ElectricalServices = Product & {
    batteryLabor: boolean;
};
export type MountingSystem = Product & {
    orientation: string;
    typeOfCraft: string;
    dependencies: Product[];
};
export type Wallbox = Product & {
    dependencies: Product[];
};

export enum ProductTypes {
    ADDITIONAL_LABOR = 'additionalLabor',
    BATTERY = 'battery',
    INVERTER = 'inverter',
    SECOND_INVERTER = 'secondInverter',
    PANEL = 'panels',
    SCAFFOLDING = 'scaffolding',
    RECOMMENDED_INVERTERS = 'recommendedInverters',
    SECOND_RECOMMENDED_INVERTERS = 'secondRecommendedInverters',
    ELECTRICAL_MATERIAL = 'electricalMaterial',
    ELECTRICAL_LABOR = 'electricalLabor',
    ELECTRICAL_CABINETS = 'electricalCabinets', // ProductState
    ELECTRICAL_CABINET_UPGRADES = 'electricalCabinetUpgrades',
    MOUNTING_SYSTEM = 'mountingSystem',
    ELECTRICAL_SERVICES = 'electricalServices', // ProductState
    WALLBOXES = 'wallboxes',
    GROUNDING = 'grounding',
    SCAFFOLDING_ADD_ONS = 'scaffoldingAddOns',
    MOUNTING_SYSTEM_ACCESSORIES = 'mountingSystemAccessories',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/ban-types
export type ProductId<T extends Product> = {};

export type PanelInPackage = GenericProduct &
    ObjectWithWidthAndLength & {
        power: number;
        quantity: number;
    };

const ProductTypeIds: { [key in ProductTypes]: ProductId<Product> } = {
    additionalLabor: [] as ProductId<AdditionalLabor>[],
    battery: {} as ProductId<Battery>,
    inverter: {} as ProductId<Inverter>,
    secondInverter: {} as ProductId<Inverter>,
    recommendedInverters: [] as ProductId<Inverter>,
    secondRecommendedInverters: [] as ProductId<Inverter>,
    panels: {} as ProductId<Panel>,
    scaffolding: {} as ProductId<Scaffolding>,
    electricalLabor: [] as ProductId<ElectricalLabor>[],
    electricalMaterial: {} as ProductId<ElectricalMaterial>,
    electricalCabinets: {} as ProductId<ElectricalCabinets>,
    electricalServices: {} as ProductId<ElectricalServices>,
    electricalCabinetUpgrades: [] as ProductId<ElectricalCabinetUpgrade>[],
    mountingSystem: [] as ProductId<MountingSystem>[],
    wallboxes: {} as ProductId<Wallbox>,
    grounding: {} as ProductId<Grounding>,
    scaffoldingAddOns: [] as ProductId<ScaffoldingAddOns>[],
    mountingSystemAccessories: [] as ProductId<MountingSystemAccessories>[],
};

export type ProductsMap = {
    [key in keyof typeof ProductTypeIds]?: (typeof ProductTypeIds)[key] extends ProductId<
        infer T1
    >
        ? T1[]
        : never;
};

export type Pricing = {
    total_cost: string;
    monthly_rate: string;
    last_month: string;
    duration: number;
};

export type Package = {
    sfid: string;
    name: string;
    config_id: number;
    size: string;
    price: number;
    battery: boolean;
    roof_area_needed: number;
    with_financing: boolean;
    products: {
        panels: PanelInPackage;
        inverter: Inverter;
        battery: Battery | [];
    };
    financing: Pricing[];
};

export enum SurfaceInput {
    roofType = 'roofType',
    flatRoofMode = 'flatRoofMode',
    azimuth = 'azimuth',
    slope = 'slope',
    sideDirections = 'sideDirections',
    width = 'width',
    height = 'height',
    yieldReduction = 'yieldReduction',
    gutter = 'gutter',
    hasGutter = 'hasGutter',
    surfaceGutter = 'surfaceGutter',
}

export type ModalButton = {
    text?: string;
    type?: 'positive' | 'negative';
    onClick: VoidHandler;
    style?: CSSProperties;
};

export type ModalProps = {
    title: string;
    description: string;
    size?: ModalSize;
    children?: any;
    formInputs?: { [name: string]: FormInputProps };
    formOnChange?: (event: React.ChangeEvent, data: any) => void;
    onModalClose?: (
        event: React.MouseEvent<HTMLElement>,
        data: SemanticModalProps
    ) => void;
    buttons: ModalButton[];
    className?: string;
};

// States
export type AppState = { isInitialised: boolean };
export type UserState = {
    username: string;
    email: string;
    name: string;
    isAuth: boolean;
    accessToken: string | undefined;
    refreshToken: string | undefined;
    role: UserRoles;
};
export type ProductsState = ProductsMap;

export type RecordType = { name: string; id: string };

export type OpportunityState = {
    qaReviewId?: string | null;
    isFetching: boolean;
    name?: string;
    opportunityId: string; // The output of the EigenAPi is id!!!!
    account?: ObjectWithNameAndId;
    customer?: Customer;
    photos?: Photo[];
    quotes?: SalesforceState[];
    opportunityData?: RoofToolPlanning[];
    planningVersion?: number;
    lastSaved?: number;
    recordType?: RecordType;
    validQuoteRecordTypes?: RecordType[];
    annualPowerConsumptionKwh?: number;
    electricityCostKwh?: number;
    stageName?: string;
    monthlyElectricityCosts?: number;
    windZone: WindZone;
};

export type Surface = {
    id: string;
    dimensions: {
        width: string;
        height: string;
    };
    height: string;
    is3D: boolean;
    roofType?: RoofTypes;
    flatRoofMode: FlatRoofModes;
    left: string;
    matrix: string;
    slope: string | number;
    azimuth: number;
    offset: number;
    originalBuildArea: {
        width: string;
        height: string;
    };
    panelSize: number;
    panelType: PanelType;
    panels: PanelInOpportunity[];
    rotated: boolean;
    shadowDist: Array<any>;
    top: string;
    width: string;
};

export type NotificationPayload = {
    type: 'error' | 'success' | 'info';
    message: string;
    icon?: string;
    details?: string;
    dismissible?: boolean;
    spinning?: boolean;
    children?: ReactElement;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onDismiss?: Function;
};

export type Notification = NotificationPayload & {
    bornAt?: number;
    dismissedAt?: number;
};

// This occurs in the opportunity state
export type PanelType = Product & {
    quantity: number;
    length: number;
};

export type PanelInOpportunity = ObjectWithWidthAndLength & {
    fullWidth: boolean;
    hidden: boolean;
    rotated: boolean;
};

export type Customer = {
    zip: string;
    city: string;
    roofType: string | null;
};

type QuoteLineItemAttribute = {
    type: string;
    url: string;
};

export type QuoteLineItem = {
    Ancillary_Cost_Line_Items__c?: string | null;
    CreatedById: string;
    CreatedDate: string;
    Description?: string | null;
    Discount?: string | null;
    Id: string;
    IsDeleted: boolean;
    Is_Active__c: boolean;
    LastModifiedById: string;
    LastModifiedDate: string | null;
    LastReferencedDate: string | null;
    LastViewedDate: string | null;
    LineNumber: string;
    ListPrice: number;
    OpportunityLineItemId?: string | null;
    PricebookEntryId: string;
    Product2Id: string;
    Product_Name__c: string;
    Product_SKU__c?: string | null;
    Quantity: number;
    QuoteId: string;
    ServiceDate?: string | null;
    SortOrder?: number | null;
    SortOrder__c?: number | null;
    Subtotal: number;
    SystemModstamp: string;
    TotalPowerCapacity__c: number;
    TotalPrice: number;
    Total_Battery_Capacity__c: number;
    UnitPrice: number;
    attributes: QuoteLineItemAttribute;
};

export type CloudQuoteState = {
    quoteId: string;
    salesforce: SalesforceState;
    planning: PlanningData;
    isFetching: boolean;
};

export type PlanningDataRoiValues = {
    [key in RoiValueId]?: {
        manual?: number | null;
        machine?: number | null;
    };
};

export type PlanningData = {
    canvases: {
        [name: string]: Canvas;
    };
    roiValues?: PlanningDataRoiValues;
};

export type ImageBox = {
    id: string;
    url: string;
    crops?: any[];
    rotation?: number;
    scale?: number;
    dimensions?: Dimensions;
    x?: number;
    y?: number;
    flipX?: boolean;
    flipY?: boolean;
};

export type Canvas = {
    id: string;
    imageBox: ImageBox;
    surfaces: {
        [name: string]: ModulePlanningData;
    };
};

type Roofer = {
    imageBox: ImageBox;
    systems: Surface[];
};

export type RoofToolPlanning = {
    quote: string;
    roofers: Roofer[];
};

export type SalesforceProductsState = {
    [key in Exclude<
        ProductTypes,
        | 'electricalCabinetUpgrades'
        | 'mountingSystem'
        | 'electricalLabor'
        | 'additionalLabor'
        | 'scaffoldingAddOns'
        | 'mountingSystemAccessories'
    >]: SpecificQuoteProduct<key> | null;
} & { panels: SpecificQuoteProduct<'panels'> } & {
    electricalLabor?: SpecificQuoteProduct<ElectricalLabor>[];
    electricalCabinetUpgrades?: SpecificQuoteProduct<ElectricalCabinetUpgrade>[];
    additionalLabor?: SpecificQuoteProduct<AdditionalLabor>[];
    mountingSystem?: SpecificQuoteProduct<MountingSystem>[];
    scaffoldingAddOns?: SpecificQuoteProduct<ScaffoldingAddOns>[];
    mountingSystemAccessories?: SpecificQuoteProduct<MountingSystemAccessories>[];
};

export type modificationDetails = {
    date: string;
    timezone: string;
    timezoneType: number;
};

export type SalesforceState = {
    id: string;
    name?: string;
    opportunity?: ObjectWithNameAndId;
    account?: ObjectWithNameAndId;
    battery?: boolean;
    batteries?: Battery[];
    selfConsumptionKwh?: number | null;
    annualYieldHours?: number | null;
    reducedAnnualYieldHours?: number | null;
    systemCapacity?: number | null;
    batteryCapacity?: number | null;
    monthlyElectricityCosts?: number | null;
    electricityCostKwh?: number | null;
    selfConsumptionRate?: number | null;
    electricityBoughtAtInitialRate?: number | null;
    degreeOfSelfSufficiency?: number | null;
    package?: string | null;
    customerInfo?: Customer | null;
    photos?: Photo[];
    Products?: SalesforceProductsState;
    isSyncing?: boolean;
    recordType?: RecordType;
    planningVersion?: number;
    status: string;
    modificationDetails?: modificationDetails;
    lastModifiedBy?: string;
} & ConsumptionObject;

export type DesignerQuoteState = {
    quoteId: string;
    salesforce: SalesforceState;
    planning: PlanningData;
    savedAt?: string;
};

export type NotificationsState = Notification[];

export type ModalState = {
    modalProps: ModalProps;
} | null;

export type UiState = {
    isShapeBuilderOn: boolean;
    isDrawPanelsOn: boolean;
    selectedPanelIndices: number[];
    lockedSurfaceIds: string[];
    activeSurfaceId?: string;
    activeCanvasId: string | undefined;
    hideGrids: boolean;
    hidePanels: boolean;
    isRenderingScreenshot: boolean;
    isUserSelectedQuoteName: boolean;
    openAfterSavingQuoteId?: string;
    isHandToolActive?: boolean;
    resetStageZoom?: VoidHandler | undefined;
    isInitialisingProducts: boolean;
    hideEnergyFields: boolean;
    isFlatRoofAlternate?: boolean;
    stringKey: StringKey;
    isStringToolActive?: boolean;
    showRecalculateButton?: boolean;
    quoteSorting: SortType;
};

export type RootState = {
    app: AppState;
    user: UserState;
    cloudQuote?: CloudQuoteState;
    designerQuote: DesignerQuoteState;
    opportunity: OpportunityState;
    products: ProductsState;
    modal: ModalState;
    notifications: NotificationsState;
    ui: UiState;
    lastAction: { type: string } | undefined;
};

// Action types
export enum AppActionType {
    APP_SET_IS_INITIALISED = 'APP_SET_IS_INITIALISED',
    DEFAULT_APP_ACTION = 'DEFAULT_APP_ACTION',
}

export enum UiActionType {
    UPDATE = 'UPDATE_UI_STATE',
    DEFAULT = 'DEFAULT_UI_ACTION',
    TOGGLE_GRID_VISIBILITY = 'TOGGLE_GRID_VISIBILITY',
    TOGGLE_ENERGY_FIELDS_VISIBILITY = 'TOGGLE_ENERGY_FIELDS_VISIBILITY',
    SHOW_RECALCULATE_BUTTON = 'SHOW_RECALCULATE_BUTTON',
    HIDE_RECALCULATE_BUTTON = 'HIDE_RECALCULATE_BUTTON',
}

export enum UserActionType {
    SET_TOKENS = 'SET_TOKENS',
    RENEW_ACCESS_TOKEN = 'RENEW_ACCESS_TOKEN',
    SET_USER_ROLE = 'SET_USER_ROLE',
    DEFAULT_USER_ACTION = 'DEFAULT_USER_ACTION',
}

export enum QuoteActionType {
    SET_QUOTE = 'SET_QUOTE',
    SET_QUOTE_ID = 'SET_QUOTE_ID',
    RESET_QUOTE = 'RESET_QUOTE',
}

export enum CloudQuoteActionType {
    REQUEST_QUOTE = 'REQUEST_CLOUD_QUOTE',
    FETCH_QUOTE_FAILED = 'FETCH_CLOUD_QUOTE_FAILED',
    DEFAULT_QUOTE_ACTION = 'DEFAULT_CLOUD_QUOTE_ACTION',
}

export enum DesignerQuoteActionType {
    DEFAULT = 'DEFAULT_DESIGNER_QUOTE_ACTION',
    UPDATE = 'UPDATE_DESIGNER_QUOTE',
    UPDATE_PRODUCT = 'UPDATE_PRODUCT',
    UPDATE_MULTIPLE_PRODUCTS = 'UPDATE_MULTIPLE_PRODUCTS',
    SET_DEFAULT_PRODUCTS = 'SET_DEFAULT_PRODUCTS',
    SET_IS_SAVED = 'SET_IS_SAVED',
    ADD_PHOTOS = 'ADD_PHOTOS_TO_DESIGNER_QUOTE',
    SET_PLANNING_DATA = 'SET_PLANNING_DATA_TO_DESIGNER_QUOTE',
    DELETE_CANVAS = 'DELETE_CANVAS',
    DELETE_SURFACE = 'DELETE_SURFACE',
    ADD_SURFACE = 'ADD_SURFACE',
    UPDATE_SURFACE = 'UPDATE_SURFACE',
    UPDATE_ROI = 'UPDATE_ROI',
    UPDATE_TOTAL_ANNUAL_CONSUMPTION = 'UPDATE_TOTAL_ANNUAL_CONSUMPTION',
    UPDATE_ENERGY_CONSUMPTION = 'UPDATE_ENERGY_CONSUMPTION',
    ADD_PANEL = 'ADD_PANEL',
    SET_PANELS = 'SET_PANELS',
    SET_VERTICES = 'SET_VERTICES',
    ROTATE_PANELS = 'ROTATE_PANELS',
    REMOVE_PANELS = 'REMOVE_PANELS',
    TOGGLE_PANELS_VISIBILITY = 'TOGGLE_PANELS_VISIBILITY',
    TOGGLE_UPGRADE = 'TOGGLE_UPGRADE',
    SET_QUOTE_NAME = 'SET_QUOTE_NAME',
    SET_RECORD_TYPE = 'SET_RECORD_TYPE',
    ADD_OR_UPDATE_PRODUCT_IN_ARRAY = 'ADD_OR_UPDATE_PRODUCT_IN_ARRAY',
    RESET_PRODUCT_SELECTION = 'RESET_PRODUCT_SELECTION',
    SET_QUOTE_STATUS = 'SET_QUOTE_STATUS',
    ADD_PANEL_TO_STRING = 'ADD_PANEL_TO_STRING',
    REMOVE_PANEL_FROM_STRING = 'REMOVE_PANEL_FROM_STRING',
    REMOVE_ALL_PANELS_FROM_REMOVED_STRINGS = 'REMOVE_ALL_PANELS_FROM_REMOVED_STRINGS',
    UPDATE_MONTHLY_COSTS = 'UPDATE_MONTHLY_COSTS',
    TOGGLE_PANEL_FACING = 'TOGGLE_PANEL_FACING',
    TOGGLE_SURFACE_GUTTER = 'TOGGLE_SURFACE_GUTTER',
    UPDATE_SURFACE_ANNUAL_YIELD_HOURS = 'UPDATE_SURFACE_ANNUAL_YIELD_HOURS',
    UPDATE_MACHINE_ROI_VALUE = 'UPDATE_MACHINE_ROI_VALUE',
    UPDATE_MANUAL_ROI_VALUE = 'UPDATE_MANUAL_ROI_VALUE',
    UPDATE_PANEL_AMOUNT = 'UPDATE_PANEL_AMOUNT',
    TOGGLE_SURFACE_RATIO = 'TOGGLE_SURFACE_RATIO',
    APPLY_SURFACE_RATIO = 'APPLY_SURFACE_RATIO',
    TOGGLE_ELECTRICAL_LABOR = 'TOGGLE_ELECTRICAL_LABOR',
    UPDATE_MOUNTING_SYSTEM_ACCESSORY = 'UPDATE_MOUNTING_SYSTEM_ACCESSORY',
}
export enum OpportunityActionType {
    REQUEST_OPPORTUNITY = 'REQUEST_OPPORTUNITY',
    SET_OPPORTUNITY = 'SET_OPPORTUNITY',
    FETCH_OPPORTUNITY_FAILED = 'FETCH_OPPORTUNITY_FAILED',
    DEFAULT_OPPORTUNITY_ACTION = 'DEFAULT_OPPORTUNITY_ACTION',
}

export enum ProductsActionType {
    PRODUCTS_SET = 'PRODUCTS_SET',
    PRODUCTS_UPDATE = 'PRODUCTS_UPDATE',
    DEFAULT_ACTION = 'DEFAULT_PRODUCT_ACTION',
}

export enum ModalActionType {
    OPEN_MODAL = 'OPEN_MODAL',
    CLOSE_MODAL = 'CLOSE_MODAL',
}

export enum NotificationsActionType {
    NEW_NOTIFICATION = 'NEW_NOTIFICATION',
    DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION',
    DEFAULT_NOTIFICATIONS_ACTION = 'DEFAULT_NOTIFICATIONS_ACTION',
}

// Action Payloads
export type UserTokenActionPayload = {
    accessToken: string;
};

export type UserSetTokensPayload = UserTokenActionPayload & {
    refreshToken: string;
};

export type UserRoleActionPayload = {
    role: UserRoles;
};

export type UiActionPayload = {
    [key in keyof UiState]?: UiState[key]; // same as UiState but all properties are optional
};

export type CloudQuoteActionPayload = {
    quoteId: string;
    data?: any;
    timeStamp?: number;
};

/* This is a temporary type, because we need to make SalesforceProductState not require every product in ProductTypes,
this will take a bit of work and will require a separate ticket */
export type MultipleProductPayload = {
    [key in Exclude<
        ProductTypes,
        'electricalCabinetUpgrades' | 'mountingSystem'
    >]?: SpecificQuoteProduct<key>;
} & {
    electricalCabinetUpgrades?: SpecificQuoteProduct<ElectricalCabinetUpgrade>[];
    mountingSystem?: SpecificQuoteProduct<MountingSystem>[];
};

export type RoiObject = { [key in RoiValueId]?: number | null };

export type DesignerQuoteActionPayload = {
    supplementary?: string;
    pyramidPanelsAmount?: number;
    annualYieldHours?: number;
    quoteName?: string;
    quoteId?: string;
    data?: any;
    surfaceId?: string;
    canvasId?: string;
    productType?: ProductTypes;
    product?: Product | null;
    quantity?: number;
    products?: MultipleProductPayload;
    productsMap?: ProductsState;
    surfaceAmount?: number;
    recordType?: RecordType;
    roiData?: RoiObject;
    color?: string;
    status?: string;
    oldRoofType?: RoofTypes;
    consumptionData?: ConsumptionObject;
};

export type ConsumptionObject = {
    energyConsumptionHouseholdKwh?: number | null;
    energyConsumptionSaunaKwh?: number | null;
    energyConsumptionPoolKwh?: number | null;
    energyConsumptionHeatPumpKwh?: number | null;
    energyConsumptionCarKwh?: number | null;
    energyConsumptionBigConsumersKwh?: number | null;
};

export type OpportunityActionPayload = {
    opportunityId: string;
    data?: any;
};

export type ModalActionPayload = {
    modalProps: ModalProps;
};

// Actions
export type AppAction = Action<AppActionType> & { isInitialised: boolean };
export type UserAction = {
    type: UserActionType;
    payload:
        | UserTokenActionPayload
        | UserRoleActionPayload
        | UserSetTokensPayload;
};
export type ProductsAction = Action<ProductsActionType> & {
    payload: ProductsMap;
};
export type InitialiseProductsAction = ThunkAction<
    void,
    RootState,
    unknown,
    ProductsAction
>;

export type CloudQuoteAction = {
    type: CloudQuoteActionType | QuoteActionType;
    payload: CloudQuoteActionPayload;
};

export type DesignerQuoteAction = {
    type:
        | DesignerQuoteActionType
        | QuoteActionType
        | OpportunityActionType.SET_OPPORTUNITY;
    payload: DesignerQuoteActionPayload;
};

export type OpportunityAction = {
    type: OpportunityActionType;
    payload: OpportunityActionPayload;
};

export type UiAction = {
    type: UiActionType;
    payload: UiActionPayload;
};

export type ModalAction = {
    type: ModalActionType;
    payload: ModalActionPayload;
};

export type NotificationsAction = {
    type: NotificationsActionType;
    payload: Notification;
};
//Action Creators
export type UserActionCreator<T> = (payload: T) => UserAction;
export type ProcessCodeActionCreator = (p: {
    code: string;
    fetchUrl: string;
    apiToken: string;
}) => ThunkAction<void, RootState, unknown, UserAction>;

export type CloudQuoteActionCreator<T> = (payload: T) => CloudQuoteAction;
export type CloudQuoteThunkActionCreator<T> = (
    payload: T
) => ThunkAction<void, RootState, unknown, CloudQuoteAction>;
export type GetQuoteActionCreator = (p: {
    quoteId: string;
}) => ThunkAction<void, RootState, unknown, CloudQuoteAction>;

export type DesignerQuoteActionCreator<T> = (payload: T) => DesignerQuoteAction;
export type DesignerQuoteUpdateActionCreator = (
    action:
        | DesignerQuoteAction
        | ThunkAction<void, RootState, unknown, DesignerQuoteAction>
        | { type: StateHistoryActionTypes }
) => ThunkAction<void, RootState, unknown, DesignerQuoteAction>;

export type DesignerQuoteThunkActionCreator<T> = (
    payload: T
) => ThunkAction<void, RootState, unknown, DesignerQuoteAction>;

export type UiThunkActionCreator<T> = (
    payload: T
) => ThunkAction<void, RootState, unknown, UiAction>;
export type SaveQuoteActionCreator = (
    finishCallback?: (success: boolean) => void,
    shouldUpdate?: boolean,
    isNotificationDismissible?: boolean
) => ThunkAction<void, RootState, unknown, CloudQuoteAction>;

export type UiActionCreator<T> = (payload: T) => UiAction;

export type OpportunityActionCreator<T> = (payload: T) => OpportunityAction;
export type GetOpportunityActionCreator = (p: {
    opportunityId: string;
}) => ThunkAction<void, RootState, unknown, OpportunityAction>;

export type ModalActionCreator<T> = (payload: T) => ModalAction;
export type ModalCloseActionCreator = () => { type: ModalActionType };

export type NotificationsActionCreator = (
    payload: NotificationPayload
) => NotificationsAction;

export type AppDispatch = Dispatch<
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    { type: string; payload?: any } | ThunkAction<any, any, any, any> | void
>;

// Reducers
export type AppReducer = Reducer<AppState, AppAction>;
export type UserReducer = Reducer<UserState, UserAction>;
export type ProductsReducer = Reducer<ProductsState, ProductsAction>;
export type CloudQuoteReducer = Reducer<CloudQuoteState, CloudQuoteAction>;
export type DesignerQuoteReducer = Reducer<
    DesignerQuoteState,
    DesignerQuoteAction
>;
export type OpportunityReducer = Reducer<OpportunityState, OpportunityAction>;
export type ModalReducer = Reducer<ModalState, ModalAction>;
export type NotificationsReducer = Reducer<
    NotificationsState,
    NotificationsAction
>;
export type UiReducer = Reducer<UiState, UiAction>;

export type StringIndexable<T = any> = {
    [key: string]: T;
};

// Geometry
export type Point = {
    x: number;
    y: number;
};
export type Dimensions = {
    width: number;
    height: number;
};
export type Rectangle = Point &
    Dimensions & {
        left: number;
        top: number;
        right: number;
        bottom: number;
    };

export type Vertex = [number, number];
export type Vertices = Vertex[];

export type PerspectiveTransform = {
    transform: (x: number, y: number) => [number, number];
    transformInverse: (x: number, y: number) => [number, number];
    coeffs: number[];
};
// Misc
export type SelectItem = {
    value: string | number;
    text: string;
    key?: string;
    description?: string;
    content?: React.ReactNode;
    disabled?: boolean;
    alternating?: boolean;
    sfvalue?: string;
};
export enum ArrowKey {
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    ArrowUp = 'ArrowUp',
    ArrowDown = 'ArrowDown',
}
export type ToolBarItemDescriptor = {
    id: string;
    semanticIcon?: SemanticICONS;
    imageIconSrc?: string;
};
export type ToolBarGroupDescriptor = {
    id: string;
    items: ToolBarItemDescriptor[];
};

export type Layer = {
    name: string;
    id: string;
    data?: any;
    imageIconSrc?: string;
};
export type Side = 'top' | 'left' | 'bottom' | 'right';

// Sizes
const sizePropName = 'size';
export type ModalSize = SemanticModalProps[typeof sizePropName];
export type PictureSize = ImageProps[typeof sizePropName];
export type InputSize = InputProps[typeof sizePropName];
export type ColumnSize = StrictGridProps['columns'];
export type Direction = DropdownProps['direction'];
export type Position = DropdownProps['additionPosition'];

export type StringPosition = {
    key: string;
    index: number;
};

// Planning
export type Spacing = {
    x: number;
    y: number;
};

export type PanelPlanningData = Point & {
    isHidden?: boolean;
    isHorizontal?: boolean;
    stringPosition?: StringPosition;
    spacing?: Spacing;
    facing?: Facing;
};

export type Facing = {
    side: string;
    hasGutter: boolean;
    direction?: SelectItem;
    isset?: boolean;
};

export type UnsetPanelPlanningData = {
    x?: number;
    y?: number;
    isHorizontal?: boolean;
};

export type SurfacePayload = {
    slope: number | string | undefined;
    directions: DirectionsPlanningData;
    yield_reduction: number | undefined;
};

export type DirectionsPlanningData = Array<{
    azimuth: number;
    quantity: number;
}>;

export enum RoofTypes {
    slope = 'slope',
    flat = 'flat',
}
export enum FlatRoofModes {
    normal = 'normal',
    alternate = 'alternate',
}

export type SideDirections = {
    bottom?: SelectItem;
    left?: SelectItem;
    top?: SelectItem;
    right?: SelectItem;
};

export type surfaceRatios = {
    surfaceWidth: number;
    surfaceHeight: number;
    ratio: { x: number; y: number };
    ratioVertices: Vertices;
};

export type ModulePlanningData = {
    id: string;
    width: number;
    height: number;
    azimuth?: number;
    sideDirections?: SideDirections;
    slope?: number;
    hasGutter?: boolean;
    yieldReduction?: number;
    roofType?: RoofTypes;
    flatRoofMode?: FlatRoofModes;
    vertices?: Vertices;
    panels: PanelPlanningData[];
    gutter?: number;
    annualYieldHours?: number;
    surfaceGutter?: number;
    ratios?: surfaceRatios;
};

export enum ButtonType {
    visibility = 'visibility',
    lock = 'lock',
    delete = 'delete',
    label = 'label',
}

export enum BatteryBrands {
    BYD = 'BYD',
    LG = 'LG',
    RCTPower = 'RCT Power',
    Axitec = 'Axitec',
    Sungrow = 'Sungrow',
    SolarEdge = 'SolarEdge',
}

export enum WallboxBrands {
    RCTPower = 'RCT Power',
    EGO = 'go-e',
    Kostal = 'Kostal',
}

export enum LayerType {
    crops = 'crops',
    surface = 'surface',
    nogo = 'no go areas',
}

export type GeometryState = {
    position: Point;
    rotation: number;
    scaleValue: number;
};

export type MinAndMax = {
    min: number;
    max: number;
};

export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

export type VoidHandler = () => void;
export type PanelsUpdateHandler = (panels: PanelPlanningData[]) => void;
export type VerticesUpdateHandler = (vertices: Vertices) => void;
export type StringsUpdateHandler = (
    e: React.MouseEvent<SVGRectElement, MouseEvent>,
    i: number,
    canvasId: string,
    surfaceId: string,
    canvasIndex: number,
    surfaceIndex: number
) => void;

export type RoiValueId =
    | SalesRoiValueId
    | 'monthlyElectricityCosts'
    | 'electricityCostKwh'
    | 'electricityBoughtAtInitialRate';

// Roles

export type SalesRoiValueId =
    | 'annualYieldHours'
    | 'monthlyElectricityCosts'
    | 'selfConsumptionRate'
    | 'selfConsumptionKwh'
    | 'reducedAnnualYieldHours'
    | 'degreeOfSelfSufficiency';

export enum UserRoles {
    planner = 'planner',
    sales = 'sales',
    stringPlanner = 'stringPlanner',
}

export type UserRolesMap<T = any> = { [key in UserRoles]?: T };

export enum UserPermissionIds {
    canManipulateSurfaces = 'canManipulateSurfaces',
    canAddPanels = 'canAddPanels',
    canManipulateElectricalCabinetUpgrades = 'canManipulateElectricalCabinetUpgrades',
    canMovePanels = 'canMovePanels',
    canStringPanels = 'canStringPanels',
    canManipulateScaffolding = 'canManipulateScaffolding',
    canOverwriteQuotes = 'canOverwriteQuotes',
    canStartNewPlanning = 'canStartNewPlanning',
    canUseTemplate = 'canUseTemplate',
    canSeeGrids = 'canSeeGrids',
    canSeeGutter = 'canSeeGutter',
    canChangeRecordType = 'canChangeRecordType',
    canSeeSurfaceOutline = 'canSeeSurfaceOutline',
    canSeeDraftQuotes = 'canSeeDraftQuotes',
    canManipulateAdditionalLabor = 'canManipulateAdditionalLabor',
    canManipulateElectricalLabor = 'canManipulateElectricalLabor',
}

export type UserPermissions = {
    [key in UserPermissionIds]: boolean;
};

export enum UseCases {
    inLogo = 'inLogo',
    inLayersMenu = 'inLayersMenu',
    inStartPlanningWizard = 'inStartPlanningWizard',
    inInputNumber = 'inInputNumber',
}

// SaveQuote Types
export type AddingStack = {
    toAdd: Product[];
    products: SalesforceProductsState;
};

export type UpdateObject = { id: string; Quantity: number };

export type ChangesStack = {
    toAdd: Product[];
    toDelete: string[]; // recordIds
    toUpdate: UpdateObject[]; // we only update the quantity
    oldProducts: SalesforceProductsState;
    newProducts: SalesforceProductsState;
    quoteLineItems: QuoteLineItem[];
};

type TranslateObject = {
    translate: string;
};

export type ShortcutDisplayItem = (TranslateObject | string)[];

export type ShortcutDisplay = ShortcutDisplayItem[];

export type HotkeyItem = {
    name: string;
    hook: string;
    display: ShortcutDisplay;
};

export type AccessTokenFunction = (
    fresh: boolean
) => Promise<string | undefined>;

export type ApiFilter = {
    name: 'role' | 'recordType' | 'shade' | 'quote_id' | 'qa_review_id';
    value: string;
};

export type StringKey = 'a' | 'b' | 'c' | 'd' | 'e' | 'none';

export type StringPathIndexes = {
    a: number;
    b: number;
    c: number;
    d: number;
    e: number;
};

export type SortType = 'date' | 'sync' | 'status';

export type WindZone = 0 | 1 | 2 | 3 | 4;
