import {
    UserActionType,
    UserReducer,
    UserRoleActionPayload,
    UserSetTokensPayload,
    UserTokenActionPayload,
} from '../../common/types';
import { defaultUserState } from '../../store/defaults';

export const userReducer: UserReducer = (state = defaultUserState, action) => {
    switch (action.type) {
        case UserActionType.SET_USER_ROLE:
            return {
                ...state,
                role: (action.payload as UserRoleActionPayload).role,
            };
        case UserActionType.SET_TOKENS:
            return {
                ...state,
                isAuth: true,
                accessToken: (action.payload as UserSetTokensPayload)
                    .accessToken,

                refreshToken: (action.payload as UserSetTokensPayload)
                    .refreshToken,
            };
        case UserActionType.RENEW_ACCESS_TOKEN:
            return {
                ...state,
                isAuth: true,
                accessToken: (action.payload as UserTokenActionPayload)
                    .accessToken,
            };
        default:
            return state;
    }
};
