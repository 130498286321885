import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { initialiseProducts } from '../../features/products/productsActions';
import { getUpdatedIds } from '../functions';
import { Product, ProductTypes, RootState } from '../types';
import { useAppDispatch } from './index';
/**
 * Use this hook to obtain products. It will get the data from the state or will fetch it using API.
 * Example:
 * ```
 * ...
 *   const [panels, updatedPanelIDs, validateUpdatedIDs] = useProducts<Panel>(ProductTypes.PANEL);
 *   return (
 *       <>
 *           {updatedPanelIDs.length > 0 && (
 *               <SomeDialogue onOk={validateUpdatedIDs} />
 *           )}
 *           <CustomSelect items={panels} />
 *       </>
 *   )
 * ...
 * ```
 * Consider your `CustomSelect` to accept `items` as prop of type `ObjectWithNameAndId`.
 * @returns an array that consists of:
 *  1. products
 *  2. updated products - array of IDs of those products that have been modified/removed since first time the hook returned the results
 *  3. a function by calling which you can validate the updated products
 */
export default function useProducts<T extends Product>(
    productType: ProductTypes
): [T[] | undefined, string[], () => void] {
    const dispatch = useAppDispatch();
    const [state, setState] = useState<{
        lastProducts?: Product[];
        currentProducts?: Product[];
        updatedProductIds: string[];
        validateProducts: () => void;
    }>({
        updatedProductIds: [],
        validateProducts: () => {
            setState({
                ...state,
                lastProducts: state.currentProducts,
                updatedProductIds: [],
            });
        },
    });
    const products = useSelector<RootState, Product[] | undefined>(
        (state) => state.products[productType]
    );
    const isInitialising = useSelector<RootState, boolean>(
        (state) => state.ui.isInitialisingProducts
    );

    useEffect(() => {
        if (!products) {
            if (
                !isInitialising &&
                productType !== ProductTypes.RECOMMENDED_INVERTERS
            ) {
                dispatch(initialiseProducts);
                return;
            }
        } else {
            const updatedProductIds = getUpdatedIds(
                state.lastProducts || [],
                products
            );

            setState({
                ...state,
                ...(!state.lastProducts ? { lastProducts: products } : {}),
                currentProducts: products,
                updatedProductIds,
            });
        }
    }, [products]);

    return [
        products as T[] | undefined,
        state.updatedProductIds,
        state.validateProducts,
    ];
}
