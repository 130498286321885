import React, { CSSProperties, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ObjectWithNameAndId,
    RecordType,
    RoofToolPlanning,
    UserPermissions,
} from '../../common/types';
import {
    setPlanningData,
    setRecordType,
} from '../../features/designerQuote/designerQuoteActions';
import Button from '../Button';
import Switch from '../Switch';
import styles from './StartPlanningWizard.module.scss';
import { useAppDispatch } from '../../common/hooks';

type Props = {
    className?: string;
    templatePlanningData: RoofToolPlanning[];
    permissions: UserPermissions;
    recordTypeOptions: RecordType[];
    recordType?: RecordType;
};

const DialogChildren: FunctionComponent<Props> = ({
    templatePlanningData,
    permissions,
    recordTypeOptions,
    recordType,
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('startPlanning');

    const setTemplateToPlanningData = (planningData: RoofToolPlanning[]) => {
        dispatch(setPlanningData({ data: planningData }));
    };

    const handleRecordTypeChange = (data: string) => {
        const newType = recordTypeOptions.find((type) => type.id === data);
        newType &&
            dispatch(
                setRecordType({
                    recordType: newType,
                })
            );
    };

    const switchOptions: ObjectWithNameAndId[] =
        recordTypeOptions.length > 0
            ? recordTypeOptions.map((record: RecordType) => ({
                  name: t(record.name),
                  id: record.id,
              }))
            : [];

    const strokeStyle = { '--stroke-width': 12 } as CSSProperties;

    return (
        <div className={styles.dialogChildren}>
            {templatePlanningData.length > 0 && permissions.canUseTemplate && (
                <Button
                    className="btnTemplate"
                    onClick={() => {
                        setTemplateToPlanningData(templatePlanningData);
                    }}
                    icon="plus"
                    style={strokeStyle}
                >
                    {t('templateButton')}
                </Button>
            )}
            {permissions.canChangeRecordType &&
                recordTypeOptions.length > 0 &&
                recordType && (
                    <Switch
                        switchOptions={switchOptions}
                        switchValue={recordType.id}
                        handleChange={handleRecordTypeChange}
                    />
                )}
        </div>
    );
};

export default DialogChildren;
