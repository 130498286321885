import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import {
    Header as SemanticHeader,
    SemanticTEXTALIGNMENTS,
} from 'semantic-ui-react';
import styles from './Header.module.scss';

type HeaderVariant = '' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type Props = {
    style?: CSSProperties;
    className?: string;
    variant?: HeaderVariant;
    attached?: boolean | 'top' | 'bottom';
    textAlign?: SemanticTEXTALIGNMENTS;
    size?: 'tiny' | 'small' | 'medium' | 'large' | 'huge';
    children: React.ReactNode;
};

const Header: FunctionComponent<Props> = ({
    children,
    style,
    className,
    attached,
    textAlign,
    size,
    variant = 'h3',
}) => {
    switch (variant) {
        case '':
            return (
                <SemanticHeader
                    style={style}
                    className={classNames(className, styles.header)}
                    as="header"
                >
                    {children}
                </SemanticHeader>
            );

        default:
            return (
                <SemanticHeader
                    style={style}
                    as={variant}
                    textAlign={textAlign}
                    attached={attached}
                    size={size}
                    className={classNames(className, styles.header)}
                >
                    {children}
                </SemanticHeader>
            );
    }
};

export default Header;
