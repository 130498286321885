import i18next from 'i18next';
import { Dispatch } from 'redux';
import { updateOpportunity } from '../../api/fetchOpportunity';
import { saveQuoteMethod } from '../../api/saveQuote';
import { updateQuoteMethod } from '../../api/updateQuote';
import { DEFAULT_MOUNTING_SYSTEM_COLOR } from '../../common/constants';
import {
    createCanvasesFromArrayOfPhotos,
    createDefaultQuoteName,
    createGetToken,
    getDefaultObjectIdFromCollectionObject,
    getMountingSystemColorFromQuote,
    getPyramidMountingSystemFromQuote,
    getSurfacesOfPlanning,
} from '../../common/functions';
import { selectMountingSystems } from '../../common/functions/autoProductSelections';
import {
    dimsInPx,
    getOrientationCount,
    hasDefaultQuoteNameFormat,
    renameKeys,
    snakeToCamel,
} from '../../common/helpers';
import { saveToLocalStorage } from '../../common/localStorage';
import { RatedInverter, RatedInverterCamel } from '../../common/responseTypes';
import {
    AdditionalLabor,
    DesignerQuoteActionCreator,
    DesignerQuoteActionPayload,
    DesignerQuoteActionType,
    DesignerQuoteThunkActionCreator,
    DesignerQuoteUpdateActionCreator,
    ElectricalCabinetUpgrade,
    ElectricalLabor,
    MultipleProductPayload,
    NotificationPayload,
    Product,
    ProductsState,
    ProductTypes,
    RoiObject,
    RootState,
    SaveQuoteActionCreator,
    UserRoles,
} from '../../common/types';
import { createUUID } from '../../common/uuids';
import { isManualStringPlanNeeded } from '../../components/StringPlanner/functions';
import i18n from '../../i18n';
import { getCloudQuote, setQuoteId } from '../cloudQuote/cloudQuoteActions';
import {
    dismissNotification,
    newNotification,
} from '../notifications/notificationsActions';
import { updateProducts } from '../products/productsActions';
import { undo } from '../stateHistory/stateHistoryActions';
import { updateUi } from '../ui/uiActions';

export const dispatchAndSaveToLocalStorage: DesignerQuoteUpdateActionCreator =
    (action) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        dispatch<any>(action);
        const state = getState();
        saveToLocalStorage(
            state.opportunity.opportunityId,
            state.designerQuote
        );
    };

export const addPanel: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> =
    () =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const state = getState();
        const { activeCanvasId, activeSurfaceId } = state.ui;

        if (!activeCanvasId || !activeSurfaceId) return;

        const activeSurface =
            state.designerQuote.planning.canvases[activeCanvasId].surfaces[
                activeSurfaceId
            ];

        if (!activeSurface.width || !activeSurface.height) {
            dispatch(
                newNotification({
                    type: 'error',
                    message: i18next.t(
                        'notifications:surfaceDimensionsMissing'
                    ),
                    icon: 'edit',
                })
            );
            return;
        }

        dispatch({
            type: DesignerQuoteActionType.ADD_PANEL,
            payload: { data: { activeCanvasId, activeSurfaceId } },
        });
    };

export const updatePanelAmount: DesignerQuoteActionCreator<
    DesignerQuoteActionPayload
> = (payload) => {
    return {
        type: DesignerQuoteActionType.UPDATE_PANEL_AMOUNT,
        payload,
    };
};

export const setPanels: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> =
    ({ data: panels, oldRoofType }) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const state = getState();
        const { activeCanvasId, activeSurfaceId } = state.ui;

        activeCanvasId &&
            activeSurfaceId &&
            dispatch({
                type: DesignerQuoteActionType.SET_PANELS,
                payload: {
                    data: {
                        activeCanvasId,
                        activeSurfaceId,
                        panels,
                        oldRoofType,
                    },
                },
            });
    };

export const setVertices: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> =
    ({ data: vertices }) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const state = getState();
        const { activeCanvasId, activeSurfaceId } = state.ui;

        if (activeCanvasId && activeSurfaceId) {
            dispatch({
                type: DesignerQuoteActionType.SET_VERTICES,
                payload: {
                    data: { activeCanvasId, activeSurfaceId, vertices },
                },
            });
        }
    };

export const rotatePanels: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> =
    () =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const state = getState();

        // Rotation toggle is based on undo and lastAction features.
        if (state.lastAction?.type === DesignerQuoteActionType.ROTATE_PANELS) {
            dispatch<any>(undo());
            return;
        }

        const activeSurfaceId = state.ui.activeSurfaceId;
        const activeCanvasId = state.ui.activeCanvasId;
        const selectedPanels = state.ui.selectedPanelIndices;

        selectedPanels &&
            activeCanvasId &&
            activeSurfaceId &&
            dispatch(
                rotateSelectedPanels({
                    activeCanvasId,
                    activeSurfaceId,
                    selectedPanels,
                })
            );
    };

export const rotateSelectedPanels: DesignerQuoteActionCreator<{
    activeCanvasId: string;
    activeSurfaceId: string;
    selectedPanels: number[];
}> = (payload) => {
    return {
        type: DesignerQuoteActionType.ROTATE_PANELS,
        payload: { data: payload },
    };
};

export const removePanels: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> =
    () =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const state = getState();
        const { activeCanvasId, activeSurfaceId, selectedPanelIndices } =
            state.ui;

        dispatch(updateUi({ selectedPanelIndices: [] }));

        activeCanvasId &&
            activeSurfaceId &&
            dispatch({
                type: DesignerQuoteActionType.REMOVE_PANELS,
                payload: {
                    data: {
                        activeCanvasId,
                        activeSurfaceId,
                        selectedPanelIndices,
                    },
                },
            });
    };

export const togglePanelFacing: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> =
    () =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const state = getState();
        const { activeCanvasId, activeSurfaceId, selectedPanelIndices } =
            state.ui;

        activeCanvasId &&
            activeSurfaceId &&
            dispatch({
                type: DesignerQuoteActionType.TOGGLE_PANEL_FACING,
                payload: {
                    data: {
                        activeCanvasId,
                        activeSurfaceId,
                        selectedPanelIndices,
                        activeSurfaceSideDirections:
                            state.designerQuote.planning.canvases[
                                activeCanvasId
                            ].surfaces[activeSurfaceId].sideDirections,
                    },
                },
            });
    };

export const togglePanelsVisibility: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> =
    () =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const state = getState();
        const { activeCanvasId, activeSurfaceId, selectedPanelIndices } =
            state.ui;

        activeCanvasId &&
            activeSurfaceId &&
            dispatch({
                type: DesignerQuoteActionType.TOGGLE_PANELS_VISIBILITY,
                payload: {
                    data: {
                        activeCanvasId,
                        activeSurfaceId,
                        selectedPanelIndices,
                    },
                },
            });
    };

export const setRecordType: DesignerQuoteActionCreator<
    DesignerQuoteActionPayload
> = (payload) => {
    return {
        type: DesignerQuoteActionType.SET_RECORD_TYPE,
        payload,
    };
};

export const setLocalState: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> = (payload) => (dispatch: Dispatch) => {
    if (payload.data.salesforce.name) {
        const isUserSelectedQuoteName = !hasDefaultQuoteNameFormat(
            payload.data.salesforce.name
        );
        dispatch(updateUi({ isUserSelectedQuoteName }));
    }

    dispatch({
        type: DesignerQuoteActionType.UPDATE,
        payload,
    });
};
export const addPhotos: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> = (payload) => async (dispatch: Dispatch) => {
    const newCanvas = await createCanvasesFromArrayOfPhotos(payload.data);

    dispatch({
        type: DesignerQuoteActionType.ADD_PHOTOS,
        payload: { data: newCanvas },
    });

    const activeCanvasId = getDefaultObjectIdFromCollectionObject(
        newCanvas.canvases
    );

    dispatch(
        updateUi({
            activeCanvasId,
        })
    );
};

export const setPlanningData: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> = (payload) => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({
        type: DesignerQuoteActionType.SET_PLANNING_DATA,
        payload,
    });

    const activeCanvasId = getDefaultObjectIdFromCollectionObject(
        getState().designerQuote.planning.canvases
    );

    dispatch(
        updateUi({
            activeCanvasId,
        })
    );
};

export const setDefaultQuoteName: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> = () => (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const defaultName = createDefaultQuoteName(state.designerQuote);

    dispatch(
        updateQuoteName({
            quoteName: defaultName,
        })
    );
};

export const setIsSaved: DesignerQuoteActionCreator<
    DesignerQuoteActionPayload
> = (payload) => {
    return {
        type: DesignerQuoteActionType.SET_IS_SAVED,
        payload,
    };
};

export const saveOrUpdateQuote: SaveQuoteActionCreator =
    (finishCallback, shouldUpdate = false, isNotificationDismissible = true) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const state = getState();

        if (
            (!state.user.accessToken ||
                isManualStringPlanNeeded(state.designerQuote)) &&
            state.user.role === UserRoles.stringPlanner &&
            state.opportunity.qaReviewId
        ) {
            dispatch(
                newNotification({
                    type: 'error',
                    message: i18n.t(
                        !state.user.accessToken
                            ? 'notifications:noTokenError'
                            : 'notifications:stringPlanIncompleteError'
                    ),
                })
            );
            finishCallback && finishCallback(false);
            return;
        }

        if (
            !state.designerQuote.salesforce.name ||
            state.designerQuote.salesforce.name === ''
        ) {
            dispatch<any>(setDefaultQuoteName({}));
        }

        const saveNoti: NotificationPayload = {
            type: 'info',
            message: i18n.t('notifications:savingQuote', {
                quoteName: state.designerQuote.salesforce.name,
            }),
            dismissible: isNotificationDismissible,
            spinning: true,
        };
        const saveNotificationAction = newNotification(saveNoti);
        dispatch(saveNotificationAction);

        // Do the whole save shebam

        const accessToken = createGetToken(dispatch, getState);

        const [quoteId, error] =
            shouldUpdate && state.cloudQuote?.quoteId
                ? await updateQuoteMethod({
                      rootState: state,
                      opportunityId: state.opportunity.opportunityId,
                      quoteId: state.cloudQuote?.quoteId,
                      accessToken,
                  })
                : await saveQuoteMethod({
                      rootState: state,
                      opportunityId: state.opportunity.opportunityId,
                      accessToken,
                  });

        if (error) {
            dispatch(dismissNotification(saveNotificationAction.payload));
            dispatch(
                newNotification({
                    type: 'error',
                    message: i18n.t(
                        'notifications:saveTemplatePlanningDataError',
                        {
                            error: error,
                        }
                    ),
                    icon: 'save',
                })
            );
            finishCallback && finishCallback(false);
            return;
        }

        dispatch(dismissNotification(saveNotificationAction.payload));
        dispatch(
            newNotification({
                type: 'success',
                message: i18n.t('notifications:saveQuotePlanningDataSuccess'),
                icon: 'save',
            })
        );

        dispatch(
            setIsSaved({
                quoteId: quoteId!,
                data: { timeStamp: Date.now() },
            })
        );

        state.ui.openAfterSavingQuoteId
            ? dispatch<any>(
                  getCloudQuote({ quoteId: state.ui.openAfterSavingQuoteId })
              )
            : dispatch<any>(setQuoteId({ quoteId: quoteId! }));

        finishCallback && finishCallback(true);
    };

export const saveTemplatePlanningData: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> = () => async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const planningData = state.designerQuote.planning;
    const id = state.opportunity.opportunityId;

    try {
        await updateOpportunity({
            opportunityId: id,
            payload: {
                planning_data__c: JSON.stringify(planningData),
            },
            accessToken: createGetToken(dispatch, getState),
        });

        dispatch(
            newNotification({
                type: 'success',
                message: i18n.t(
                    'notifications:saveTemplatePlanningDataSuccess'
                ),
                icon: 'save',
            })
        );
    } catch (e) {
        dispatch(
            newNotification({
                type: 'error',
                message: i18n.t('notifications:saveTemplatePlanningDataError', {
                    error: e,
                }),
            })
        );
    }
};

export const deleteCanvas: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> = (payload) => async (dispatch: Dispatch, getState: () => RootState) => {
    const canvasId = payload.data.canvasId as string;
    const state = getState();
    const activeCanvasId = state.ui.activeCanvasId;
    const canvases = { ...state.designerQuote.planning.canvases };
    delete canvases[canvasId];

    if (canvasId === activeCanvasId) {
        //If there are canvases left, set the first canvas in the object to be the active canvas
        if (Object.keys(canvases).length > 0) {
            dispatch(
                updateUi({
                    activeCanvasId:
                        getDefaultObjectIdFromCollectionObject(canvases),
                })
            );
        } else {
            dispatch(updateUi({ activeCanvasId: undefined }));
        }
    }

    dispatch({
        type: DesignerQuoteActionType.DELETE_CANVAS,
        payload,
    });
};

export const toggleSurfaceGutter: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> =
    () =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const state = getState();
        const activeCanvasId = state.ui.activeCanvasId;
        const activeSurfaceId = state.ui.activeSurfaceId;

        dispatch({
            type: DesignerQuoteActionType.TOGGLE_SURFACE_GUTTER,
            payload: { data: { activeCanvasId, activeSurfaceId } },
        });
    };

export const updateActiveSurfaceYield: DesignerQuoteThunkActionCreator<
    number | null
> =
    (annualYieldHours) =>
    async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const activeCanvasId = state.ui.activeCanvasId;
        const activeSurfaceId = state.ui.activeSurfaceId;
        dispatch({
            type: DesignerQuoteActionType.UPDATE_SURFACE_ANNUAL_YIELD_HOURS,
            payload: {
                annualYieldHours,
                surfaceId: activeSurfaceId,
                canvasId: activeCanvasId,
            },
        });
    };

export const deleteSurface: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> = (payload) => async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const activeCanvasId = state.ui.activeCanvasId;

    // Deselecting the surface first
    dispatch(updateUi({ activeSurfaceId: undefined }));

    dispatch({
        type: DesignerQuoteActionType.DELETE_SURFACE,
        payload: {
            ...payload,
            canvasId: activeCanvasId,
        },
    });
};

export const addSurface: DesignerQuoteActionCreator<
    DesignerQuoteActionPayload
> = (payload) => {
    const surface = {
        ...payload.data.surface,
        id: createUUID(),
        hasGutter: false,
        surfaceGutter: 0,
    };
    return {
        type: DesignerQuoteActionType.ADD_SURFACE,
        payload: { data: { surface, canvasId: payload.data.canvasId } },
    };
};

export const updateSurface: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> = (payload) => async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const canvasId = state.ui.activeCanvasId;
    const activeSurfaceId = state.ui.activeSurfaceId;
    const panels =
        canvasId &&
        activeSurfaceId &&
        state.designerQuote.planning.canvases[canvasId].surfaces[
            activeSurfaceId
        ].panels;

    dispatch({
        type: DesignerQuoteActionType.UPDATE_SURFACE,
        payload: { ...payload, canvasId },
    });

    if (panels) dispatch<any>(setPanels({ data: panels }));
};

export const updateProduct: DesignerQuoteActionCreator<{
    productType: ProductTypes;
    product: Product | null;
}> = (payload) => {
    return {
        type: DesignerQuoteActionType.UPDATE_PRODUCT,
        payload,
    };
};

export const updateMountingSystemAccessory: DesignerQuoteActionCreator<{
    product: Product | null;
    supplementary: string;
}> = (payload) => {
    return {
        type: DesignerQuoteActionType.UPDATE_MOUNTING_SYSTEM_ACCESSORY,
        payload,
    };
};

export const updateMultipleProducts: DesignerQuoteActionCreator<{
    products: MultipleProductPayload;
}> = (payload) => {
    return {
        type: DesignerQuoteActionType.UPDATE_MULTIPLE_PRODUCTS,
        payload,
    };
};

export const toggleUpgrade: DesignerQuoteActionCreator<{
    product: ElectricalCabinetUpgrade;
}> = (payload) => {
    return {
        type: DesignerQuoteActionType.TOGGLE_UPGRADE,
        payload,
    };
};

export const toggleElectricalLabor: DesignerQuoteActionCreator<{
    product: ElectricalLabor;
}> = (payload) => {
    return {
        type: DesignerQuoteActionType.TOGGLE_ELECTRICAL_LABOR,
        payload,
    };
};

export const addOrUpdateProductInArray: DesignerQuoteActionCreator<{
    productType: ProductTypes;
    product: AdditionalLabor;
    quantity: number;
}> = (payload) => {
    return {
        type: DesignerQuoteActionType.ADD_OR_UPDATE_PRODUCT_IN_ARRAY,
        payload,
    };
};

export const setDefaultProducts: DesignerQuoteActionCreator<{
    productsMap: ProductsState;
}> = (payload) => {
    return {
        type: DesignerQuoteActionType.SET_DEFAULT_PRODUCTS,
        payload,
    };
};

export const updateROIValue: DesignerQuoteActionCreator<
    Pick<DesignerQuoteActionPayload, 'roiData'>
> = (payload) => {
    return {
        type: DesignerQuoteActionType.UPDATE_ROI,
        payload,
    };
};

export const updateMachineROIValueInPlanning: DesignerQuoteActionCreator<
    Pick<DesignerQuoteActionPayload, 'roiData'>
> = (payload) => {
    return {
        type: DesignerQuoteActionType.UPDATE_MACHINE_ROI_VALUE,
        payload,
    };
};

export const updateManualROIValueInPlanning: DesignerQuoteActionCreator<
    Pick<DesignerQuoteActionPayload, 'roiData'>
> = (payload) => {
    return {
        type: DesignerQuoteActionType.UPDATE_MANUAL_ROI_VALUE,
        payload,
    };
};

export const updateTotalAnnualConsumptionValue = () => {
    return {
        type: DesignerQuoteActionType.UPDATE_TOTAL_ANNUAL_CONSUMPTION,
    };
};

export const updateMonthlyCosts = () => {
    return {
        type: DesignerQuoteActionType.UPDATE_MONTHLY_COSTS,
    };
};

export const updateConsumptionData: DesignerQuoteActionCreator<
    Pick<DesignerQuoteActionPayload, 'consumptionData'>
> = (payload) => {
    return {
        type: DesignerQuoteActionType.UPDATE_ENERGY_CONSUMPTION,
        payload,
    };
};

export const setValidMountingSystems: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> = (payload) => (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const color =
        payload.color === undefined
            ? getMountingSystemColorFromQuote(state.designerQuote) ??
              DEFAULT_MOUNTING_SYSTEM_COLOR
            : payload.color;
    const pyramidCount =
        payload.pyramidPanelsAmount === undefined
            ? getPyramidMountingSystemFromQuote(state.designerQuote)
                  ?.quantity ?? 0
            : payload.pyramidPanelsAmount;
    const systems = selectMountingSystems(
        state.products?.mountingSystem ?? [],
        color,
        pyramidCount,
        getOrientationCount(getSurfacesOfPlanning(state.designerQuote.planning))
    );
    if (systems.length > 0) {
        dispatch(
            updateMultipleProducts({
                products: {
                    mountingSystem: systems,
                },
            })
        );
    }
};

export const setRecommendedInverters: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> = (payload) => async (dispatch: Dispatch) => {
    const recommendedInverters = payload.data.recommendedInverters;
    const secondRecommendedInverters = payload.data.secondRecommendedInverters;
    const annualYieldHours = payload.data.annualYieldHours;
    const reducedAnnualYieldHours =
        payload.data?.reducedAnnualYieldHours || undefined;

    const recommended = recommendedInverters.map((inverter: RatedInverter) =>
        renameKeys(snakeToCamel, inverter)
    ) as RatedInverterCamel[];

    const secondRecommended = secondRecommendedInverters.map(
        (inverter: RatedInverter) => renameKeys(snakeToCamel, inverter)
    ) as RatedInverterCamel[];

    dispatch(
        updateProducts({
            recommendedInverters: recommended,
            secondRecommendedInverters: secondRecommended,
        })
    );

    const roiData: RoiObject = {};
    if (annualYieldHours) {
        roiData.annualYieldHours = annualYieldHours;
    }
    if (reducedAnnualYieldHours) {
        roiData.reducedAnnualYieldHours = reducedAnnualYieldHours;
    }

    Object.keys(roiData).length && dispatch(updateROIValue({ roiData }));
};

export const updateQuoteName: DesignerQuoteActionCreator<
    DesignerQuoteActionPayload
> = (payload) => ({
    type: DesignerQuoteActionType.SET_QUOTE_NAME,
    payload,
});

export const addPanelToString: DesignerQuoteActionCreator<
    DesignerQuoteActionPayload
> = (payload) => ({
    type: DesignerQuoteActionType.ADD_PANEL_TO_STRING,
    payload,
});

export const removeAllPanelsOnStrings: DesignerQuoteActionCreator<
    DesignerQuoteActionPayload
> = (payload) => ({
    type: DesignerQuoteActionType.REMOVE_ALL_PANELS_FROM_REMOVED_STRINGS,
    payload,
});

export const removePanelFromString: DesignerQuoteActionCreator<
    DesignerQuoteActionPayload
> = (payload) => {
    return {
        type: DesignerQuoteActionType.REMOVE_PANEL_FROM_STRING,
        payload,
    };
};
export const resetProductSelection: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> = (payload) => async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    dispatch({
        type: DesignerQuoteActionType.RESET_PRODUCT_SELECTION,
        payload,
    });
    dispatch(setDefaultProducts({ productsMap: state.products }));
};
export const setQuoteStatus: DesignerQuoteActionCreator<
    DesignerQuoteActionPayload
> = (payload) => ({
    type: DesignerQuoteActionType.SET_QUOTE_STATUS,
    payload,
});

export const toggleSurfaceRatio: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> = () => (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const { activeCanvasId, activeSurfaceId } = state.ui;

    if (!activeCanvasId || !activeSurfaceId) return;

    const activeCanvas = state.designerQuote.planning.canvases[activeCanvasId];
    const activeSurface = activeCanvas.surfaces[activeSurfaceId];

    if (activeSurface.width && activeSurface.height && activeSurface.vertices) {
        const pixelDimensions = dimsInPx(activeSurface.vertices);

        if (activeSurface && activeSurface.ratios === undefined) {
            activeSurface.ratios = {
                surfaceWidth: pixelDimensions.x,
                surfaceHeight: pixelDimensions.y,
                ratio: {
                    x: activeSurface.width / pixelDimensions.x,
                    y: activeSurface.height / pixelDimensions.y,
                },
                ratioVertices: activeSurface.vertices,
            };
        } else {
            activeSurface.ratios = undefined;
        }

        dispatch({
            type: DesignerQuoteActionType.TOGGLE_SURFACE_RATIO,
            payload: { activeCanvas, activeSurface },
        });
    }
};

export const applyRatio: DesignerQuoteThunkActionCreator<
    DesignerQuoteActionPayload
> =
    ({ data: vertices }) =>
    async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const canvasId = state.ui.activeCanvasId;
        const surfaceId = state.ui.activeSurfaceId;
        const activeSurface =
            canvasId &&
            surfaceId &&
            state.designerQuote.planning.canvases[canvasId].surfaces[surfaceId];
        const ratios = activeSurface && activeSurface.ratios;
        const pixelDimensions = dimsInPx(vertices);

        if (!ratios || !pixelDimensions || !activeSurface) return;

        activeSurface.width = pixelDimensions.x * ratios.ratio.x;
        activeSurface.height = pixelDimensions.y * ratios.ratio.y;

        const payload = {
            data: {
                width: activeSurface.width,
                height: activeSurface.height,
            },
        };

        dispatch({
            type: DesignerQuoteActionType.APPLY_SURFACE_RATIO,
            payload: { ...payload, canvasId, surfaceId },
        });
    };
