import { AccessTokenFunction, RootState } from '../common/types';
import { deleteQuoteFiles, updateQuote } from './saveToSalesforce';
import { savePlanningPicturesFromQuote } from './saveQuote';
import { getRoofInclinations, getRoofOrientations } from '../common/functions';

export const updateQuoteMethod = async (p: {
    rootState: RootState;
    opportunityId: string;
    quoteId: string;
    accessToken: AccessTokenFunction;
}): Promise<[string | undefined, Error?]> => {
    try {
        const planningData = p.rootState.designerQuote.planning;

        await deleteQuoteFiles({
            quoteId: p.quoteId,
            accessToken: p.accessToken,
        });

        await savePlanningPicturesFromQuote(p);

        // // Update sync
        const roofInclinations = getRoofInclinations(planningData);
        await updateQuote({
            quoteId: p.quoteId,
            accessToken: p.accessToken,
            payload: {
                sd_sync__c: true,
                planning_data__c: JSON.stringify(planningData),
                roof_inclination__c: roofInclinations[0] ?? 0,
                roof_orientation__c:
                    getRoofOrientations(planningData).join(';'),
            },
        });

        return [p.quoteId];
    } catch (error) {
        return [undefined, error as Error];
    }
};
