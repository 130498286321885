import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { UseCases } from '../../common/types';
import Button from '../Button';
import Input from '../Input';
import styles from './InputNumber.module.scss';

type Props = {
    id: string;
    className?: string;
    value: number;
    min?: number;
    max?: number;
    step?: number;
    onChange: (value: number) => void;
    resetToDefaultOnBlur?: boolean;
    title?: string;
};
const InputNumber: FunctionComponent<Props> = ({
    id,
    className,
    value = 100,
    min = 0,
    max = 100,
    step = 1,
    onChange,
    title,
}) => {
    const [localValue, setLocalValue] = useState(value);
    const [isFocused, setIsFocused] = useState(false);
    const handleNumberButtons = (type: 'dec' | 'inc') => {
        // make a multiple of 'step' again
        let currentValue;
        if (type === 'inc') {
            currentValue = Math.floor(localValue / step) * step;
            if (currentValue <= value && currentValue + step <= max)
                currentValue += step;
        } else {
            currentValue = Math.floor(localValue / step) * step;
            if (currentValue >= value && currentValue - step >= min)
                currentValue -= step;
        }
        setLocalValue(currentValue);
        onChange(currentValue);
    };
    const handleNumberInput = (event: React.ChangeEvent, data: any) => {
        const inputNumber = Number(data.value);
        if (!isFocused || event.type === 'blur') {
            if (inputNumber >= max) {
                setLocalValue(max);
                onChange(max);
            } else if (inputNumber <= min) {
                setLocalValue(min);
                onChange(min);
            } else {
                setLocalValue(inputNumber);
                onChange(inputNumber);
            }
        } else {
            setLocalValue(inputNumber);
        }
    };

    const handleFocusBlurInput = (event: React.ChangeEvent, data: any) => {
        const focus = event.type === 'focus' || !(event.type === 'blur');
        setIsFocused(focus);
        event.type === 'blur' && handleNumberInput(event, data);
    };

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    return (
        <div
            className={classNames(className, styles.inputNumber)}
            data-tooltip={title}
        >
            <Button
                icon="minus"
                iconOnly
                onClick={() => handleNumberButtons('dec')}
                className={styles.button}
                iconUseCase={UseCases.inInputNumber}
                iconStrokeWidth={16}
                disabled={localValue <= min}
            />
            <Input
                type="number"
                id={id}
                value={localValue.toString()}
                className={styles.input}
                onChange={handleNumberInput}
                onFocus={handleFocusBlurInput}
                onBlur={handleFocusBlurInput}
                style={{ width: max.toString().length + 1 + 'em' }}
            />
            <Button
                icon="plus"
                iconOnly
                onClick={() => handleNumberButtons('inc')}
                className={styles.button}
                iconUseCase={UseCases.inInputNumber}
                iconStrokeWidth={16}
                disabled={localValue >= max}
            />
        </div>
    );
};
export default InputNumber;
