import { Dispatch } from 'redux';
import {
    RootState,
    UiActionCreator,
    UiActionPayload,
    UiActionType,
    UiThunkActionCreator,
} from '../../common/types';

export const toggleGridVisibility: UiActionCreator<UiActionPayload> = (
    payload
) => {
    return {
        type: UiActionType.TOGGLE_GRID_VISIBILITY,
        payload,
    };
};

export const toggleEnergyFieldsVisibility = () => {
    return {
        type: UiActionType.TOGGLE_ENERGY_FIELDS_VISIBILITY,
    };
};

export const updateUi: UiActionCreator<UiActionPayload> = (payload) => {
    const conditionalPayload = { ...payload };
    if ('activeSurfaceId' in payload) {
        // Reset active panels when changing active surface
        if (!('selectedPanelIndices' in payload))
            conditionalPayload.selectedPanelIndices = [];
    }

    // Reset activeSurface when changing canvas
    if ('activeCanvasId' in payload && !('activeSurfaceId' in payload))
        conditionalPayload.activeSurfaceId = undefined;

    return {
        type: UiActionType.UPDATE,
        payload: conditionalPayload,
    };
};

export const showRecalculateButton = () => {
    return {
        type: UiActionType.SHOW_RECALCULATE_BUTTON,
    };
};

export const hideRecalculateButton = () => {
    return {
        type: UiActionType.HIDE_RECALCULATE_BUTTON,
    };
};

export const toggleLockAllSurfaces: UiThunkActionCreator<UiActionPayload> =
    () =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const state = getState();
        const { activeCanvasId } = state.ui;

        let surfaceIds = activeCanvasId
            ? Object.keys(
                  state.designerQuote.planning.canvases[activeCanvasId].surfaces
              )
            : [];

        if (surfaceIds.length === state.ui.lockedSurfaceIds.length)
            surfaceIds = [];
        dispatch({
            type: UiActionType.UPDATE,
            payload: {
                lockedSurfaceIds: surfaceIds,
                activeSurfaceId: undefined,
            },
        });
    };
