import { callSalesforceApiWith } from '.';
import { validateObjectData } from '../common/functions';
import { BulkPostResponse } from '../common/responseTypes';
import { AccessTokenFunction, Product, UpdateObject } from '../common/types';

// Create bulk post item
export const createBulkItem = (quoteId: string, product: Product) => {
    return {
        attributes: { type: 'QuoteLineItem', referenceId: product.id },
        QuoteId: quoteId,
        Product2Id: product.id,
        Quantity: product.quantity || 1,
        PricebookEntryId: product.pricebookEntryId,
        UnitPrice: product.price,
        SortOrder__c: product.sortOrder || 0,
    };
};

// Create bulk update item
export const makeUpdateRecord = (updateObject: UpdateObject) => {
    return {
        attributes: {
            type: 'QuoteLineItem',
        },
        id: updateObject.id,
        Quantity: updateObject.Quantity,
    };
};

// POST multiple QuoteLineItems
export const bulkPostQuoteLineItems = async (p: {
    quoteId: string;
    acceptableProducts: Product[];
    accessToken: AccessTokenFunction;
}) => {
    const records = p.acceptableProducts.map((product) =>
        createBulkItem(p.quoteId, product)
    );

    try {
        const response = await callSalesforceApiWith<BulkPostResponse>({
            name: 'tree',
            id: 'QuoteLineItem',
            middleware: [validateObjectData],
            options: {
                method: 'POST',
                body: JSON.stringify({ records }),
            },
            accessToken: p.accessToken,
            composite: true,
        });
        return [response];
    } catch (e) {
        return [undefined, e];
    }
};

// Delete multiple QuoteLineItems
export const bulkDeleteQuoteLineItems = async (p: {
    recordIds: string[];
    accessToken: AccessTokenFunction;
}) => {
    try {
        const response = await callSalesforceApiWith<BulkPostResponse>({
            name: `sobjects?ids=${p.recordIds.join()}`,
            middleware: [validateObjectData],
            options: {
                method: 'DELETE',
            },
            accessToken: p.accessToken,
            composite: true,
        });
        return [response];
    } catch (e) {
        return [undefined, e];
    }
};

// Update multiple QuoteLineItems
export const bulkUpdateQuoteLineItems = async (p: {
    updateObjects: UpdateObject[];
    accessToken: AccessTokenFunction;
}) => {
    const records = p.updateObjects.map((rec) => makeUpdateRecord(rec));

    try {
        const response = await callSalesforceApiWith<BulkPostResponse>({
            name: 'sobjects',
            middleware: [validateObjectData],
            options: {
                method: 'PATCH',
                body: JSON.stringify({ records: records }),
            },
            accessToken: p.accessToken,
            composite: true,
        });
        return [response];
    } catch (e) {
        return [undefined, e];
    }
};
