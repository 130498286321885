import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
import { closeModal, openModal } from '../../features/modals/modalActions';
import { clearFromLocalStorage, loadFromLocalStorage } from '../localStorage';
import {
    CloudQuoteAction,
    DesignerQuoteAction,
    OpportunityState,
    RootState,
} from '../types';
import useDesignerQuote from './useDesignerQuote';
import { useAppDispatch } from './index';

type IntendedActionType =
    | ThunkAction<void, RootState, unknown, CloudQuoteAction>
    | DesignerQuoteAction;

export default function useUnsavedChangesModal() {
    const dispatch = useAppDispatch();
    const opportunity = useSelector<RootState, OpportunityState>(
        (state) => state.opportunity
    );
    const quote = useDesignerQuote();
    const { t } = useTranslation(['notifications']);

    // No, don't save the Quote, just switch
    const handleClickNoSave = (intendedAction: IntendedActionType) => {
        dispatch(intendedAction);
        clearFromLocalStorage(opportunity.opportunityId);
        dispatch(closeModal());
    };

    // Close modal and keep Local storage
    const handleModalClose = () => {
        dispatch(closeModal());
    };

    // Return a function you can call when needed
    return (intendedAction: IntendedActionType) => {
        if (
            // Are there unsaved local changes to the currentQuote?
            quote &&
            loadFromLocalStorage(opportunity.opportunityId)
        ) {
            // DISPATCH THE MODAL
            dispatch(
                openModal({
                    modalProps: {
                        title: t('modals:changeQuote.title'),
                        description: t('modals:changeQuote.description', {
                            quoteName: quote.salesforce.name,
                        }),
                        buttons: [
                            {
                                type: 'negative',
                                onClick: handleModalClose,
                            },
                            {
                                text: t('modals:changeQuote.cancel') as string,
                                type: 'positive',
                                onClick: () =>
                                    handleClickNoSave(intendedAction),
                            },
                        ],
                        onModalClose: handleModalClose,
                        size: 'large',
                    },
                })
            );
        } else {
            dispatch(intendedAction);
        }
    };
}
