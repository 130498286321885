import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent } from 'react';
import { Side } from '../../common/types';
import styles from './Float.module.scss';

type Props = { [key in Side]?: boolean } & {
    style?: CSSProperties;
    className?: string;
    children?: React.ReactNode;
};

const Float: FunctionComponent<Props> = ({
    style,
    top,
    left,
    bottom,
    right,
    children,
    className,
}) => {
    return (
        <div
            className={classNames(styles.float, className)}
            style={{
                ...style,
                marginTop:
                    bottom || ((left || right) && !top) ? 'auto' : undefined,
                marginLeft:
                    right || ((top || bottom) && !left) ? 'auto' : undefined,
                marginBottom:
                    top || ((left || right) && !bottom) ? 'auto' : undefined,
                marginRight:
                    left || ((top || bottom) && !right) ? 'auto' : undefined,
            }}
        >
            {children}
        </div>
    );
};
export default Float;
