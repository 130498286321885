import { useEffect } from 'react';
import {
    getNumberOfStrings,
    getStringOptions,
    getStringsFromSurfaces,
} from '../../components/StringPlanner/functions';
import useDesignerQuote from './useDesignerQuote';
import { getSurfacesOfPlanning } from '../functions';
import { useDispatch } from 'react-redux';
import { removeAllPanelsOnStrings } from '../../features/designerQuote/designerQuoteActions';

export default function useElectricalMaterials() {
    const quote = useDesignerQuote();
    const dispatch = useDispatch();

    useEffect(() => {
        const newNumberOfStrings = getNumberOfStrings(
            quote.salesforce.Products
        );
        const currentNumberOfStrings = getStringsFromSurfaces(
            getSurfacesOfPlanning(quote.planning)
        ).length;
        if (newNumberOfStrings < currentNumberOfStrings) {
            dispatch(
                removeAllPanelsOnStrings({
                    data: {
                        currentStrings: getStringOptions(
                            newNumberOfStrings
                        ).map((option) => option.id),
                    },
                })
            );
        }
    }, [quote.salesforce.Products]);
}
