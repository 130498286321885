import {
    MAX_SELECTION_WIDTH,
    PANEL_SPACING,
    SOUTH_FACING_PANEL_GUTTER,
} from '../../common/constants';
import {
    getOrientedDimensions,
    getOrientedSpacing,
    getPanelFacing,
    getPanelSpacing,
    getSideFacingOptions,
    getSouthFacingSide,
    isAlternatingPanels,
} from '../../common/functions';
import { getNeighbouringPanels } from '../../common/functions/smartLayout';
import {
    ArrowKey,
    ModulePlanningData,
    ObjectWithWidthAndLength,
    Panel,
    PanelPlanningData,
    PerspectiveTransform,
    Point,
    Rectangle,
    RoofTypes,
    SideDirections,
    Spacing,
} from '../../common/types';
import { ModuleState } from './types';

export const createPanelIdFromIndex = (i: number) => 'panel_' + i;
export const getPanelIndexFromId = (id: string) => Number(id.split('_')[1]);

export const minMax = (min: number, max: number, value: number) =>
    Math.min(Math.max(min, value), max);

export const snapValue = (
    sourceValue: number,
    comparedValues: number[],
    threshold = 200
) => {
    let minDist = 999999;
    let minDistValue: number | undefined;
    comparedValues.forEach((value) => {
        const d = Math.abs(sourceValue - value);
        if (d > threshold || d > minDist) return;
        minDist = d;
        minDistValue = value;
    });

    return minDistValue !== undefined ? minDistValue : sourceValue;
};

export const getSelectionRectangle = (
    panels: PanelPlanningData[],
    selectedPanelIndices: number[],
    panelProduct: Panel,
    roofType: RoofTypes
) => {
    const result: Rectangle = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        left: 999999,
        top: 999999,
        right: -999999,
        bottom: -999999,
    };

    selectedPanelIndices.forEach((p) => {
        const panelX = panels[p].x || 0;
        const panelY = panels[p].y || 0;
        const [panelWidth, panelHeight] = getOrientedDimensions(
            panelProduct.width,
            panelProduct.length,
            roofType,
            panels[p].isHorizontal
        );

        result.left = Math.min(result.left, panelX);
        result.top = Math.min(result.top, panelY);

        result.right = Math.max(result.right, panelX + panelWidth);
        result.bottom = Math.max(result.bottom, panelY + panelHeight);
    });

    result.x = result.left;
    result.y = result.top;
    result.width = result.right - result.left;
    result.height = result.bottom - result.top;

    return result;
};

export const panelsChangeStateHandler =
    (panels: PanelPlanningData[]) => (state: ModuleState) => {
        return {
            ...state,
            modifiedData: {
                ...state.modifiedData,
                panels: [...panels.map((p) => ({ ...p }))],
            },
        };
    };

export const selectedPanelsChangeStateHandler =
    (selectedPanelIndices: number[], roofType: RoofTypes) =>
    (state: ModuleState): ModuleState => {
        if (!selectedPanelIndices.length) {
            return {
                ...state,
                selection: {
                    x: 0,
                    y: 0,
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    width: 0,
                    height: 0,
                    mouseGrabPoint: { x: 0, y: 0 },
                    indices: [],
                    positions: [],
                },
            };
        }

        const selectionPanelPositions: Point[] = [];

        const selectionGeometry = getSelectionRectangle(
            state.modifiedData.panels,
            selectedPanelIndices,
            state.panelProduct,
            roofType
        );

        selectedPanelIndices.forEach((p) => {
            const { x: panelX = 0, y: panelY = 0 } =
                state.modifiedData.panels[p];

            selectionPanelPositions.push({
                x: panelX - selectionGeometry.left,
                y: panelY - selectionGeometry.top,
            });
        });

        return {
            ...state,
            selection: {
                ...state.selection,
                ...selectionGeometry,
                indices: selectedPanelIndices,
                positions: selectionPanelPositions,
            },
        };
    };

export const getModuleStateOnMouseDown = (props: {
    state: ModuleState;
    perspective: PerspectiveTransform;
    mouseX: number;
    mouseY: number;
    containerBoundaries: Rectangle;
    index: number;
    shiftKey: boolean;
    altKey: boolean;
    roofType: RoofTypes;
    environmentScale?: number;
}) => {
    const {
        state,
        perspective,
        mouseX,
        mouseY,
        containerBoundaries,
        index,
        shiftKey,
        altKey,
        environmentScale = 1,
        roofType,
    } = props;

    const activePanelData = state.modifiedData.panels[index];
    if (activePanelData.x === undefined || activePanelData.y === undefined)
        return undefined;

    let updatedSelectedPanels: number[] = [...state.selection.indices];
    if (altKey) {
        updatedSelectedPanels = getNeighbouringPanels(
            state.modifiedData.panels,
            index,
            {
                width: state.panelProduct.width || 0,
                height: state.panelProduct.length || 0,
            },
            roofType
        );
    } else if (shiftKey) {
        const existingIndex = updatedSelectedPanels.indexOf(index);

        if (existingIndex !== -1)
            updatedSelectedPanels.splice(existingIndex, 1);
        else updatedSelectedPanels.push(index);
    } else if (
        state.selection.indices.length < 2 ||
        !state.selection.indices.includes(index)
    ) {
        updatedSelectedPanels = [index];
    }

    const [pointX, pointY] = perspective.transform(
        (mouseX - containerBoundaries.x) / environmentScale,
        (mouseY - containerBoundaries.y) / environmentScale
    );

    return {
        updatedSelectedPanels,
        mouseGrabPoint: { x: pointX || 0, y: pointY || 0 },
    };
};

export const updatePanelPositions = (
    indexesOfPanelsToUpdate: number[],
    panels: PanelPlanningData[],
    offsetX: number,
    offsetY: number,
    localPositions: Point[]
) => {
    const updatedPanels = [...panels];
    indexesOfPanelsToUpdate.forEach((panelIndex, i) => {
        const newX = offsetX + localPositions[i].x;
        const newY = offsetY + localPositions[i].y;

        const panelData = updatedPanels[panelIndex];
        if (
            !panelData ||
            panelData.x === undefined ||
            panelData.y === undefined
        )
            return;

        panelData.x = newX;
        panelData.y = newY;
    });
    return updatedPanels;
};

export const getStateOnDragSelection = (
    state: ModuleState,
    startPoint: Point,
    endPoint: Point,
    perspective: PerspectiveTransform,
    containerBoundaries: Rectangle,
    environmentScale: number,
    roofType: RoofTypes,
    updatePanelIndices?: (indices: number[]) => void
) => {
    let [localStartPointX, localStartPointY] = perspective.transform(
        (startPoint.x - containerBoundaries.left) / environmentScale,
        (startPoint.y - containerBoundaries.top) / environmentScale
    );
    localStartPointX = Math.round(localStartPointX);
    localStartPointY = Math.round(localStartPointY);
    let [localEndPointX, localEndPointY] = perspective.transform(
        (endPoint.x - containerBoundaries.left) / environmentScale,
        (endPoint.y - containerBoundaries.top) / environmentScale
    );
    localEndPointX = Math.round(localEndPointX);
    localEndPointY = Math.round(localEndPointY);

    const left = Math.min(localStartPointX, localEndPointX);
    const right = Math.max(localStartPointX, localEndPointX);
    const top = Math.min(localStartPointY, localEndPointY);
    const bottom = Math.max(localStartPointY, localEndPointY);

    const { panels } = state.modifiedData;
    const panelProduct = state.panelProduct;
    const selectedPanelIndices: number[] = [];

    panels.forEach((p, i) => {
        const [panelWidth, panelHeight] = getOrientedDimensions(
            panelProduct.width,
            panelProduct.length,
            roofType,
            p.isHorizontal
        );
        const { x = 0, y = 0 } = p;
        if (
            x + panelWidth < left ||
            x > right ||
            y + panelHeight < top ||
            y > bottom
        )
            return;
        selectedPanelIndices.push(i);
    });
    const selection = {
        ...state.selection,
    };

    if (
        updatePanelIndices &&
        state.selection.indices.join() !== selectedPanelIndices.join()
    )
        updatePanelIndices(selectedPanelIndices);

    if (
        right - left > MAX_SELECTION_WIDTH ||
        bottom - top > MAX_SELECTION_WIDTH
    )
        return state;

    return {
        ...state,
        selection,
        dragSelection: {
            x: localStartPointX,
            y: localStartPointY,
            left,
            right,
            top,
            bottom,
            width: right - left,
            height: bottom - top,
        },
    };
};

export const getPossibleSnapCoordinates = (
    comparedPanelXorYPosition: number,
    selectionWidthOrHeight: number,
    panelWidthOrHeight: number,
    spacing: number,
    hasGutter: boolean,
    gutter: number = SOUTH_FACING_PANEL_GUTTER
) => {
    const possibleSnapCoordinates: number[] = [];

    hasGutter &&
        possibleSnapCoordinates.push(
            comparedPanelXorYPosition - panelWidthOrHeight - spacing - gutter,
            comparedPanelXorYPosition + panelWidthOrHeight + spacing + gutter
        );

    possibleSnapCoordinates.push(
        comparedPanelXorYPosition, // To align horizontally or vertically with the panel
        comparedPanelXorYPosition - selectionWidthOrHeight + panelWidthOrHeight, //
        comparedPanelXorYPosition - selectionWidthOrHeight - spacing,
        comparedPanelXorYPosition + panelWidthOrHeight + spacing
    );

    return possibleSnapCoordinates;
};

export const mouseMoveModuleStateHandler =
    (
        perspective: PerspectiveTransform,
        mouseX: number,
        mouseY: number,
        containerBoundaries: Rectangle,
        shiftKey: boolean,
        panelIndices: number[],
        width: number,
        height: number,
        environmentScale = 1,
        roofType: RoofTypes,
        gutter = SOUTH_FACING_PANEL_GUTTER
    ) =>
    (state: ModuleState) => {
        const { selection, modifiedData } = state;
        if (!panelIndices.length) return state;

        const newModifiedData = { ...modifiedData };

        const [pointX, pointY] = perspective.transform(
            (mouseX - containerBoundaries.left) / environmentScale,
            (mouseY - containerBoundaries.top) / environmentScale
        );

        const newSelectionPosition = {
            x: Math.round(pointX - state.selection.mouseGrabPoint.x),
            y: Math.round(pointY - state.selection.mouseGrabPoint.y),
        };

        let possibleSnapValuesX: number[] = [];
        let possibleSnapValuesY: number[] = [];

        if (!shiftKey) {
            possibleSnapValuesX.push(gutter, width - selection.width - gutter);
            possibleSnapValuesY.push(
                gutter,
                height - selection.height - gutter
            );

            newModifiedData.panels.forEach((comparedPanel, i) => {
                if (
                    panelIndices.includes(i) ||
                    comparedPanel.x === undefined ||
                    comparedPanel.y === undefined
                )
                    return;

                const [panelWidth, panelHeight] = getOrientedDimensions(
                    state.panelProduct.width,
                    state.panelProduct.length,
                    roofType,
                    comparedPanel.isHorizontal
                );

                const hasGutter = !!(
                    comparedPanel.facing && comparedPanel.facing.hasGutter
                );

                possibleSnapValuesX = possibleSnapValuesX.concat(
                    getPossibleSnapCoordinates(
                        comparedPanel.x,
                        selection.width,
                        panelWidth,
                        getPanelSpacing(comparedPanel).x,
                        hasGutter && !comparedPanel.isHorizontal,
                        gutter
                    )
                );

                possibleSnapValuesY = possibleSnapValuesY.concat(
                    getPossibleSnapCoordinates(
                        comparedPanel.y,
                        selection.height,
                        panelHeight,
                        getPanelSpacing(comparedPanel).y,
                        hasGutter && !!comparedPanel.isHorizontal,
                        gutter
                    )
                );
            });

            newSelectionPosition.x = snapValue(
                newSelectionPosition.x,
                possibleSnapValuesX
            );
            newSelectionPosition.y = snapValue(
                newSelectionPosition.y,
                possibleSnapValuesY
            );
        }

        return {
            ...state,
            selection: {
                ...state.selection,
                x: newSelectionPosition.x,
                y: newSelectionPosition.y,
                left: newSelectionPosition.x,
                top: newSelectionPosition.y,
                right: newSelectionPosition.x + state.selection.width,
                bottom: newSelectionPosition.y + state.selection.height,
            },
        };
    };

const areRectanglesIntersect = (
    rectAx: number,
    rectAy: number,
    rectAWidth: number,
    rectAHeight: number,
    rectBx: number,
    rectBy: number,
    rectBWidth: number,
    rectBHeight: number
) =>
    !(
        rectAx >= rectBx + rectBWidth ||
        rectAx + rectAWidth <= rectBx ||
        rectAy >= rectBy + rectBHeight ||
        rectAy + rectAHeight <= rectBy
    );

export const findConflictingPanels = (
    panels: PanelPlanningData[],
    containerWidth: number,
    containerHeight: number,
    gutterWidth: number,
    panelWidth = 0,
    panelHeight = 0,
    roofType: RoofTypes,
    gutter = SOUTH_FACING_PANEL_GUTTER
) => {
    const foundConflictingPanels: number[] = [];
    panels.forEach((sourcePanel, i) => {
        if (
            foundConflictingPanels.includes(i) ||
            sourcePanel.x === undefined ||
            sourcePanel.y === undefined
        )
            return;

        const [sourcePanelWidth, sourcePanelHeight] = getOrientedDimensions(
            panelWidth,
            panelHeight,
            roofType,
            sourcePanel.isHorizontal
        );

        if (
            sourcePanel.x < gutterWidth ||
            sourcePanel.y < gutterWidth ||
            sourcePanel.x + sourcePanelWidth > containerWidth - gutterWidth ||
            sourcePanel.y + sourcePanelHeight > containerHeight - gutterWidth
        ) {
            foundConflictingPanels.push(i);
            return;
        }

        const modifiedSourcePanel = getPanelDimensionsWithGutter(
            sourcePanel,
            {
                width: sourcePanelWidth,
                height: sourcePanelHeight,
            },
            gutter
        );

        panels.forEach((comparedPanel, j) => {
            if (i === j) return;
            if (
                sourcePanel.x === undefined ||
                comparedPanel.x === undefined ||
                sourcePanel.y === undefined ||
                comparedPanel.y === undefined ||
                panelWidth === undefined ||
                panelHeight === undefined
            )
                return;

            const [comparedPanelWidth, comparedPanelHeight] =
                getOrientedDimensions(
                    panelWidth,
                    panelHeight,
                    roofType,
                    comparedPanel.isHorizontal
                );

            const modifiedComparedPanel = getPanelDimensionsWithGutter(
                comparedPanel,
                { width: comparedPanelWidth, height: comparedPanelHeight },
                gutter
            );

            if (
                !areRectanglesIntersect(
                    modifiedSourcePanel.x,
                    modifiedSourcePanel.y,
                    modifiedSourcePanel.width + getPanelSpacing(sourcePanel).x,
                    modifiedSourcePanel.height + getPanelSpacing(sourcePanel).y,
                    modifiedComparedPanel.x,
                    modifiedComparedPanel.y,
                    modifiedComparedPanel.width +
                        getPanelSpacing(comparedPanel).x,
                    modifiedComparedPanel.height +
                        getPanelSpacing(comparedPanel).y
                )
            )
                return;
            !foundConflictingPanels.includes(i) &&
                foundConflictingPanels.push(i);
            !foundConflictingPanels.includes(j) &&
                foundConflictingPanels.push(j);
        });
    });
    return foundConflictingPanels;
};

export const getPanelDimensionsWithGutter = (
    panel: PanelPlanningData,
    panelDimensions: { width: number; height: number },
    gutter = SOUTH_FACING_PANEL_GUTTER
) => {
    const response = {
        x: panel.x,
        y: panel.y,
        width: panelDimensions.width,
        height: panelDimensions.height,
    };

    const hasGutter = !!(panel.facing && panel.facing.hasGutter);

    if (hasGutter) {
        panel.isHorizontal
            ? (response.height += gutter)
            : (response.width += gutter);

        switch (panel.facing?.side) {
            case 'top':
                response.y -= gutter;
                break;
            case 'left':
                response.x -= gutter;
                break;
        }
    }

    return response;
};

export const arrowKeyModuleStateHandler =
    (key: ArrowKey, shiftKey = false, step = 50) =>
    (state: ModuleState) => ({
        ...state,
        selection: {
            ...state.selection,
            x:
                state.selection.x +
                step *
                    (key === ArrowKey.ArrowLeft
                        ? -1
                        : key === ArrowKey.ArrowRight
                        ? 1
                        : 0) *
                    (shiftKey ? 10 : 1),
            y:
                state.selection.y +
                step *
                    (key === ArrowKey.ArrowUp
                        ? -1
                        : key === ArrowKey.ArrowDown
                        ? 1
                        : 0) *
                    (shiftKey ? 10 : 1),
        },
    });
export const getAlternatingSides = (
    panelIndex: { hozIndex: number; verIndex: number },
    isHorizontal: boolean
) => {
    return isHorizontal
        ? panelIndex.verIndex % 2 === 0
            ? 'top'
            : 'bottom'
        : panelIndex.hozIndex % 2 === 0
        ? 'left'
        : 'right';
};

export const getFacingSideAndDirection = (
    panelIndex: { hozIndex: number; verIndex: number },
    sideDirections: SideDirections,
    azimuth: number,
    isHorizontal: boolean,
    isFlipped: boolean
) => {
    return isAlternatingPanels(azimuth, isHorizontal, isFlipped)
        ? getAlternatingSides(panelIndex, isHorizontal)
        : getSouthFacingSide(
              getSideFacingOptions(sideDirections, isHorizontal)
          );
};

export const isPanelOnFlatRoof = (panel: PanelPlanningData) =>
    panel && 'facing' in panel;

export const calculateXYByConfiguration = (
    surface: ModulePlanningData,
    panelIndex: { hozIndex: number; verIndex: number },
    panel: PanelPlanningData,
    panelProduct: ObjectWithWidthAndLength,
    newSpacing: Spacing
) => {
    const panelSpacingDifferenceX = getPanelSpacing(panel).x - newSpacing.x;
    const panelSpacingDifferenceY = getPanelSpacing(panel).y - newSpacing.y;

    if (
        (isPanelOnFlatRoof(panel) && surface.roofType !== RoofTypes.flat) ||
        (!isPanelOnFlatRoof(panel) && surface.roofType === RoofTypes.flat)
    ) {
        const [newPanelWidth, newPanelHeight] = getOrientedDimensions(
            panelProduct.width,
            panelProduct.length,
            isPanelOnFlatRoof(panel) ? RoofTypes.slope : RoofTypes.flat,
            panel.isHorizontal
        );

        const [oldPanelWidth, oldPanelHeight] = getOrientedDimensions(
            panelProduct.width,
            panelProduct.length,
            isPanelOnFlatRoof(panel) ? RoofTypes.flat : RoofTypes.slope,
            panel.isHorizontal
        );

        const panelWidthDifference = oldPanelWidth - newPanelWidth;
        const panelHeightDifference = oldPanelHeight - newPanelHeight;

        return {
            x:
                panel.x -
                (panelSpacingDifferenceX + panelWidthDifference) *
                    panelIndex.hozIndex,
            y:
                panel.y -
                (panelSpacingDifferenceY + panelHeightDifference) *
                    panelIndex.verIndex,
        };
    } else {
        return {
            x: panel.x - panelSpacingDifferenceX * panelIndex.hozIndex,
            y: panel.y - panelSpacingDifferenceY * panelIndex.verIndex,
        };
    }
};

export const adjustPanel = (
    surface: ModulePlanningData,
    panelIndex: { hozIndex: number; verIndex: number },
    panel: PanelPlanningData,
    panelProduct: ObjectWithWidthAndLength
) => {
    const newSpacing = getSpacingByConfiguration(surface, panel.isHorizontal!);

    const { x, y } = calculateXYByConfiguration(
        surface,
        panelIndex,
        panel,
        panelProduct,
        newSpacing
    );

    switch (surface.roofType) {
        case RoofTypes.flat: {
            return {
                x,
                y,
                isHorizontal: panel.isHorizontal,
                isHidden: panel.isHidden,
                facing: panel.facing?.isset
                    ? panel.facing
                    : getPanelFacing(surface, panelIndex, panel.isHorizontal!),
                spacing: newSpacing,
                stringPosition: panel.stringPosition,
            };
        }
        default:
            return {
                x,
                y,
                isHorizontal: panel.isHorizontal,
                isHidden: panel.isHidden,
                spacing: newSpacing,
                stringPosition: panel.stringPosition,
            };
    }
};

export const drawPanel = (
    selection: Rectangle,
    surface: ModulePlanningData,
    panelIndex: { hozIndex: number; verIndex: number },
    panelDimensions: { panelWidth: number; panelHeight: number },
    alignment: { leftAligned: boolean; topAligned: boolean },
    isHorizontal: boolean,
    spacing: Spacing
) => {
    switch (surface.roofType) {
        case RoofTypes.flat: {
            return {
                x: alignment.leftAligned
                    ? selection.x +
                      panelIndex.hozIndex * panelDimensions.panelWidth
                    : selection.x -
                      (panelIndex.hozIndex + 1) * panelDimensions.panelWidth,
                y: alignment.topAligned
                    ? selection.y +
                      panelIndex.verIndex * panelDimensions.panelHeight
                    : selection.y -
                      (panelIndex.verIndex + 1) * panelDimensions.panelHeight,
                isHorizontal,
                facing: getPanelFacing(surface, panelIndex, isHorizontal),
                spacing,
            };
        }
        default:
            return {
                x: alignment.leftAligned
                    ? selection.x +
                      panelIndex.hozIndex * panelDimensions.panelWidth
                    : selection.x -
                      (panelIndex.hozIndex + 1) * panelDimensions.panelWidth,
                y: alignment.topAligned
                    ? selection.y +
                      panelIndex.verIndex * panelDimensions.panelHeight
                    : selection.y -
                      (panelIndex.verIndex + 1) * panelDimensions.panelHeight,
                isHorizontal,
                spacing,
            };
    }
};

const getPanelDrawingStartingPoint = (
    selection: Rectangle,
    gutterWidth: number
): Rectangle => {
    return {
        ...selection,
        x: selection.x < gutterWidth ? gutterWidth : selection.x,
        y: selection.y < gutterWidth ? gutterWidth : selection.y,
    };
};

export const getSpacingByConfiguration = (
    surface: ModulePlanningData,
    isHorizontal: boolean
) => {
    switch (surface.roofType) {
        case RoofTypes.flat: {
            return isAlternatingPanels(surface.azimuth!, isHorizontal, false)
                ? getOrientedSpacing(PANEL_SPACING.alternating, isHorizontal)
                : getOrientedSpacing(PANEL_SPACING.southFacing, isHorizontal);
        }
        default: {
            return getOrientedSpacing(PANEL_SPACING.sloped, isHorizontal);
        }
    }
};

export const getPanelsFitToSelection = (
    selection: Rectangle,
    panelProduct: Panel,
    surface: ModulePlanningData,
    isHorizontal: boolean,
    surfaceGutterWidth: number,
    gutter: number = SOUTH_FACING_PANEL_GUTTER
) => {
    const result: PanelPlanningData[] = [];
    const panelGutter =
        surface.roofType === RoofTypes.flat &&
        !isAlternatingPanels(surface.azimuth!, isHorizontal, false)
            ? gutter
            : 0;

    const spacing = getSpacingByConfiguration(surface, isHorizontal);

    const [panelWidth, panelHeight] = getOrientedDimensions(
        panelProduct.width,
        panelProduct.length,
        surface.roofType!,
        isHorizontal
    );

    const panelWidthWithSpacing =
        panelWidth + spacing.x + (isHorizontal ? 0 : panelGutter);
    const panelHeightWithSpacing =
        panelHeight + spacing.y + (isHorizontal ? panelGutter : 0);

    const leftAligned = selection.x < selection.right;
    const topAligned = selection.y < selection.bottom;

    const adjustedSelection = getPanelDrawingStartingPoint(
        selection,
        surfaceGutterWidth
    );

    for (
        let j = 0;
        j < Math.floor(selection.height / panelHeightWithSpacing);
        j++
    ) {
        for (
            let i = 0;
            i < Math.floor(selection.width / panelWidthWithSpacing);
            i++
        ) {
            result.push(
                drawPanel(
                    adjustedSelection,
                    surface,
                    { hozIndex: i, verIndex: j },
                    {
                        panelWidth: panelWidthWithSpacing,
                        panelHeight: panelHeightWithSpacing,
                    },
                    { leftAligned, topAligned },
                    isHorizontal,
                    spacing
                )
            );
        }
    }

    return result;
};
