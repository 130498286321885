import classNames from 'classnames';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { GeometryState, Vertex, Vertices } from '../../common/types';
import styles from './ShapeBuilder.module.scss';

type Props = {
    style?: CSSProperties;
    className?: string;
    onChange?: (vertices: Vertices) => void;
    onReady?: (vertices: Vertices) => void;
    environmentState?: GeometryState;
};

const areVerticesEqual = (vertexA: Vertex, vertexB: Vertex) =>
    vertexA[0] === vertexB[0] && vertexA[1] === vertexB[1];

const rendererShape = (vertices: Vertices) => {
    if (vertices.length < 2) return null;

    const points: string[] = vertices.map((vertex) => vertex.join());

    return (
        <>
            <polygon points={points.join(' ')} className={styles.fillColor} />
            {vertices.map((vertex, index) => {
                if (!index) return undefined;
                return (
                    <line
                        key={index.toString()}
                        className={styles.strokeColor}
                        x1={vertices[index - 1][0]}
                        y1={vertices[index - 1][1]}
                        x2={vertex[0]}
                        y2={vertex[1]}
                    />
                );
            })}
        </>
    );
};

const ShapeBuilder: React.FC<Props> = ({
    style,
    className,
    onChange,
    onReady,
    environmentState,
}) => {
    const [vertices, setVertices] = useState<Vertices>([]);
    const [isReady, setIsReady] = useState(false);
    const [mouseVertex, setMouseVertex] = useState<Vertex>();

    const verticesRef = useRef(vertices);
    verticesRef.current = vertices;

    const handleClick = (e: React.MouseEvent) => {
        if (isReady) return;

        let { clientX, clientY } = e;

        if (environmentState) {
            clientX =
                (clientX - environmentState.position.x) /
                environmentState.scaleValue;
            clientY =
                (clientY - environmentState.position.y) /
                environmentState.scaleValue;
        }

        if (!mouseVertex) {
            setVertices([[clientX, clientY]]);
            return;
        }

        if (areVerticesEqual(mouseVertex, vertices[0])) {
            setIsReady(true);
            return;
        }

        setVertices([...vertices, mouseVertex]);

        if (vertices.length === 3) {
            setIsReady(true);
        }
    };

    useEffect(() => {
        vertices.length && onChange && onChange(vertices);
    }, [vertices]);

    useEffect(() => {
        if (isReady) {
            onReady && onReady(verticesRef.current);
            return;
        }
        const handleMouseMove = (e: MouseEvent) => {
            const currentVertices = verticesRef.current;

            if (!currentVertices.length) return;

            let newX = e.clientX;
            let newY = e.clientY;

            if (environmentState) {
                newX =
                    (newX - environmentState.position.x) /
                    environmentState.scaleValue;
                newY =
                    (newY - environmentState.position.y) /
                    environmentState.scaleValue;
            }

            // if it's possible to close the shape and the mouse is near the first vertex
            if (
                currentVertices.length > 2 &&
                Math.abs(newX - currentVertices[0][0]) < 10 &&
                Math.abs(newY - currentVertices[0][1]) < 10
            ) {
                newX = currentVertices[0][0];
                newY = currentVertices[0][1];
            }
            // (-:
            setMouseVertex([newX, newY]);
        };
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [
        environmentState?.scaleValue,
        environmentState?.position.x,
        environmentState?.position.x,
        vertices.length > 0,
        isReady,
    ]);

    return (
        <div
            className={classNames(className, styles.root, {
                [styles.notReady]: !isReady,
            })}
            style={style}
            onClick={handleClick}
        >
            <svg width={'100%'} height={'100%'}>
                {rendererShape([
                    ...vertices,
                    ...(mouseVertex ? [mouseVertex] : []),
                    ...(isReady ? [vertices[0]] : []),
                ])}
            </svg>
        </div>
    );
};

export default ShapeBuilder;
