import classNames from 'classnames';
import React, { CSSProperties, FunctionComponent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { stringKeyColors } from '../../../common/constants';
import { mmToPixels } from '../../../common/functions';
import useDesignerQuote from '../../../common/hooks/useDesignerQuote';
import {
    ModulePlanningData,
    ObjectWithNameAndId,
    PanelPlanningData,
    PlanningData,
    Product,
    StringPathIndexes,
} from '../../../common/types';
import {
    getNumberOfStrings,
    getSortedStringSurfaceIndex,
    getStringOptions,
} from '../functions';

// inline styles for proper rendering in PDF
const pathMarkerStyles: CSSProperties = {
    color: '#305dd3',
    boxShadow: '0 0 0 3px currentColor, 1px 1px 3px 3px #000a',
    backgroundColor: '#fff',
    borderRadius: '50%',
    fontWeight: 800,
    width: '1.1em',
    height: '1.1em',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    lineHeight: 1.1,
    textTransform: 'uppercase',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
};

const pathLabelStyles: CSSProperties = {
    width: '1.5em',
    height: '1.5em',
    lineHeight: 1.5,
    color: '#fff',
    backgroundColor: '#305dd3',
    letterSpacing: '-.1ex',
    boxShadow: 'none',
};

type Props = {
    className?: string;
    highestStringIndexes: StringPathIndexes;
    surfacePlanningData: ModulePlanningData;
    panelProduct: Product;
    planningData: PlanningData;
};

const StringPath: FunctionComponent<Props> = ({
    className,
    highestStringIndexes,
    surfacePlanningData,
    panelProduct,
    planningData,
}) => {
    const { t } = useTranslation(['notifications', 'modals']);
    const quote = useDesignerQuote();
    const stringOptions: ObjectWithNameAndId[] = [
        ...getStringOptions(getNumberOfStrings(quote.salesforce.Products)),
        { id: 'none', name: t('all') },
    ];
    const pathMarkerWidth = 100;
    const stringPlan = stringOptions.map((sk) =>
        surfacePlanningData.panels.filter((panel) =>
            panel.stringPosition
                ? panel.stringPosition.key === sk.id
                    ? panel
                    : undefined
                : undefined
        )
    );

    const makePath = (stringPlanArray: string, stringColor: string) => {
        return (
            <polyline
                key={stringPlanArray.toString()}
                fill="none"
                stroke={stringColor}
                strokeWidth="var(--stroke-width, 5)"
                strokeLinecap="round"
                strokeLinejoin="round"
                points={stringPlanArray}
                markerStart={'url(#marker-minus)'}
                markerEnd={'url(#marker-plus)'}
            />
        );
    };

    const createConPath = (
        path: string,
        stringColor: string,
        stringKey: string,
        toSide?: string
    ) => {
        const tempCoords = path.split(' ').pop();
        const startCoords = tempCoords ? tempCoords.split(',') : [0, 0];
        const toSidePath = toSide && toSide === 'left' ? -150 : 150;
        const curve = toSide && (
            <polyline
                key={stringKey + '' + toSide.length}
                fill="none"
                stroke="currentColor"
                color={stringColor}
                strokeWidth="var(--stroke-width, 5)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray={'10 10'}
                markerStart={`url(#marker-${stringKey})`}
                markerEnd={`url(#marker-${stringKey})`}
                points={`${startCoords[0]},${startCoords[1]} ${
                    Number(startCoords[0]) + toSidePath
                },${startCoords[1]}`}
            />
        );
        const offsetPath = path.length && (
            <polyline
                key={stringKey + '' + path.length}
                fill="none"
                stroke="currentColor"
                color={stringColor}
                strokeWidth="var(--stroke-width, 5)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray={'10 10'}
                markerStart={`url(#marker-${stringKey})`}
                markerEnd={`url(#marker-${stringKey})`}
                points={path}
            />
        );

        return [offsetPath, curve];
    };
    const jointLabel = (
        x: number,
        y: number,
        children: ReactElement,
        pathContinuesTo?: number,
        style?: CSSProperties
    ) => {
        return (
            <foreignObject
                key={x + y}
                x={x - pathMarkerWidth / 2}
                y={y - pathMarkerWidth / 2}
                width={pathMarkerWidth}
                height={pathMarkerWidth}
                className={'label-container'}
                style={{
                    ...style,
                    pointerEvents: 'none',
                    position: 'relative',
                    fontSize:
                        pathContinuesTo && pathContinuesTo !== 0
                            ? '.66em'
                            : undefined,
                }}
            >
                {children}
            </foreignObject>
        );
    };

    const output = stringPlan.map((stringEntry: PanelPlanningData[]) => {
        const sortedSurfaceStringPlan = stringEntry.find(
            (elem) =>
                elem.stringPosition!.index !== undefined &&
                (elem.isHidden === undefined ||
                    elem.isHidden.toString() === 'false')
        )
            ? stringEntry.sort((a: any, b: any) => {
                  return a.stringPosition!.index > b.stringPosition!.index
                      ? 1
                      : -1;
              })
            : [];

        let stringPlanArray = '';
        const stringJointsLabels: any[] = [];
        let pathMarker = undefined;
        let stringKey = undefined;
        let stringColor = '#fff';
        const path: any[] = [];
        const dashedPaths: any[] = [];

        sortedSurfaceStringPlan.forEach((panel: PanelPlanningData) => {
            const strings = getSortedStringSurfaceIndex(planningData.canvases);
            if (panel.stringPosition) {
                stringKey = panel.stringPosition.key;
                stringColor =
                    stringKeyColors[stringKey as keyof typeof stringKeyColors];
                const productDims = {
                    width: !panel.isHorizontal
                        ? panelProduct.width
                        : panelProduct.length || 0,
                    height: !panel.isHorizontal
                        ? panelProduct.length
                        : panelProduct.width || 0,
                };
                const highestStringIndex =
                    highestStringIndexes[
                        panel.stringPosition
                            .key as keyof typeof highestStringIndexes
                    ];
                const surfaceIndexes = [
                    // if already a node before exists
                    panel.stringPosition.index > 0 &&
                        strings[stringKey][panel.stringPosition.index - 1],
                    // current
                    strings[stringKey][panel.stringPosition.index],
                    // if another node exists
                    panel.stringPosition.index < highestStringIndex &&
                        strings[stringKey][panel.stringPosition.index + 1],
                ];

                const pathIndexes: number[] = [
                    // [prev, next]:
                    // smaller index
                    surfaceIndexes[0] &&
                    surfaceIndexes[1] &&
                    surfaceIndexes[1].surfaceIndex <
                        surfaceIndexes[0].surfaceIndex
                        ? 1
                        : // bigger index
                        surfaceIndexes[0] &&
                          surfaceIndexes[1] &&
                          surfaceIndexes[1].surfaceIndex >
                              surfaceIndexes[0].surfaceIndex
                        ? -1
                        : // equal or all other cases
                          0,
                    // smaller index
                    surfaceIndexes[1] &&
                    surfaceIndexes[2] &&
                    surfaceIndexes[1].surfaceIndex <
                        surfaceIndexes[2].surfaceIndex
                        ? 1
                        : // bigger index
                        surfaceIndexes[1] &&
                          surfaceIndexes[2] &&
                          surfaceIndexes[1].surfaceIndex >
                              surfaceIndexes[2].surfaceIndex
                        ? -1
                        : // equal or all other cases
                          0,
                ];

                const pathContinuesFrom = pathIndexes[0];
                const pathContinuesTo = pathIndexes[1];

                const coords = {
                    x: mmToPixels(panel.x + productDims.width / 2),
                    y: mmToPixels(panel.y + productDims.height / 2),
                };

                // string joint numbers
                stringPlanArray += `${coords.x},${coords.y} `;

                stringJointsLabels.push(
                    jointLabel(
                        coords.x,
                        coords.y,
                        <div
                            style={{
                                ...pathMarkerStyles,
                                ...pathLabelStyles,
                                background: stringColor,
                            }}
                            className={'label-id'}
                        >
                            {panel.stringPosition.index + 1}
                        </div>
                    )
                );
                // string key labels at the end
                pathMarker = jointLabel(
                    coords.x + 23,
                    coords.y - 23 - pathMarkerWidth / 2,
                    <div
                        style={{ ...pathMarkerStyles, color: stringColor }}
                        className={'label-string'}
                    >
                        {panel.stringPosition.key}
                    </div>,
                    pathContinuesTo
                );
                let continuousPath = '';

                if (pathContinuesFrom !== 0 || pathContinuesTo !== 0) {
                    continuousPath = `${coords.x},${coords.y} `;
                    const to = { x: 0, y: 0 };
                    const distances = {
                        left: coords.x,
                        right: mmToPixels(surfacePlanningData.width) - coords.x,
                        top: coords.y,
                        bottom:
                            mmToPixels(surfacePlanningData.height) - coords.y,
                    };
                    const [shortestWay] = Object.entries(distances).sort(
                        ([k1, v1], [k2, v2]) => v1 - v2
                    );

                    const svgMargin = -10;

                    // get key of shortest way
                    switch (shortestWay[0]) {
                        case 'left':
                            to.x = svgMargin;
                            to.y = coords.y;
                            break;
                        case 'right':
                            to.x =
                                mmToPixels(surfacePlanningData.width) -
                                svgMargin;
                            to.y = coords.y;
                            break;
                        case 'top':
                            to.x = coords.x;
                            to.y = svgMargin;
                            break;
                        case 'bottom':
                            to.x = coords.x;
                            to.y =
                                mmToPixels(surfacePlanningData.height) -
                                svgMargin;
                            break;
                    }
                    continuousPath += `${to.x},${to.y}`;
                }

                // create dashed continuous paths
                if (pathContinuesFrom !== 0) {
                    let toSide: string;
                    if (pathContinuesFrom > 0) {
                        toSide = 'right';
                    } else {
                        toSide = 'left';
                    }
                    dashedPaths.push(
                        createConPath(
                            continuousPath,
                            stringColor,
                            stringKey,
                            toSide
                        )
                    );
                }

                // if continues on another surface, create closed polyline
                if (pathContinuesTo !== 0) {
                    let toSide;
                    if (pathContinuesTo > 0) {
                        toSide = 'right';
                    } else {
                        toSide = 'left';
                    }
                    path.push(makePath(stringPlanArray, stringColor));
                    stringPlanArray = '';
                    dashedPaths.push(
                        createConPath(
                            continuousPath,
                            stringColor,
                            stringKey,
                            toSide
                        )
                    );
                }
            }
        });

        path.push(makePath(stringPlanArray, stringColor));

        return (
            stringKey && (
                <g data-string={stringKey} key={stringKey}>
                    {path}
                    {dashedPaths}
                    {stringJointsLabels}
                    {pathMarker}
                </g>
            )
        );
    });

    return (
        <g
            className={classNames('stringPaths', className)}
            style={{ pointerEvents: 'none' }}
        >
            {output}
        </g>
    );
};

export default StringPath;
