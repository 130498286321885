import i18next from 'i18next';
import React, {
    CSSProperties,
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import {
    MAX_PICTURE_AMOUNT,
    QUOTE_STATUS_DRAFT,
    SOLARDESIGNER_PLANNINGVERSION,
} from '../../common/constants';
import { replacePlanningImagesWithPresigned } from '../../common/functions';
import { getQuoteRecordTypeFromOpportunity } from '../../common/helpers';
import useLocalChanges from '../../common/hooks/useLocalChanges';
import { useUi } from '../../common/hooks/useUi';
import useUserPermissions from '../../common/hooks/useUserPermissions';
import useUserRole from '../../common/hooks/useUserRole';
import { clearFromLocalStorage } from '../../common/localStorage';
import {
    OpportunityState,
    RecordType,
    RootState,
    SalesforceState,
    SortType,
} from '../../common/types';
import { getCloudQuote } from '../../features/cloudQuote/cloudQuoteActions';
import {
    addPhotos,
    dispatchAndSaveToLocalStorage,
    resetProductSelection,
    setLocalState,
    setRecordType,
} from '../../features/designerQuote/designerQuoteActions';
import { closeModal, openModal } from '../../features/modals/modalActions';
import { newNotification } from '../../features/notifications/notificationsActions';
import { initialiseProducts } from '../../features/products/productsActions';
import i18n from '../../i18n';
import Header from '../Header';
import LoadingScreen from '../LoadingScreen';
import Prompt from '../Prompt';
import DialogChildren from './DialogChildren';
import PlanningOptions from './PlanningOptions';
import styles from './StartPlanningWizard.module.scss';
import { useAppDispatch } from '../../common/hooks';

type Props = {
    style?: CSSProperties;
    className?: string;
};

const StartPlanningWizard: FunctionComponent<Props> = ({ style }) => {
    const [selectedPhotoIds, setSelectedPhotoIds] = useState<string[]>([]);
    const [selectedQuote, setSelectedQuote] = useState<SalesforceState>();
    const recordType = useSelector<RootState, RecordType | undefined>(
        (state) => state.designerQuote.salesforce.recordType
    );
    const [recordTypeOfQuote, setRecordTypeOfQuote] = useState<RecordType>();

    const opportunity = useSelector<RootState, OpportunityState | undefined>(
        (state) => state.opportunity
    );
    const oppId = opportunity?.opportunityId as string;
    const photos = opportunity?.photos || [];
    const quotes = opportunity?.quotes || [];
    const templatePlanningData = opportunity?.opportunityData || [];
    const [{ quoteSorting }, updateUi] = useUi();

    const dispatch = useAppDispatch();
    const { t } = useTranslation(['common', 'startPlanning', 'modals']);

    const permissions = useUserPermissions();
    const role = useUserRole();

    const sdQuotes = quotes.filter(
        (quote) =>
            quote.planningVersion === SOLARDESIGNER_PLANNINGVERSION &&
            !(
                quote.status === QUOTE_STATUS_DRAFT &&
                !permissions.canSeeDraftQuotes
            )
    );

    // sorting logic
    const sortOrder: SortType = quoteSorting;

    const sortFunc = {
        date: (quote1: SalesforceState, quote2: SalesforceState) => {
            const quote1Date =
                quote1.modificationDetails &&
                new Date(quote1?.modificationDetails.date);
            const quote2Date =
                quote2.modificationDetails &&
                new Date(quote2?.modificationDetails.date);
            if (quote1Date && quote2Date) {
                if (quote1Date < quote2Date) return 1;
                if (quote1Date > quote2Date) return -1;
            }
            return -1;
        },

        sync: (quote1: SalesforceState, quote2: SalesforceState) => {
            const quote1Sync = quote1.isSyncing;
            const quote2Sync = quote2.isSyncing;
            return Number(quote2Sync) - Number(quote1Sync);
        },

        status: (quote1: SalesforceState, quote2: SalesforceState) => {
            const quote1Status = quote1.status.length;
            const quote2Status = quote2.status.length;
            let order = 0;
            if (quote1Status && quote2Status) {
                if (quote1Status < quote2Status) order = 1;
                if (quote1Status > quote2Status) order = -1;
            }
            return order;
        },
    };
    const sortedQuotes = sdQuotes.sort(sortFunc[sortOrder]);

    useEffect(() => {
        // Set default: opp type = quote type
        dispatch(
            setRecordType({
                recordType: getQuoteRecordTypeFromOpportunity(opportunity!),
            })
        );
    }, [opportunity]);

    const handlePictureClick = (photoId: string) => {
        // reset selected quote
        setSelectedQuote(undefined);
        // If the photo is already selected, remove it.
        if (selectedPhotoIds.includes(photoId)) {
            setSelectedPhotoIds(
                selectedPhotoIds.filter((elt) => elt !== photoId)
            );
        } else {
            //When max picture amount show notification
            if (selectedPhotoIds.length + 1 > MAX_PICTURE_AMOUNT) {
                dispatch(
                    newNotification({
                        type: 'error',
                        message: i18n.t('notifications:maxPictures', {
                            amount: MAX_PICTURE_AMOUNT,
                        }),
                    })
                );
                return;
            }
            // add photo to selected photos
            const foundPhoto = photos.find((photo) => photo.id === photoId);
            if (!foundPhoto) return;
            setSelectedPhotoIds([...selectedPhotoIds, foundPhoto.id]);
        }
    };

    const handleQuoteClick = (
        quote: SalesforceState,
        openInstantly?: boolean
    ) => {
        setSelectedPhotoIds([]);
        setSelectedQuote(quote);
        if (quote.recordType) {
            dispatch(
                setRecordType({
                    recordType: quote.recordType,
                })
            );
            setRecordTypeOfQuote(quote.recordType);
        }
        openInstantly && handleClickAffirm();
    };

    const handleClickAffirm = () => {
        if (selectedPhotoIds.length > 0) {
            dispatch(initialiseProducts);
            dispatch(
                addPhotos({
                    data: selectedPhotoIds.map((id) =>
                        photos.find((photo) => photo.id === id)
                    ),
                    quoteId: 'tempId',
                })
            );
        }

        if (selectedQuote) {
            if (recordTypeOfQuote && recordType?.id !== recordTypeOfQuote.id) {
                dispatch(
                    openModal({
                        modalProps: {
                            title: i18next.t('modals:recordTypeChange.title'),
                            description: i18next.t(
                                'modals:recordTypeChange.description',
                                {
                                    oldType: t(recordTypeOfQuote.name),
                                    newType: t(recordType?.name as string),
                                }
                            ),
                            buttons: [
                                {
                                    text: i18next.t(
                                        'modals:recordTypeChange.cancel'
                                    ) as string,
                                    type: 'negative',
                                    onClick: () => {
                                        // Change type back to original
                                        dispatch(
                                            setRecordType({
                                                recordType: recordTypeOfQuote,
                                            })
                                        );
                                        // make sure to get products for right type
                                        dispatch(initialiseProducts);
                                        dispatch(
                                            getCloudQuote({
                                                quoteId: selectedQuote.id,
                                            })
                                        );
                                        dispatch(closeModal());
                                    },
                                },
                                {
                                    text: i18next.t(
                                        'modals:recordTypeChange.confirm'
                                    ) as string,
                                    type: 'positive',
                                    onClick: () => {
                                        // keep the type changed as planned
                                        if (
                                            recordType &&
                                            recordType.name === 'rent'
                                        ) {
                                            // remove unavailable products
                                            dispatch(resetProductSelection({}));
                                        }
                                        dispatch(initialiseProducts);
                                        dispatch(
                                            getCloudQuote({
                                                quoteId: selectedQuote.id,
                                            })
                                        );
                                        dispatch(closeModal());
                                    },
                                },
                            ],
                            onModalClose: () => dispatch(closeModal()),
                            size: 'small',
                        },
                    })
                );
            } else {
                dispatch(initialiseProducts);
                dispatch(
                    getCloudQuote({
                        quoteId: selectedQuote.id,
                    })
                );
            }
        }
    };

    const localChanges = useLocalChanges(oppId);
    useEffect(() => {
        if (!localChanges) return;
        dispatch(
            openModal({
                modalProps: {
                    title: i18next.t('modals:localChanges.title'),
                    description: i18next.t('modals:localChanges.description'),
                    className: role,
                    buttons: [
                        {
                            text: i18next.t(
                                'modals:localChanges.cancel'
                            ) as string,
                            type: 'negative',
                            onClick: () => {
                                clearFromLocalStorage(oppId);
                                dispatch(closeModal());
                            },
                        },
                        {
                            text: i18next.t(
                                'modals:localChanges.confirm'
                            ) as string,
                            type: 'positive',
                            onClick: async () => {
                                const presignedPlanningData =
                                    await replacePlanningImagesWithPresigned(
                                        localChanges
                                    );
                                dispatch(
                                    dispatchAndSaveToLocalStorage(
                                        setLocalState({
                                            data: presignedPlanningData,
                                        })
                                    )
                                );
                                dispatch(initialiseProducts);
                                const canvasIds = Object.keys(
                                    localChanges.planning.canvases
                                );
                                if (canvasIds.length) {
                                    const activeCanvasId = canvasIds[0];
                                    dispatch(
                                        updateUi({
                                            activeCanvasId,
                                        })
                                    );
                                }

                                dispatch(closeModal());
                            },
                        },
                    ],
                    onModalClose: () => dispatch(closeModal()),
                    size: 'large',
                },
            })
        );
    }, [localChanges]);

    const dialogChildren: JSX.Element = (
        <DialogChildren
            templatePlanningData={templatePlanningData}
            permissions={permissions}
            recordTypeOptions={opportunity?.validQuoteRecordTypes || []}
            recordType={recordType}
        />
    );

    return opportunity?.isFetching ? (
        <LoadingScreen />
    ) : (
        <>
            <section className={styles.container}>
                <Grid
                    stackable
                    divided="vertically"
                    className={styles.startPlanningGrids}
                    style={style}
                >
                    <Header variant="h2" className={styles.planningTitle}>
                        {t('startPlanning:title')}
                    </Header>
                    <Prompt
                        onClickAffirm={handleClickAffirm}
                        description=""
                        className={styles.prompt}
                        right
                        dialogChildren={dialogChildren}
                        affirmButtonDisabled={
                            !selectedQuote && !(selectedPhotoIds.length > 0)
                        }
                    >
                        <PlanningOptions
                            permissions={permissions}
                            sdQuotes={sortedQuotes}
                            selectedQuote={selectedQuote}
                            onQuoteClick={handleQuoteClick}
                            onPictureClick={handlePictureClick}
                            selectedPhotoIds={selectedPhotoIds}
                            sortOrder={sortOrder}
                        />
                    </Prompt>
                </Grid>
            </section>
        </>
    );
};

export default StartPlanningWizard;
