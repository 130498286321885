import classNames from 'classnames';
import React, {
    CSSProperties,
    FunctionComponent,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Point } from '../../../common/types';
import Corners from '../Corners';
import styles from './../Geometry.module.scss';

type Props = {
    style?: CSSProperties;
    className?: string;
    defaultValue?: number;
    onChange?: (scale: number) => void;
    offset?: Point;
    environmentState?: { scale: number; offset: Point };
};

function calculateLength(dx: number, dy: number) {
    return Math.sqrt(dx * dx + dy * dy);
}

function calculateScale(
    initialDistance: number,
    point: Point,
    center: Point,
    offset: Point = { x: 0, y: 0 }
) {
    const dx = Math.abs(point.x - center.x - offset.x);
    const dy = Math.abs(point.y - center.y - offset.y);
    const newDistance = calculateLength(dx, dy);

    return newDistance / initialDistance;
}

const ScaleControls: FunctionComponent<Props> = ({
    style,
    className,
    defaultValue = 1,
    onChange,
    offset,
    environmentState = { scale: 1, offset: { x: 0, y: 0 } },
}) => {
    const environmentStateRef = useRef(environmentState);
    environmentStateRef.current = environmentState;

    const [state, setState] = useState<{
        scale: number;
        offset?: Point;
        center?: Point;
        dragStartScale: number;
        dragStartDistance?: number;
        handleCornerPress: React.MouseEventHandler;
        handleMouseMove: (e: MouseEvent) => void;
        handleMouseUp: (e: MouseEvent) => void;
    }>({
        scale: defaultValue,
        dragStartScale: defaultValue,
        offset,
        handleMouseMove: (e: MouseEvent) => {
            const { clientX, clientY } = e;

            setState((prevState) => {
                if (
                    !prevState.center ||
                    prevState.dragStartDistance === undefined
                )
                    return prevState;

                const relativeScale = calculateScale(
                    prevState.dragStartDistance,
                    { x: clientX, y: clientY },
                    prevState.center,
                    prevState.offset
                );

                return {
                    ...prevState,
                    scale: prevState.dragStartScale * relativeScale,
                };
            });
        },
        handleMouseUp: (e: MouseEvent) => {
            window.removeEventListener('mousemove', state.handleMouseMove);
            window.removeEventListener('mouseup', state.handleMouseUp);
            window.removeEventListener('mouseleave', state.handleMouseUp);
        },
        handleCornerPress: (e: React.MouseEvent) => {
            const { clientX, clientY } = e;

            window.addEventListener('mousemove', state.handleMouseMove);
            window.addEventListener('mouseup', state.handleMouseUp);
            window.addEventListener('mouseleave', state.handleMouseUp);

            setState((prevState) => {
                const { center } = prevState || {};
                if (!center) return prevState;

                const offset = prevState.offset || { x: 0, y: 0 };

                const currentDistance = calculateLength(
                    clientX - center.x - offset.x,
                    clientY - center.y - offset.y
                );

                return {
                    ...prevState,
                    dragStartDistance: currentDistance,
                    dragStartScale: prevState.scale,
                };
            });
        },
    });

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!ref.current) return;

        const bounds: DOMRect = ref.current.getBoundingClientRect();

        const x = bounds.x + bounds.width * 0.5;
        const y = bounds.y + bounds.height * 0.5;

        const center = { x, y };

        setState({
            ...state,
            center,
        });
    }, [
        ref,
        offset?.x,
        offset?.y,
        environmentState?.offset.x,
        environmentState?.offset.y,
    ]);

    useEffect(() => {
        onChange && onChange(state.scale);
    }, [state.scale]);

    useEffect(() => {
        if (!state.center) return;
        setState({
            ...state,
            offset,
        });
    }, [offset]);

    return (
        <div
            ref={ref}
            style={style}
            className={classNames(className, styles.scaleControls)}
        >
            {state.center && (
                <Corners
                    onMouseDown={state.handleCornerPress}
                    size={20 / environmentState.scale}
                    overflow={'50%'}
                    cursors={{
                        '00': 'nwse-resize',
                        '01': 'nesw-resize',
                        '11': 'nwse-resize',
                        '10': 'nesw-resize',
                    }}
                />
            )}
        </div>
    );
};

export default ScaleControls;
