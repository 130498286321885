import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppAction, AppActionType, RootState } from '../../common/types';

export const setIsAppInitialised: ActionCreator<AppAction> = (
    isInitialised
) => ({
    type: AppActionType.APP_SET_IS_INITIALISED,
    isInitialised,
});

export const reload: ThunkAction<void, RootState, unknown, AppAction> = () => {
    window.location.reload();
};
