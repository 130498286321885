import React, { CSSProperties, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import Icon from '../Icon';
import styles from './LoadingScreen.module.scss';

type Props = {
    style?: CSSProperties;
};

const LoadingScreen: FunctionComponent<Props> = ({ style }) => {
    const { t } = useTranslation(['common']);
    return (
        <div className={styles.loadingScreen}>
            <Header className={styles.lsHeader}>
                <Icon id="loading" style={{ opacity: 0.7 }} stroke={3} />
                <strong>{t('loadingScreen.1')}</strong>
                &nbsp;{t('loadingScreen.2')}
            </Header>
        </div>
    );
};
export default LoadingScreen;
